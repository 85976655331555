import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TituloTela from '../../../components/TituloTela'
import FormGrupoInput from '../../../components/FormGrupoInput'
import GrupoCards from '../../../components/GrupoCards'
import { FilterContainer } from './styles'
import { Col, Row, DatePicker, Button, notification, Spin } from 'antd'
import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import moment from 'moment';
import ExpandableTable from './ExpandableTable'
import DataTableColumnHeader from './ExpandableTable/DataTableColumnHeader'
import { sortByTime } from './ExpandableTable/helper'

import api from '../../../service/api'
import { useAuth } from '../../../hooks/useAuth'
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { currency } from '../../../utils/currency'

const formatoDataBR = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const baseColumns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Horas apontadas" />,
    cell: ({ row }) => row.original.qtd_hr_apontada,
    title: "Horas apontadas",
    accessorKey: "qtd_hr_apontada",
    widthColumn: 50,
    align: 'right',
    sortingFn: sortByTime
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Horas cobradas na OS" />,
    cell: ({ row }) => row.original.qtd_hr_cobrada_os,
    title: "Horas cobradas na OS",
    accessorKey: "qtd_hr_cobrada_os",
    widthColumn: 50,
    align: 'right',
    sortingFn: sortByTime
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dif. Horas Cobradas X Apontadas" />,
    cell: ({ row }) => {
      const icon = Number(row.original.dif_qtd_sec_cobrada_x_qtd_sec_apontada) >= 0
        ? <FaChevronUp size={12} color="#52c41a" />
        : <FaChevronDown size={12} color="#f5222d" />

      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'flex-end' }}>
          {icon}  {row.original.dif_qtd_hr_cobrada_x_qtd_hr_apontada}
        </div>
      )
    },
    title: "Horas cobradas na OS",
    accessorKey: "dif_qtd_hr_cobrada_x_qtd_hr_apontada",
    widthColumn: 50,
    align: 'right',
    sortingFn: sortByTime
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Valor" />,
    cell: ({ row }) => currency.mask({ value: row.original.valor_total, currency: "BRL", locale: "pt-BR" }),
    title: "Valor",
    accessorKey: "valor_total",
    widthColumn: 75,
    align: 'right',
    sortingFn: sortByTime
  },
]

const columnsOperadores = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Operador" />,
    title: "Nome",
    accessorKey: "nome_operador",
    widthColumn: 300,
  },
  ...baseColumns
]

const columnsApontamentos = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
    title: "ID",
    accessorKey: "id_produto_acabado",
    widthColumn: 50,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Descrição" />,
    title: "Descrição",
    accessorKey: "desc_produto_acabado",
    widthColumn: 300,
  },
  ...baseColumns
]

export const ApontamentosPorOperadorProdutoAcabado = () => {
  const [fetchStatus, setFetchStatus] = useState('idle')
  const [timePeriod, setTimePeriod] = useState({
    from: moment(new Date(), 'YYYY/MM/DD').startOf('month'),
    to: moment(new Date(), 'YYYY/MM/DD'),
  })
  const [data, setData] = useState([])
  const [isFirstQuery, setIsFirstQuery] = useState(true)

  const { empresa } = useAuth()

  const isError = useMemo(() => fetchStatus === 'error', [fetchStatus])
  const isLoading = useMemo(() => fetchStatus === 'fetching', [fetchStatus])

  const handleOnChangeDate = useCallback((dates) => {
    if (isError) {
      setFetchStatus('idle')
    }
    if (dates && Array.isArray(dates) && dates.length === 2) {
      setTimePeriod({
        from: moment(dates[0]).format('YYYY/MM/DD'),
        to: moment(dates[1]).format('YYYY/MM/DD')
      })
    } else {
      setTimePeriod({
        from: moment().startOf('month').format('YYYY/MM/DD'),
        to: moment(new Date()).format('YYYY/MM/DD')
      })
    }
  }, [isError]);

  const getData = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/empresas/${empresa.id}/pcp/apontamento/operador/produto-acabado`,
        {
          params: {
            inicioPeriodo: new Date(timePeriod.from),
            finalPeriodo: new Date(timePeriod.to)
          }
        }
      )

      if (data.response.length === 0) {
        notification.open({
          message: 'Nenhum resultado encontrado',
          description: "Nenhum registro encontrado no período informado"
        });
      }

      setData(data.response)
    } catch (error) {
      notification.open({
        message: 'Erro!',
        description: "Ocorreu um erro ao realizar a busca"
      });
    } finally {
      setFetchStatus('idle')
    }
  }, [empresa.id, timePeriod.from, timePeriod.to])

  const handleFilter = useCallback(async () => {
    setFetchStatus('fetching')
    getData()
  }, [getData])

  useEffect(() => {
    if (fetchStatus === 'idle' && data.length === 0 && isFirstQuery) {
      setIsFirstQuery(false)
      handleFilter()
    }
  }, [data, fetchStatus, handleFilter, isFirstQuery])

  return (
    <div>
      <TituloTela codigoTela="01.77.1475" nomeTela="Apontamentos por Operador por Produto Acabado" />
      <FilterContainer>
        <Row>
          <Col>
            <FormGrupoInput
              label='Período'
              children={
                <RangePicker
                  style={{ width: 250 }}
                  locale={locale}
                  format={formatoDataBR}
                  defaultValue={[
                    moment(new Date(), 'YYYY/MM/DD').startOf('month'),
                    moment(new Date(), 'YYYY/MM/DD')
                  ]}
                  ranges={{
                    "Hoje": [moment(), moment()],
                    "Este mês": [moment().startOf('month'), moment().endOf('month')],
                    "Mês anterior": [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month')
                    ]
                  }}
                  disabled={isLoading}
                  onChange={handleOnChangeDate}
                />
              }
            />
          </Col>
          <Col flex='0 1 60px'>
            <div style={{ padding: '30px 0px 16px 16px' }}>
              <Button type='primary' onClick={() => handleFilter()} disabled={isError || isLoading}>Filtrar</Button>
            </div>
          </Col>

        </Row>
        <Row>
          <Col span={24}>
            <Spin spinning={isLoading} >
              <GrupoCards titulo="Apontamento por Operador - Produto Acabado">
                <ExpandableTable
                  columns={columnsOperadores}
                  detailsColumns={columnsApontamentos}
                  dataSource={data}
                  timePeriod={timePeriod}
                />
              </GrupoCards>
            </Spin>
          </Col>
        </Row>
      </FilterContainer>
    </div>
  )
}