import React from 'react';
import { Gauge } from '@ant-design/charts';

import { Container } from './styles';
import { formatDecimal } from '../../../../utils/format-decimal'

function CardChart({ title, value, value2, color }) {
  const percentual = (value / 100);

  const configGauge = {
    percent: percentual > 0 ? percentual : 0.00000001,
    range: { color: 'l(0) 0:#3B82F6 1:#3730A3' },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -10,
        style: {
          fontSize: '16px',
          color: '#595959',
        },
        formatter: function formatter() {
          return formatPercentualGauge(percentual);
        },
      },
    },
  };

  function formatPercentualGauge(value) {
    const percent = value * 100;

    const [int = 0, decimal = 0] = percent.toString()
      .split('.')
      .map((num) => parseInt(num));

    if (decimal > 0)
      return `${formatDecimal(percent)}%`
    else
      return `${int}%`
  }


  return (
    <>
      <Container color={color}>
        <div className="info" style={{ borderRight: ' 1px solid #e5e5e5' }}>
          <span className="tituloValorMeta">Valor Meta</span>
          <span className="valorMeta">{value2}</span>
        </div>
        <div className="info">
          <span className="tituloGrafico">{title}</span>
          <Gauge {...configGauge} />
        </div>
      </Container>
    </>
  );
}

export default CardChart;
