import React from 'react';

import { Tag } from 'antd';

import { Container } from './style';

function TituloTela({
  codigoTela='00.00.0000', 
  nomeTela='Nome da tela', 
  tema='preto', 
  fontSize='28px', 
  paddingBottom='14px' }) {

  return (
    <Container 
      tema={tema} 
      fontSize={fontSize} 
      paddingBottom={paddingBottom}>

      <Tag color="default">{codigoTela}</Tag>
      {nomeTela}
    </Container>
  );
  
}

export default TituloTela;