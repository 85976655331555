import React from 'react';

import { BiDollar } from "react-icons/bi";
import { GoHome } from "react-icons/go";
import { FaTruck, FaTruckLoading } from "react-icons/fa";
import { AiOutlineCluster, AiOutlineDropbox } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";

export default [
  {
    title: 'Home',
    codigo_acesso: '0',
    link_URL: '/',
    icon: <GoHome />,
    itens_menu: [],
  },
  {
    title: 'Frota',
    codigo_acesso: '1416',
    link_URL: '/frota/dashboard',
    icon: <FaTruck style={{ fontSize: '14px' }} />,
    itens_menu: [
      // {
      //   title: 'Cadastros Pendentes',
      //   codigo_acesso: '',
      //   link_URL: '/cadastrosPendentes'
      // },
      // {
      //   title: 'Itens Manutenção Motorista',
      //   codigo_acesso: '',
      //   link_URL: '/itensManutencaoMotorista'
      // },
      // {
      //   title: 'Itens Manutenção Veículo',
      //   codigo_acesso: '',
      //   link_URL: '/itensManutencaoVeiculo'
      // },
      {
        title: 'Consulta posição dos veículos',
        codigo_acesso: '1369',
        link_URL: '/consulta-posicao-veiculo',
      },
      {
        title: 'Média de combustíveis',
        codigo_acesso: '1418',
        link_URL: '/frota/media-combustiveis',
      },
    ],
  },
  {
    title: 'Financeiro',
    codigo_acesso: '1325',
    link_URL: '/dashboard_financeiro',
    icon: <BiDollar style={{ fontSize: '14px' }} />,
    itens_menu: [
      {
        title: 'DRE Resumido',
        codigo_acesso: '1414',
        link_URL: '/financeiro/dre'
      },
      {
        title: 'DRE Veículos',
        codigo_acesso: '1429',
        link_URL: '/financeiro/dre/veiculos'
      },
      {
        title: 'Aprovar titulos a pagar',
        codigo_acesso: '1370',
        link_URL: '/aprovar-titulos-pagar'
      },
    ],
  },
  {
    title: 'Faturamento',
    codigo_acesso: '1327',
    link_URL: '/dashboard-faturamento',
    codigo_acesso_intercarg: '1405',
    link_URL_intercarg: '/faturamento/dashboard',
    icon: <FiShoppingCart />,
    itens_menu: [
      {
        title: 'Curva ABC Clientes',
        codigo_acesso: '1326',
        link_URL: '/curva-abc-clientes',
        codigo_acesso_intercarg: '1417',
        link_URL_intercarg: '/intercarg/faturamento/curva-abc-clientes',
      },
      {
        title: 'Curva ABC Representantes',
        codigo_acesso: '1328',
        link_URL: '/curva-abc-representantes'
      },
      {
        title: 'Curva ABC Produtos',
        codigo_acesso: '1329',
        link_URL: '/curva-abc-produtos'
      }
      ,
      {
        title: 'Locação',
        codigo_acesso: '1396',
        link_URL: '/faturamento/locacao_equipamentos/dashboard'
      },
    ],
  },
  {
    title: 'PCP',
    codigo_acesso: '',
    link_URL: '*',
    icon: <AiOutlineCluster style={{ fontSize: '12px' }} />,
    itens_menu: [
      {
        title: 'Painel Recursos',
        codigo_acesso: '1330',
        link_URL: '/painel-recursos'
      },
      {
        title: 'Ficha X Requisitado',
        codigo_acesso: '1331',
        link_URL: '/ordem_producao_comparativo'
      },
      {
        title: 'OP Situação',
        codigo_acesso: '1332',
        link_URL: '/ordem_producao_situacao'
      },
      {
        title: 'Apontamentos OP',
        codigo_acesso: '1338',
        link_URL: '/apontamentos'
      },
      {
        title: 'Apontamentos por Operador por Produto Acabado',
        codigo_acesso: '1475',
        link_URL: '/apontamentos-operador-produto-acabado'
      },
      {
        title: 'Apontamentos por Produto Acabado',
        codigo_acesso: '1477',
        link_URL: '/apontamentos-produto-acabado'
      },
    ],
  },
  {
    title: 'Logistica',
    codigo_acesso: '',
    link_URL: '-',
    icon: <FaTruckLoading />,
    itens_menu: [],
  },
  {
    title: 'Estoque',
    codigo_acesso: '',
    link_URL: '_',
    icon: <AiOutlineDropbox style={{ fontSize: '14px' }} />,
    itens_menu: [
      {
        title: 'Aprovação Ordens de Compras',
        codigo_acesso: '1367',
        link_URL: '/aprovacao-ordens-compras'
      },
    ],
  }
];