import React, { useContext } from 'react';

import { SetaDireiraIcon } from  '../../assets/icon'
import { Container } from './styles';

import { Context } from '../../Context/AuthContext';

function CardEmpresa({empresa, openCloseModal}) {
  const { handleSelectedEmpresa } = useContext(Context);

  function handleclickEmpresa(empresa) {
    handleSelectedEmpresa(empresa);
    openCloseModal();
  }

  return (
    <Container onClick={ () => handleclickEmpresa(empresa)  }>
      <span>{empresa.id_empresa_cliente}</span>
      <h3>{empresa.nome_fantasia}</h3>
      <div><SetaDireiraIcon /></div>
    </Container>
  );
}

export default CardEmpresa;