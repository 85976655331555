import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    width: 140px;
    height: auto;
  }

  @media (max-width: 650px) {
    flex-direction:  column;

    img {
      width: 100px;
    }

    div {
      margin-top: 16px;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 20px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);

  span {
    margin-top: 16px;;
  }
`;