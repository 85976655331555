import styled from 'styled-components';

export const ContainerTabela = styled.div`
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  min-width: 0;
  background-color: #fff;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: auto;

  .customers {
  border-collapse: collapse;
  width: 100%;
  }

  .customers td, #customers th {
    padding: 12px;
    font-size: 12px;
    border-top: 1px solid #f1f1f1;
    color: #000;
  }


  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    text-align: left;
    font-size: 12px;
    color: #000;
    font-weight: 600;
  }

`;

export const ContainerPrincipal = styled.div`
  padding: 0 10px 10px 10px;
  background: #F3F4F6;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  min-width: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-top: 3px solid var(--corCard);
  border-radius: 8px;
`;

export const ContainerProdutos = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  min-width: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-top: 3px solid var(--corCard);
  border-radius: 8px;
  overflow: hidden;
`;

export const Informacoes = styled.div`
  position: relative;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1px 1fr;
  padding: 15px 20px;
  width: 100%;

  > .divisor{
    border-right: 1px solid #f1f1f1; 
    height: 100%;
  }


  @media only screen and (max-width: 1024px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
    
    > .divisor{
      border-bottom: 1px solid #f1f1f1; 
    }
  }  
`;

export const InformacoesProduto = styled.div`
  display: grid;
  position: flex;
  grid-template-columns: 300px 300px 300px 300px;
  padding: 4px;
  grid-gap: 50px;
  

  @media only screen and (max-width: 1024px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
    
    > .divisor{
      border-bottom: 1px solid #f1f1f1; 
    }
  }  
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 150px 150px 150px 150px;
  grid-template-rows: auto;
  grid-gap: 20px;
  
  
  > strong {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    padding: 4px;
    color: rgba(0, 0, 0, 0.85);
  }

  > span {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
    color: #000000;
    padding: 4px;
  }
  
  > span.timer {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 12px;
    color: #fff;
    background: var(--corCard);
    border-radius: 4px;
    padding: 3px 7px;
    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
      margin-right: 6px;
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  } 
  
`;