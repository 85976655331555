import React from "react";

import { Input, Radio } from 'antd';
import { CloseOutlined, TableOutlined, AppstoreOutlined } from '@ant-design/icons';

import { Container, WrapperButtons, WrapperInput } from './styles';

export function DataTableToolbar({
  globalFilter,
  onClearGlobalFilter,
  onChangeGlobalFilter,
  onChangeTableStructure,
  tableStructure
}) {
  return (
    <Container>
      <WrapperInput>
        <Input
          value={globalFilter}
          placeholder="Pesquisar..."
          suffix={<CloseOutlined onClick={() => {
            onClearGlobalFilter();
          }} />}
          onChange={ event => onChangeGlobalFilter(event)}
        />
      </WrapperInput>

      <WrapperButtons>
        <Radio.Group value={tableStructure} onChange={(event) => onChangeTableStructure(event.target.value)}>
          <Radio.Button value="list"><TableOutlined /></Radio.Button>
          <Radio.Button value="card"><AppstoreOutlined /></Radio.Button> 
        </Radio.Group>
      </WrapperButtons>
    </Container>
  )
}