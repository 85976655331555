import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch, AiOutlineArrowLeft } from "react-icons/ai";
import { FiCopy } from "react-icons/fi";

import { useParams, Link } from 'react-router-dom'
import { Collapse, Grid, Spin, notification } from 'antd';

import { Container, Content, DataBox, HeaderButton, LoadingContainer, TimelineBox, TimelineItem } from './styles';
import DataItem from '../components/DataItem'
import api from '../../../../service/api';
import { formatDateBrazil } from '../../../../utils/format-date-brazil';
import { formatDecimal } from '../../../../utils/format-decimal';
import { useTrackingFormContext } from '../../../../Context/TrackingFormContext';
import ErrorState from '../components/ErrorState';
import { LoadingScreen } from '../styles';

const INVOICE_INFO_LABELS = {
  numero_nota: "Número",
  serie_nota: "Série",
  numero_pedido: "Número do pedido",
  data_emissao: "Data de emissão",
  peso_bruto: "Peso bruto (kg)",
  valor_nota: "Valor (R$)"
}

function DetalhesRastreio() {
  const [invoiceData, setInvoiceData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  const { 
    getTransporterData, 
    transporterData,
    isLoadingTransporter, 
    isLoadingTransporterError 
  } = useTrackingFormContext()

  const [loadError, setLoadError] = useState(false)

  const [apiNotification, contextHolder] = notification.useNotification()
  const { hash, nfHash } = useParams()
  const { clearForm } = useTrackingFormContext()

  const [collapseIsOpen, setCollapseIsOpen] = useState(false)

  const copyUrlToClipboard = useCallback(async () => {
    try {
      const permission = await navigator.permissions.query({ name: "clipboard-write" })

      if (permission.state === "denied") {
        throw new Error()
      }

      await navigator.clipboard.writeText(window.location.href)

      apiNotification.success({
        message: `Sucesso!`,
        description: "Texto copiado para a área de transferêcia",
        placement: 'topRight',
        duration: 5,
      })
    } catch (error) {
      apiNotification.error({
        message: `Erro!`,
        description: "Não foi possível copiar. Verifique as permissões do navegador e tente novamente.",
        placement: 'topRight',
        duration: 5,
      })
    }
  }, [apiNotification])

  const getInvoiceData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/nota-fiscal/${nfHash}/detalhes`)
      setInvoiceData(data.body)
    } catch (error) {
      setLoadError(true)
    } finally {
      setIsLoading(false)
    }
  }, [hash, nfHash])

  useEffect(() => {
    // tratativa para evitar que o React use setState com o componente desmontado
    let isMounted = true;

    if (isMounted && !transporterData) {
      getTransporterData(hash);
    }

    return () => {
      isMounted = false
    }
  }, [getTransporterData, hash, transporterData])

  useEffect(() => {
    if (!!transporterData) getInvoiceData()

  }, [getInvoiceData, transporterData])

  useEffect(() => {
    window.scroll({ top: 0, left: 0 })
  }, [])

  if (isLoadingTransporter) return <LoadingScreen><Spin size='large' /></LoadingScreen>

  return isLoadingTransporterError || loadError
    ? <ErrorState />
    : <Container>
      {contextHolder}
      <header>
        <div className='header-content'>
          <div className='header-title'>
            <div className='header-title-container'>
              <HeaderButton>
                <Link
                  to={{ pathname: `/logistica/rastreamento-transportadora/${hash}/` }}
                >
                  <AiOutlineArrowLeft />Voltar
                </Link>
              </HeaderButton>
              <h3>Detalhes de Rastreamento</h3>
              <HeaderButton
              >
                <Link
                  to={{ pathname: `/logistica/rastreamento-transportadora/${hash}/` }}
                  onClick={() => {
                    clearForm()
                  }}
                >
                  <AiOutlineSearch />Nova pesquisa
                </Link>
              </HeaderButton>
            </div>
          </div>

          {!!transporterData?.razao_social && (
            <div className='header-transporter-container'>
              <div className="infos">
                <span>Transportadora</span>
                <span>{transporterData?.razao_social}</span>
              </div>

              <div className="copy-link">
                <button
                  type="button"
                  onClick={copyUrlToClipboard}
                >
                  <FiCopy /> Copiar link de rastreio da nota
                </button>
              </div>
            </div>
          )}
        </div>
      </header>

      {(isLoading && !invoiceData) && (
        <LoadingContainer>
          <Spin size='large' ></Spin>
        </LoadingContainer>
      )}
      {(!isLoading && !!invoiceData) && (
        <Content>
          {screens.md ? (
            <DataBox key={"desktopView"}>
              <div className='invoice-info'>
                <h4>Nota</h4>
                {Object.entries(invoiceData).map(([labelKey, value]) => {
                  if (typeof value !== "object" && labelKey !== 'peso_bruto' && labelKey !== 'valor_nota') {
                    return (<DataItem key={labelKey} label={INVOICE_INFO_LABELS[labelKey]} record={value} />)
                  }
                })}
              </div>
              <div className='sender-info'>
                <h4>Remetente</h4>
                <DataItem label="Nome" record={invoiceData.remetente.nome} />
              </div>
              <div className='recipient-info'>
                <h4>Destinatário</h4>
                <DataItem label="Nome" record={invoiceData.destinatario.nome} />
              </div>
            </DataBox>
          ) : (
            <Collapse onChange={(ev) => { setCollapseIsOpen(!!ev.length) }} key={"mobileView"}>
              <Collapse.Panel header={`Clique para ${collapseIsOpen ? "fechar" : "expandir"} os dados da NF-e`}>
                <DataBox>
                  <div className='invoice-info'>
                    <h4>Nota</h4>
                    {Object.entries(invoiceData).map(([labelKey, value]) => {
                      if (labelKey === 'peso_bruto' || labelKey === 'valor_nota') {
                        value = formatDecimal(value)
                      }

                      if (typeof value === "object") return <></>

                      return (<DataItem key={labelKey} label={INVOICE_INFO_LABELS[labelKey]} record={value} />)
                    })}
                  </div>
                  <div className='sender-info'>
                    <h4>Remetente</h4>
                    <DataItem label="Nome" record={invoiceData.remetente.nome} />
                  </div>
                  <div className='recipient-info'>
                    <h4>Destinatário</h4>
                    <DataItem label="Nome" record={invoiceData.destinatario.nome} />
                  </div>
                </DataBox>
              </Collapse.Panel>
            </Collapse>
          )}
          <section>
            <h3>Ocorrências</h3>
            <TimelineBox>
              {invoiceData.ocorrencias.map((item, index) => {
                return <TimelineItem key={`${item.codigo}-${index}`}>
                  <p className='item-title'>{item.descricao}</p>
                  <div className='item-infos'>
                    <div className='item-info'>
                      <div className='item-data-label'>Data</div>
                      <div className='item-data-value'>{formatDateBrazil(item.data, 'dd/MM/yyyy')}</div>
                    </div>
                    <div className='item-info'>
                      <div className='item-data-label'>Hora</div>
                      <div className='item-data-value'>{item.hora}</div>
                    </div>
                  </div>
                </TimelineItem>
              })}
            </TimelineBox>
          </section>
        </Content>
      )}

      <footer>
        <p>
          Desenvolvido por: {' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href='http://centrodata.com.br/'
          >
            Centrodata Sistemas
          </a>
        </p>
      </footer>
    </Container>;
}

export default DetalhesRastreio;