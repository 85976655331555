import React, { useContext, useEffect, useState,  } from 'react';
import {Context} from '../Context/AuthContext'
import api from '../service/api';
import { Typography, Button  } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; 

import { Table } from 'antd';

import ConvertJsonToCSV from '../utils/convertJsonToCSV';

const { Title } = Typography;

const tituloPagina = {
  motorista: 'Motorista',
  veiculosOutros: 'Outros Veículos',
  veiculosTrator: 'Veículos Trator',
  operacaoDeslocamentoVazio: 'Operação Deslocamento Vazio', 
  operacoesNormais: 'Operações Normais'
}

const DestalheCadastroPedente = (props) => {
  const [detalhesCadastros, setDetalhesCadastros] = useState([]);
  const [estruturaColunas, setEstruturaColunas] = useState([]);
  const [dadosCVS, setDadosCVS] = useState([]);
  const { empresa } = useContext(Context);
  const tipoCadastro = props.location.state;
  
  useEffect(() => {
    async function ConsultaDestalhesCadastros() {
      const response = await api.get(`intercarg/cadastros_pendentes/${empresa.id}/${tipoCadastro}`);
      setDadosCVS(response.data);
      
      const data = response.data.map( (registro, index) => { 
        return {
          key: `${index.toString()}cadastro`,
          ...registro
        }
      });
      
      setDetalhesCadastros(data);
      
      const colunas = [];
      const estruturaRegistro = data[0];
      
      for (var prop in estruturaRegistro){
        if (estruturaRegistro.hasOwnProperty(prop)) {
          if( prop !== 'key'){
            colunas.push({
              title: prop,
              dataIndex: prop,
              width: 150,  
            });
          };
        }
        
      };
      setEstruturaColunas(colunas)
    };

    ConsultaDestalhesCadastros();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa]);

  function handleConvetSCV () {
    const nomeColunaCSV = {};

    const estruturaRegistro = dadosCVS[0];

    for (var prop in estruturaRegistro){
      if (estruturaRegistro.hasOwnProperty(prop)) {
        let field = prop;
        nomeColunaCSV[field] = field.split('_').join(' ').toUpperCase(); 
      }     
    };

    ConvertJsonToCSV( nomeColunaCSV, dadosCVS, 'arquivo');
  }

  return (
    <>
      <Title level={3}>{`Cadastros Pendentes de ${tituloPagina[tipoCadastro]}`}</Title>
      <div>
        <Table
          scroll={{y: 'calc(100vh - 400px)', x: 500}}
          columns={estruturaColunas} 
          size={'middle'}
          dataSource={detalhesCadastros} pagination={{ pageSize: 20 }} />
      </div> 
      <Button type="primary" onClick={handleConvetSCV} icon={<DownloadOutlined />}>Baixar CSV</Button>
      </>
  );
}

export default DestalheCadastroPedente;