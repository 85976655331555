import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 18px;
  background: #F3F4F6;
  height: 100%;
`;

export const Header = styled.header`
   display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #fff;
  border-radius: 4px;
  padding: 24px;

  strong {
    font-size: 14px;
    margin-right: 16px;
  }
  
  span {
    font-size: 14px;
  }
`;

export const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 16px;
  overflow: auto;
  height: 100%;
  background: #fff;
  border-radius: 4px;


  .ant-table-thead > tr > th {
    background-color: #fff;
    padding: 12px 0px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 13px;;
    line-height: 1.5rem;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(0, 0, 0, 0.04);
}
`;