import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  p {
    font-size: 16px;
    line-height: 24px;
    color: #B6BFCC;

    strong {
      color: #dc2626;
    }
  }
`;