import React from 'react';

import { Container } from './styles';
import { Spin } from 'antd';

export default function Loading({isLoading}) {
  if(!isLoading)
    return null;
    
  return (
    <Container>
      <Spin size='large' />
    </Container>
  );
}
