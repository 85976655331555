import React, { useState } from 'react';
import './auth.css';
import imageLogin from '../../assets/img-login.svg';
import warningIcon from '../../assets/icon/warning.svg';
import { LoadingOutlined } from '@ant-design/icons';

import { useAuth } from '../../hooks/useAuth';

const Auth = () => {
  const { signIn, authenticationErrors, isAuthenticating } = useAuth(); 

  const [ email, setEmail] = useState('');
  const [ senha, setSenha ] = useState('');

  async function handleSubmitLogin(e) {
    e.preventDefault();
    await signIn({email, senha});
  };

  return (
    <div className='login'>
        <div className='login__content'>
            <div className='login__img'>
                <img src={imageLogin} alt='Login'/>
            </div>

            <div className='login__forms'>
                <form onSubmit={handleSubmitLogin} className='login__registre' id='login-in'>
                    <h1 className='login__title'>Login Centrodata</h1>
       
                    <div className='login__box'>
                        <i className='bx bx-user login__icon'></i>
                        <input autoFocus type='text' value={email} onChange={e => setEmail(e.target.value)} placeholder='E-mail' className='login__input'/>
                    </div>

                    <div className='login__box'>
                        <i className='bx bx-lock-alt login__icon'></i>
                        <input type='password'  value={senha} onChange={e => setSenha(e.target.value)} placeholder='Senha' className='login__input'/>
                    </div>

                    {authenticationErrors &&
                      <div className='login__error'>
                        <div className='login__image-erro'>
                          <img src={warningIcon} alt='Icone Alerta'/>
                        </div>
                        
                        <div className='login__list-error'>
                          { authenticationErrors.map( (erro, index) => {
                            console.log(erro);
                          return (<span key={index}>{erro.message}</span>)
                          })}
                        </div>
                      </div>
                    }
                 
                    <button
                      style={ isAuthenticating ? { cursor: 'not-allowed' } : {}}
                      type='submit' 
                      className="login__button"
                      disabled={ isAuthenticating }
                    >
                      { isAuthenticating ? <LoadingOutlined /> : 'Acessar'}
                    </button>
                </form>
            </div>
        </div>
    </div>
  )
};

export default Auth;
