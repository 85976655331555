import React, { useState, useEffect, useContext } from 'react';

import { Container, Header, WrapperTable } from './styles';

import { Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { formatDecimal } from '../../../utils/format-decimal';
import { round } from '../../../utils/round';

import { Context } from '../../../Context/AuthContext';
import api from '../../../service/api';

import moment from 'moment'

const coresClasse = {
  a: '#1cc88a',
  b: '#FCD34D',
  c: '#e74a3b',
}

const colunasTabelaQuantidade = [
  {
    title: 'ID',
    dataIndex: 'id_cliente',
    key: 'id_cliente',
    width: 100,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'nome_cliente',
    key: 'nome_cliente',
    width: 500,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Quantidade Total Vendas',
    dataIndex: 'quantidade_total_liquida',
    key: 'quantidade_total_liquida',
    width: 200,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: '% Vendas',
    dataIndex: 'perc_quatidade_vendas',
    key: 'perc_quatidade_vendas',
    width: 150,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: '% Acumulado',
    dataIndex: 'perc_quantidade_acumulada',
    key: 'perc_quantidade_acumulada',
    width: 140,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Classe',
    dataIndex: 'classe',
    key: 'classe',
    align: 'center',
    width: 70,
    isVisible: true,
    isSearch: true,
    render: value => (
      <Text
        style={{ color: coresClasse[value.toLowerCase()] }}
        strong
      >
        {value}
      </Text>)
  },
  {
    title: 'Ordem',
    dataIndex: 'ordem',
    key: 'ordem',
    width: 70,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
];

const colunasTabelaValor = [
  {
    title: 'ID',
    dataIndex: 'id_cliente',
    key: 'id_cliente',
    width: 100,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'nome_cliente',
    key: 'nome_cliente',
    width: 500,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Valor Total Vendas',
    dataIndex: 'valor_total_liquido',
    key: 'valor_total_liquido',
    width: 200,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: '% Vendas',
    dataIndex: 'perc_valor_vendas',
    key: 'perc_valor_vendas',
    width: 150,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: '% Acumulado',
    dataIndex: 'perc_valor_acumulado',
    key: 'perc_valor_acumulado',
    width: 140,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Classe',
    dataIndex: 'classe',
    key: 'classe',
    align: 'center',
    width: 70,
    isVisible: true,
    isSearch: true,
    render: value => (
      <Text
        style={{ color: coresClasse[value.toLowerCase()] }}
        strong
      >
        {value}
      </Text>)
  },
  {
    title: 'Ordem',
    dataIndex: 'ordem',
    key: 'ordem',
    width: 70,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
];

function ListaClintes({ produto, periodo, close }) {
  const [isLoading, setIsLoading] = useState(true);
  const [listaClientes, setListaClientes] = useState([]);
  const [colunasTabela, setColunasTabela] = useState(colunasTabelaValor)
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function getListaClientes() {
      try {
        setIsLoading(true);

        const { data } = await api.get(
          `empresas/${empresa.id}/faturamento/curva-abc-produtos/${produto.id_produto}/detalhes`,
          {
            params: {
              dataInicio: moment(periodo.dataInicial).format('YYYY/MM/DD'),
              dataFim: moment(periodo.dataFinal).format('YYYY/MM/DD'),
              tipo: periodo.tipo
            }
          }
        );

        if (periodo.tipo === 'valor') {
          const clientes = data.response.map((cliente, key) => (
            {
              ...cliente,
              key: `cli${key}`,
              valor_total_liquido: formatDecimal(cliente.valor_total_liquido),
              perc_valor_vendas: formatDecimal(round(cliente.perc_valor_vendas, 2)),
              perc_valor_acumulado: formatDecimal(round(cliente.perc_valor_acumulado, 2)),
            }
          ));

          setColunasTabela(colunasTabelaValor);
          setListaClientes(clientes);
        } else {
          const clientes = data.response.map((cliente, key) => (
            {
              ...cliente,
              key: `cli${key}`,
              quantidade_total_liquida: formatDecimal(cliente.quantidade_total_liquida, 3),
              perc_quatidade_vendas: formatDecimal(round(cliente.perc_quatidade_vendas, 2)),
              perc_quantidade_acumulada: formatDecimal(round(cliente.perc_quantidade_acumulada, 2)),
            }
          ));

          setColunasTabela(colunasTabelaQuantidade);
          setListaClientes(clientes);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }

    }

    getListaClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Header>
        <strong>Produto: </strong>
        <span>{produto.id_produto} - {produto.desc_produto}</span>
      </Header>


      <WrapperTable>
        <Table
          loading={isLoading}
          columns={colunasTabela}
          dataSource={listaClientes}
          size='small'
          scroll={{ x: '100%', y: 550 }}
          pagination={{
            size: 'small',
            defaultPageSize: 20,
          }}
        />
      </WrapperTable>
    </Container>);
}

export default ListaClintes;
