import React, { useState, useContext, useEffect, useMemo } from 'react';

import Text from 'antd/lib/typography/Text';
import { Button, Col, DatePicker, Row, Spin, Alert } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { ContainerFiltro } from './styles';
import FormGrupoInput from '../../components/FormGrupoInput';
import TabelaPersonalizada from '../../components/TabelaPersonalizada';
import Modal from '../../components/Modal';

import { Context } from '../../Context/AuthContext';

import { FORMAT_DATE_BR } from '../../constants';
import { round } from '../../utils/round';
import { formatDecimal } from '../../utils/format-decimal';

import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';

import api from '../../service/api';
import ListaProdutos from './ListaProdutos';
import FormModal from '../../components/FormModal';
import TituloTela from '../../components/TituloTela';

moment.locale('pt-br');

const coresClasse = {
  a: '#1cc88a',
  b: '#FCD34D',
  c: '#e74a3b',
}

function CurvaABCClientes() {
  const [loading, setLoading] = useState(false);
  const [curvaABC, setCurvaABC] = useState([]);
  const [dataInicial, setDataInicio] = useState(moment());
  const [dataFinal, setDataFinal] = useState(moment());
  const [clienteListaProdutos, setClienteListaProdutos] = useState(null);
  const [erros, setErros] = useState();
  const { empresa } = useContext(Context);

  useEffect(() => {
    getCurvaABCClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa]);

  async function getCurvaABCClientes() {
    try {
      setLoading(true);

      const { data } = await api.get(
        `/empresas/${empresa.id}/faturamento/curva-abc-clientes`,
        {
          params: {
            dataInicio: moment(dataInicial).format('YYYY/MM/DD'),
            dataFim: moment(dataFinal).format('YYYY/MM/DD'),
          }
        });

      const dataFormated = data.response.map((item, key) => ({
        ...item,
        key: `cli${key}`,
        valor_total_descontos: formatDecimal(item.valor_total_descontos),
        vlr_total_acrescimos: formatDecimal(item.vlr_total_acrescimos),
        vlr_devolvido: formatDecimal(item.vlr_devolvido),
        vlr_total: formatDecimal(item.vlr_total),
        vlr_total_liquido: formatDecimal(item.vlr_total_liquido),
        soma_geral: formatDecimal(item.soma_geral),
        perc_vendas: formatDecimal(round(item.perc_vendas, 2)),
        perc_acumulado: formatDecimal(round(item.perc_acumulado, 2)),
      }))

      setCurvaABC(dataFormated);
    } catch (e) {
      if (e || e.response.data.statusCode >= 500) {
        const erro = 'Erro 500, servidor não respondendo contate o suporte!';
        setErros(erro);
      }
      else {
        const erro = e.response.data.errors[0].message;
        setErros(erro);
      }
      setCurvaABC([]);
    } finally {
      setLoading(false)
    }
  }

  const colunasTabela = useMemo(() => (
    [
      {
        title: 'ID Cliente',
        dataIndex: 'id_cliente',
        key: 'id_cliente',
        width: 100,
        align: 'right',
        isVisible: true,
        isSearch: true,
      },
      {
        title: 'Nome Cliente',
        dataIndex: 'nome_cliente',
        key: 'nome_cliente',
        width: 500,
        isVisible: true,
        isSearch: true,
      },
      {
        title: 'Valor Total Vendas',
        dataIndex: 'vlr_total_liquido',
        key: 'vlr_total_liquido',
        width: 200,
        align: 'right',
        isVisible: true,
        isSearch: true,
      },
      {
        title: '% Vendas',
        dataIndex: 'perc_vendas',
        key: 'perc_vendas',
        width: 200,
        align: 'right',
        isVisible: true,
        isSearch: true,
      },
      {
        title: '% Acumulado',
        dataIndex: 'perc_acumulado',
        key: 'perc_acumulado',
        width: 200,
        align: 'right',
        isVisible: true,
        isSearch: true,
      },
      {
        title: 'Classe',
        dataIndex: 'classe',
        key: 'classe',
        align: 'center',
        width: 70,
        isVisible: true,
        isSearch: true,
        render: value => (
          <Text
            style={{ color: coresClasse[value.toLowerCase()] }}
            strong
          >
            {value}
          </Text>)
      },
      {
        title: 'Ordem',
        dataIndex: 'ordem',
        key: 'ordem',
        width: 70,
        align: 'right',
        isVisible: true,
        isSearch: true,
      },
      {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'ordeacoesm',
        width: 100,
        align: 'center',
        isVisible: true,
        isSearch: true,
        render: (_, record) => (
          <Button
            icon={<SearchOutlined />}
            onClick={() => { handleClickOpenListaProdutos(record) }}
          />),
      }
    ]
  ), []);

  function handleOnClickFiltro() {
    getCurvaABCClientes();
  }

  function handleOnChangeDataInicio(date) {
    setDataInicio(date);
  }

  function handleOnChangeDataFinal(date) {
    setDataFinal(date);
  }

  function handleClickOpenListaProdutos(cliente) {
    setClienteListaProdutos(cliente);
  }

  function handleClickCloseListaProdutos() {
    setClienteListaProdutos(null);
  }

  const title = {
    codigoTela: '01.76.1340',
    nomeTela: 'Relação de produtos por clientes',
    tema: 'white',
    fontSize: 'medium',
    paddingBottom: 'without',
  }

  return (
    <>
      <TituloTela codigoTela="01.76.1326" nomeTela="Curva ABC Clientes" />

      {clienteListaProdutos &&
        <Modal openCloseModal={handleClickCloseListaProdutos}>
          <FormModal
            title={title}
            close={handleClickCloseListaProdutos}
          >
            <ListaProdutos
              cliente={clienteListaProdutos}
              periodo={{ dataInicial, dataFinal }}
              close={handleClickCloseListaProdutos}
            />
          </FormModal>
        </Modal>
      }

      <ContainerFiltro>
        <Row>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Data Inicio'
              children={
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE_BR}
                  defaultValue={dataInicial}
                  placeholder='Selecione Data'
                  onChange={date => handleOnChangeDataInicio(date)}
                />
              }
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Data Final'
              children={
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE_BR}
                  defaultValue={dataFinal}
                  placeholder='Selecione Data'
                  onChange={date => handleOnChangeDataFinal(date)}
                />
              }
            />
          </Col>

          <Col flex='0 1 60px'>
            <div style={{ padding: '28px 0px 16px 16px' }}>
              <Button
                type='primary'
                onClick={handleOnClickFiltro}>Filtrar</Button>
            </div>
          </Col>
          <Col md={24} lg={24} xs={24} sm={24} xl={24}>
            {erros &&

              <Alert

                closeText="Fechar"
                message={erros}
                showIcon
                type="error"
                action={
                  <Button size="small" danger show={true}>
                    Detalhes
                  </Button>
                }
              />
            }
          </Col>
        </Row>

      </ContainerFiltro>
      {loading
        ?
        <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
          <Spin size="large" />
        </Row>
        :
        <Row>
          <TabelaPersonalizada
            titulo='Curva ABC Clientes'
            columns={colunasTabela}
            dataSource={curvaABC}
            configTabela={{
              size: 'small',
              scroll: { x: 'auto' },
              pagination: {
                size: 'small',
                defaultPageSize: 20,
              }
            }}
          />
        </Row>}
    </>
  );
}

export default CurvaABCClientes;