import styled from 'styled-components';

const colors = {
  green: {
    200: '#A7F3D0',
    400: '#34D399',
  },
  blue: {
    200: '#BFDBFE',
    400: '#60A5FA',
  },
  purple: {
    200: '#DDD6FE',
    400: '#A78BFA',
  }
}

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 110px;
  max-height: 110px;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  background-color: ${({ color }) => colors[color][400]};
  overflow: hidden;

  &::after {
    position: absolute;
    top: -50px;
    right: -40px;
    content: '';
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 0.2;
    background-color: ${({ color }) => colors[color][200]};
  }

  &::before {
    position: absolute;
    top: 50px;
    right: 5px;
    content: '';
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 0.2;
    background-color: ${({ color }) => colors[color][200]};
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    padding: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => colors[color][200]};
    color:  ${({ color }) => colors[color][400]};

    svg {
      width: 80%;
      height: auto;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    
    span:nth-child(1) {
      font-size: 18px;
    }
    
    span:nth-child(2) {
      font-weight: 500;
      font-size: 16px;
    }

    span:nth-child(3) {
      font-weight: 500;
      font-size: 16px;
    }

    label {
      font-size: 16px;
    }

  }

`;
