import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500; 
  padding-bottom: ${({ paddingBottom }) => paddingBottom === 'without' ? '0' : '14px'};
  font-size: ${({ fontSize }) => fontSize === 'medium' ? '18px' : '24px'};
  color: ${({ tema }) => tema === 'white' ? 'white' : 'rgba(0, 0, 0, 0.75)'};

  // span {
  //   margin-right: 5px;
  //   font-size: ${({ fontSize }) => fontSize === 'medium' ? '15px' : '18px'};
  //   color: ${({ tema }) => tema === 'white' ? 'white' : 'rgba(0, 0, 0, 0.75)'};
  // }

  @media screen and (max-width: 768px) {
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    font-size: 14px;

    // span {
    //   font-size: 14px;
    // }
  }
`;

