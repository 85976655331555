import React, { useEffect, useState, useContext, useCallback } from 'react'
import Loader from '../../../components/Loader';
import GrupoCards from '../../../components/GrupoCards';
import { Column as ChartsColumn } from '@ant-design/charts';
import { Row, Col, Select, Button, Alert } from 'antd';
import { Grafico, GraficoMensal } from './styles'

import api from '../../../service/api';
import { formatDecimal } from '../../../utils/format-decimal';
import { round } from '../../../utils/round';
import { Context } from '../../../Context/AuthContext';
import { SyncOutlined } from '@ant-design/icons';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { BiDollar } from 'react-icons/bi';
import { IoPeopleSharp } from 'react-icons/io5';

import Card from './Card';
import CardChart from './CardChart';
import CardValorVendas from './CardValorVendas';
import MetasRepresentantes from './MetasRepresentantes';
import FaturamentoDiario from './FaturamentoDiario';

import { MESES_EXTENSO } from '../../../constants';

import TituloTela from '../../../components/TituloTela';

const { Option } = Select;

const mesAtual = new Date().getMonth();
const anoAtual = new Date().getFullYear();

const initialState = {
  dadosFaturamento: [],
  ticketMedio: formatDecimal(0),
  numeroVendas: 0,
  metaGeral: 0,
  representantes: [],
  valorFaturamento: 0,
  faturamentoDiario: [],
}

function Dashboard() {
  const [opcoesAno, setOpcoesAno] = useState([anoAtual])
  const [dadosDashboard, setDadosFaturamento] = useState(initialState);
  const [mesSelecionado, setMesSelecionado] = useState(mesAtual);
  const [loading, setLoading] = useState(false);
  const [erros, setErros] = useState();
  const [anoSelecionado, setAnoSelecionado] = useState(anoAtual);
  const { empresa } = useContext(Context);

  const configCharts = {
    data: dadosDashboard.dadosFaturamento,
    isGroup: true,
    xField: 'mes',
    yField: 'valor',
    seriesField: 'ano',
    label: null,
    legend: {
      position: 'right',
    },
    tooltip: {
      formatter: (date) => (
        { name: date.ano, value: formatDecimal(date.valor) })
    }
  };

  const loadDataDashboard = useCallback(async (periodo) => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/empresas/${empresa.id}/faturamento/dashboard`, {
        params: { dataPeriodo: periodo },
      }
      );

      const {
        faturamentoUltimo24Meses,
        valorMeta,
        quantidadeVandas,
        tiquetiMedio,
        valorLiquido,
        metasRepresentantes,
        faturamentoDiario,
        quantidadeClientes,
        valorTiqueteMedioCliente,
      } = data.response

      const faturamentoUltimo24MesesFormatado = faturamentoUltimo24Meses.map(item => ({
        periodo: item.periodo,
        mes: item.mes,
        ano: String(item.ano),
        valor: parseFloat(item.valor)
      }));

      const percMetaGeral =
        (Number(valorLiquido) === 0 || Number(valorMeta) === 0)
          ? 0
          : (valorLiquido / valorMeta) * 100;


      setDadosFaturamento({
        dadosFaturamento: faturamentoUltimo24MesesFormatado,
        ticketMedio: formatDecimal(tiquetiMedio),
        numeroVendas: quantidadeVandas,
        metaGeral: percMetaGeral ? round(percMetaGeral) : 0,
        valorMeta: formatDecimal(valorMeta),
        quantidadeClientes: quantidadeClientes,
        valorTiqueteMedioCliente: formatDecimal(valorTiqueteMedioCliente),
        valorFaturamento: formatDecimal(valorLiquido),
        representantes: metasRepresentantes,
        faturamentoDiario: faturamentoDiario,
      });
      setLoading(false);
    } catch (error) {
      setDadosFaturamento(initialState);
      setLoading(false);

    }

  }, [empresa.id]);

  function handleAtualuzarDados() {
    const mesFormated = mesSelecionado + 1 < 10 ? `0${mesSelecionado + 1}` : mesSelecionado + 1;
    const perido = `${anoSelecionado}-${mesFormated}-01`
    loadDataDashboard(perido);
  }

  function handleMesChange(value) {
    setMesSelecionado(value);
  }

  function handleAnoChange(value) {
    setAnoSelecionado(value);
  }

  useEffect(() => {
    const mesFormated = mesSelecionado + 1 < 10 ? `0${mesSelecionado + 1}` : mesSelecionado + 1;
    const perido = `${anoSelecionado}-${mesFormated}-01`
    loadDataDashboard(perido);
  }, [empresa, mesSelecionado, anoSelecionado, loadDataDashboard]);

  useEffect(() => {
    try {
      async function getAnoPrimeiraVenda() {
        const { data } = await api.get(
          `/empresas/${empresa.id}/faturamento/ano-primeira-venda`,
          {
            params: { anoPrimeiraVenda: true },
          }
        );

        if (data.response.ano) {
          let anos = [];
          let anoPrimeiraVenda = Number(data.response.ano);

          while (anoPrimeiraVenda <= anoAtual) {
            anos.push(anoPrimeiraVenda);
            anoPrimeiraVenda++;
          }

          anos.sort(function (a, b) { return b - a });

          setOpcoesAno(anos);
        }
      }

      getAnoPrimeiraVenda();

    } catch (error) {
    }
  }, [empresa]);

  return (
    <>
      <Loader isLoading={loading} />

      <TituloTela codigoTela="01.76.1327" nomeTela="Dashboard Faturamento" />

      <Row>
        <Col>
          <Select
            defaultValue={`${MESES_EXTENSO[mesAtual]}`}
            style={{ width: 120, marginBottom: '18px', marginRight: '8px' }}
            onChange={handleMesChange}
          >
            {MESES_EXTENSO.map((mes, index) => (
              <Option
                key={mes}
                value={index}
              >
                {mes}
              </Option>))}
          </Select>
          <Select
            defaultValue={`${anoAtual}`}
            style={{ width: 120, marginBottom: '18px', marginRight: '8px' }}
            onChange={handleAnoChange}
          >
            {opcoesAno.map((ano, index) => (
              <Option
                key={ano}
                value={ano}
              >
                {ano}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={handleAtualuzarDados}
          />
        </Col>
        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          {erros &&

            <Alert

              closeText="Fechar"
              message={erros}
              showIcon
              type="error"
              action={
                <Button size="small" danger show={true}>
                  Detalhes
                </Button>
              }
            />
          }
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col xs={24} md={12} lg={8} xl={6}>
          <Card
            title='Faturamento'
            value={dadosDashboard.valorFaturamento}
            icon={<BiDollar />}
            color='green'
          />
        </Col>

        <Col xs={24} md={12} lg={8} xl={6}>
          <CardValorVendas
            title='Ticket médio vendas'
            label='Vendas'
            value={dadosDashboard.numeroVendas}
            color='purple'
            icon={<HiOutlineShoppingCart />}
            label2='Ticket médio'
            value2={dadosDashboard.ticketMedio}
          />
        </Col>

        <Col xs={24} md={12} lg={8} xl={6}>
          <CardValorVendas
            title='Ticket médio clientes'
            label='Clientes'
            value={dadosDashboard.quantidadeClientes}
            color='blue'
            icon={<IoPeopleSharp />}
            label2='Ticket médio'
            value2={dadosDashboard.valorTiqueteMedioCliente}
          />
        </Col>

        <Col xs={24} md={12} lg={8} xl={6}>
          <CardChart
            title='Meta'
            value={dadosDashboard.metaGeral}
            value2={dadosDashboard.valorMeta}
            color='gray'
          />
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xs={24} sm={24} xl={12}>
          <GrupoCards titulo='Faturamento mensal últimos 24 meses'>
            <GraficoMensal>
              <ChartsColumn {...configCharts} />
            </GraficoMensal>
          </GrupoCards>
        </Col>
        <Col md={24} lg={24} sm={24} xs={24} xl={12}>
          <GrupoCards titulo='Metas por representantes'>
            <Grafico>
              <MetasRepresentantes representantes={dadosDashboard.representantes} />
            </Grafico>
          </GrupoCards>
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} sm={24} xl={24} xs={24}>
          <GrupoCards titulo='Faturamento Diário'>
            <Grafico>
              <FaturamentoDiario faturamentoDiario={dadosDashboard.faturamentoDiario} />
            </Grafico>
          </GrupoCards>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard;