import styled from 'styled-components';

export const Cabecalho = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 20px;
  margin-bottom: 10px;

  label {
    color: #000;
  }

  span {
    color: #000;
  }

  h3 {
    font-size: 14px;
    color: #000;
  }

  @media (min-width: 320px) and (max-width: 560px) {
      display: inline;
    }
`;

export const Container = styled.div`
  margin-bottom: 16px;
  border-radius: 5px;
  background: #fff; 
  overflow-x: auto;
  overflow-y: auto;
  margin: 10px;
  box-shadow: 0 0 1em #a5a5a5;
  height: auto;

  .customers {
  border-collapse: collapse;
  width: 100%;
  margin-top: 8px;
  }

  .customers td, #customers th {
    padding: 12px;
    padding-left: 18px;
    font-size: 12px;
    border-top: 1px solid #f1f1f1;
    color: #888888;
  }
  
  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 18px;
    text-align: left;
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  span {
    font-size: 18px;
    padding: 18px;
  }
`;

export const InfoPedido = styled.div`
    background: #FFF;
    margin-top: -30px;

    width: 100%;
    padding: 2px 11px;
    margin-bottom: 10px;
    


    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(#F0EFEF ,#1E88E5) 1;
    
    h1 {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #000;
    }

    .grupoTitulos {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .grupoLabelEsquerda {
      align-items: left;
      text-align: left;
    }

    .grupoSpandireita {
      align-items: right;
      text-align: right;
    }

    .grupoTituloInfo {
      display: flex;
      flex-direction: column;

      label {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
      }

      span {
        font-weight: 500;
        color: #000;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .containerAprovar {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;  

        .detalhe {
          text-align: center;
          align-items: center;
          color: #000;
          display: flex;
          flex-direction: row;

            span {
            font-weight: 700;
            font-family: "Roboto";
            text-decoration:  underline;
            color: #000;
            font-size: 14px;
            }

            img {
              width: 18px;
              margin-left: 7px;
            }
        }
    }
`;

export const Divisor = styled.hr`
    color: #000000;
    opacity: 20%;
`;

export const BotaoFechar = styled.button`
    width: 99px;
    height: 30px;
    margin-top: 4px;
    border-radius: 5px;
    background: #FFF;
    border: 1px solid #1e88e5;
    font-weight: 600;
    color: #1e88e5;

    &:hover {
        background: #1e88e5;
        color: #FFFFFF;
        transition-duration: 0.4s ease-out;
    }
`;

export const BotaoAprovar = styled.button`
    width: 99px;
    height: 30px;
    margin-top: 4px;
    border-radius: 5px;
    background: #1e88e5;
    border: 1px solid #FFF;
    font-weight: 600;
    color: #FFF;

    &:hover {
        background-color: #FFF;
        color: #1e88e5;
        transition-duration: 0.4s, 0.4s;
    }
`;
