import React, { useState, useEffect, useContext } from 'react'
import { Table, Spin, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Context } from '../Context/AuthContext'
import api from '../service/api';
import { WrapperTables } from '../components/TabelaPersonalizada/styles';

import moment from 'moment';
import { round } from '../utils/round';
import TabelaPersonalizada from './TabelaPersonalizada';

const filtroTituloReceber = {
  vencidos: {
    data_inicio: moment().subtract('20', 'years').format('YYYY/MM/DD'),
    data_final: moment().subtract('1', 'day').format('YYYY/MM/DD'),
  },
  hoje: {
    data_inicio: moment().format('YYYY/MM/DD'),
    data_final: moment().format('YYYY/MM/DD'),
  },
  vence_sete_dias: {
    data_inicio: moment().add('1', 'day').format('YYYY/MM/DD'),
    data_final: moment().add('7', 'day').format('YYYY/MM/DD'),
  },
  vence_trinta_dias: {
    data_inicio: moment().add('1', 'day').format('YYYY/MM/DD'),
    data_final: moment().add('30', 'day').format('YYYY/MM/DD'),
  },
};

const colunasTabela = [
  {
    title: 'Data Emissão',
    dataIndex: 'data_emissao',
    key: 'data_emissao',
    width: 110,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Bom Para',
    dataIndex: 'bom_para',
    key: 'bom_para',
    width: 110,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Dias Atraso',
    dataIndex: 'dias_atraso',
    key: 'dias_atraso',
    width: 90,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nro. Controle',
    dataIndex: 'nro_controle',
    key: 'nro_controle',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Banco',
    dataIndex: 'banco',
    key: 'banco',
    width: 80,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Agência',
    dataIndex: 'agencia',
    key: 'agencia',
    width: 100,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Conta',
    dataIndex: 'conta',
    key: 'conta',
    width: 130,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nro. Cheque',
    dataIndex: 'nro_cheque',
    key: 'nro_cheque',
    width: 130,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
    key: 'valor',
    width: 130,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Cód. Cliente',
    dataIndex: 'id_cliente',
    key: 'id_cliente',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'nome_cliente',
    key: 'nome_cliente',
    width: 500,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'CPF/CNPJ Emissor',
    dataIndex: 'cpf_cnpj_emissor',
    key: 'cpf_cnpj_emissor',
    width: 150,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Emissor',
    dataIndex: 'emissor',
    key: 'emissor',
    width: 350,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Conta Situação',
    dataIndex: 'sit_conta',
    key: 'sit_conta',
    width: 130,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Código Situação',
    dataIndex: 'situacao_atual',
    key: 'situacao_atual',
    width: 130,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Descrição Situação',
    dataIndex: 'descricao_situacao_atual',
    key: 'descricao_situacao_atual',
    width: 300,
    isVisible: true,
    isSearch: true,
  }
];

function formataValorMoeda(value) {
  let valorFormatado = '--';

  if (value || value === 0) {
    valorFormatado = parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  return valorFormatado;
}

const TitulosReceber = (props) => {
  const [chequesReceber, setChequesReceber] = useState([]);
  const [totalValor, setTotalValor] = useState(0);
  const [carregando, setCarregando] = useState([false]);
  const { empresa } = useContext(Context);
  const tipoRelatorio = props.prazo;
  const parametrosPesquisa = filtroTituloReceber[tipoRelatorio];


  useEffect(() => {
    consultaTitulosReceber(parametrosPesquisa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa]);

  async function consultaTitulosReceber(dadosConsulta) {
    try {
      setCarregando(true);

      if (!dadosConsulta) {

        const dataFormatAmerican = props.dataPeriodo.split('/');
        const dataFormatAmericanFluxoCaixa = `${dataFormatAmerican[2]}/${dataFormatAmerican[1]}/${dataFormatAmerican[0]}`;

        dadosConsulta = {
          data_inicio: dataFormatAmericanFluxoCaixa,
          data_final: dataFormatAmericanFluxoCaixa
        }
      }


      const { data: { response } } = await api.get(`titulos-receber-cheque/${empresa.id}`, {
        params: {
          data_inicio: dadosConsulta.data_inicio,
          data_final: dadosConsulta.data_final
        }
      });

      const dadosTabela = response.map((cheque, key) => {
        return {
          key: `cheque${key}`,
          ...cheque,
          data_emissao: moment(cheque.data_emissao).format('DD/MM/YYYY'),
          bom_para: moment(cheque.bom_para).format('DD/MM/YYYY'),
          valor: formataValorMoeda(cheque.valor),
        }
      });

      let totalValorCheque = 0;
      totalValorCheque = response.reduce(
        (totalValorCheque, cheque) => totalValorCheque + Number(cheque.valor), 0
      );


      setTotalValor(round(totalValorCheque, 2));
      setChequesReceber(dadosTabela);
      setCarregando(false);

    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>

      {carregando
        ?
        <Row justify="center" align="small" style={{ height: 'calc(100vh / 2)' }}>
          <Spin size="large" />
        </Row>
        :
        <WrapperTables>
          <TabelaPersonalizada
            titulo='Cheques a Receber'
            columns={colunasTabela}
            dataSource={chequesReceber}
            configTabela={{
              size: 'small',
              scroll: { x: '100%', y: '550px' },
              pagination: {
                defaultPageSize: 20,
              },
              summary: () => (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={8}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Text strong>{formataValorMoeda(totalValor)}</Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }}
          />
          {/* <Table 
            bordered
            size='small' 
            scroll={{ x: 3000 }}
            pagination={{
              size: 'small',
              defaultPageSize: 20,
            }} 
            columns={colunasTabela} 
            dataSource={chequesReceber}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell  colSpan={8}>
                  <Text strong>Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell> 
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Text strong>{formataValorMoeda(totalValor)}</Text>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              )}
          /> */}
        </WrapperTables>
      }
    </>
  );
};

export default TitulosReceber;