import React, { useEffect, useState } from 'react';
import api from '../../service/api';

import CardEmpresa from '../CardEmpresa';
import { Container, Wrapper } from './styles';


function ListaEmpresas({openCloseModal}) {
  const [empresas, setEmpresas] = useState([]);
  const [isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    async function carregaEmpresas() {
      try {
        setIsLoading(true);
        
        const { data } = await api.get('/empresas');

        setEmpresas(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }  
    };

    carregaEmpresas();
  }, []);

  return( 
    <Container>
      <h5>Empresas</h5>
      
      <Wrapper>
      { isLoading 
        ? <span> Carregando </span> 
        : (
          empresas.map( empresa => (
            <CardEmpresa 
              key={empresa.id} 
              empresa={empresa}
              openCloseModal={openCloseModal} />
          ))
        )
      }
      </Wrapper>
    </Container>
  );
}

export default ListaEmpresas;