import { format, utcToZonedTime } from 'date-fns-tz';

export const formatDateBrazil = (date, formatDate = 'dd/MM/yyyy kk:mm:ss', tz = 'UTC') => {
  const newDate = new Date(date);
  const dateFormated = format(utcToZonedTime(newDate, tz), formatDate, { timeZone: tz });

  return dateFormated;
}


