import React, { useEffect, useRef, useState } from 'react';

import { Button, Tooltip, Menu, Dropdown, Checkbox, Table } from 'antd'
import {
  SearchOutlined,
  DownloadOutlined,
  TableOutlined,
  CloseOutlined
} from '@ant-design/icons';

import { Container, Header, WrapperPesquisa, Opcoes, WrapperTable, Titulo } from './styles';
import ConvertJsonToCSV from '../../utils/convertJsonToCSV';

import { debounce } from '../../utils/debounce';
import preparaDadosCSV from '../../utils/prepara-dados-csv';
function TabelaPersonalizada({ titulo, columns, dataSource, configTabela }) {
  const [inputPesquisaVisivel, setInputPesquisaVisivel] = useState(false);
  const [colunasTabela, setColunasTabela] = useState([...columns]);
  const [colunasExibir, setColunasExibir] = useState([...columns]);
  const [dataSourceFilter, setDataSourceFilter] = useState([]);
  const [loadingPesquisa, setSetLoadingPesquisa] = useState(false);
  const [colunasPesquisa] = useState(() => {
    let listaColunas = [];
    columns.forEach(coluna => {
      coluna.isSearch && listaColunas.push(coluna.dataIndex);
    })

    return listaColunas;
  });
  const refPesquisa = useRef();

  const handlePesquisaVisivel = () => {
    if (inputPesquisaVisivel) {
      refPesquisa.current.value = '';
      setDataSourceFilter([...dataSource]);
    };

    setInputPesquisaVisivel(prevState => !prevState)
  };

  const handleChecked = (e) => handleOcutaColuna(e.target.name);

  const onChangeFiltro = () => {
    if (dataSource.length === 0) {
      return
    };

    setSetLoadingPesquisa(true);

    const filtro = String(refPesquisa.current.value).toLowerCase();

    const filtraItem = (obj) => {
      let encontrou = false;

      colunasPesquisa.forEach(coluna => {
        if (!encontrou) {
          encontrou = String(obj[coluna]).toLowerCase().indexOf(filtro) >= 0;
        }
      });

      return encontrou;
    };

    const data = dataSource.filter(filtraItem);
    setDataSourceFilter(data);
    setSetLoadingPesquisa(false);
  };

  function handleConvetCSV() {
    const { columnsFormated, dataSourceFormated, } = preparaDadosCSV(colunasExibir, dataSourceFilter);
    ConvertJsonToCSV(columnsFormated, dataSourceFormated, `arquivo_`);
  }

  const menu = (
    <Menu>
      {colunasTabela.map(coluna => (
        <Menu.Item key={coluna.dataIndex}>
          <Checkbox
            name={coluna.dataIndex}
            checked={coluna.isVisible}
            onChange={handleChecked}>
            {coluna.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleOcutaColuna = (nomeColuna) => {
    const colunas = colunasTabela.map(coluna => {
      return {
        ...coluna,
        isVisible: coluna.dataIndex === nomeColuna ? !coluna.isVisible : coluna.isVisible
      }
    });

    setColunasTabela(colunas);
  };

  useEffect(() => {
    if (inputPesquisaVisivel) {
      const currentEl = refPesquisa.current;
      currentEl && currentEl.focus();
    }
  }, [inputPesquisaVisivel]);

  useEffect(() => {
    const colunasExibir = colunasTabela.filter(coluna => coluna.isVisible);
    setColunasExibir(colunasExibir);
  }, [colunasTabela]);

  useEffect(() => {
    setDataSourceFilter([...dataSource]);
  }, [dataSource])

  return (
    <Container>
      <Header>
        <WrapperPesquisa visivel={inputPesquisaVisivel}>
          <SearchOutlined style={{ marginRight: '12px' }} />
          <input
            type='text'
            placeholder='Pesquisa'
            ref={refPesquisa}
            onChange={debounce(onChangeFiltro, 500)} />
          <button type='button' onClick={handlePesquisaVisivel}><CloseOutlined /></button>
        </WrapperPesquisa>
        <Titulo>{titulo}</Titulo>
        <Opcoes>
          <Tooltip title="Pesquisar">
            <Button
              shape="circle"
              icon={<SearchOutlined />}
              onClick={handlePesquisaVisivel}
            />
          </Tooltip>
          <Tooltip title="Baixar CSV">
            <Button
              style={{ marginLeft: '16px' }}
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={handleConvetCSV} />
          </Tooltip>
          <Tooltip title="Ocultar Coluna">
            <Dropdown overlay={menu} trigger={['click']}>
              <Button
                style={{ marginLeft: '16px' }}
                shape="circle"
                icon={<TableOutlined />} />
            </Dropdown>
          </Tooltip>
        </Opcoes>
      </Header>

      <WrapperTable>
        <Table
          columns={colunasExibir}
          dataSource={dataSourceFilter}
          loading={loadingPesquisa}
          scroll={{ x: 550, y: 550 }}
          {...configTabela}
        />
      </WrapperTable>
    </Container>
  );
}

export default TabelaPersonalizada;