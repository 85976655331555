import React, { useState, useContext, useEffect } from 'react';

import api from '../../service/api';
import { Context } from '../../Context/AuthContext';

import { Container, Wrapper, ContainerTitulo, ContainerPesquisa, Bottom, WrapperTable } from '../FiltraOperador/styles';
import { Table, Button, Input, Alert, Col, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import TituloTela from '../TituloTela';
import FormGrupoInput from '../FormGrupoInput';

const { Search } = Input;

const { Option } = Select;

const columns = [
    {
        title: 'id',
        dataIndex: 'id',
        align: 'right',
        width: '4%',
    },
    {
        title: 'Nome',
        dataIndex: 'Nome',
    },
];

function FiltraOperador({ atualizaCodigoOperador, atualizaNomeOperador, openCloseModal }) {
    const [codigoSelecionado, setCodigoSelecionado] = useState('');
    const [operadoresFiltrados, setOperadoresFiltrados] = useState([]);
    const [OperadorNome] = useState('');
    const [valorTipoProduto, setValorTipoProduto] = useState('04');
    const [codigoFiltrado, setcodigoFiltrado] = useState('');
    const [tipoProdutoSelect, setTipoProdutoSelect] = useState([]);
    const [erro, setErro] = useState(false);

    const { empresa } = useContext(Context);
    function handleSelecionar() {
        if (codigoSelecionado !== '') {
            atualizaCodigoOperador(codigoSelecionado);
            atualizaNomeOperador(OperadorNome);
            openCloseModal();
        } else {
            setErro(true)
        }
    };

    const onChange = (event) => {
        setcodigoFiltrado(event.target.value);
        event.target.value.length > 0 && setErro(false)
    }

    useEffect(() => {
        async function getTipoProduto() {
            try {
                const { data: { response } } = await api.get(`empresas/${empresa.id}/pcp/tipos-produtos`);

                setTipoProdutoSelect(response);

            } catch (error) {
                console.log(`Erro na consulta de produto: ${error}`);
            };
        }
        getTipoProduto();
    }, [empresa]);

    const onSearch = async (value) => {
        try {
            setErro(false);
            let result = [];

            if ((codigoFiltrado === '') && (OperadorNome === '')) {
                const { data } = await api.get(`/empresas/${empresa.id}/pcp/produtos`, {
                    params: { tipo_produto: valorTipoProduto }
                });
                result = data.response;
            }
            if (codigoFiltrado) {
                const { data } = await api.get(`/empresas/${empresa.id}/pcp/produtos`, {
                    params: { id: codigoFiltrado }
                });
                result = data.response
            }
            if (OperadorNome) {
                const { data } = await api.get(`/empresas/${empresa.id}/pcp/produtos`, {
                    params: { Nome: value }
                });
                result = data.response
            }
            if (valorTipoProduto === "TO") {
                const { data } = await api.get(`/empresas/${empresa.id}/pcp/produtos`);
                result = data.response
            }

            const dataCliente = result.map((operador, index) => {
                return {
                    key: `codigo${index}`,
                    ...operador,
                }
            });

            setOperadoresFiltrados(dataCliente);
        } catch (error) {
            console.log(`Erro na consulta  de cliente: ${error}`);
        };
    };

    console.log(valorTipoProduto);
    return (
        <Container>
            <Wrapper>
                <ContainerTitulo>
                    <TituloTela codigoTela="01.75.1358" nomeTela="Filtrar Produtos" tema='white' fontSize='medium' paddingBottom='without' />

                    <Button size="small" shape="circle" icon={<CloseOutlined />} onClick={openCloseModal} />
                </ContainerTitulo>
                <ContainerPesquisa>
                    <Input size="middle" style={{ width: "95px" }} placeholder="Código" value={codigoFiltrado} onChange={onChange} />
                    <Search size="middle" placeholder="Produto" onSearch={onSearch} enterButton />
                </ContainerPesquisa>
                <Col flex='0 1 130px' style={{ marginLeft: "8px" }} >
                    <FormGrupoInput
                        label='Tipo produto'
                        children={
                            <Select
                                showSearch
                                style={{ width: 350 }}
                                optionFilterProp="children"
                                defaultValue={valorTipoProduto}
                                onChange={(value) => { setValorTipoProduto(value) }}
                            >
                                <Option value="TO">Todos</Option>
                                {tipoProdutoSelect.map((tipoProduto) => (
                                    <Option value={tipoProduto.id}>{tipoProduto.id} - {tipoProduto.Nome}</Option>
                                ))}
                            </Select>
                        }

                    />
                </Col>
                {erro && <Alert message="Operação inválida. Nenhum Produto selecionado." type="warning" />}

                <WrapperTable>
                    <Table
                        rowSelection={{
                            type: 'radio',
                            onChange: (selectedRowKeys, selectedRows) => {
                                setCodigoSelecionado(selectedRows[0].id)
                            },
                            getCheckboxProps: (record) => ({
                                disabled: record.Nome === "Disabled User",
                                Descricao: record.Nome
                            })
                        }}

                        columns={columns}
                        size="small"
                        description={"Nenhum Registro Encontrado"}
                        itemRender={("Pagina")}
                        dataSource={operadoresFiltrados}
                    />
                    <Bottom>
                        <Button size="middle" type="primary" onClick={handleSelecionar}>Selecionar</Button>
                    </Bottom>
                </WrapperTable>
            </Wrapper>
        </Container >
    );
}
export default FiltraOperador;