import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { notification } from "antd";
import { mask, unMask } from "../utils/mask";
import api from "../service/api";
import { cleanObject } from "../utils/cleanObject";

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-br');

const TrackingFormContext = createContext({});

const tenDays = 1000 * 60 * 60 * 24 * 10

function TrackingFormProvider({ children }) {
  const [hash, setHash] = useState(null)

  const [, contextHolder] = notification.useNotification()

  const [invoiceInfo, setInvoiceInfo] = useState({
    number: '',
    series: '',
    orderNumber: '',
  })

  const [queryPeriod, setQueryPeriod] = useState({
    from: new moment(new Date((Date.now() - tenDays))),
    to: new moment(new Date(Date.now()))
  })

  const [sender, setSender] = useState({ name: '', document: '', isValid: false });
  const [recipient, setRecipient] = useState({ name: '', document: '', isValid: false });
  const [lastModificationTarget, setLastModificationTarget] = useState(null)
  const [lastDocumentSentToApi, setLastDocumentSentToApi] = useState({ name: '', document: '', target: '' })

  const [transporterData, setTransporterData] = useState(null)
  const [isLoadingTransporter, setIsLoadingTransporter] = useState(false)
  const [isLoadingTransporterError, setIsLoadingTransporterError] = useState(false)

  const [isValidatingDocument, setIsValidatingDocument] = useState(false)

  const [loadingInvoices, setLoadingInvoices] = useState(false)
  const [invoices, setInvoices] = useState(null)

  const [disabledByDocument, setDisabledByDocument] = useState(false)
  const [disabledByInvoiceInfo, setDisabledByInvoiceInfo] = useState(false)
  const [disabledByDate, setDisabledByDate] = useState(false)

  useEffect(() => {
    if (invoiceInfo.number.length !== 0 || invoiceInfo.orderNumber.length !== 0) {
      setDisabledByDate(false)
      setDisabledByInvoiceInfo(false)
    } else {
      setDisabledByInvoiceInfo(true)
    }

    const senderOrRecipientDocumentIsValid = (sender.document && sender.isValid) || (recipient.document && recipient.isValid)

    if (senderOrRecipientDocumentIsValid && queryPeriod.from && queryPeriod.to) {
      setDisabledByDate(false)
      setDisabledByInvoiceInfo(false)
    }

  }, [disabledByInvoiceInfo, invoiceInfo, queryPeriod, recipient, sender])

  const handleInvoiceChange = useCallback((ev, propKey) => {
    const { value } = ev.target

    setInvoiceInfo((prev) => {
      return { ...prev, [propKey]: value.trim() }
    })
  }, [setInvoiceInfo])

  const sendNotification = useCallback((config) => {
    notification.error(config);
  }, [])

  const getTransporterData = useCallback(async (hash) => {
    setIsLoadingTransporterError(false)
    setIsLoadingTransporter(true);
    setHash(hash)

    try {
      const { data } = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/dados`);
      setTransporterData(data.body)
    } catch (errorRequest) {
      setIsLoadingTransporterError(true)
    } finally {
      setIsLoadingTransporter(false);
    }
  }, [])

  const [dateError, setDateError] = useState('')

  useEffect(() => {
    if (!dateError) return

    sendNotification({
      message: `Erro`,
      description: dateError ?? "Algo deu errado",
      placement: 'topRight',
      duration: 5,
    });
  }, [dateError, sendNotification])

  const handleChangePeriod = useCallback((ev, propKey) => {

    let error = false

    if (propKey === 'from' && ev.isAfter(queryPeriod.to)) {
      setDateError("Não conseguimos nos conectar ao servidor. Tente novamente mais tarde")
      error = true
    }
    
    if (propKey === 'to' && ev.isBefore(queryPeriod.from)) {
      setDateError("A data final não pode ser antes da data de início")
      error = true
    }
    
    if (ev.isAfter(moment().endOf('day'))) {
      setDateError("A consulta não deve ser mais a frente que o dia de hoje")
      error = true
    }

    if (!error) {
      setQueryPeriod((prev) => ({ ...prev, [propKey]: ev }))
      return
    }
  }, [queryPeriod.from, queryPeriod.to])

  const getInvoices = useCallback(async (hash) => {
    try {
      const paramsRequest = {
        invoiceNumber: invoiceInfo.number,
        invoiceSeries: invoiceInfo.series,
        orderNumber: invoiceInfo.orderNumber,
        senderDocument: sender.document,
        recipientDocument: recipient.document,
        periodFrom: queryPeriod.from.format("DD/MM/YYYY"),
        periodTo: queryPeriod.to.format("DD/MM/YYYY"),
      }

      const cleanedParams = cleanObject(paramsRequest)

      setLoadingInvoices(true);
      const { data } = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/buscar-nota-fiscal`, {
        params: cleanedParams
      });

      setInvoices(data.body)
    } catch (errorRequest) {
      console.log(errorRequest)
      setInvoices([])

      if (!errorRequest.response) {
        sendNotification({
          message: `Erro`,
          description: "Não conseguimos nos conectar ao servidor. Tente novamente mais tarde",
          placement: 'topRight',
          duration: 5,
        });
        return;
      }

      const { response } = errorRequest;
      sendNotification({
        message: `Erro`,
        description: response.data.message,
        placement: 'topRight',
        duration: 5,
      });

    } finally {
      setLoadingInvoices(false);
    }
  }, [invoiceInfo.number, invoiceInfo.orderNumber, invoiceInfo.series, queryPeriod.from, queryPeriod.to, recipient.document, sendNotification, sender.document])

  const handleDocumentChange = useCallback(async (value, target, hash) => {
    const maskPattern = (value) => unMask(value).length === 11
      ? ['999.999.999-99']
      : ['SS.SSS.SSS/SSSS-99']

    const newValue = {
      name: '',
      document: mask(value, maskPattern(value)),
      isValid: false
    }

    newValue.document === '' ? setDisabledByDocument(false) : setDisabledByDocument(true)

    setLastModificationTarget(target)
    target === 'recipient'
      ? setRecipient(newValue)
      : setSender(newValue)
  }, [])

  const validateDocument = useCallback(async (target, data, hash) => {
    try {
      setIsValidatingDocument(true)
      setLastDocumentSentToApi({ name: data.name, document: data.document, target })
      const response = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/buscar-cliremet-documento?document=${data.document}`);

      if (response.data.statusCode === 200) {
        const validatedDocument = response.data
        target === 'recipient'
          ? setRecipient({
            name: validatedDocument.body.nome,
            document: validatedDocument.body.cnpj,
            isValid: true
          })
          : setSender({
            name: validatedDocument.body.nome,
            document: validatedDocument.body.cnpj,
            isValid: true
          })

        setDisabledByDocument(false)

        return
      }
    } catch (err) {
      sendNotification({
        message: `Erro`,
        description: err.response.data.message || "Algo deu errado!",
        placement: 'topRight',
        duration: 5,
      });
    } finally {
      setIsValidatingDocument(false)
    }
  }, [sendNotification])

  useEffect(() => {
    const timeOut = setTimeout(async () => {
      let lastModifiedField = lastModificationTarget === 'sender' ? sender : recipient


      if (lastModifiedField?.document?.length !== 14 && lastModifiedField?.document?.length !== 18) return
      if (lastModifiedField.isValid) return
      if (lastDocumentSentToApi.document === lastModifiedField.document && lastDocumentSentToApi.target === lastModificationTarget) {
        lastDocumentSentToApi.target === 'recipient'
          ? setRecipient(lastModifiedField)
          : setSender(lastModifiedField)
        return
      }

      await validateDocument(lastModificationTarget, lastModifiedField, hash)
    }, 500)

    return () => {
      clearTimeout(timeOut)
    }
  }, [sender, recipient, lastModificationTarget, hash, validateDocument, lastDocumentSentToApi])

  const handleDocumentChangeFromModal = useCallback((selected, modalMode) => {
    if (modalMode === "sender") {
      setSender({
        name: selected.nome,
        document: selected.cnpj,
        isValid: true
      })
    } else {
      setRecipient({
        name: selected.nome,
        document: selected.cnpj,
        isValid: true
      })
    }
  }, [])

  const clearForm = useCallback(() => {
    setInvoiceInfo({
      number: '',
      series: '',
      orderNumber: '',
    })
    setQueryPeriod({
      from: new moment(new Date((Date.now() - tenDays))),
      to: new moment(new Date(Date.now()))
    })
    setSender('')
    setRecipient('')
    setLoadingInvoices(null)
    setIsValidatingDocument(false)
    setInvoices(null)
    setDisabledByDocument(false)
  }, [])

  const value = useMemo(() => ({
    invoiceInfo,
    sender,
    recipient,
    queryPeriod,
    invoices,
    loadingInvoices,
    isValidatingDocument,
    disabledByDocument,
    transporterData,
    isLoadingTransporter,
    isLoadingTransporterError,
    disabledByInvoiceInfo,
    disabledByDate,
    getTransporterData,
    getInvoices,
    handleInvoiceChange,
    handleDocumentChange,
    handleDocumentChangeFromModal,
    handleChangePeriod,
    clearForm,
  }), [invoiceInfo, sender, recipient, queryPeriod, invoices, loadingInvoices, isValidatingDocument, disabledByDocument, transporterData, isLoadingTransporter, isLoadingTransporterError, disabledByInvoiceInfo, disabledByDate, getTransporterData, getInvoices, handleInvoiceChange, handleDocumentChange, handleDocumentChangeFromModal, handleChangePeriod, clearForm])

  return <TrackingFormContext.Provider value={value}>
    {contextHolder}
    {children}
  </TrackingFormContext.Provider>
}

function useTrackingFormContext(hash) {
  const context = useContext(TrackingFormContext)
  return context
}

export { TrackingFormProvider, useTrackingFormContext }

