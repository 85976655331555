import styled from 'styled-components';

export const ContainerFiltro = styled.div`
  margin-bottom: 16px;
  border-radius: 3px;
  background: #fff;
`;

export const IconClose = styled.div`
  color: red;
`;

export const IconCheck = styled.div`
  color: #228B22;
`;


export const ButtonStyled = styled.button`
  width: 100%;
  height: auto;
  padding: 2px;
  margin: 1px solid transparent;
  text-align: left;
  background: white;
  outline: none;
`;

