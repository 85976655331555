import * as React from "react";

export const TotalIcon = (props) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.167 0H5.833A5.84 5.84 0 0 0 0 5.833v16.334A5.84 5.84 0 0 0 5.833 28h16.334A5.84 5.84 0 0 0 28 22.167V5.833A5.84 5.84 0 0 0 22.167 0Zm3.5 5.833H17.5v-3.5h4.667a3.5 3.5 0 0 1 3.5 3.5Zm-12.834-3.5h2.334v5.834a1.167 1.167 0 0 1-2.334 0V2.333Zm-7 0H10.5v3.5H2.333a3.5 3.5 0 0 1 3.5-3.5Zm16.334 23.334H5.833a3.5 3.5 0 0 1-3.5-3.5v-14H10.5a3.5 3.5 0 1 0 7 0h8.167v14a3.5 3.5 0 0 1-3.5 3.5Zm1.166-3.5a1.167 1.167 0 0 1-1.166 1.166h-3.5a1.167 1.167 0 1 1 0-2.333h3.5a1.167 1.167 0 0 1 1.166 1.167Z"
      fill="#000000"
    />
  </svg>
);

export const LocadoIcon = (props) => (
  <svg
    width={24}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.834 16.333a1.167 1.167 0 0 1-1.167 1.167H7.334a1.167 1.167 0 1 1 0-2.333h9.333a1.167 1.167 0 0 1 1.166 1.166Zm-4.667 3.5H7.334a1.167 1.167 0 1 0 0 2.334h5.833a1.167 1.167 0 1 0 0-2.334Zm10.5-7.6v9.934A5.84 5.84 0 0 1 17.833 28H6.168a5.84 5.84 0 0 1-5.833-5.833V5.833A5.84 5.84 0 0 1 6.167 0h5.267a8.118 8.118 0 0 1 5.775 2.392l4.065 4.067a8.11 8.11 0 0 1 2.393 5.774ZM15.56 4.04a5.845 5.845 0 0 0-1.226-.91v5.036A1.167 1.167 0 0 0 15.5 9.333h5.035a5.81 5.81 0 0 0-.91-1.225L15.56 4.041Zm5.774 8.191c0-.192-.038-.376-.055-.565H15.5a3.5 3.5 0 0 1-3.5-3.5V2.388c-.189-.017-.374-.055-.566-.055H6.167a3.5 3.5 0 0 0-3.5 3.5v16.334a3.5 3.5 0 0 0 3.5 3.5h11.666a3.5 3.5 0 0 0 3.5-3.5v-9.935Z"
      fill="#000000"
    />
  </svg>
);

export const EstoqueIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#000000">
      <path d="M20.457 4.555 12.487.126a1 1 0 0 0-.973 0l-7.97 4.43A3 3 0 0 0 2 7.176V19a5.006 5.006 0 0 0 5 5h10a5.006 5.006 0 0 0 5-5V7.177a3 3 0 0 0-1.543-2.622ZM20 19a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7.177a1 1 0 0 1 .515-.877L12 2.144 19.486 6.3a1 1 0 0 1 .514.877V19Z" />
      <path d="M12 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const ManutencaoIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#000000">
      <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-3.999 2 2 0 0 1 0 4Z" />
      <path d="m21.294 13.9-.444-.256a9.1 9.1 0 0 0 0-3.29l.444-.256a3 3 0 0 0 .328-4.98 3.001 3.001 0 0 0-3.328-.22l-.445.257A8.976 8.976 0 0 0 15 3.513V3a3 3 0 1 0-6 0v.513a8.977 8.977 0 0 0-2.848 1.646L5.705 4.9a3.002 3.002 0 0 0-3 5.2l.444.256a9.1 9.1 0 0 0 0 3.29l-.444.256a3.002 3.002 0 0 0 3 5.2l.445-.257A8.976 8.976 0 0 0 9 20.487V21a3 3 0 0 0 6 0v-.513a8.976 8.976 0 0 0 2.848-1.646l.447.258a3.002 3.002 0 0 0 3-5.2l-.001.001Zm-2.548-3.776a7.047 7.047 0 0 1 0 3.75 1 1 0 0 0 .464 1.133l1.084.626a1 1 0 1 1-1 1.733l-1.086-.628a1 1 0 0 0-1.215.165 6.983 6.983 0 0 1-3.243 1.875 1 1 0 0 0-.751.969V21a1 1 0 1 1-2 0v-1.252a1 1 0 0 0-.751-.969A6.984 6.984 0 0 1 7.006 16.9a1 1 0 0 0-1.215-.165l-1.084.627a1 1 0 1 1-1-1.732l1.084-.626a1 1 0 0 0 .464-1.133 7.048 7.048 0 0 1 0-3.75 1 1 0 0 0-.465-1.129l-1.084-.626a1 1 0 0 1 1-1.733l1.086.628A1 1 0 0 0 7.006 7.1a6.984 6.984 0 0 1 3.243-1.875A1 1 0 0 0 11 4.252V3a1 1 0 0 1 2 0v1.252a1 1 0 0 0 .751.969A6.983 6.983 0 0 1 16.994 7.1a1 1 0 0 0 1.215.165l1.084-.627a1 1 0 1 1 1 1.732l-1.084.626a1 1 0 0 0-.463 1.129v-.001Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

