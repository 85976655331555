import React from 'react';

// import { Container } from './styles';

function SadEmoji() {
  return <svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M943.528 511.802C943.528 750.259 750.221 943.567 511.764 943.567C273.308 943.567 80 750.259 80 511.802C80 273.346 273.308 80.0381 511.764 80.0381C750.221 80.0381 943.528 273.346 943.528 511.802Z" fill="#A3B5D6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M494.123 449.835C494.123 499.943 453.502 540.563 403.394 540.563C353.285 540.563 312.665 499.943 312.665 449.835C312.665 399.727 353.285 359.105 403.394 359.105C453.502 359.105 494.123 399.727 494.123 449.835Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M710.864 449.835C710.864 499.943 670.243 540.563 620.135 540.563C570.027 540.563 529.406 499.943 529.406 449.835C529.406 399.727 570.027 359.105 620.135 359.105C670.243 359.105 710.864 399.727 710.864 449.835Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M443.718 447.314C443.718 468.192 426.792 485.118 405.915 485.118C385.035 485.118 368.11 468.192 368.11 447.314C368.11 426.436 385.035 409.511 405.915 409.511C426.792 409.511 443.718 426.436 443.718 447.314Z" fill="#253368" />
    <path fillRule="evenodd" clipRule="evenodd" d="M655.418 447.314C655.418 468.192 638.493 485.118 617.615 485.118C596.736 485.118 579.811 468.192 579.811 447.314C579.811 426.436 596.736 409.511 617.615 409.511C638.493 409.511 655.418 426.436 655.418 447.314Z" fill="#253368" />
    <path fillRule="evenodd" clipRule="evenodd" d="M652.017 706.903C646.457 706.903 641.108 703.827 638.464 698.511C617.13 655.613 567.397 627.895 511.763 627.895C456.131 627.895 406.398 655.613 385.063 698.511C381.344 705.989 372.268 709.037 364.789 705.317C357.311 701.598 354.264 692.521 357.983 685.044C384.388 631.955 444.749 597.65 511.763 597.65C578.778 597.65 639.14 631.955 665.544 685.044C669.262 692.521 666.216 701.598 658.739 705.317C656.576 706.393 654.279 706.903 652.017 706.903Z" fill="#253368" />
  </svg>

}

export default SadEmoji;