import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  min-width: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-top: 3px solid var(--corCard);
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  height: 800px;
`;