import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #AAB2C5;
  }
`;
