import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Alert, Button, Col, Drawer, Row, Select } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import { BiDollar } from 'react-icons/bi';

import Loader from "../../../../components/Loader";
import TituloTela from "../../../../components/TituloTela";
import Card from "../../../faturamento/Dashboard/Card";
import GrupoCards from "../../../../components/GrupoCards";

import api from '../../../../service/api';
import { Context } from "../../../../Context/AuthContext";

import { formatDecimal } from "../../../../utils/format-decimal";
import { MESES_EXTENSO } from "../../../../constants";

import { Container, CardContainer, ButtonTag, TableContainer, Table } from './styles';
import DetalhesGrupoDRE from "./DetalhesGrupoDRE";

const erros = '';
const mesAtual = new Date().getMonth();
const anoAtual = new Date().getFullYear();
const initialStateDre = {
  vlr_receitas: formatDecimal(0),
  vlr_custos: formatDecimal(0),
  vlr_despesas: formatDecimal(0),
  resultado: formatDecimal(0)
}

export default function DRE() {
  const [isLoading, setIsLoading] = useState(false);
  const [opcoesAno, setOpcoesAno] = useState([anoAtual]);
  const [mesSelecionado, setMesSelecionado] = useState(mesAtual);
  const [anoSelecionado, setAnoSelecionado] = useState(anoAtual);
  const [dre, setDRE] = useState(initialStateDre);
  const [dreProEmpresa, setDREPorEmpresa] = useState([]);
  const [detalhesGrupoDRE, setDetalhesGrupoDRE] = useState({ 
    empresa: {
      id: 0,
      descricao: 'Todos' 
    },
    grupo: 0,
    periodo: '' })
  
  const { empresa } = useContext(Context);

  const getDRE = useCallback( async (periodo) => {
    try {
      setIsLoading(true);
  
      const { data } = await api.get(
        `/empresas/${empresa.id}/intercarg/financeiro/dre`, {
        params: { dataPeriodo: periodo },
      });
  
      const dreMapper = {
        vlr_receitas: data.response.totaisGeral.vlr_receitas,
        vlr_custos: data.response.totaisGeral.vlr_custos,
        vlr_despesas: data.response.totaisGeral.vlr_despesas,
        resultado: data.response.totaisGeral.resultado,
        vlr_receitas_formatado: formatDecimal(data.response.totaisGeral.vlr_receitas),
        vlr_custos_formatado: formatDecimal(data.response.totaisGeral.vlr_custos),
        vlr_despesas_formatado: formatDecimal(data.response.totaisGeral.vlr_despesas),
        resultado_formatado: formatDecimal(data.response.totaisGeral.resultado),
      }
  
      const dreEmpresasMapper = data.response.totaisPorEmpresa.map(item => ({
        id_empresa: item.id_empresa,
        nome_fantasia: item.nome_fantasia,
        vlr_receitas: item.vlr_receitas,
        vlr_custos: item.vlr_custos,
        vlr_despesas: item.vlr_despesas,
        resultado: item.resultado,
        vlr_receitas_formatado: formatDecimal(item.vlr_receitas),
        vlr_custos_formatado: formatDecimal(item.vlr_custos),
        vlr_despesas_formatado: formatDecimal(item.vlr_despesas),
        resultado_formatado: formatDecimal(item.resultado)
      }))
  
      setDREPorEmpresa(dreEmpresasMapper);
      setDRE(dreMapper);
    }catch(error){
      console.log(error);
    }finally{
      setIsLoading(false);
    }
  }, [empresa]) 

  function handleChangeMes(value){
    setMesSelecionado(value);
  }

  function handleChangeAno(value){
    setAnoSelecionado(value);
  }

  const getPeriodoResquest = useCallback(() => {
    const mesFormated = String(mesSelecionado + 1).padStart(2, '0');

    return `${anoSelecionado}-${mesFormated}-01`;
  }, [anoSelecionado, mesSelecionado]);

  function handleAtualuzarDados() {
    const periodo = getPeriodoResquest();
    getDRE(periodo);
  }

  const openDrawer = useMemo( () => {
    return detalhesGrupoDRE.grupo !== 0
  }, [detalhesGrupoDRE])

  
  const handleCloseDetalhesGrupoDRE = useCallback( () => {
    setDetalhesGrupoDRE({ 
      empresa: {
        id: 0,
        descricao: 'Todos' 
      },
      grupo: 0,
      periodo: '' })
  }, []) 

  function  handleOpenDetalhesGrupoDRE(idEmpresa, descricaoEmpresa, grupoDRE) {
    setDetalhesGrupoDRE({ 
      empresa: {
        id: idEmpresa,
        descricao: descricaoEmpresa 
      },
      grupo: grupoDRE,
      periodo: getPeriodoResquest() })
  }

  useEffect(() => {
    async function getAnoPrimeiroRegistro() {
      try {
        const { data } = await api.get(`/empresas/${empresa.id}/intercarg/faturamento/ano_primeiro_lancamento`);
        
        let anos = []
        
        if(data.response.ano){
          const intervalo = anoAtual - Number(data.response.ano);
          
          for( let i = 0; i <= intervalo; i++) {
            anos.push(Number(data.response.ano) + i)
          } 
        }else{
          anos.push(anoAtual)
        }

        setOpcoesAno(anos);
      } catch (error) {
        console.log('getAnoPrimeiroRegistro', error);
      }finally{
        setIsLoading(false);
      }
    }
    
    getAnoPrimeiroRegistro();
  }, [empresa]);

  useEffect(() => {
    const periodo = getPeriodoResquest();
    getDRE(periodo);

  }, [empresa, getPeriodoResquest, getDRE])

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <Drawer 
        title="(02.74.1415) Detalhes do Grupo DRE" 
        width="90%" 
        placement="right"
        onClose={() => handleCloseDetalhesGrupoDRE()}
        visible={openDrawer}
      >
        <DetalhesGrupoDRE payLoad={detalhesGrupoDRE}/>
      </Drawer>

      <TituloTela
        codigoTela="02.74.1414"
        nomeTela="DRE Resumido"
      />

      <Row>
        <Col>
          <Select
            defaultValue={`${MESES_EXTENSO[mesAtual]}`}
            style={{ width: 120, marginBottom: '18px', marginRight: '8px' }}
            onChange={handleChangeMes}
          >
            {MESES_EXTENSO.map((mes, index) => (
              <Select.Option
                key={mes}
                value={index}
              >
                {mes}
              </Select.Option>))}
          </Select>
        
          <Select
            defaultValue={`${anoAtual}`}
            style={{ width: 120, marginBottom: '18px', marginRight: '8px' }}
            onChange={handleChangeAno}
          >
            {opcoesAno.map((ano) => (
              <Select.Option
                key={ano}
                value={ano}
              >
                {ano}
              </Select.Option>
            ))}
          </Select>

          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={handleAtualuzarDados}
          />
        </Col>

        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          {erros &&

            <Alert
              closeText="Fechar"
              message={erros}
              showIcon
              type="error"
              action={
                <Button size="small" danger show={true}>
                  Detalhes
                </Button>
              }
            />
          }
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
          <Col xs={24} md={12} lg={8} xl={6}>
            <CardContainer 
              onClick={ () => handleOpenDetalhesGrupoDRE(0, 'Todos', 1)}
            >
              <Card
                title='Receitas'
                value={dre.vlr_receitas_formatado}
                icon={<BiDollar />}
                color='green'
              />
            </CardContainer>
          </Col>

          <Col xs={24} md={12} lg={8} xl={6}>
            <CardContainer 
                onClick={ () => handleOpenDetalhesGrupoDRE(0, 'Todos', 2)}
              >
              <Card
                title='Custos'
                value={dre.vlr_custos_formatado}
                icon={<BiDollar />}
                color='red'
              />
            </CardContainer>
          </Col>   

          <Col xs={24} md={12} lg={8} xl={6}>
            <CardContainer 
              onClick={ () => handleOpenDetalhesGrupoDRE(0, 'Todos', 3)}
            >
              <Card
                title='Despesas'
                value={dre.vlr_despesas_formatado}
                icon={<BiDollar />}
                color='red'
              />
            </CardContainer>
          </Col>                    

          <Col xs={24} md={12} lg={8} xl={6}>
            <Card
              title='Resultado'
              value={dre.resultado_formatado}
              icon={<BiDollar />}
              color={Number(dre.resultado) < 0 ? 'red' : 'green' }
            />
          </Col>                    
      </Row>

      <Row gutter={[12, 16]}>
        <Col xl={24} >
          <GrupoCards titulo='DRE Resumido por Filial'>
            <Row gutter={24}>
              <Col xl={24}>
                <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th style={{textAlign: 'right'}}>ID</th>
                      <th>Nome</th>
                      <th style={{textAlign: 'right'}}>Receitas</th>
                      <th style={{textAlign: 'right'}}>Custos</th>
                      <th style={{textAlign: 'right'}}>Despesas</th>
                      <th style={{textAlign: 'right'}}>Resultado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dreProEmpresa.map( empresa => (
                      <tr key={empresa.id_empresa}>
                        <td style={{textAlign: 'right'}}>{empresa.id_empresa}</td>
                        <td>{empresa.nome_fantasia}</td>
                        <td style={{textAlign: 'right', color:'#34D399'}}> 
                          <ButtonTag
                            onClick={() => handleOpenDetalhesGrupoDRE(empresa.id_empresa, empresa.nome_fantasia, 1)}
                          >
                            {empresa.vlr_receitas_formatado}
                          </ButtonTag>
                        </td>

                        <td style={{textAlign: 'right', color: '#ef4444'}}>
                          <ButtonTag
                            onClick={() => handleOpenDetalhesGrupoDRE(empresa.id_empresa, empresa.nome_fantasia, 2)}
                          >
                            {empresa.vlr_custos_formatado}
                          </ButtonTag>
                        </td>

                        <td style={{textAlign: 'right', color: '#ef4444'}}>
                          <ButtonTag
                            onClick={() => handleOpenDetalhesGrupoDRE(empresa.id_empresa, empresa.nome_fantasia, 3)}
                          >
                            {empresa.vlr_despesas_formatado}
                          </ButtonTag>
                        </td>

                        <td 
                          style={{textAlign: 'right', color: `${Number(empresa.resultado) < 0 ? '#ef4444' : '#34D399' }`}}
                        >
                          {empresa.resultado_formatado}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
              </Col>
            </Row>
          </GrupoCards> 
        </Col>
      </Row>    
  </Container>
  )
}