import React from 'react';

import { Container } from './styles';

function Card({ title, value, icon, color }) {
  return (
    <Container color={color}>
      <div className="icon">
        {icon}
      </div>

      <div className="info">
        <span>{title}</span>
        <span>{value}</span>
      </div>

    </Container>
  );
}

export default Card;