import React from 'react';
import { DollarCircleOutlined } from '@ant-design/icons';
import { Container, CardInfo, CardImage } from './styles';

function Card({ titulo, valor, cor }) {
  return (
    <Container cor={cor}>
      <CardInfo cor={cor}>
          <h5>{titulo}</h5> 
          <strong>
            <small>R$ </small> 
            {valor}
          </strong>
      </CardInfo>

      <CardImage>
        <DollarCircleOutlined />
      </CardImage>
    </Container>
  );
}

export default Card;