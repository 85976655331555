import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Table, Spin, Row, Button, Alert } from 'antd';
import { Context } from '../Context/AuthContext'
import api from '../service/api';
import { round } from '../utils/round';
import { WrapperTables } from '../components/TabelaPersonalizada/styles';
import Modal from './Modal'

import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import TabelaPersonalizada from './TabelaPersonalizada';

const filtroTituloPagar = {
  vencidos: {
    data_inicio: moment().subtract('20', 'years').format('YYYY/MM/DD'),
    data_final: moment().subtract('1', 'day').format('YYYY/MM/DD'),
    id_fornecedor: '0'
  },
  hoje: {
    data_inicio: moment().format('YYYY/MM/DD'),
    data_final: moment().format('YYYY/MM/DD'),
    id_fornecedor: '0'
  },
  vence_sete_dias: {
    data_inicio: moment().add('1', 'day').format('YYYY/MM/DD'),
    data_final: moment().add('7', 'day').format('YYYY/MM/DD'),
    id_fornecedor: '0'
  },
  vence_trinta_dias: {
    data_inicio: moment().add('1', 'day').format('YYYY/MM/DD'),
    data_final: moment().add('30', 'day').format('YYYY/MM/DD'),
    id_fornecedor: '0'
  },
};



function formataValorMoeda(value) {
  let valorFormatado = '--';

  if (value || value === 0) {
    valorFormatado = parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  return valorFormatado;
}

const TitulosReceber = (props) => {
  const { empresa } = useContext(Context);
  const [titulosPagar, setTitulosPagar] = useState([]);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [totalValor, setTotalValor] = useState(0);
  const [carregando, setCarregando] = useState([false]);
  const [carregandoNotaFiscal, setCarregandoNotaFiscal] = useState(false);
  const [erros, setErros] = useState();
  const tipoRelatorio = props.prazo;
  const parametrosPesquisa = filtroTituloPagar[tipoRelatorio];

  const consultaTitulosPagar = useCallback( async (dadosConsulta) => {
    try {
      setCarregando(true);

      if (!dadosConsulta) {

        const dataFormatAmerican = props.dataPeriodo.split('/');
        const dataFormatAmericanFluxoCaixa = `${dataFormatAmerican[2]}/${dataFormatAmerican[1]}/${dataFormatAmerican[0]}`;

        dadosConsulta = {
          id_fornecedor: 0,
          data_inicio: dataFormatAmericanFluxoCaixa,
          data_final: dataFormatAmericanFluxoCaixa
        }
      }

      const { data: { response } } = await api.get(`titulos-pagar/${empresa.id}`, {
        params: {
          id_fornecedor: dadosConsulta.id_fornecedor,
          data_inicio: dadosConsulta.data_inicio,
          data_final: dadosConsulta.data_final
        }
      });

      const dadosTabela = response.map((titulo, key) => {
        return {
          key: `titulo${key}`,
          ...titulo,
          data_emissao: moment(titulo.data_emissao).format('DD/MM/YYYY'),
          data_vencimento: moment(titulo.data_vencimento).format('DD/MM/YYYY'),
          valor: formataValorMoeda(titulo.valor),
          saldo: formataValorMoeda(titulo.saldo),
        }
      });

      let totalSaldo = 0;
      totalSaldo = response.reduce(
        (totalSaldo, titulo) => totalSaldo + Number(titulo.saldo), 0
      );

      let totalValor = 0;
      totalValor = response.reduce(
        (totalValor, titulo) => {
          return totalValor + Number(titulo.valor)
        }, 0
      );


      setTotalSaldo(round(totalSaldo, 2));
      setTotalValor(round(totalValor, 2));
      setTitulosPagar(dadosTabela);
      setCarregando(false);

    } catch (error) {
      console.log({ error });
    }    
  }, [empresa.id, props.dataPeriodo])

  useEffect(() => {
    consultaTitulosPagar(parametrosPesquisa);
  }, [consultaTitulosPagar, empresa, parametrosPesquisa]);

  const colunasTabela = [
    {
      title: 'Data Emissão',
      dataIndex: 'data_emissao',
      key: 'data_emissao',
      width: 130,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Data Vencimento',
      dataIndex: 'data_vencimento',
      key: 'data_vencimento',
      width: 130,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Dias Atraso',
      dataIndex: 'dias_atraso',
      key: 'dias_atraso',
      width: 110,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Nota Fiscal',
      dataIndex: 'nota_fiscal',
      key: 'nro_titulo',
      width: 70,
      align: 'center',
      isVisible: true,
      isSearch: true,
      render: (_, record) => record.id_nf_entrada !== null && (<Button size="small" onClick={() => nfeConsultasPagar(record.id_titulos_pagar)} target="_blank">NF-e</Button>),
    },
    {
      title: 'Nro. Título',
      dataIndex: 'nro_titulo',
      key: 'nro_titulo',
      width: 110,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Parcela',
      dataIndex: 'nro_parcela',
      key: 'nro_parcela',
      width: 70,
      align: 'center',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Parcela/Total',
      dataIndex: 'total_parcelas',
      key: 'total_parcelas',
      width: 70,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Série',
      dataIndex: 'serie',
      key: 'serie',
      width: 70,
      align: 'center',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      width: 110,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Saldo',
      dataIndex: 'saldo',
      key: 'saldo',
      width: 110,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Cód. Fornecedor',
      dataIndex: 'id_fornecedor',
      key: 'id_fornecedor',
      width: 150,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Nome Fornecedore',
      dataIndex: 'nome_fornecedor',
      key: 'nome_fornecedor',
      width: 500,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Plano de Contas',
      dataIndex: 'descricao_plano_contas',
      key: 'descricao_plano_contas',
      width: 500,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Observação',
      dataIndex: 'observacao',
      key: 'observacao',
      isVisible: true,
      width: 800,
      isSearch: true,
    },
  ];



  async function nfeConsultasPagar(value) { //
    setCarregandoNotaFiscal(true);
    try {
      // await delay();
      const { data } = await api.get(`/empresas/${empresa.id}/financeiro/titulos_pagar/danfe_nfe_entrada/${value}`);
      const base64EncodedData = data.response.pdf_base64;

      if (base64EncodedData === "") {
        setErros("Não foi possível gerar o DANFE, Nota inexistente ou não gerada.");
        setCarregandoNotaFiscal(false);
      } else {
        const byteCharacters = window.atob(base64EncodedData);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        setCarregandoNotaFiscal(false);
      }



    } catch (error) {
      setErros(error.response.data.error);
    }

  }




  return (
    <>
      {erros &&
        <Alert
          closeText="Fechar"
          message={erros}
          showIcon
          type="error"
        />
      }
      {carregandoNotaFiscal &&
        < Modal style={{ background: '#000' }}>
          <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
            <Spin size="large" />
          </Row>
        </Modal>
      }
      {
        carregando
          ?
          <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
            <Spin size="large" />
          </Row>
          :
          <WrapperTables>
            <TabelaPersonalizada
              titulo='Títulos a Pagar'
              columns={colunasTabela}
              dataSource={titulosPagar}
              configTabela={{
                size: 'small',
                scroll: { x: '100%', y: '550px' },
                pagination: {
                  defaultPageSize: 20,
                },
                summary: () => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={8}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Text strong>{formataValorMoeda(totalValor)}</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Text strong>{formataValorMoeda(totalSaldo)}</Text>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )
              }}
            />
          </WrapperTables>
      }
    </>
  );
};

export default TitulosReceber;