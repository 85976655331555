const mask = ({ locale, currency, value }) => {
  const { format } = new Intl.NumberFormat(`${locale}`, {
    style: 'currency',
    currency,
  })

  return format(value)
}

const unmask = ({ locale, currency, value }) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  const unformatted = `${value}`.replace(/[^0-9-]/g, '')

  const parts = formatter.formatToParts(1.1)
  const fractionPart = parts.find((item) => item.type === 'fraction')

  return fractionPart
    ? parseInt(unformatted) / (parseInt(fractionPart.value) * 10)
    : parseInt(unformatted)
}

export const currency = { mask, unmask }