import styled from "styled-components";

export const FilterContainer = styled.div`
  margin-bottom: 16px;
  border-radius: 5px;
  background: #fff; 
`


export const DetailButtonContainer = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: transparent;
  border: none;

  &:hover { 
    color: #1890ff;
    cursor: pointer;
  }
`