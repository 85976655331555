import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import api from '../service/api';

const Context = createContext();

function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticationErrors, setAuthenticationErrors] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [empresa, setEmpresa] = useState({});

  const signIn = useCallback(async (payload) => {
    const { email, senha } = payload;

    try {
      setAuthenticationErrors(undefined);
      setIsAuthenticating(true);

      const { data } = await api.post('/login', { email, senha });

      const { token, user } = data;

      sessionStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.removeItem('empresa');

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setAuthenticated(true);
      setUser(user);
    } catch (error) {
      console.error(error.response);
      if ((!error.response) || (!error.response.data.errors)) {
        setAuthenticationErrors([
          {
            message: `Servidor não respondendo. Por favor tente novamente ou
            contate o suporte se você não conseguir acessar sua conta.`,
          },
        ]);
      } else {
        setAuthenticationErrors(error.response.data.errors);
      };
    } finally {
      setIsAuthenticating(false);
    };
  }, []);

  const signOut = useCallback(async () => {
    setAuthenticated(false);
    setEmpresa({});
    setUser({});

    sessionStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('empresa')

    api.defaults.headers.Authorization = undefined;
  }, []);

  const handleSelectedEmpresa = useCallback((empresa) => {
    setEmpresa(empresa);
    localStorage.setItem('empresa', JSON.stringify(empresa));
  }, []);

  useEffect(() => {
    const sessionStorageToken = sessionStorage.getItem('token');
    const localStorageUser = localStorage.getItem('user');
    const localStorageEmpresa = localStorage.getItem('empresa');

    if (sessionStorageToken && localStorageEmpresa && localStorageUser) {
      setEmpresa(JSON.parse(localStorageEmpresa));

      setUser(JSON.parse(localStorageUser));

      api.defaults.headers.Authorization = `Bearer ${JSON.parse(sessionStorageToken)}`;
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    async function getEmpresas() {
      try {
        if(!authenticated){
          return
        }

        const { data } = await api.get('/empresas');
        
        if(data.length === 1){
          handleSelectedEmpresa(data[0]);
        }
      } catch (error) {
        console.log(error);
      }  
    };

    getEmpresas();
  }, [authenticated, handleSelectedEmpresa]);

  const value = useMemo(
    () => ({
      authenticated,
      authenticationErrors,
      empresa,
      handleSelectedEmpresa,
      isAuthenticating,
      signIn,
      signOut,
      user,
    }), 
    [authenticated, authenticationErrors, empresa, handleSelectedEmpresa, isAuthenticating, signIn, signOut, user]); 

  if (loading) {
    return <h1> Carregando </h1>;
  }

  return (
    <Context.Provider
      value={value}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
