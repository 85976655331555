import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #1e88e5;
    border-bottom: 1px solid #f1f1f1;
    height: 63px;
    width: 100%;
`;

export const Bar = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    width: 228px;
    

    @media (min-width: 300px) and (max-width: 480px) {
        display: none;
        height: 100%;
        align-items: center;
        justify-content: center;
        margin-right: 1%;
        color: #fff;
        font-size: 14px;
        padding: 0 16px 0 16px;
        font-weight: 600;
    }
`;
export const Button = styled.button`
    display: flex;
    background: #1e88e5;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;

    @media (min-width: 360px) and (max-width: 720px) {
        margin-left: 10px;
    }

    @media (min-width: 1024px){
        display: none;
    }
`;

export const InfoEmpresa = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    @media (min-width: 360px) and (max-width: 480px) {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: left;
        justify-content: left;
        margin-right: 10%;
        color: #fff;
        font-size: 14px;
        padding: 0 10px 0 10px;
        font-weight: 600;
    }
`;

export const IconList = styled.div`
    display: flex;
    flex-direction: flex-end;
    align-items: center;
    color: #000;

   
    @media (min-width: 300px) and (max-width: 720px) {

    div{
    display: none;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    }

    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600; 
}
`;

export const Logo = styled.img`
    height: 32px;
`;

















