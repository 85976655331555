import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  ${({minWidth}) => minWidth && css`
    min-width: ${minWidth}%;
  `}

  label {
    font-size: 14px;
    color: #232323;
    margin-bottom: 4px;

    @media screen and (max-width: 768px){
      font-size: 12px;
    }
  }

  input {
    width: 100%;
  }


  small {
    color: #F57E77;
    font-size: 12px;
    display: block;
    margin-top: 8px;
  }
`;