import React, { useState } from 'react';
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender, getExpandedRowModel, createColumnHelper } from '@tanstack/react-table'

import { Container, Table, Td, Th, BodyRow } from './styles';
import { Empty } from 'antd';
import { DataTable } from '../../../../components/Table/DataTable';

import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { ButtonContainer } from '../../../../components/Table/DataTableColumnHeader/styles';


const defaultValueTableConfig = {
  initialColumnOrdering: [],
  onClickRow: null,
  convertTableIntoCardMobileVersion: true
}

const columnHelper = createColumnHelper(); 

export default function TableExpanding({ 
  columns=[],
  dataSource=[],
  detailsColumns=[],
  tableConfig: tableConfigValues}) {

  const tableConfig = {
    ...defaultValueTableConfig,
    ...tableConfigValues
  };

  const [sorting, setSorting] = useState(tableConfig.initialColumnOrdering);
  const [expanded, setExpanded] = useState({});

  const columnsExpanded = [
    columnHelper.display({
      id: "expander",
      widthColumn: 40,
      header: ({ table }) => (
        <ButtonContainer
          justifyContent='center'
          {...{
            onClick: table.getToggleAllRowsExpandedHandler()
          }}
        >
          {table.getIsAllRowsExpanded() ? <MdExpandLess size={16} /> : <MdExpandMore size={16} />}
        </ButtonContainer>
      ),
      cell: ({ row }) => (
        <ButtonContainer
          justifyContent='center'
          {...{
            onClick: () => row.toggleExpanded(),
            style: { cursor: "pointer" }
          }}
        >
          {row.getIsExpanded() ? <MdExpandLess size={16} /> : <MdExpandMore size={16} />}
        </ButtonContainer>
      )
    }),
    ...columns
  ];
  
  const table = useReactTable( { 
    columns: columnsExpanded, 
    data: dataSource,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting,
      expanded
    }
  } );

  return (
    <Container>
      <Table
        convertTableIntoCardMobileVersion={tableConfig.convertTableIntoCardMobileVersion}
        teste={tableConfig.convertTableIntoCardMobileVersion}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, _index) => {
                return(
                  <Th
                    key={header.id}
                    width={header.column.columnDef.widthColumn}
                    align={header.column.columnDef.align}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Th>
                );
              })}
            </tr>
          ))}
        </thead>     

        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <BodyRow
                    key={row.id}
                    className={`backgroundColorDark ${row.getIsExpanded() ? 'hideBorder' : ''}`}
                    onClick={tableConfig.onClickRow ? () => tableConfig.onClickRow(row) : null}
                    cursorPointer={tableConfig.onClickRow ? true : null}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td 
                        key={cell.id}
                        data-label={cell.column.columnDef.title}
                        align={cell.column.columnDef.align}
                        width={cell.column.columnDef.widthColumn}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </BodyRow>

                  {
                  row.getIsExpanded() ? (
                    <BodyRow 
                      key={`${146}`}
                      className='disableBackgroundColor'
                    >
                      <Td 
                        colSpan={columns.length + 1}
                      >
                        <DataTable 
                          columns={detailsColumns}
                          dataSource={row.original.detalhes || []}
                        />
                      </Td>
                    </BodyRow>
                  ) : null}

                </React.Fragment>
              )
            })
          ) : (
            <BodyRow>
              <Td colSpan={columns.length}>
                <Empty />
              </Td>
            </BodyRow>
          )}
        </tbody>
      </Table>
    </Container>      
  );
}

