import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  padding: 12px;

  @media (min-width: 1224px) {
    padding: 24px;
  }  
`;

export const WrapperHeaderDrawer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-right: 8px;
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 24px;
  padding: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;