import React, { useState, useContext } from 'react';

import api from '../../service/api';
import { Context } from '../../Context/AuthContext';
import { ContainerFiltro, IconCheck, IconClose } from './styles';
import { SearchOutlined } from '@ant-design/icons';
import FormGrupoInput from '../FormGrupoInput';
import { Col, Row, Spin, Table, InputNumber, DatePicker, Button, Alert } from 'antd';
import ApontamentosOrdemProducao from '../../pages/pcp/ApontamentosOrdemProducao';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import Modal from '../Modal';
import FormModal from '../FormModal';
import moment from 'moment';
import TabelaPersonalizada from '../TabelaPersonalizada';

import TituloTela from '../TituloTela';

import { HiCheck } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";

moment.locale('pt-br');

const formatoDataBR = 'DD/MM/YYYY';



const colunasTabelaMateriaPrima = [{
  title: 'Código',
  dataIndex: 'codigo_mat_prima',
  key: 'codigo_mat_prima',
  width: 100,
  align: 'right',
},
{
  title: 'Matéria Prima',
  dataIndex: 'desc_mat_prima',
  key: 'desc_mat_prima',
},
{
  title: 'Ficha Técnica',
  dataIndex: 'qt_ficha_tecnica',
  key: 'qt_ficha_tecnica',
  width: 130,
  align: 'right',
},
{
  title: 'Requisitada',
  dataIndex: 'qt_requisitada',
  key: 'qt_requisitada',
  width: 130,
  align: 'right',
},
{
  title: 'Percentual',
  dataIndex: 'percentual',
  key: 'percentual',
  width: 130,
  align: 'right',
},
];

function OrdemProducaoFichaRequisitado() {
  const [loading, setLoading] = useState(false);
  const [ordemProducao, setOrdemProducao] = useState([]);
  const [detalhesOrdemProducao, setDetalhesOrdemProducao] = useState();
  const [opInicial, setOPInicio] = useState(1);
  const [opFinal, setOPFinal] = useState(999999999);
  const [dataInicial, setDataInicio] = useState(moment(new Date()));
  const [dataFinal, setDataFinal] = useState(moment(new Date()));
  const { empresa } = useContext(Context);
  const [erros, setErros] = useState();

  async function carregaOrdensProducoes() {
    setLoading(true);

    try {
      const response = await api.get(
        `empresas/${empresa.id}/relacaoop`,
        {
          params: {
            dataInicial,
            dataFinal,
            opInicial,
            opFinal,
          }
        }
      );

      const data = response.data.map((op, index) => ({

        ...op,
        key: `op${index}`,

        // percentual_total: op.usou_quantidade_prevista === true ? <HiCheck/> : <GrFormClose/>,

        // percentual_total: op.percentual_total.replace('.', ','),

        materias_primas: op.materias_primas.map((mp, index) => ({
          ...mp,
          key: `mp${index}`,
          percentual: mp.percentual.replace('.', ','),
          qt_ficha_tecnica: mp.qt_ficha_tecnica.replace('.', ','),
          qt_requisitada: mp.qt_requisitada.replace('.', ','),
        }))
      }));

      setOrdemProducao(data);

    } catch (e) {
      if (e || e.response.data.statusCode >= 500) {
        const erro = 'Erro 500, servidor não respondendo contate o suporte!';
        setErros(erro);
      }
      else {
        const erro = e.response.data.errors[0].message;
        setErros(erro);
      }
    }
    finally {
      setLoading(false);
    }
  };

  const onChangeDataInicio = (data, dataString) => {
    setDataInicio(data);
  };

  const onChangeDataFinal = (data, dataString) => {
    setDataFinal(data);
  }

  const onChangeOPInicio = (value) => {
    setOPInicio(value);
  }

  const onChangeOPFinal = (value) => {
    setOPFinal(value);
  }

  const expandedRowRender = (field) => {
    return <Table columns={colunasTabelaMateriaPrima}
      dataSource={field.materias_primas}
      pagination={false} />;
  }

  //testes
  const estruturaColunasTabelaOrdem = [{
    title: 'Nro. OP',
    dataIndex: 'numero_ordem_producao',
    key: 'numero_ordem_producao',
    width: 100,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Produto Acabado',
    dataIndex: 'desc_prod_acabado',
    key: 'desc_prod_acabado',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Usou Quantidade Prevista',
    dataIndex: 'usou_quantidade_prevista',
    key: 'usou_quantidade_prevista',
    width: 200,
    align: 'right',
    isVisible: true,
    isSearch: true,
    render: (usou_quantidade_prevista) => {
      return (
        usou_quantidade_prevista
          ? <IconCheck><HiCheck size="20px" /></IconCheck>
          : <IconClose><RiCloseLine size="20px" /></IconClose>
      )
    }
  },
  {
    title: 'Ações',
    dataIndex: 'acoes',
    key: 'acoes',
    width: 100,
    isVisible: true,
    isSearch: true,
    render: (_, record) => (
      <Button
        icon={<SearchOutlined />}
        onClick={() => handlerClickNumeroOp(record.numero_ordem_producao)}
      />),
  },
  ];



  function closeModal() {
    setDetalhesOrdemProducao(null);
  }


  function handlerClickNumeroOp(opNumero) {
    setDetalhesOrdemProducao(opNumero);
  }

  const title = {
    codigoTela: '01.77.1342',
    nomeTela: 'Apontamento da ordem de produção',
    tema: 'white',
    fontSize: 'medium',
    paddingBottom: 'without',
  }


  return (
    <>
      {detalhesOrdemProducao &&
        <Modal openCloseModal={closeModal} >
          <FormModal
            title={title}
            close={closeModal}
          >
            <ApontamentosOrdemProducao opNumero={detalhesOrdemProducao} />
          </FormModal>
        </Modal>
      }


      < TituloTela codigoTela="01.77.1331" nomeTela="Relação de Quantidade Ficha x Requisitado" />

      <ContainerFiltro>
        <Row>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Data Inicio'
              children={<DatePicker
                locale={locale}
                format={formatoDataBR}
                defaultValue={dataInicial}
                placeholder='Selecione Data'
                onChange={onChangeDataInicio} />}
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Data Final'
              children={<DatePicker
                locale={locale}
                format={formatoDataBR}
                defaultValue={dataFinal}
                placeholder='Selecione Data'
                onChange={onChangeDataFinal} />}
            />
          </Col>
          <Col flex='0 1 60px'>
            <FormGrupoInput
              label='OP Inicio'
              children={<InputNumber min={1} width={300} defaultValue={opInicial} onChange={onChangeOPInicio} />}
            />
          </Col>
          <Col flex='0 1 60px'>
            <FormGrupoInput
              label='OP Final'
              children={<InputNumber width={1000} min={1} defaultValue={opFinal} onChange={onChangeOPFinal} />}
            />
          </Col>

          <Col flex='0 1 60px'>
            <div style={{ padding: '28px 0px 16px 16px' }}>
              <Button type='primary' onClick={() => carregaOrdensProducoes()}>Filtrar</Button>
            </div>
          </Col>
          <Col md={24} lg={24} xs={24} sm={24} xl={24}>
            {erros &&

              <Alert

                closeText="Fechar"
                message={erros}
                showIcon
                type="error"
                action={
                  <Button size="small" danger show={true}>
                    Detalhes
                  </Button>
                }
              />
            }
          </Col>
        </Row>
      </ContainerFiltro>

      {loading
        ?
        <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
          <Spin size="large" />
        </Row>
        :
        <Row>
          <Col span={24} >
            <TabelaPersonalizada
              titulo='Ficha Técnica x Requisitada'
              columns={estruturaColunasTabelaOrdem}
              dataSource={ordemProducao}
              configTabela={{
                size: 'small',
                pagination: {
                  size: 'small',
                  defaultPageSize: 20,
                },
                expandable:
                  { expandedRowRender: (field) => expandedRowRender(field) }
              }}
            />

          </Col>
        </Row>
      }
    </>
  );
}

export default OrdemProducaoFichaRequisitado;