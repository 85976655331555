import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';

import api from '../service/api';
import { Context } from './AuthContext';
const AccessContext = createContext([]);
const codePageHome = 0;

function AccessProvider({ children }) {
  const [accessCodeRelease, setAccessCodeRelease] = useState([0]);
  const [acessCodesHide, setAcessCodesHide] = useState([]);
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function loadPermissoes() {
      if (!empresa.id) {
        setAccessCodeRelease([codePageHome]);
        setAcessCodesHide([]);
        return false;
      }
      
      try {
        const { data } = await api.get(`empresas/${empresa.id}/permissoes-acesso`);
        const permissoes = data.response;
        setAccessCodeRelease([codePageHome, ...permissoes.codigo_telas_liberar]);
        setAcessCodesHide(permissoes.codigo_telas_ocultar);
      } catch (error) {
        setAccessCodeRelease([codePageHome]);
        setAcessCodesHide([]);
      }
    }

    loadPermissoes();
  }, [empresa]);

  const hasAccess = useCallback((code) => {
    if (code === '') {
      return false;
    }

    return accessCodeRelease.includes(Number(code));
  }, [accessCodeRelease]);

  const hideItem = useCallback((code) => {
    if (code === '') {
      return false;
    }

    return acessCodesHide.includes(Number(code));
  }, [acessCodesHide]);

  return (
    <AccessContext.Provider value={{ hasAccess, hideItem, accessCodeRelease, acessCodesHide }}>
      {children}
    </AccessContext.Provider>
  );
};

function useAccess() {
  const context = useContext(AccessContext);
  return context;
}

export { AccessProvider, useAccess };