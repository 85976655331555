import React from "react";

import { DataTableColumnHeader } from '../../../../../components/Table/DataTableColumnHeader';

export const columns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Código" />,
    title: "Código",
    accessorKey: "codigo_conta",
    widthColumn: 100,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Conta" />,
    title: "Conta",
    accessorKey: "desc_conta",
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Valor" />,
    cell: ({ row }) => row.original.valor_formatado,
    title: "Valor",
    accessorKey: "valor",
    widthColumn: 140,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="%" />,
    cell: ({ row }) => row.original.parcentual_formatado,
    title: "%",
    accessorKey: "parcentual",
    widthColumn: 140,
    align: 'right',
  }
];