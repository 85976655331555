import React from 'react';

import ImageInitialState from './ImageInitialState';

import { Container } from './styles';

function InitialState() {
  return (
    <Container>
      <ImageInitialState />
      <p>Informe os dados da nota fiscal para consultar as ocorrências disponíveis.</p>
    </Container>
  );
}

export default InitialState;