import React, { useState } from 'react';
import {
    useLoadScript,
    GoogleMap,
    Marker,
    InfoWindow,
    MarkerClusterer
} from "@react-google-maps/api";


const Maps = ({ locations }) => {
    const [mapRef, setMapRef] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [center, setCenter] = useState({ lat: -15.804602, lng: -47.849138 });
    const [zoom, setZoom] = useState(5);
    const [clickedLatLng, setClickedLatLng] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);
    const { isLoaded } = useLoadScript({
        // Enter your own Google Maps API key
        googleMapsApiKey: "AIzaSyCc6W5RZWC5xD49PpnBLqFQPkZp42ySl8c"
    });

    const mapContainerStyle = {
        height: "85.5vh",
        width: "100%"
    };

    const fitBounds = map => {
        const bounds = new window.google.maps.LatLngBounds();
        locations.map(place => {
            bounds.extend({ lat: place.lat, lng: place.lng });
            return place.uid;
        });
        map.fitBounds(bounds);
    };

    const loadHandler = map => {
        // Store a reference to the google map instance in state
        setMapRef(map);
        // Fit map bounds to contain all markers
        // todos os marcadores
        fitBounds(map);
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            return { ...prevState, [place.uid]: marker };
        });
    };

    const onCenterChanged = mapRef => {
        if (mapRef && mapRef.getCenter()) {
            const ccenter = mapRef.getCenter().toJSON();
            if (ccenter.lat !== center.lat && ccenter.lng !== center.lng) {
                setCenter(mapRef.getCenter().toJSON());
            }
        }
    };

    const markerClickHandler = (event, place) => {
        // Remember which place was clicked
        setSelectedPlace(place);

        // Required so clicking a 2nd marker works as expected
        if (infoOpen) {
            setInfoOpen(false);
        }

        setInfoOpen(true);

        // If you want to zoom in a little on marker click
        if (zoom < 13) {
            setZoom(10);
        }

        // if you want to center the selected Marker
        setCenter(place.pos);
    };

    const statusVeiculoEmOperacaoColor = {
        0: '#e74a3b',
        1: '#1cc88a',
        2: '#ffff00',
    }

    const iconBase = 'https://i.imgur.com/';
    const icons = {
        0: {
            icon: iconBase + 'dBIJzi3.png',
        },
        1: {
            icon: iconBase + 'EiwzRU5.png'
        },
        2: {
            icon: iconBase + 'lM3viwW.png',
        },
    };

    const renderMap = () => {
        return (
            <>
                <GoogleMap
                    id="maps"
                    // onLoad={loadHandler}
                    onUnmount={() => console.log("Unmount")}
                    onCenterChanged={() => onCenterChanged(mapRef)}
                    onClick={e => setClickedLatLng(e.latLng.toJSON())}
                    center={center}
                    zoom={zoom}
                    mapContainerStyle={mapContainerStyle}
                >
                    <MarkerClusterer>
                        {clusterer =>
                            locations.map(location => (
                                <Marker
                                    key={location.uid}
                                    position={{
                                        lng: parseFloat(location.lng),
                                        lat: parseFloat(location.lat)
                                    }}
                                    // icon={{
                                    //     path: "M20.125 7C20.3571 7 20.5796 7.09219 20.7437 7.25628C20.9078 7.42038 21 7.64294 21 7.875V8.75H22.785C23.1783 8.75035 23.5665 8.83907 23.9209 9.00961C24.2753 9.18014 24.5868 9.42813 24.8325 9.73525L27.4242 12.9728C27.7972 13.4386 28.0002 14.0175 28 14.6142V18.375C28 19.0712 27.7234 19.7389 27.2312 20.2312C26.7389 20.7234 26.0712 21 25.375 21H24.5C24.5 21.9283 24.1313 22.8185 23.4749 23.4749C22.8185 24.1313 21.9283 24.5 21 24.5C20.0717 24.5 19.1815 24.1313 18.5251 23.4749C17.8687 22.8185 17.5 21.9283 17.5 21H8.75C8.75 21.9283 8.38125 22.8185 7.72487 23.4749C7.0685 24.1313 6.17826 24.5 5.25 24.5C4.32174 24.5 3.4315 24.1313 2.77513 23.4749C2.11875 22.8185 1.75 21.9283 1.75 21C1.28587 21 0.840752 20.8156 0.512563 20.4874C0.184375 20.1592 0 19.7141 0 19.25V17.5H19.25V7.875C19.25 7.64294 19.3422 7.42038 19.5063 7.25628C19.6704 7.09219 19.8929 7 20.125 7ZM5.25 19.25C4.78587 19.25 4.34075 19.4344 4.01256 19.7626C3.68437 20.0908 3.5 20.5359 3.5 21C3.5 21.4641 3.68437 21.9092 4.01256 22.2374C4.34075 22.5656 4.78587 22.75 5.25 22.75C5.71413 22.75 6.15925 22.5656 6.48744 22.2374C6.81563 21.9092 7 21.4641 7 21C7 20.5359 6.81563 20.0908 6.48744 19.7626C6.15925 19.4344 5.71413 19.25 5.25 19.25ZM21 19.25C20.5359 19.25 20.0908 19.4344 19.7626 19.7626C19.4344 20.0908 19.25 20.5359 19.25 21C19.25 21.4641 19.4344 21.9092 19.7626 22.2374C20.0908 22.5656 20.5359 22.75 21 22.75C21.4641 22.75 21.9092 22.5656 22.2374 22.2374C22.5656 21.9092 22.75 21.4641 22.75 21C22.75 20.5359 22.5656 20.0908 22.2374 19.7626C21.9092 19.4344 21.4641 19.25 21 19.25ZM24.031 19.25H25.375C25.6071 19.25 25.8296 19.1578 25.9937 18.9937C26.1578 18.8296 26.25 18.6071 26.25 18.375V14.6125C26.2496 14.4139 26.1818 14.2214 26.0575 14.0665L23.4675 10.829C23.3857 10.7265 23.2818 10.6437 23.1637 10.5868C23.0456 10.5299 22.9161 10.5002 22.785 10.5H21V17.5C21.6144 17.5 22.2179 17.6617 22.7499 17.9689C23.282 18.2761 23.7238 18.7179 24.031 19.25Z",
                                    //     fillColor: statusVeiculoEmOperacaoColor[location.status],
                                    //     fillOpacity: 1,
                                    //     scale: 1,
                                    // }}
                                    // icon = {{ 

                                    // }}

                                    icon={
                                        icons[location.status].icon
                                    }
                                    clusterer={clusterer}
                                    onLoad={marker => markerLoadHandler(marker, location)}
                                    onClick={event => markerClickHandler(event, location)}
                                />
                            ))
                        }
                    </MarkerClusterer>
                    {infoOpen && selectedPlace && (
                        <InfoWindow
                            anchor={markerMap[selectedPlace.uid]}
                            onCloseClick={() => setInfoOpen(false)}
                        >
                            <div>
                                <h3>{selectedPlace.uid}</h3>
                                <div>Placa: {selectedPlace.placa}</div>
                                <div> {selectedPlace.user_name} </div>
                                <div> {selectedPlace.veiculos} </div>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        );
    };

    return (
        <div>
            <>
                <div >{isLoaded ? renderMap() : null}</div>
            </>
        </div>
    )
}

export default Maps;
