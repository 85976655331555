import React, { useState, useContext } from 'react';

import api from '../../service/api';
import { Context } from '../../Context/AuthContext';

import { Container, Wrapper, ContainerTitulo, ContainerPesquisa, Bottom, WrapperTable } from '../FiltraOperador/styles';
import { Table, Button, Input, Alert } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import TituloTela from '../TituloTela';

const { Search } = Input;

const columns = [
    {
        title: 'id',
        dataIndex: 'id',
        align: 'right',
        width: '4%',
    },
    {
        title: 'Nome',
        dataIndex: 'Nome',
    },
];

function FiltraRecursos({ atualizaCodigoOperador, atualizaNomeOperador, openCloseModal }) {
    const [codigoSelecionado, setCodigoSelecionado] = useState('');
    const [operadoresFiltrados, setOperadoresFiltrados] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [OperadorNome, setOperadorNome] = useState('');
    const [codigoFiltrado, setcodigoFiltrado] = useState('');
    const [erro, setErro] = useState(false);

    const { empresa } = useContext(Context);

    function handleSelecionar() {
        if (codigoSelecionado !== '') {
            atualizaCodigoOperador(codigoSelecionado);
            atualizaNomeOperador(OperadorNome);
            openCloseModal();
        } else {
            setErro(true)
        }
    };

    const onChange = (event) => {
        setcodigoFiltrado(event.target.value);
        event.target.value.length > 0 && setErro(false)
    }

    const onSearch = async (value) => {
        try {
            setErro(false);
            let result = [];

            if (codigoFiltrado === '') {
                const { data } = await api.get(`/empresas/${empresa.id}/pcp/recursos`, {
                    params: { Nome: value }
                });

                result = data.response;
            } else {
                const { data } = await api.get(`/empresas/${empresa.id}/pcp/recursos`, {
                    params: { id: codigoFiltrado }
                });
                result = data.response
            }

            const dataCliente = result.map((operador, index) => {
                return {
                    key: `codigo${index}`,
                    ...operador,
                }

            });

            setOperadoresFiltrados(dataCliente);
        } catch (error) {
            console.log(`Erro na consulta  de cliente: ${error}`);
        };
    };



    return (
        <Container>
            <Wrapper>
                <ContainerTitulo>
                    <TituloTela codigoTela="01.77.1355" nomeTela="Filtrar Recursos" tema='white' fontSize='medium' paddingBottom='without' />

                    <Button size="small" shape="circle" icon={<CloseOutlined />} onClick={openCloseModal} />
                </ContainerTitulo>
                <ContainerPesquisa>
                    <Input size="middle" style={{ width: "95px" }} placeholder="Código" value={codigoFiltrado} onChange={onChange} />
                    <Search size="middle" placeholder="Recurso" onSearch={onSearch} enterButton />
                </ContainerPesquisa>
                {erro && <Alert message="Operação inválida. Nenhum Recurso selecionado." type="warning" />}

                <WrapperTable>
                    <Table
                        rowSelection={{
                            type: 'radio',
                            onChange: (selectedRowKeys, selectedRows) => {
                                setCodigoSelecionado(selectedRows[0].id)
                            },
                            getCheckboxProps: (record) => ({
                                disabled: record.Nome === "Disabled User",
                                name: record.Nome
                            })
                        }}

                        columns={columns}
                        size="small"
                        description={"Nenhum Registro Encontrado"}
                        itemRender={("Pagina")}
                        dataSource={operadoresFiltrados}
                    />
                    <Bottom>
                        <Button size="middle" type="primary" onClick={handleSelecionar}>Selecionar</Button>
                    </Bottom>
                </WrapperTable>
            </Wrapper>
        </Container>
    );
}
export default FiltraRecursos;