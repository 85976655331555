import styled from "styled-components";

import { RiArrowLeftRightFill } from "react-icons/ri";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 15px 15px 15px 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
`;

export const WrapperIcon = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
`;

export const WrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
`;

export const WrapperMovimento = styled.div`
  display: flex;
  gap: 100px;
  width: 100%;
  height: 100%;
`;

export const WrapperLocacao = styled.div`
  display: flex;
  gap: 100px;
  width: 100%;
  height: 100%;
`;

export const ArrowLeftRightIcon = styled(RiArrowLeftRightFill)`
  width: 18px;
  height: 18px;
  color: #171717;
`;