/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Context } from "../../../Context/AuthContext";
import { useQuery } from "../../../hooks/useQueryParams";
import api from "../../../service/api";
import TituloTela from "../../../components/TituloTela";
import CardGrupo from "./CardGrupo";
import { GrupoIcon } from "./CardGrupo/icons";
import Modal from '../../../components/Modal';
import FormModal from '../../../components/FormModal';
import EquipamentoDetalhes from "../EquipamentoDetalhes";

import { Header,
         Container,
         SecaoGruposEquipamentos,
         InputFiltro,
         WrapperInput,
         ListaGrupos,
         SecaoListaEquipamentos,
         HeaderContainer, 
         WrapperIcon,
         TitleGrupo,
         Table,
         HeaderRow,
         HeaderCell,
         Row,
         Cell,
         IconArrowRight,
         Tag,
         IconBack,
         LinkBack
} from './styles';

const titulos = {
  t: 'Equipamentos',
  l: 'Equipamentos Locados',
  e: 'Equipamentos Estoque',
  m: 'Equipamentos Manutenção',
}

function EquipamentosSituacao() {
  const [equipamentos, setEquipamentos] = useState([]);
  const [idEquipamento, setIdEquipamento] = useState(null);
  const [buscaGrupo, setBuscaGrupo] = useState('')
  const [grupoAtivo, setGrupoAtivo] = useState({});
  const { empresa } = useContext(Context);
  const query = useQuery();

  const gruposEquipamento = useMemo(() => {
    const grupos = [];
    let idGrupo = 0;
    
    equipamentos.forEach(equipamento => {
      if(idGrupo !== equipamento.id_grupo){
        const grupo = {
          idGrupo: equipamento.id_grupo,
          descGrupo: equipamento.desc_grupo,
          quantidade: 0,
          percentual: 0,
        }
        
        const equipamentosGrupo = equipamentos.filter(
          (equip) => equip.id_grupo === equipamento.id_grupo
        );

        grupo.quantidade = equipamentosGrupo.length;

        grupo.percentual = (equipamentosGrupo.length / equipamentos.length * 100)
                           .toFixed(2)
                           .replace('.', ',');

        grupos.push(grupo);
      };

      idGrupo = equipamento.id_grupo;
    })

    return grupos
  }, [equipamentos]);

  useEffect(() => {
    async function getEquipamentosPorSituacao() {
      const idSitucao = query.get('id_situacao') || 't';
      const { data } = await api.get(`empresas/${empresa.id}/faturamento/locacao_equipamentos/equipamentos/situacao?id_situacao=${idSitucao}`)

      setEquipamentos(data.result)
    }

    getEquipamentosPorSituacao();
  }, []);

  const gruposEquipamentoFiltrados = useMemo(() => gruposEquipamento.filter((grupo) => (
    grupo.descGrupo.toLowerCase().includes(buscaGrupo.toLowerCase())
  )), [equipamentos, buscaGrupo]);

  const equipamentosDoGrupo = useMemo(() => {
    const equipamentosFiltrados = equipamentos.filter( equipamento => equipamento.id_grupo === grupoAtivo.idGrupo )

    return equipamentosFiltrados
  }, [grupoAtivo])
  
  const handleClickGrupo = useCallback((grupo) => {
    setGrupoAtivo(grupo);
  }, []);

  const handleClickEquipamento = useCallback((id) => {
    setIdEquipamento(id);
  }, []);
  
  const handleCloseModal = useCallback(() => {
    setIdEquipamento(null);
  }, []);

  function handleChangeBuscaGrupo(event) {
    setBuscaGrupo(event.target.value);
  };

  return (
    <>
      {
        idEquipamento && (
          <Modal 
            openCloseModal={handleCloseModal}
          >
            <FormModal 
              title={{
                nomeTela: 'Detalhes Equipamento',
                codigoTela: "01.76.1398",
                tema: 'white',
                fontSize: 'medium',
                paddingBottom: 'without',
              }}
              close={handleCloseModal}
            >
              <EquipamentoDetalhes idEquipamento={idEquipamento}/>
            </FormModal>
          </Modal>
        )
      }

      <Header>
        <TituloTela
          nomeTela={titulos[query.get('id_situacao')?.toLowerCase() || 't']}
          codigoTela="01.76.1397"
        />

        <LinkBack to='/faturamento/locacao_equipamentos/dashboard'>
          <IconBack /> 
          Voltar
        </LinkBack>
      </Header>

      <Container>
        <SecaoGruposEquipamentos>
          <WrapperInput>
            <InputFiltro 
              value={buscaGrupo}
              onChange={(event) => handleChangeBuscaGrupo(event)}
              placeholder="Pesquisar por grupo..."/>
          </WrapperInput>

          <ListaGrupos>
            {gruposEquipamentoFiltrados.map(grupo => (
              <CardGrupo
                key={grupo.idGrupo}
                grupo={grupo}
                active={ grupoAtivo.idGrupo === grupo.idGrupo}
                onClick={handleClickGrupo}
              />
            ))}
          </ListaGrupos>
        </SecaoGruposEquipamentos>

        <SecaoListaEquipamentos>
          {grupoAtivo.idGrupo > 0
            ? (
            <>
              <HeaderContainer>
                <WrapperIcon>
                  <GrupoIcon width={24} height={24} />
                </WrapperIcon>
                
                <TitleGrupo>{grupoAtivo.descGrupo}</TitleGrupo>
              </HeaderContainer>

              <Table> 
                <thead>
                  <HeaderRow>
                    <HeaderCell>ID</HeaderCell>
                    <HeaderCell>Descrição</HeaderCell>
                    <HeaderCell>Lacre</HeaderCell>
                    <HeaderCell>Situação</HeaderCell>
                    <HeaderCell></HeaderCell>

                  </HeaderRow>
                </thead>
                <tbody>
                  {equipamentosDoGrupo.map((equipamento) => (
                    <Row key={equipamento.id_equipamento}>
                      <Cell><div>{equipamento.id_equipamento}</div></Cell>
                      <Cell><div>{equipamento.desc_equipamento}</div></Cell>
                      <Cell><div>{equipamento.nro_lacre}</div></Cell>
                      <Cell>
                        <div>
                          <Tag type={equipamento.id_situacao.toLowerCase()}>{equipamento.desc_situacao}</Tag>
                        </div>
                      </Cell>
                      <Cell>
                        <div>
                          <IconArrowRight onClick={() => handleClickEquipamento(equipamento.id_equipamento)}/>
                        </div>
                      </Cell>
                    </Row>
                  ))}
                </tbody>
              </Table>
            </>
            )
            : (
              <div style={{fontSize: '24px'}}>Selecione um grupo...</div>
            )}
        </SecaoListaEquipamentos>
      </Container>
    </>

  )
}

export default EquipamentosSituacao;