import styled from 'styled-components';

export const Container = styled.div`
  --corCard: ${(props) => (parseInt(props.situacao) === 0 ? '#3CB371' : '#f4c22b')};
  --corCard: ${(props) => (parseInt(props.situacao) > 1 ? '#f44236' : '')};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  min-width: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-top: 3px solid var(--corCard);
  border-radius: 8px;
  overflow: hidden;
`;

export const ButtonStyled = styled.button`
  cursor: ${({ nrOp }) => nrOp ? 'pointer' : 'no-drop'};
  width: 100%;
  height: auto;
  text-align: left;
  padding: 0;
  border-radius: 8px;
  background: white;
  outline: none;
`;

export const ButtonStyledOcupado = styled.button`
  cursor: ${({ recursoId }) => recursoId ? 'pointer' : 'no-drop'};
  width: 100%;
  height: auto;
  text-align: left;
  padding: 0;
  border-radius: 8px;
  background: white;
  outline: none;
`;

export const Cabecalho = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 20px;

  > h3 {
    margin: 0;
  }
`;

export const Informacoes = styled.div`
  position: relative;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1px 1fr;
  padding: 15px 20px;

  > .divisor{
    border-right: 1px solid #f1f1f1; 
    height: 100%;
  }


  @media only screen and (max-width: 1024px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
    
    > .divisor{
      border-bottom: 1px solid #f1f1f1; 
    }
  }  
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  
  > strong {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    padding: 4px 0;
    color: rgba(0, 0, 0, 0.85);
  }

  > span {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
    color: #888;
    padding: 4px;
  }
  
  > span.timer {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 12px;
    color: #fff;
    background: var(--corCard);
    border-radius: 4px;
    padding: 3px 7px;
    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
      margin-right: 6px;
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  } 
`;

