import styled from "styled-components";

export const Container = styled.li`
  display: flex;
  padding: 16px;
  width: 100%;

  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e5e7eb;

  background: ${({ active }) => active ? '#F5F5F5' : '#FFFFFF'};

  cursor: pointer;
  :hover {
    background: #F5F5F5;    
  }

  > div {
    display: flex;
    align-items: center;  
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 18px;
  margin-right: 16px;
  background: #d1d5db;
`;

export const WrapperInfo = styled.div`
  
`;

export const TitleCard = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #000000;
`;

export const ValuesContiner = styled.div`
  span:first-child {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-right: 60px;
  }

  span:last-child {
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
  }

`;
