import React from 'react';
import { Container, Message } from './styles';

import acessoNegado from '../../assets/acesso-negado.svg';


function AcessoNegado() {
  return (
    <Container>
      <img src={acessoNegado} alt="Acesso Negado" />
      
      <Message>
        <strong>Acesso Negado</strong>
        <span>Suas credencias não possuem permissão para acessar esta área do sistema.</span>
      </Message>
    </Container>
  ) 
}

export default AcessoNegado;