import React, {useState} from 'react';

import { Container } from './styles';
import { Table } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { formatDateBrazil } from '../../../../../utils/format-date-brazil';
import { useTrackingFormContext } from '../../../../../Context/TrackingFormContext';

function DataTable() {
  const { hash } = useParams()
  const { invoices } = useTrackingFormContext()

  const [pageSize, setPageSize] = useState(50)

  return <Container>
    <Table
      bordered
      tableLayout='auto'
      columns={
        [
          {
            title: "Data de Emissão", dataIndex: "data_emissao", key: "data_emissao", width: 175,
            render: (_, record) => {
              return formatDateBrazil(record.data_emissao, 'dd/MM/yyyy')}
          },
          { title: "Nº da Nota Fiscal", dataIndex: "numero_nota", key: "numero_nota", width: 150 },
          { title: "Série", dataIndex: "serie_nota", key: "serie_nota", width: 75 },
          { title: "Nº do pedido", dataIndex: "nro_pedido", key: "nro_pedido", width: 120, },
          {
            title: "Remetente", dataIndex: "nome_remetente", key: "sender",
            render: (_, record) => (
              <>{record.nome_fantasia_remetente}</>
            )
          },
          {
            title: "Destinatário", dataIndex: "nome_destinatario", key: "recipient",
            render: (_, record) => (
              <>{record.nome_fantasia_destinatario}</>
            )
          },
          {
            title: "Ações", key: "action", fixed: 'right', width: 115, render: (_, record) => (
              <Link
                to={{ pathname: `/logistica/rastreamento-transportadora/${hash}/detalhes/${record.hash}` }}
              >Ver detalhes</Link>
            )
          }
        ]
      }
      dataSource={invoices}
      rowKey={record => 
        `${record.numero_nota}-${record.serie_nota}-${record.nro_pedido}-${record.nome_remetente}-${record.data_emissao}`
      }
      size="large"
      scroll={{
        x: 'max-content',
      }}
      pagination={{ pageSize, onShowSizeChange: (_e, value) => {setPageSize(value)},size: "small", showTotal: (total, range) => (<p>{total} registros</p>), position: ["topRight", "bottomRight"]}}
    />
  </Container>;
}

export default DataTable;