import React, { useState, useEffect, useContext } from 'react';

import { Container, Header, WrapperTable } from './styles';

import { Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { formatDecimal } from '../../../utils/format-decimal';
import { round } from '../../../utils/round';

import { Context } from '../../../Context/AuthContext';
import api from '../../../service/api';

import moment from 'moment'

const coresClasse = {
  a: '#1cc88a',
  b: '#FCD34D',
  c: '#e74a3b',
}

const colunasTabela = [
  {
    title: 'ID',
    dataIndex: 'id_produto',
    key: 'id_produto',
    width: 60,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nome Produto',
    dataIndex: 'desc_produto',
    key: 'desc_produto',
    width: 500,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'UND',
    dataIndex: 'unidade_medida',
    key: 'unidade_medida',
    width: 100,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Quantidade Total Vendas',
    dataIndex: 'quantidade_total_liquida',
    key: 'quantidade_total_liquida',
    width: 200,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Valor Total Vendas',
    dataIndex: 'valor_total_liquido',
    key: 'valor_total_liquido',
    width: 150,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: '% Vendas',
    dataIndex: 'perc_valor_vendas',
    key: 'perc_valor_vendas',
    width: 150,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: '% Acumulado',
    dataIndex: 'perc_valor_acumulado',
    key: 'perc_valor_acumulado',
    width: 140,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Classe',
    dataIndex: 'classe',
    key: 'classe',
    align: 'center',
    width: 70,
    isVisible: true,
    isSearch: true,
    render: value => (
      <Text 
        style={{color: coresClasse[value.toLowerCase()]}}
        strong
      >
        {value}
      </Text>)
  },
  {
    title: 'Ordem',
    dataIndex: 'ordem',
    key: 'ordem',
    width: 70,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
]; 

function ListaProdutos({cliente, periodo, close}) {
  const [isLoading, setIsLoading] = useState(true);
  const [listaProdutos, setListaProdutos] = useState([]);
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function getListaProdutos(){
      try {
        setIsLoading(true);
        
        const {data} = await api.get(
          `empresas/${empresa.id}/faturamento/curva-abc-clientes/${cliente.id_cliente}/detalhes`,
          {
            params: {
              dataInicio:  moment(periodo.dataInicial).format('YYYY/MM/DD'),
              dataFim: moment(periodo.dataFinal).format('YYYY/MM/DD'),
            }
          }
        );

        const produtos = data.response.map( (produto, key) => (
          {
            ...produto,
            key: `prod${key}`,
            valor_total_liquido: formatDecimal(produto.valor_total_liquido),
            quantidade_total_liquida: formatDecimal(produto.quantidade_total_liquida, 3),
            soma_geral: formatDecimal(produto.soma_geral),
            perc_valor_vendas: formatDecimal(round(produto.perc_valor_vendas, 2)),
            perc_valor_acumulado: formatDecimal(round(produto.perc_valor_acumulado, 2)),
          }
        ))

        setListaProdutos(produtos);
      } catch (error) {
        console.log(error);  
      } finally {
        setIsLoading(false);
      }

    }

    getListaProdutos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Header>
        <strong>Cliente: </strong>
        <span>{cliente.id_cliente} - { cliente.nome_cliente}</span>
      </Header>


      <WrapperTable>
        <Table
          loading={isLoading} 
          columns={colunasTabela}
          dataSource={listaProdutos}
          size= 'small'
          scroll={{ x: '100%', y:550 }}
          pagination={{
            pageSize: 20
          }}
          
        />
      </WrapperTable>
    </Container>);
}

export default ListaProdutos;