import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgb(248, 249, 250);
  padding: 4px 16px;
`;

export const WrapperInfo = styled.div`
  display: flex;
  background: #FFFFFF;
  border-radius: 8px;
`

export const WrapperInfoEquipamento = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
`

export const WrapperLocacao = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  border-left: 1px solid #d1d5db;
`

export const WrapperMovimentacaoes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
`;

export const ListaMovimentacoes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

`;
