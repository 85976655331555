import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

export const WrapperInput = styled.div`
  width: 350px;
  max-width: 100%;
`;