import styled from 'styled-components';

export const ButtonContainer = styled.div`
  padding-top: 31px;

  @media (max-width: 767px) {
    padding-top: 10px;
  }
`;

export const TituloCollapse = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
`;
