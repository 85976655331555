import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  
  .textContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      margin: 0;
      color: #b91c1c;
    }

    p {
      margin: 0;
      text-align: center;
      color: #787878;
      font-weight: bold;
      font-size: 1rem;
      line-height: 150%;
      max-width: 60%;

      @media screen and (max-width: 768px){
        font-size: 12px;
      }
    }
  }

  svg {
    filter: grayscale(0.65);
    width: 96px;
    height: 96px;

    @media screen and (max-width: 768px){
      width: 72px;
      height: 72px;
    }
  }
`;
