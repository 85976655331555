const HOUR_SEC_EQUIVALENT = {
  qtd_hr_apontada: 'qtd_sec_apontado',
  qtd_hr_cobrada_os: 'qtd_sec_cobrada_os',
  dif_qtd_hr_cobrada_x_qtd_hr_apontada: 'dif_qtd_sec_cobrada_x_qtd_sec_apontada',
  qtd_hr_ficha_tecnica: 'qtd_sec_ficha_tecnica',
  dif_qtd_hr_real_x_qtd_hr_ficha_tecnica: 'dif_qtd_sec_real_x_qtd_sec_ficha_tecnica',
  qtd_hr_executado: 'qtd_sec_executado'
}

export const sortByTime = (rowA, rowB, columnId) => {
  const secA = rowA.original[HOUR_SEC_EQUIVALENT[columnId]]
  const secB = rowB.original[HOUR_SEC_EQUIVALENT[columnId]]
  return secA - secB
}