import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  height: 100vh;

  header {
    width: 100%;
    
    h3 {
      width: 100%;
      text-align: center;
      font-size: 24px;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
   
    p {
      font-size: 16px;
      line-height: 24px;
      color: #B6BFCC;
    }
  }
`;

export const TranspotadoraContainer = styled.div`
  display: flex;
  align-items: flex-end;
  row-gap: 8px;
  column-gap: 14px;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0014FF;
    border-radius: 8px;
    width: 52px;
    height: 52px;
    padding: 8px;
  }

  .infos {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-size: 14px;
      line-height: 20px;
      color: #B0B0B0;
    }

    span:last-child {
      font-size: 16px;
      line-height: 24px;
      color: #232323;
    }
  }
`
export const Form = styled.form`
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
  gap: 12px;

  flex-wrap: wrap;

  @media screen and (max-width: 600px){
    button {
      min-width: 100%;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NotaFiscalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .grupo-info {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-size: 14px;
      line-height: 20px;
      color: #B0B0B0;
    }

    span:last-child {
      font-size: 16px;
      line-height: 24px;
      color: #232323;
    }
  }

  .detalhes-nota {
    display: flex;
    padding: 12px;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    border-radius: 12px;

    div {
      display: flex;
      column-gap: 24px;
      margin-top: 4px;
    }

    strong {
      font-size: 16px;
      line-height: 24px;
      color: #232323;
      text-transform: uppercase;
    }
  }

  .container-remetente-destinatario {
    display: flex;
    padding: 12px;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    border-radius: 12px;  
    margin-top: -1px;

    .detalhes-remetente{
      div {
        display: flex;
        column-gap: 24px;  
        margin-top: 4px;
      }

      strong {
        color: #0EBC93
      }

      @media screen and (max-width: 600px){
        div {
          flex-direction: column;
        }
      }
    }

    .detalhes-destinatario{
      div {
        display: flex;
        column-gap: 24px;
        margin-top: 4px;  
      }

      strong {
        color: #5251FA
      }

      @media screen and (max-width: 600px){
        div {
          flex-direction: column;
        }
      }
    }
  }
`
export const OcorrenciasContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 32px;

`
export const OcorrenciaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .status {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #0EBC93;

    ::before, ::after {
      position: absolute;
      content: '';
      border-left: 2px dashed #E3E3E3;
      height: 80%;
    }

    ::before {
      top: 100%;
      display: ${({isLast}) => isLast ? 'none' : 'flex'};
    }    
    
    ::after {
      bottom: 100%;
      display: ${({isFirst}) => isFirst ? 'none' : 'flex'};
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    color: #232323;

    .data-hora {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;
      line-height: 20px;
      color: #B0B0B0;

      .separador {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #D9D9D9;
      }
    }
  }
`

export const Separador = styled.div`
  width: 100%;
  border-top: 1px dashed #B0B0B0;
  margin: 12px 0;
` 