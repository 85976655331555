import styled from 'styled-components';

export const Grafico = styled.div`
    height: auto;
    width: 100%;
`;

export const GraficoMensal = styled.div`
     height: 445px;
      width: 100%;
`;
