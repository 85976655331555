import React from "react";


import { Container } from './styles';
function TabelaAprovacaoOrdemCompraMobile({ itens }) {

    return (
        <Container>
            <table className="tabela">
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Qtd.</th>
                        <th>UN.</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {itens.map((item) => (
                        <tr key={item.codigo_produto}>
                            <td>{item.descricao_produto}</td>
                            <td>{item.quantidade}</td>
                            <td>{item.unidade_medida}</td>
                            <td>{item.valor_unitario}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    )
}

export default TabelaAprovacaoOrdemCompraMobile;