import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  min-height: 90vh;
  min-height: 90svh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  height: 100svh;

  gap: 32px;

  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    position: relative;
    
    .header-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 0;
      gap: 16px;
      
      background-size: 100% 100%;
      background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
      background-image: 
        linear-gradient(315deg, #00FFFF2E 92%, #073AFF00 100%),
        radial-gradient(75% 75% at 238% 218%, #00FFFF12 30%, #073AFF14 39%),
        radial-gradient(99% 99% at 109% 2%, #00C9FFFF 1%, #073AFF00 100%),
        radial-gradient(99% 99% at 21% 78%, #2800FFFF 0%, #073AFF00 100%),
        radial-gradient(160% 154% at 711px -303px, #2000FFFF 0%, #073AFFFF 100%);

      @media (max-width: 1200px) {
        padding: 24px;
      }

      .header-title {

        .header-title-container {
          max-width: 1200px;
          margin: 0 auto;
          
          /* padding: 24px 24px; */
          display: grid;
          grid-template-areas: "button-1 button-2" "title title";
          row-gap: 24px;
          
          @media (min-width: 1200px) {
            padding: 0;
          }
  
          @media (min-width: 576px) {
            display: flex;
            align-items: center;
            width: 100%;
          }
        }
      }

      .header-transporter-container {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        gap: 24px;
        column-gap: 24px;

        max-width: 1200px;
        /* border: 1px solid #292929; */
        
        .infos {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          span:first-child {
            font-size: 14px;
            line-height: 20px;
            color: #f4f4f5;

            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 16px;
            }
          }

          span:last-child {
            font-size: 24px;
            line-height: 32px;
            font-weight: bold;
            color: #fafafa;

            @media (max-width: 1024px) {
              font-size: 32px;
              line-height: auto;
            }

            @media (max-width: 768px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
        
        .copy-link {
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            padding: 12px 16px;
            border-radius: 2px;
            background-color: transparent;
            border: 1px solid #fafafa;
            
            color: #fafafa;
            font-weight: bold;
            font-size: 16px;
            text-align: left;
            
            cursor: pointer;
            transition: color 150ms;
            
            &:hover {
              background-color: #fafafa;
              color: #00C9FFFF;
            }

            @media screen and (max-width: 768px) {
              padding: 6px 8px;
              font-size: 12px;
            }
          }
        }
      }
    }

    h3 {
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin: 0;
      flex: 1;
      grid-area: title;

      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 14px;
        display: none;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
   
    p {
      font-size: 16px;
      line-height: 24px;
      color: #B6BFCC;
    }
  }
`

export const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  
  
  font-size: 14px;
  text-decoration: none;
  
  white-space: nowrap;
  grid-area: button-1;
  
  a {
    color: #000000;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      color: #fff;
    }
  }

  &:last-of-type {
    justify-content: flex-end;
    grid-area: button-2;
  }
`

export const Content = styled.div`
  width: 100%; 
  max-width: 1200px;

  margin: 0 auto;
  
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
  
  @media (max-width: 768px) {
    padding: 24px 24px;
  }

  @media (max-width: 1200px) {
    padding: 0 24px;
  }

  section {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #292929;
      text-align: center;
      margin: 0;

      @media screen and (max-width: 768px){
        font-size: 20px;
      }
    }
  }
`

export const DataBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 767px) {
    border: 1px solid #D9D9D9;
    padding: 24px;

    display: grid;
    grid-template-areas: "nf-data nf-data" "sender recipient";
    grid-template-columns: 1fr 1fr;
  }

  >div {
    width: 100%;
    
    @media (min-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
    }
  }
  
  .invoice-info {
    grid-area: nf-data;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    h4 { 
      grid-column: 1/5;

      @media (max-width: 768px) {
        grid-column: 1/3;
      }
    }
  }

  .sender-info {
    grid-area: sender;
  }

  .recipient-info {
    grid-area: recipient;
  }

  .sender-info,
  .recipient-info {
    h4 { 
      grid-column: 1/3;
    }

    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 24px;
    row-gap: 8px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  
  h4 { 
    font-size: 20px;
    line-height: 24px;
    color: #292929;
    font-weight: bold;
    text-align: left;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
      grid-column: 1/3;
    }
  }
`

export const TimelineBox = styled.div`
  border: 1px solid #D9D9D9;
  padding: 20px 16px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 24px;
`

export const TimelineItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 56px;

  @media screen and (max-width: 768px){
    gap: 2px;
    padding-left: 28px;
  }

  p {
    margin: 0;
  }

  .item-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: #292929;

    @media screen and (max-width: 768px){
      font-size: 14px;
      line-height: 16px;
    }
  }

  .item-infos {
    display: flex;
    gap: 16px;

    .item-info {
      display: flex;
      flex-direction: column;

      .item-data-label {
        color: #787878;
        font-size: 12px;

        @media screen and (max-width: 768px){
          font-size: 10px;
        }
      }
      
      .item-data-value {
        color: #292929;
        font-size: 16px;

        @media screen and (max-width: 768px){
          font-size: 14px;
        }
      }
    }
  }



  &:not(:last-of-type)::after {
    content: ' ';
    width: 2px;
    height: calc(100% + 24px);
    background-color: #1890FF;

    position: absolute;
    left: 23px;
    top: 100%;
    transform: translateY(calc(-50% + 12px));

    @media screen and (max-width: 768px){
      left: 5px;
    } 
  }

  &::before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1890FF;

    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 768px){
      left: 0px;
    } 
  }
`