import styled from "styled-components";

export const ContainerFiltro = styled.div`
  margin-bottom: 16px;
  border-radius: 3px;
  background: #FFFFFF;
`;

export const ModalResult = styled.div`
  width: 50%;
  border-radius: 5px;
  background: #FFFFFF;
  overflow-y: auto;
  overflow-x: auto;

  @media (min-width: 320px) and (max-width: 760px){
    width: 100%;
  }
`;