import styled from 'styled-components';

export const ButtonStyled = styled.button`
  cursor: ${({ nrOp }) => nrOp ? 'pointer' : 'no-drop'};
  width: 100%;
  height: auto;
  text-align: left;
  padding: 0;
  border-radius: 8px;
  background: white;
  outline: none;
`;

export const ContainerButton = styled.div`

  Button {
    margin: 8px;

    @media (min-width: 320px) and (max-width: 480px) {
       margin: 8px 0 0 0;
    }
  }
`;

