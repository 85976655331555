import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Input, DatePicker, Button, Alert, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import FormGrupoInput from '../FormGrupoInput';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import moment from 'moment';
import FormModal from '../Modal';

import { Context } from '../../Context/AuthContext';
import api from '../../service/api';

import TituloTela from '../TituloTela'
import { formatDateBrazil } from '../../utils/format-date-brazil'
import FiltraOperador from '../FiltraOperador';
import FiltraRecursos from '../FiltraRecursos';
import FiltrarProcessos from '../FiltrarProcessos';
import FiltrarProdutos from '../FiltraProdutos';

import { ContainerFiltro, Cabecalho, Container } from './styles';

const formatoDataBR = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

function ApontamentosOperador() {
    const [dataInicial, setDataInicio] = useState(moment().format('YYYY/MM/DD'));
    const [dataFinal, setDataFinal] = useState(moment().format('YYYY/MM/DD'));
    const [id_operador, setId_operador] = useState(0);
    const [idRecurso, setIdRecurso] = useState(0);
    const [idProcesso, setProcesso] = useState(0);
    const [idProduto, setIdProduto] = useState(0);
    const [inputNomeProduto, setInputNomeProduto] = useState('');
    const [inputNomeOperador, setInputNomeOperador] = useState('');
    const [inputNomeRecurso, setInputNomeRecurso] = useState('');
    const [inputNomeProcesso, setInputNomeProcesso] = useState('');
    const [erros, setErros] = useState();
    const [errosStatus, setErrosStatus] = useState({});
    const [carregando, setCarregando] = useState(false);
    const { empresa } = useContext(Context);
    const [apontamentos, setApontamentos] = useState([]);
    const [abrirModalOperadores, setAbrirModalOperadores] = useState(false);
    const [abrirModalRecursos, setAbrirModalRecursos] = useState(false);
    const [abrirModalProcesso, setAbrirModalProcesso] = useState(false);
    const [abrirModalProduto, setAbrirModalProduto] = useState(false);

    async function handlerConslutaOrdem() {
			let erros = {};

			if (id_operador === '') {
					erros.erroCliente = '* Campo obrigatório';
			}

			if (idRecurso === '') {
					erros.erroCliente = '* Campo obrigatório';
			}

			if (idProcesso === '') {
					erros.erroCliente = '* Campo obrigatório';
			}

			if (idProduto === '') {
					erros.erroCliente = '* Campo obrigatório';
			}

			if ((isNaN(Date.parse(dataInicial))) || (isNaN(Date.parse(dataFinal)))) {
					erros.erroPeriodo = '* Campo obrigatório';
			}

			if (Object.keys(erros).length > 0) {
					setErrosStatus(erros);
					return
			}

      setErrosStatus(erros);

			try {
				setCarregando(true);
	
				const { data } = await api.get(`/empresas/${empresa.id}/pcp/apontamento/`, {
					params: {
						dataInicio: moment(dataInicial).format('YYYY-MM-DD'),
						dataFim: moment(dataFinal).format('YYYY-MM-DD'),
						id_operador: id_operador,
						id_recursos: idRecurso,
						id_processo: idProcesso,
						id_produto: idProduto,
					}
				});

				const apontamento = data.response;

				setApontamentos(apontamento);
			} catch (e) {
				if (e || e.response.data.statusCode >= 500) {
						const erro = 'Erro 500, servidor não respondendo contate o suporte!';
						setErros(erro);
				}
				else {
						const erro = e.response.data.errors[0].message;
						setErros(erro);
				}
			} finally {
					setCarregando(false);
			}
  };

	function handleOnChangeData(dates, dateStrings) {
			if (dates) {
					setDataInicio(moment(dates[0]).format('YYYY/MM/DD'));
					setDataFinal(moment(dates[1]).format('YYYY/MM/DD'));
			} else {
					setDataInicio(null);
					setDataFinal(null);
			}
	};

	function handleOnChangeOperador(value) {
			setId_operador(value.replace(/[^\d\s-/]/g, ""));
	}

	function handleOnChangeRecurso(value) {
			setIdRecurso(value.replace(/[^\d\s-/]/g, ""));
	}

	function handleOnChangeProcesso(value) {
			setProcesso(value.replace(/[^\d\s-/]/g, ""));
	}

	function handleOnChangeProduto(value) {
			setIdProduto(value.replace(/[^\d\s-/]/g, ""));
	}

	function handleFiltraOperador() {
			setAbrirModalOperadores(prevState => !prevState);
	}

	function handleFiltraRecursos() {
			setAbrirModalRecursos(prevState => !prevState);
	}

	function handleFiltraProcessos() {
			setAbrirModalProcesso(prevState => !prevState);
	}

	function handleFiltraProduto() {
			setAbrirModalProduto(prevState => !prevState);
	}

	useEffect(() => {
		async function filtraOperador() {
			if ((id_operador === 0) || (Number(id_operador) === 0)) {
				setInputNomeOperador('');
				return;
			}

			const { data } = await api.get(`/empresas/${empresa.id}/operadores`, {
					params: { id: id_operador }
			});

			if (data.response[0].Nome) {
					setInputNomeOperador(data.response[0].Nome);
			} else
					setInputNomeOperador('Operador não encontrado.');
		};

		filtraOperador();

	}, [empresa.id, id_operador]);

	useEffect(() => {
		async function filtraRecursos() {
			if ((idRecurso === 0) || (Number(idRecurso) === 0)) {
				setInputNomeRecurso('');
				return;
			}

			const { data } = await api.get(`/empresas/${empresa.id}/pcp/recursos`, {
				params: { id: idRecurso }
			});

			if (data.response[0].Nome) {
				setInputNomeRecurso(data.response[0].Nome);
			} else
				setInputNomeRecurso('Recurso não encontrado.');
		};

		filtraRecursos();

	}, [empresa.id, idRecurso]);

	useEffect(() => {
		async function FiltrarProcessos() {
			if ((idProcesso === 0) || (Number(idProcesso) === 0)) {
				setInputNomeProcesso('');
				return;
			}

			const { data } = await api.get(`/empresas/${empresa.id}/pcp/processos`, {
					params: { id: idProcesso }
			});

			if (data.response[0].Nome) {
				setInputNomeProcesso(data.response[0].Nome);
			} else
				Alert.alert('Processo não encontrado.');
		};

		FiltrarProcessos();
	}, [empresa.id, idProcesso]);

	useEffect(() => {
		async function FiltrarProduto() {
			if ((idProduto === 0) || (Number(idProduto) === 0)) {
				setInputNomeProduto('');
				return;
			}

			const { data } = await api.get(`/empresas/${empresa.id}/pcp/produtos`, {
				params: { id: idProduto }
			});

			if (data.response[0].Nome) {
				setInputNomeProduto(data.response[0].Nome);
			} else
				setInputNomeProduto('Produto não encontrado.');
		};

		FiltrarProduto();

	}, [empresa.id, idProduto]);

	function colorDiferenca(valor) {
		if (!valor) {
			return '#000'
		}
		if (valor[0] === '-') {
			return "#e74a3b"
		} else {
			return '#1cc88a'
		}
	}

    return (
        <>
            {abrirModalOperadores
                ? (
                    <FormModal
                        openCloseModal={() => { }}
                    >
                        <FiltraOperador
                            atualizaCodigoOperador={setId_operador}
                            atualizaNomeOperador={setInputNomeOperador}
                            openCloseModal={handleFiltraOperador} />
                    </FormModal>
                )
                : null
            }
            {abrirModalRecursos
                ? (
                    <FormModal
                        openCloseModal={() => { }}
                    >
                        <FiltraRecursos
                            atualizaCodigoOperador={setIdRecurso}
                            atualizaNomeOperador={setInputNomeRecurso}
                            openCloseModal={handleFiltraRecursos} />
                    </FormModal>
                )
                : null
            }
            {abrirModalProcesso
                ? (
                    <FormModal
                        openCloseModal={() => { }}
                    >
                        <FiltrarProcessos
                            atualizaCodigoOperador={setProcesso}
                            atualizaNomeOperador={setInputNomeProcesso}
                            openCloseModal={handleFiltraProcessos} />
                    </FormModal>
                )
                : null
            }
            {abrirModalProduto
                ? (
                    <FormModal
                        openCloseModal={() => { }}
                    >
                        <FiltrarProdutos
                            atualizaCodigoOperador={setIdProduto}
                            atualizaNomeOperador={setInputNomeProduto}
                            openCloseModal={handleFiltraProduto} />
                    </FormModal>
                )
                : null
            }
            <TituloTela codigoTela="01.77.1338" nomeTela="Apontamento por operador" />
            <ContainerFiltro>
                <Row>
                    <Col flex='0 1 150px'>
                        <FormGrupoInput
                            label='Período (Abertura)'
                            children={
                                <RangePicker
                                    style={{ width: 250 }}
                                    locale={locale}
                                    format={formatoDataBR}
                                    defaultValue={
                                        [moment(new Date(), 'YYYY/MM/DD'),
                                        moment(new Date(), 'YYYY/MM/DD')]
                                    }
                                    onChange={handleOnChangeData}
                                />}
                        />
                    </Col>
                    <Col flex='0 1 130px'>
                        <FormGrupoInput
                            label='Operador'
                            erro={errosStatus.erroCliente}
                            children={
                                <>
                                    <Input
                                        style={{ width: 60 }}
                                        value={id_operador}
                                        onChange={(e) => handleOnChangeOperador(e.target.value)}
                                    />
                                    <Input disabled
                                        tabIndex={-1}
                                        value={inputNomeOperador}
                                        style={{ width: 300 }}
                                        placeholder="Selecione o operador"
                                    />
                                    <Button type='primary' icon={<SearchOutlined />} onClick={() => handleFiltraOperador()} />
                                </>
                            }
                        />
                    </Col>
                    <Col flex='0 1 130px'>
                        <FormGrupoInput
                            label='Recurso'
                            erro={errosStatus.erroCliente}
                            children={
                                <>
                                    <Input
                                        style={{ width: 60 }}
                                        value={idRecurso}
                                        onChange={(e) => handleOnChangeRecurso(e.target.value)}
                                    />
                                    <Input disabled
                                        tabIndex={-1}
                                        value={inputNomeRecurso}
                                        style={{ width: 300 }}
                                        placeholder="Selecione o recurso"
                                    />
                                    <Button type='primary' icon={<SearchOutlined />} onClick={() => handleFiltraRecursos()} />
                                </>
                            }
                        />
                    </Col>
                    <Col flex='0 1 130px'>
                        <FormGrupoInput
                            label='Processo'
                            erro={errosStatus.erroCliente}
                            children={
                                <>
                                    <Input
                                        style={{ width: 60 }}
                                        value={idProcesso}
                                        onChange={(e) => handleOnChangeProcesso(e.target.value)}
                                    />
                                    <Input disabled
                                        tabIndex={-1}
                                        value={inputNomeProcesso}
                                        style={{ width: 300 }}
                                        placeholder="Selecione o Processo"
                                    />
                                    <Button type='primary' icon={<SearchOutlined />} onClick={() => handleFiltraProcessos()} />
                                </>
                            }
                        />
                    </Col>
                    <Col flex='0 1 130px'>
                        <FormGrupoInput
                            label='Produto'
                            erro={errosStatus.erroCliente}
                            children={
                                <>
                                    <Input
                                        style={{ width: 60 }}
                                        value={idProduto}
                                        onChange={(e) => handleOnChangeProduto(e.target.value)}
                                    />
                                    <Input disabled
                                        tabIndex={-1}
                                        value={inputNomeProduto}
                                        style={{ width: 300 }}
                                        placeholder="Selecione o Produto"
                                    />
                                    <Button type='primary' icon={<SearchOutlined />} onClick={() => handleFiltraProduto()} />
                                </>
                            }
                        />
                    </Col>
                    <Col flex='0 1 60px'>
                        <div style={{ padding: '28px 0px 16px 16px' }}>
                            <Button type='primary' onClick={() => handlerConslutaOrdem()} >Filtrar</Button>
                        </div>

                    </Col>
                    <Col md={24} lg={24} xs={24} sm={24} xl={24}>
                        {erros &&

                            <Alert

                                closeText="Fechar"
                                message={erros}
                                showIcon
                                type="error"
                                action={
                                    <Button size="small" danger show={true}>
                                        Detalhes
                                    </Button>
                                }
                            />
                        }
                    </Col>
                </Row>
            </ContainerFiltro>
            {
                carregando
                    ?
                    <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
                        <Spin size="large" />
                    </Row>
                    :
                    <>
                        {
                            apontamentos.map((operador) => (
                                <Container >
                                    <Cabecalho>
                                        <h3>{operador.id_operador}</h3>
                                        <h3>{operador.nome_operador}</h3>
                                    </Cabecalho>
                                    <table className="customers">
                                        <thead>
                                            <tr key={operador.id_operador}>
                                                <th>Recurso</th>
                                                <th>Processo</th>
                                                <th>Produto</th>
                                                <th>Numero op</th>
                                                <th>Data inicial</th>
                                                <th>Data final</th>
                                                <th>Tempo total</th>
                                                <th>Tempo prev. unid.</th>
                                                <th>Tempo real unid.</th>
                                                <th>Diferença</th>
                                                <th>Quantidade produzida</th>
                                                <th>Quantidade perdida</th>
                                                <th>Finalizou processo</th>
                                            </tr>
                                        </thead>
                                        {operador.apontamentos.map((apontamento) => (
                                            <tbody>
                                                <tr>
                                                    <td>{apontamento.desc_maquina}</td>
                                                    <td>{apontamento.desc_processo}</td>
                                                    <td>{apontamento.desc_prod_acabado}</td>
                                                    <td>{apontamento.numero_op}</td>
                                                    <td>{formatDateBrazil(apontamento.data_inicio, 'dd/MM/yyyy HH:mm:ss')}</td>
                                                    <td>{formatDateBrazil(apontamento.data_final, 'dd/MM/yyyy HH:mm:ss')}</td>
                                                    <td>{apontamento.tempo}</td>
                                                    <td>{apontamento.tempo_por_qt_previsto}</td>
                                                    <td>
                                                        {apontamento.tempo_por_qt_real}
                                                    </td>
                                                    <td style={{ color: colorDiferenca(apontamento.tempo_real_x_prev) }}>
                                                        {apontamento.tempo_real_x_prev}
                                                    </td>
                                                    <td>{apontamento.qtdade_produziu}</td>
                                                    <td>{apontamento.qtdade_perdeu}</td>
                                                    <td>
                                                        {apontamento.finalizou_processo === "S" ? "Sim" : "Não"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Container>

                            ))
                        }
                    </>
            }

        </>
    )
}


export default ApontamentosOperador;


