import React from 'react';

import { Container } from './styles';
import { useTrackingFormContext } from '../../../../../Context/TrackingFormContext';
import Card from './Card';

function CardsList() {
  const { invoices } = useTrackingFormContext()

  return <Container>
    {invoices.map((item) => (
      <Card key={`${item.numero_nota}-${item.serie_nota}-${item.nro_pedido}-${item.nome_remetente}-${item.data_emissao}`} data={item} />
    ))}
  </Container>;
}

export default CardsList;