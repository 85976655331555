import styled from "styled-components";

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;
  gap: 4px;
  border: 0;
  padding: 0;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: #6B7280;
  letter-spacing: 0.05rem;
  white-space: nowrap;
  cursor: pointer;
  
  span {
    padding: 0;
  }
`;