import styled from 'styled-components';

const varicacoesCoresCard = {
  vermelho: '#e74a3b',
  roxo: '#4e73df',
  verde: '#1cc88a',
  azul: '#4e73df'
}

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    border-radius: .35rem;  
    border-left: .25rem solid ${({ cor }) => varicacoesCoresCard[cor]};
    padding: 1.25rem;
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h5 {
    font-size: .7rem;
    color: ${({ cor }) => varicacoesCoresCard[cor]};
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .25rem;
  }

  strong {
    color: #5a5c69;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 1.25rem;

    small {
      color: #5a5c69;
      font-weight: 700;
      margin-bottom: 0;
      font-size: .60rem;
    }      
  }
`;

export const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;

  svg {
    fill: #dddfeb;
    width: 30px;
    height: auto;

  }
`;
