import React, { useContext, useEffect, useState } from 'react';

import api from '../../../service/api';
import { Context } from '../../../Context/AuthContext';
import { formatDateBrazil } from '../../../utils/format-date-brazil';
import Loader from '../../../components/Loader';
import { formatDecimal } from '../../../utils/format-decimal'

import { Container, Cabecalho } from './styles';

function PedidoCompra({ nroOrdemPedido }) {

    const { empresa } = useContext(Context);
    const [ordemPedido, setOrdemPedido] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function detalheOrdemPedido() {
            setLoading(true);
            const { data } = await api.get(
                `/empresas/${empresa.id}/estoque/detalhe_ordem_compra/${nroOrdemPedido}`,
            );

            const pedidoCompra = {
                ...data,
                data_emissao: formatDateBrazil(data.data_emissao, 'dd/MM/yyyy '),
                valor_produtos: formatDecimal(data.valor_produtos),
                valor_servicos: formatDecimal(data.valor_servicos),
                valor_ipi: formatDecimal(data.valor_ipi),
                valor_frete: formatDecimal(data.valor_frete),
                valor_icms_st: formatDecimal(data.valor_icms_st),
                valor_descontos: formatDecimal(data.valor_descontos),
                valor_total: formatDecimal(data.valor_total),
                valorTotal: parseFloat(data.valor_total) + parseFloat(data.valor_servicos),
                itens: data.itens.map((item) => ({
                    ...item,
                    quantidade: formatDecimal(item.quantidade),
                    valor_unitario: formatDecimal(item.valor_unitario)
                }))
            };


            setOrdemPedido(pedidoCompra);
            setLoading(false);
        }
        detalheOrdemPedido();
    }, [empresa.id, nroOrdemPedido])

    return (
        <>
            < div style={{ background: '#f8f9fa' }}>
                <Container>
                    {loading
                        ?
                        <Loader isLoading={loading} />
                        :
                        <>
                            <Cabecalho>
                                <div>
                                    <div>
                                        <label>Número pedido</label>
                                        <h3>{ordemPedido.nro_oc}</h3>
                                    </div>
                                    <div>
                                        <label>Valor produto</label>
                                        <h3>{ordemPedido.valor_total}</h3>
                                    </div>
                                    <div>
                                        <label>valor icms st</label>
                                        <h3>{ordemPedido.valor_icms_st}</h3>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label>Data pedido</label>
                                        <h3>{ordemPedido.data_emissao}</h3>
                                    </div>
                                    <div>
                                        <label>Valor serviços</label>
                                        <h3>{ordemPedido.valor_servicos}</h3>
                                    </div>
                                    <div>
                                        <label>Valor desconto</label>
                                        <h3>{ordemPedido.valor_descontos}</h3>
                                    </div>

                                </div>
                                <div>
                                    <div>
                                        <label>Código fornecedor</label>
                                        <h3>{ordemPedido.codigo_fornecedor}</h3>
                                    </div>
                                    <div>
                                        <label>Valor frete</label>
                                        <h3>{ordemPedido.valor_frete}</h3>
                                    </div>
                                    <div>
                                        <label>Valor total</label>
                                        <h3>{ordemPedido.valorTotal}</h3>
                                    </div>


                                </div>
                                <div>
                                    <div>
                                        <label>Nome fornecedor</label>
                                        <h3>{ordemPedido.nome_fornecedor}</h3>
                                    </div>
                                    <div>
                                        <label>Valor ipi</label>
                                        <h3>{ordemPedido.valor_ipi}</h3>
                                    </div>
                                    <div>
                                        <label>Situação</label>
                                        <h3>{ordemPedido.situacao}</h3>
                                    </div>
                                </div>


                            </Cabecalho>
                            <div>
                                <span>
                                    Produtos
                                </span>
                            </div>
                            <table className="customers">
                                <thead>
                                    <tr>
                                        <th>Código produto</th>
                                        <th>Descrição produto</th>
                                        <th>Quantidade</th>
                                        <th>Unidade medida</th>
                                        <th>Valor inicial</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordemPedido.itens && ordemPedido.itens.map((item) => (
                                        <tr key={item.codigo_produto}>
                                            <td>{item.codigo_produto}</td>
                                            <td>{item.descricao_produto}</td>
                                            <td>{formatDecimal(item.quantidade)}</td>
                                            <td>{item.unidade_medida}</td>
                                            <td>{formatDecimal(item.valor_unitario)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    }
                </Container>


            </div>
        </>
    )
}

export default PedidoCompra;