import React from "react";
import { formatDateBrazil } from "../../../../utils/format-date-brazil";
import { Tag } from "../../EquipamentosSituacao/styles";

import GrupoLabel from "../GrupoLabel";

import { 
  Container,
  WrapperIcon,
  WrapperInfos,
  WrapperMovimento,
  WrapperLocacao,
  ArrowLeftRightIcon
 } from './styles'

function Movimentacao({movimento}) {
  return (
    <Container>
      <WrapperIcon>
        <ArrowLeftRightIcon /> 
      </WrapperIcon>

      <WrapperInfos>
       <WrapperMovimento>
         <GrupoLabel label='Data do movimento'>
            {formatDateBrazil(movimento.dt_hr_movimento)}
         </GrupoLabel>
       
         <GrupoLabel label='Situação'>
           <Tag type={movimento.id_situacao}>
             {movimento.desc_situacao}
           </Tag>
         </GrupoLabel>
       </WrapperMovimento>

       {movimento.id_contrato && (
        <WrapperLocacao>
            <GrupoLabel label='Contrato'>
              {movimento.id_contrato}
            </GrupoLabel>
            
            <GrupoLabel label='Remessa'>
              {movimento.id_remessa}  
            </GrupoLabel>

            <GrupoLabel label='Cliente'>
            {movimento.id_cliente} - {movimento.desc_cliente}  
            </GrupoLabel>
            
            <GrupoLabel label='Obra'>
              {movimento.id_obra} - {movimento.desc_obra}  
            </GrupoLabel>
          </WrapperLocacao> 
       )}

        <GrupoLabel label='Observação'>
          {movimento.observacao}
        </GrupoLabel>     
      </WrapperInfos>
    </Container>
  );
}

export default Movimentacao;