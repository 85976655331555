import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Col, DatePicker, Input, Row, Select, Spin, Alert } from 'antd';
import FormGrupoInput from '../FormGrupoInput';
import api from '../../service/api';
import { Context } from '../../Context/AuthContext'
import { ContainerFiltro } from './styles';
import { formatDecimal } from '../../utils/format-decimal';
import { formatDateBrazil } from '../../utils/format-date-brazil'

import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';

import TabelaPersonalizada from '../TabelaPersonalizada';
import FormModal from '../Modal';
import FiltraClientes from '../FiltraClientes';
import { SearchOutlined } from '@ant-design/icons';
import OrdemProducaoProcesso from '../OrdemProducaoProcesso';

import TituloTela from '../../components/TituloTela';

const formatoDataBR = 'DD/MM/YYYY';
const { Option } = Select;
const { RangePicker } = DatePicker;

moment.locale('pt-br');




function OrdemProducaoSituacao() {
  const estruturaColunasTabelaOrdem = [
    {
      title: 'Nro. OP',
      dataIndex: 'numero_op',
      key: 'numero_op',
      width: 100,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Cód. Produto',
      dataIndex: 'codigo_produto',
      key: 'codigo_produto',
      width: 100,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Descrição Produto',
      dataIndex: 'descricao_produto',
      key: 'descricao_produto',
      width: 300,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
      width: 100,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'UND',
      dataIndex: 'unid_medida',
      key: 'unid_medida',
      width: 100,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Data Abertura',
      dataIndex: 'data_abertura',
      key: 'data_abertura',
      width: 150,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Data Prev. Entrega',
      dataIndex: 'data_previsao_entrega',
      key: 'data_previsao_entrega',
      width: 150,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Data Conclusão',
      dataIndex: 'data_conclusao',
      key: 'data_conclusao',
      width: 150,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      key: 'situacao',
      width: 100,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Programada',
      dataIndex: 'programada',
      key: 'programada',
      align: 'center',
      width: 100,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      width: 100,
      isVisible: true,
      isSearch: true,
      render: (_, record) => (
        <Button
          icon={<SearchOutlined />}
          onClick={() => handleOpenCloseModalProcessosClick(record.numero_op)}
          disabled={record.programada === 'Não'}
        />),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [ordemProducao, setOrdemProducao] = useState([]);
  const [inputIDOrdem, setInputIDOrdem] = useState('');
  const [inputCliente, setInputCliente] = useState('');
  const [inputNomeCliente, setInputNomeCliente] = useState('');
  const [periodoInicial, setPeriodoInicial] = useState(moment(new Date()).format('YYYY/MM/DD'));
  const [periodoFinal, setPeriodoFinal] = useState(moment().format('YYYY/MM/DD'));
  const [inputProgramada, setInputProgramada] = useState('Todas');
  const [inputSituacao, setInputSituacao] = useState('Todas');
  const [errosStatus, setErrosStatus] = useState({});
  const [abrirModalClientes, setAbrirModalClientes] = useState(false);
  const [idOrdemDetalhesProcessos, setIdOrdemDetalhesProcessos] = useState(0);
  const [erros, setErros] = useState();
  const inputIdOrdemRef = useRef(null);

  const { empresa } = useContext(Context);

  async function handlerConslutaOrdem() {
    let dadosPesquisa = {};
    let erros = {};

    if (inputIDOrdem) {
      dadosPesquisa = {
        idOrdem: inputIDOrdem,
      };
    } else {
      if (inputCliente === '' || Number(inputCliente) === 0) {
        erros.erroCliente = '* Campo obrigatório';
      }

      if ((isNaN(Date.parse(periodoInicial))) || (isNaN(Date.parse(periodoFinal)))) {
        erros.erroPeriodo = '* Campo obrigatório';
      }

      if (Object.keys(erros).length > 0) {
        setErrosStatus(erros);
        return
      }

      dadosPesquisa = {
        idCliente: inputCliente,
        dataAberturaInicio: periodoInicial,
        dataAberturaFinal: periodoFinal,
        programada: inputProgramada,
        situacao: inputSituacao,
      };

    }

    setErrosStatus(erros);

    try {
      const { data } = await api.get(`/empresas/${empresa.id}/pcp/ordem_producao_status`, {
        params: dadosPesquisa
      });

      const dataKey = data.map((op, index) => ({
        ...op,
        key: `op${index}`,
        quantidade: formatDecimal(op.quantidade, 3),
        programada: op.programada === 'S' ? 'Sim' : 'Não',
        data_previsao_entrega: (isNaN(Date.parse(op.data_previsao_entrega))) ? '' : formatDateBrazil(op.data_previsao_entrega),
        data_conclusao: isNaN(Date.parse(op.data_conclusao)) ? '' : formatDateBrazil(op.data_conclusao),
        data_abertura: isNaN(Date.parse(op.data_abertura)) ? '' : formatDateBrazil(op.data_abertura),
      }));

      setOrdemProducao(dataKey);
    } catch (e) {
      if (e || e.response.data.statusCode >= 500) {
        const erro = 'Erro 500, servidor não respondendo contate o suporte!';
        setErros(erro);
      }
      else {
        const erro = e.response.data.errors[0].message;
        setErros(erro);
      }

    }
  };

  function onChangeCodigoCliente(value) {
    setInputCliente(value.replace(/[^\d\s-/]/g, ""));
  }

  function onChangeIDOrdem(event) {
    setErrosStatus({});
    setInputIDOrdem(event.target.value.replace(/[^\d\s-/]/g, ""));
  }

  useEffect(() => {
    async function filtraCliente() {
      if ((inputCliente === '') || (Number(inputCliente) === 0)) {
        setInputNomeCliente('');
        return;
      }

      const { data } = await api.get(`/empresas/${empresa.id}/clientes`, {
        params: { id_cliente: inputCliente }
      });

      if (data.response[0].nome) {
        setInputNomeCliente(data.response[0].nome);
      } else
        setInputNomeCliente('Cliente não encontrado.');
    };

    filtraCliente();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCliente]);

  function handleFiltraCliente() {
    setAbrirModalClientes(prevState => !prevState);
  }

  function onChangeDataPeriodo(dates, dateStrings) {
    if (dates) {
      setPeriodoInicial(moment(dates[0]).format('YYYY/MM/DD'));
      setPeriodoFinal(moment(dates[1]).format('YYYY/MM/DD'));
    } else {
      setPeriodoInicial(null);
      setPeriodoFinal(null);
    }
  };

  function handleOpenCloseModalProcessosClick(idOrdem = 0) {
    setIdOrdemDetalhesProcessos(idOrdem);
  }

  useEffect(() => {
    inputIdOrdemRef.current.focus()
  }, [])

  return (
    <>
      {abrirModalClientes
        ? (
          <FormModal
            openCloseModal={() => { }}
          >
            <FiltraClientes
              atualizaCodigoCliente={setInputCliente}
              atualizaNomeCliente={setInputNomeCliente}
              openCloseModal={handleFiltraCliente} />
          </FormModal>
        )
        : null
      }

      {idOrdemDetalhesProcessos > 0
        ? (
          <FormModal
            openCloseModal={() => { }}
          >
            <OrdemProducaoProcesso
              idOrdem={idOrdemDetalhesProcessos}
              close={handleOpenCloseModalProcessosClick}
            />
          </FormModal>
        )
        : null
      }

      <TituloTela codigoTela="01.77.1332" nomeTela="Ordem de Produção Situação" />

      <ContainerFiltro>
        <Row>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Nro. Ordem'
              children={
                <>
                  <Input value={inputIDOrdem}
                    onChange={onChangeIDOrdem}
                    style={{ width: '100%' }}
                    placeholder="Número ordem"
                  />
                </>
              }
            />
          </Col>

          <Col flex='0 1 150px'>
            <FormGrupoInput
              label='Período (Abertura)'
              erro={errosStatus.erroPeriodo}
              children={
                <RangePicker
                  style={{ width: 250 }}
                  locale={locale}
                  format={formatoDataBR}
                  defaultValue={
                    [moment(new Date(), 'YYYY/MM/DD'),
                    moment(new Date(), 'YYYY/MM/DD')]
                  }
                  ranges={{
                    'Hoje': [moment(), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  onChange={onChangeDataPeriodo}
                />}
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Cliente'
              erro={errosStatus.erroCliente}
              children={
                <>
                  <Input value={inputCliente}
                    onChange={(e) => onChangeCodigoCliente(e.target.value)}
                    style={{ width: 60 }} placeholder="Código"
                    ref={inputIdOrdemRef}
                  />
                  <Input disabled
                    tabIndex={-1}
                    value={inputNomeCliente}
                    style={{ width: 300 }}
                    placeholder="Selecione o cliente"
                  />
                  <Button type='primary' icon={<SearchOutlined />} onClick={() => handleFiltraCliente()} />
                </>
              }
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Programada'
              children={
                <Select
                  onChange={(value) => { setInputProgramada(value) }}
                  showSearch
                  style={{ width: 160 }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  defaultValue='Todas'
                >
                  <Option value="Todas">Todas</Option>
                  <Option value="S">Programadas</Option>
                  <Option value="N">Não Programadas</Option>
                </Select>
              }
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Situação'
              children={
                <Select
                  showSearch
                  style={{ width: 160 }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  defaultValue='NaoFinalizada'
                  onChange={(value) => { setInputSituacao(value) }}
                >
                  <Option value="Todas">Todas</Option>
                  <Option value="NaoFinalizada">Não Finalizadas</Option>
                  <Option value="Aberta">Abertas</Option>
                  <Option value="Reservada">Reservadas</Option>
                  <Option value="Requisitada">Requisitadas</Option>
                  <Option value="Iniciada">Iniciadas</Option>
                  <Option value="Apontada">Apontadas</Option>
                  <Option value="Finalizada">Finalizadas</Option>
                  <Option value="Cancelada">Canceladas</Option>
                </Select>
              }
            />
          </Col>
          <Col flex='0 1 60px'>
            <div style={{ padding: '28px 0px 16px 16px' }}>
              <Button type='primary' onClick={() => handlerConslutaOrdem()}>Filtrar</Button>
            </div>
          </Col>
          <Col md={24} lg={24} xs={24} sm={24} xl={24}>
            {erros &&

              <Alert

                closeText="Fechar"
                message={erros}
                showIcon
                type="error"
                action={
                  <Button size="small" danger show={true}>
                    Detalhes
                  </Button>
                }
              />
            }
          </Col>
        </Row>
      </ContainerFiltro>
      {loading
        ?
        <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
          <Spin size="large" />
        </Row>
        :
        <Row>
          <Col span={24} >
            <TabelaPersonalizada
              titulo='Ordem de Produção Situação'
              columns={estruturaColunasTabelaOrdem}
              dataSource={ordemProducao}
              configTabela={{
                size: 'small',
                scroll: { x: 'auto' },
                pagination: {
                  size: 'small',
                  defaultPageSize: 20,
                },
              }}
            />
          </Col>
        </Row>
      }
    </>
  );
}

export default OrdemProducaoSituacao;