import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  p {
    margin: 0;
  }

  .label {
    color: #787878;
    font-size: 12px;

    @media screen and (max-width: 768px){
      font-size: 11px;
    }
  }
  
  .data {
    color: #292929;
    font-size: 16px;

    @media screen and (max-width: 768px){
      font-size: 14px;
    }
  }
`;
