import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 10px;
/* 
  @media (max-width: 480px){
    display: flex;
    flex-direction: row;
    padding: 3px;
  } */
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  width: 100%;
  height: 100%;

  @media (max-width: 480px){
    /* min-height: 640px; */
  }
`;

export const ContainerTitulo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: rgb(30, 136, 229);
  color: white;
  border-radius: 4px 4px 0 0;
`;

export const ContainerPesquisa = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px; 

  @media (max-width: 480px){
    margin-top: 12px;

    input{
      margin-right: 6px;
    }
  }

  @media (min-width: 480px){
    input{
      margin-right: 6px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  align-items: flex-end;

  @media (max-width: 480px){
    margin-top: 4px;
  }
`;

export const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: auto;

  .ant-table-thead > tr > th {
    background-color: #fff;
    padding: 12px 0px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.5rem;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(0, 0, 0, 0.04);
}
`;