import React from 'react';

import { Container } from './styles';

function DataItem({label, record}) {

  return (
    <Container>
      <p className='label'>{label}</p>
      <p className='data'>{record || "Não informado"}</p>
    </Container>
  );
}

export default DataItem;