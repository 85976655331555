import React from 'react';

import { FaSort, FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';

import { ButtonContainer } from './styles';

export default function DataTableColumnHeader({ column, title }){
  const justifyContent = column.columnDef.align ? column.columnDef.align : 'flex-start';
  
  return (
    <ButtonContainer
      justifyContent={justifyContent}
      onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    >
      <span>{title}</span>
      {column.getIsSorted() === "desc" ? (
        <FaLongArrowAltDown size={12} />
      ) : column.getIsSorted() === "asc" ? (
        <FaLongArrowAltUp size={12} />
      ) : (
        <FaSort size={12} color='#9da3af'/>
      )}
    </ButtonContainer>
  )
}