import React, { useState } from 'react';

import { Radio } from 'antd';
import { Bar } from '@ant-design/charts';

import { ContainerTabela, OpcoesTipoExibicao, Tabela } from './styles';

import { formatDecimal } from '../../../../utils/format-decimal';

function MetasRepresentantes({ representantes }) {
  const [tipoExibicao, setTipoExibicao] = useState('grafico');

  const representantesOrderByMeta = representantes.sort( 
    function (repreA, repreB) {
      return  repreB.perc_meta - repreA.perc_meta 
    });

  const formatedRepresentantes = representantesOrderByMeta.map( (representante) => ({
      ...representante,
      nome_vendedor_legenda: formataTamanhoNome(representante.nome_vendedor),
      valor_total_liquido: formatDecimal(representante.valor_total_liquido),
      valor_meta: formatDecimal(representante.valor_meta),
      perc_meta: Number(representante.perc_meta),
      perc_meta_formatado: formatDecimal(representante.perc_meta),
  }));

  const configCharts = {
    data: formatedRepresentantes,
    xField: 'perc_meta',
    yField: 'nome_vendedor_legenda',
    legend: false,
    seriesField: 'perc_meta',
    color: (data) => Number(data.perc_meta) < 100 ? '#e74a3b' : '#1cc88a',
    tooltip: {
      fields: ['nome_vendedor', 'perc_meta_formatado'],
      title: 'nome_vendedor',
      formatter: (date) => (
        { name: 'Meta realizada', value: date.perc_meta_formatado + '%' }) },
  };
  
  function formataTamanhoNome(nome) {
    if( nome && nome.length > 35 ) {
      return `${nome.substring(0, 33)}...`
    }

    return nome;
  }

  function handleChangeOpcao(event) {
    setTipoExibicao(event.target.value);
  };

  return (
    <>
      <OpcoesTipoExibicao>
        <Radio.Group value={tipoExibicao} onChange={handleChangeOpcao}>
          <Radio.Button value="grafico">Gráfico</Radio.Button>
          <Radio.Button value="lista">Lista</Radio.Button>
        </Radio.Group>
      </OpcoesTipoExibicao>
      { tipoExibicao === 'lista'
      ?  
        <ContainerTabela>
            <Tabela>
              <thead>
                <tr>
                  <th style={{textAlign: 'right'}}>#ID</th>
                  <th>Nome</th>
                  <th style={{textAlign: 'right'}}>Valor Vendas</th>
                  <th style={{textAlign: 'right'}}>Valor Meta</th>
                  <th style={{textAlign: 'right'}}>% Realizado</th>
                </tr>
              </thead>
              <tbody>
                {formatedRepresentantes.map( representante => (
                  <tr key={representante.id_vendedor}>
                    <td style={{textAlign: 'right'}}>{representante.id_vendedor}</td>
                    <td>{representante.nome_vendedor}</td>
                    <td style={{textAlign: 'right'}}>{representante.valor_total_liquido}</td>
                    <td style={{textAlign: 'right'}}>{representante.valor_meta}</td>
                    <td 
                      style={{
                        textAlign: 'right', 
                        color: Number(representante.perc_meta) > 100 ? '#1cc88a' : '#e74a3b' }
                      }>
                        {representante.perc_meta_formatado}
                    </td>
                  </tr>
                  
                ))}
              </tbody>
            </Tabela>
          </ContainerTabela>
        :
         <Bar {... configCharts} />
      }
    </>
  );
}

export default MetasRepresentantes;