import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  height: 90vh;
  border-radius: 4px;
  overflow: auto;

  @media(max-width: 720px){
    width: calc(100vw - 10px);
  }

  @media(max-width: 1024px){
    width: calc(100vw - 20px);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  /* padding: 8px 18px 0 18px; */
  height: 40px;
  width: 100%;
  background: linear-gradient(160deg, #1890ff 0%, #22b5f4 40%, #76cbed 60%);

  button {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    align-items: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        transition: background 0.2s ease-in;
        border-radius: 50%;
    }

    svg{
        width: 100%;
        height: auto;
    }
  }
`;

export const Body = styled.div` 
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

export const ButtonCloseContainer = styled.div` 
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    align-items: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        transition: background 0.2s ease-in;
    }

    svg{
        width: 100%;
        height: auto;
    }
  
`;

