import React from 'react'

import { Container } from './styles';

function GrupoCards({titulo, children}) {
  return(
    <Container>
      <div>
        <h6>{titulo}</h6>
      </div>
      <div>
        {children}
      </div>
    </Container>
  );
}

export default GrupoCards;