/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import api from '../../../service/api';

import { Context } from '../../../Context/AuthContext'
import { Row, Col, Spin, Button, Empty, Radio, Alert } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import TituloTela from '../../../components/TituloTela';

import DestalhesOp from './DetalhesOp';
import toHHMMSS from '../../../utils/toHHMMSS';

import { ContainerButton } from './styles';

function PainelRecursos() {
  const [loading, setLoading] = useState(false);
  const [recursos, setRecursos] = useState([]);
  const [tipoFiltro, setTipoFiltro] = useState('todos');
  const [erros, setErros] = useState();
  const [recursoFiltro, setRecursoFiltro] = useState([]);
  const { empresa } = useContext(Context);

  useEffect(() => {
    carregaRecursos();
  }, [empresa]);

  useEffect(() => {
    let filtro = [];
    switch (tipoFiltro) {
      case 'ocioso':
        filtro = recursos.filter(
          recurso => parseInt(recurso.codigo_situacao_recurso) === 0
        );
        break;

      case 'produzindo':
        filtro = recursos.filter(
          recurso => parseInt(recurso.codigo_situacao_recurso) === 1
        );
        break;

      case 'parado':
        filtro = recursos.filter(
          recurso => parseInt(recurso.codigo_situacao_recurso) >= 2
        );
        break;

      default:
        filtro = recursos;
        break;
    };

    setRecursoFiltro(filtro);

  }, [recursos, tipoFiltro]);

  function handleFiltro(e) {
    setTipoFiltro(e.target.value);
  };

  async function carregaRecursos() {
    setLoading(true);
    setErros(null);

    try {
      const { data } = await api.get(
        `empresas/${empresa.id}/modulos/recursos`
      );

      const recursoFormatado = data.response.map(recurso => {
        return {
          ...recurso,
          tempo_na_situacao: recurso.tempo_na_situacao >= 0
            ? toHHMMSS(recurso.tempo_na_situacao)
            : 'Sempre Parada'
        }
      });

      setRecursos(recursoFormatado);

    } catch (e) {
      if (e || e.response.data.statusCode >= 500) {
        const erro = 'Erro 500, servidor não respondendo contate o suporte!';
        setErros(erro);
      } else {
        const erro = e.response.data.errors[0].message;
        setErros(erro);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <TituloTela codigoTela="01.77.1330" nomeTela="Painel de Recursos" />

      <div style={{
        marginBottom: '16px',
        border: '1px solid #f0f0f0',
        borderRadius: '3px',
        padding: '16px'
      }}>

        <ContainerButton>
          <Radio.Group value={tipoFiltro} onChange={handleFiltro}>
            <Radio.Button value="todos">Todos</Radio.Button>
            <Radio.Button value="ocioso">Ocioso</Radio.Button>
            <Radio.Button value="produzindo">Produzindo</Radio.Button>
            <Radio.Button value="parado">Parado</Radio.Button>
          </Radio.Group>

          <Button
            className='button-reload'
            type="primary"
            onClick={() => { carregaRecursos() }}
          >
            <ReloadOutlined />
          </Button>
        </ContainerButton>
        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          {erros &&
            <Alert
              closeText="Fechar"
              message={erros}
              showIcon
              type="error"
              action={
                <Button size="small" danger show={true}>
                  Detalhes
                </Button>
              }
            />
          }
        </Col>
      </div>

      {
        loading
          ?
          <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
            <Spin size="large" />
          </Row>
          :
          <Row gutter={[12, 12]}>
            {recursoFiltro.length > 0
              ?
              recursoFiltro.map(recurso => (
                <Col key={recurso.id_recurso} xs={24} sm={12} xl={8}>

                  <DestalhesOp recurso={recurso} />

                </Col>
              ))
              :
              (
                <div style={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
                  <Empty description='' />
                </div>
              )
            }

          </Row>
      }

    </>
  );
}

export default PainelRecursos;