import React, { useState } from 'react';

import { Radio } from 'antd';
import { Line as LineChart } from '@ant-design/charts';

import { formatDateBrazil } from '../../../../utils/format-date-brazil';
import { formatDecimal } from '../../../../utils/format-decimal';

import { ContainerTabela, OpcoesTipoExibicao, Tabela } from './styles';

function FaturamentoDiario({ faturamentoDiario }) {
  const [tipoExibicao, setTipoExibicao] = useState('grafico');

  const faturamentoDiarioFormatado = faturamentoDiario.map((faturamentoDia) => {
    const newDate = new Date(faturamentoDia.data_emissao);
    newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000)

    return {
      dia: `${newDate.getDate()}`,
      valor: Number(faturamentoDia.vlr_vendas_dia),
      data_emissao: formatDateBrazil(faturamentoDia.data_emissao, 'dd/MM/yyyy'),
      dia_semana: faturamentoDia.dia_semana,
      vlr_vendas_dia: formatDecimal(faturamentoDia.vlr_vendas_dia),
      vlr_meta_dia: formatDecimal(faturamentoDia.vlr_meta_dia),
      perc_meta_dia_atingido: formatDecimal(faturamentoDia.perc_meta_dia_atingido),
      vlr_venda_acumulado: formatDecimal(faturamentoDia.vlr_venda_acumulado),
      vlr_meta_acumulada: formatDecimal(faturamentoDia.vlr_meta_acumulada),
      perc_meta_acumulada_atingido: formatDecimal(faturamentoDia.perc_meta_acumulada_atingido),
    }
  });

  const configLineChart = {
    data: faturamentoDiarioFormatado,
    padding: 'auto',
    xField: 'dia',
    yField: 'valor',
    yAxis: {
      tickCount: 10,
      grid: {
        line: {
          style: {
            stroke: '#F3F4F6',
          }
        }
      }
    },
    point: {
      size: 3,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      fields: ['data_emissao', 'valor'],
      title: 'data_emissao',
      formatter: (date) => (
        { name: 'Valor', value: formatDecimal(date.valor) })
    },
    interactions: [{ type: 'marker-active' }],
  };

  function handleChangeOpcao(event) {
    setTipoExibicao(event.target.value);
  };
  return (
    <>
      <OpcoesTipoExibicao>
        <Radio.Group value={tipoExibicao} onChange={handleChangeOpcao}>
          <Radio.Button value="grafico">Gráfico</Radio.Button>
          <Radio.Button value="lista">Lista</Radio.Button>
        </Radio.Group>
      </OpcoesTipoExibicao>
      {tipoExibicao === 'lista'
        ?
        <ContainerTabela>
          <Tabela>
            <thead>
              <tr>
                <th>Data</th>
                <th>Dia da Semana</th>
                <th>Valor Vendas Dia</th>
                <th>Valor Meta Dia</th>
                <th>% Realizado Dia</th>
                <th>Valor Vendas Acumulado</th>
                <th>Valor Meta Acumulado</th>
                <th>% Meta Acumulado</th>
              </tr>
            </thead>
            <tbody>
              {faturamentoDiarioFormatado.map(faturamentoDia => (
                <tr key={faturamentoDia.data_emissao}>
                  <td>{faturamentoDia.data_emissao}</td>
                  <td>{faturamentoDia.dia_semana}</td>
                  <td>{faturamentoDia.vlr_vendas_dia}</td>
                  <td>{faturamentoDia.vlr_meta_dia}</td>
                  <td>{faturamentoDia.perc_meta_dia_atingido}</td>
                  <td>{faturamentoDia.vlr_venda_acumulado}</td>
                  <td>{faturamentoDia.vlr_meta_acumulada}</td>
                  <td>{faturamentoDia.perc_meta_acumulada_atingido}</td>
                </tr>
              ))}
            </tbody>
          </Tabela>
        </ContainerTabela>
        :
        <LineChart {...configLineChart} />
      }
    </>
  );
}

export default FaturamentoDiario;
