import styled from 'styled-components';

export const ContainerFiltro = styled.div`
  margin-bottom: 16px;
  border-radius: 5px;
  background: #fff; 
`;

export const Cabecalho = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 20px;

  > h3 {
    padding: 5px;
  }
`;

export const Container = styled.div`
  margin-bottom: 16px;
  border-radius: 5px;
  background: #fff; 
  overflow-x: auto;
  flex: 1;

  .customers {
  border-collapse: collapse;
  width: 100%;
  
  }

  .customers td, #customers th {
    padding: 12px;
    font-size: 12px;
    border-top: 1px solid #f1f1f1;
    color: #888888;
  }


  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    text-align: left;
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
`;


export const Informacoes = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 15px 20px;

    span {
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    color: #888888;
    padding: 4px;
  }

`;
