import React, { useEffect, useState, useContext} from 'react';

import api from '../../../service/api';
import { Context } from '../../../Context/AuthContext';

import TituloTela from '../../../components/TituloTela';
import Card from './Card';
import { TotalIcon, LocadoIcon, EstoqueIcon, ManutencaoIcon } from './Icons';

const cardsConfig = {
  t: {
    type: 'total',
    icon: TotalIcon,
  },
  l: {
    type: 'locado',
    icon: LocadoIcon,
  },
  e: {
    type: 'estoque',
    icon: EstoqueIcon,
  },
  m: {
    type: 'manutencao',
    icon: ManutencaoIcon,
    href: '',
  },
}

function LocacaoEquipamentosDashboard() {
  const [situacaoEquipamentos, setSituacaoEquipamentos] = useState([]);
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function getDadosDashboard() {
      const { data } = await api.get(`empresas/${empresa.id}/faturamento/locacao_equipamentos/dashboard`);
      const cardTotal = {
        type: cardsConfig['t'].type,
        idSituacao: 't',
        title: 'Total Equipamentos',
        amount: data.result.totais_equipamentos.quantidade_total,
        icon: cardsConfig['t'].icon,
      }

      const cards = data.result.equipamentos_situacao.map((situacao => {
        return {
          type: cardsConfig[situacao.id_situacao.toLowerCase()].type,
          idSituacao: situacao.id_situacao,
          title: situacao.desc_situacao,
          amount: situacao.quantidade,
          percentage: situacao.percentual.replace('.', ','),
          icon: cardsConfig[situacao.id_situacao.toLowerCase()].icon,
        }
      }))

      cards.unshift(cardTotal);   

      setSituacaoEquipamentos(cards);
    }

    getDadosDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TituloTela
        nomeTela='Dashboard Locação'
        codigoTela='01.76.1396'
      />

      <div style={{background: '#F4F4F5', display: 'flex', gap: '16px', padding: '20px'}}>
        { situacaoEquipamentos.map( equipamento => (
          <Card 
            key={equipamento.title}
            type={equipamento.type}
            to={`/faturamento/locacao_equipamentos/equipamentos_situacao?id_situacao=${equipamento.idSituacao}`}
            title={equipamento.title}
            amount={equipamento.amount}
            percentage={equipamento.percentage}
            icon={equipamento.icon}
        />
        ))}
      </div>
    </>
    
  )
}

export default LocacaoEquipamentosDashboard;