import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #cfecfe;
  border-radius: 4px;
  line-height: normal;
  transition: all .3s ease;
  cursor: pointer;


  &:hover {
    transform: scale(1.05);
    
    > span {
      height: 100%;
      width: 50px;
      border-radius: 0%;
    }
  }

  > span {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 0 30px 30px 30px;
    background-color: #cfecfe;
    color: #1e88e5;
    margin: 0;
    transition: all .2s ease;
  }

  > h3 {
    padding: 16px 16px 16px 66px;
    margin: 0;
    color: #6c757d;
  }

  > div {
    display: flex;
    flex-direction: center;
    align-items: center;
    padding: 0 16px;
    width: 50px;

    svg {
      width: 20px;
      height: 20px;
      fill: #6c757d;
    }
  }

`;
