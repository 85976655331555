import React, { useState, useEffect, useContext } from 'react'
import { Table, Spin, Row } from 'antd';
import { Context } from '../Context/AuthContext'
import api from '../service/api';
import { WrapperTables } from '../components/TabelaPersonalizada/styles';

import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import { round } from '../utils/round';
import TabelaPersonalizada from './TabelaPersonalizada';


const filtroTituloReceber = {
  vencidos: {
    data_inicio: moment().subtract('20', 'years').format('YYYY/MM/DD'),
    data_final: moment().subtract('1', 'day').format('YYYY/MM/DD'),
    codigo_cliente: '0'
  },
  hoje: {
    data_inicio: moment().format('YYYY/MM/DD'),
    data_final: moment().format('YYYY/MM/DD'),
    codigo_cliente: '0'
  },
  vence_sete_dias: {
    data_inicio: moment().add('1', 'day').format('YYYY/MM/DD'),
    data_final: moment().add('7', 'day').format('YYYY/MM/DD'),
    codigo_cliente: '0'
  },
  vence_trinta_dias: {
    data_inicio: moment().add('1', 'day').format('YYYY/MM/DD'),
    data_final: moment().add('30', 'day').format('YYYY/MM/DD'),
    codigo_cliente: '0'
  },
};

const colunasTabela = [
  {
    title: 'Data Emissão',
    dataIndex: 'dataEmissao',
    key: 'dataEmissao',
    width: 130,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Data Vencimento',
    dataIndex: 'dataVencimento',
    key: 'dataVencimento',
    width: 130,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Dias Atraso',
    dataIndex: 'diasAtraso',
    key: 'diasAtraso',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nro. Título',
    dataIndex: 'nroTitulo',
    key: 'nroTitulo',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Parcela',
    dataIndex: 'nroParcela',
    key: 'nroParcela',
    width: 70,
    align: 'center',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Série',
    dataIndex: 'serie',
    key: 'serie',
    width: 70,
    align: 'center',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
    key: 'valor',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Saldo',
    dataIndex: 'saldo',
    key: 'saldo',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nro. Doc. Origem',
    dataIndex: 'nroDocOrigem',
    key: 'nroDocOrigem',
    width: 140,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Tipo Doc. Origem',
    dataIndex: 'tipoDocOrigem',
    key: 'tipoDocOrigem',
    width: 140,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nro. DF-e',
    dataIndex: 'nroDFe',
    key: 'nroDFe',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Tipo DF-e',
    dataIndex: 'tipoDFe',
    key: 'tipoDFe',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Cód. Cliente',
    dataIndex: 'idCliente',
    key: 'idCliente',
    width: 110,
    align: 'right',
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'nomeCliente',
    key: 'nomeCliente',
    width: 500,
    isVisible: true,
    isSearch: true,
  },
  {
    title: 'Observação',
    dataIndex: 'observacao',
    key: 'observacao',
    width: 800,
    isVisible: true,
    isSearch: true,
  },
];

function formataValorMoeda(value) {
  let valorFormatado = '--';

  if (value || value === 0) {
    valorFormatado = parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }
  return valorFormatado;
}


const TitulosReceber = (props) => {

  const [titulosReceber, setTitulosReceber] = useState([]);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [totalValor, setTotalValor] = useState(0);
  const [carregando, setCarregando] = useState([false]);
  const { empresa } = useContext(Context);
  const tipoRelatorio = props.prazo;
  const parametrosPesquisa = filtroTituloReceber[tipoRelatorio];

  useEffect(() => {
    consultaTitulosReceber(parametrosPesquisa);
  }, [empresa]);

  async function consultaTitulosReceber(dadosConsulta) {
    try {
      setCarregando(true);

      if (!dadosConsulta) {

        const dataFormatAmerican = props.dataPeriodo.split('/');
        const dataFormatAmericanFluxoCaixa = `${dataFormatAmerican[2]}/${dataFormatAmerican[1]}/${dataFormatAmerican[0]}`;

        dadosConsulta = {
          codigo_cliente: 0,
          data_inicio: dataFormatAmericanFluxoCaixa,
          data_final: dataFormatAmericanFluxoCaixa
        }
      }

      const { data: { response } } = await api.get(`titulos-receber/${empresa.id}`, {
        params: {
          codigo_cliente: dadosConsulta.codigo_cliente,
          data_inicio: dadosConsulta.data_inicio,
          data_final: dadosConsulta.data_final
        }
      });

      const dadosTabela = response.map((titulo, key) => {
        return {
          key: `titulo${key}`,
          ...titulo,
          dataEmissao: moment(titulo.dataEmissao).format('DD/MM/YYYY'),
          dataVencimento: moment(titulo.dataVencimento).format('DD/MM/YYYY'),
          valor: formataValorMoeda(titulo.valor),
          saldo: formataValorMoeda(titulo.saldo),
        }
      });

      let totalSaldoTitulo = 0;
      totalSaldoTitulo = response.reduce(
        (totalSaldoTitulo, titulo) => totalSaldoTitulo + Number(titulo.saldo), 0
      );

      let totalValorTitulo = 0;
      totalValorTitulo = response.reduce(
        (totalValorTitulo, titulo) => totalValorTitulo + Number(titulo.saldo), 0
      );

      setTotalSaldo(round(totalSaldoTitulo, 2));
      setTotalValor(round(totalValorTitulo, 2));
      setTitulosReceber(dadosTabela);
      setCarregando(false);

    } catch (error) {
      console.log({ error });
    }

  };


  return (
    <>
      {carregando
        ?
        <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
          <Spin size="large" />
        </Row>
        :
        <WrapperTables >
          <TabelaPersonalizada
            titulo='Títulos a Receber'
            columns={colunasTabela}
            dataSource={titulosReceber}
            configTabela={{
              size: 'small',
              scroll: { x: '100%', y: '550px' },
              pagination: {
                defaultPageSize: 20,
              },
              summary: () => (
                <Table.Summary.Row >
                  <Table.Summary.Cell colSpan={6}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Text strong>{formataValorMoeda(totalSaldo)}</Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Text strong >{formataValorMoeda(totalValor)}</Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ),
            }}

          />
        </WrapperTables>
      }
    </>
  );
};

export default TitulosReceber;