import styled, { css } from 'styled-components';

const colors = {
  sucess: css`
    background: #d1fae5;
    color: #0e654c;`,
  danger: css`
    background: #FCA5A5;
    color: #DC2626;`,
  default: css`
    background: #E5E7EB;
    color: #4B5563;`,
}

export const Tag = styled.strong`
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-weight: 600;
  ${({ color }) => color ? colors[color] : colors['default']}
`;
