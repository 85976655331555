import React, { useContext, useMemo } from 'react';

import { Route, Redirect } from 'react-router-dom';

import defaults from './Layout';
import auth from './Auth';
import { Context } from '../Context/AuthContext';
import { useAccess } from '../Context/AccessContext';

import AcessoNegado from '../components/AcessoNegado';

const App = ({ component: Component, accessCode, isPrivate, rest }) => {
  const { authenticated } = useContext(Context);
  const signed = authenticated;
  const { hasAccess } = useAccess();

  const access = useMemo(() => {
    return hasAccess(accessCode);
  }, [hasAccess, accessCode]);

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
   return <Redirect to="/home" />;
  }

  const ComponentRender = access ? Component : AcessoNegado

  const Layout = signed ? defaults : auth;


  return (
  
    <Route
      {...rest}
      render={(routeProps) => (
        <Layout>
          <ComponentRender {...routeProps} />
        </Layout>
      )}
    />

  );
};

export default App;
