import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  /* max-height: 600px;
  min-height: 200px; */
`;

export const Table = styled.table`
  width: 100%;
  height: 100%;

  thead {
    background-color: rgba(249, 250, 251, 1);
    /* position: sticky;
    top: 0;
    left: 0; */
    
    th {
      text-transform: uppercase;
      padding: 12px 1.25rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      color: #6B7280;
      letter-spacing: 0.05rem;
      /* text-align: left; */
      white-space: nowrap;
    }
  }

  tbody {
    max-height: 358px;
    width: 100%;
    background-color: #fff;
    
    tr {
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      transition: background 0.3s ease-in;
      
      &:last-child,
      &.hideBorder {
        border-bottom: 0;  
      }

      &:hover {
        background: #F9FAFB;
      }
      
      &.disableBackgroundColor:hover {
        background: transparent;
      }

      td {
        padding: 1rem 1.25rem;
        font-size: 0.785rem;
        font-weight: 100.25rem;
        color: #9CA3AF;

      }

      &.backgroundColorDark {
        background: #f3f4f6;

        td {
          color: #374151;
        }
      }
    }
  }
  
  ${({ convertTableIntoCardMobileVersion }) => convertTableIntoCardMobileVersion &&
    css`
    @media screen and (max-width: 767px) {
      & {
        border: 0;
      }

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    
      tbody tr {
        border: 1px solid rgba(229, 231, 235, 1);
        padding: 12px;
        display: block;
        margin-bottom: .625em;
        border-radius: 8px;

        td {
          display: block;
          text-align: right;
          font-size: 14px;
          width: 100%;
          padding: 4px 0;
        }
      }
    
      td::before {
        content: attr(data-label);
        float: left;
        font-size: 14px;
        color: #6B7280;
        font-weight: bold;
        text-transform: uppercase;
      }
    }     
  `}
`;

export const Td = styled.td`
  min-width: ${({ width }) => width ? `${width}px` : "auto"};
  text-align: ${({ align }) => align ? align : "left"};  
`;

export const Th = styled.th`
  min-width: ${({ width }) => width ? `${width}px` : "auto"};
  text-align: ${({ align }) => align ? align : "left"};
`;

export const BodyRow = styled.tr`
  cursor: ${({ cursorPointer }) => cursorPointer ? 'pointer' : "default"};
`;

export const DetailButtonContainer = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: transparent;
  border: none;

  &:hover { 
    color: #1890ff;
    cursor: pointer;
  }
`