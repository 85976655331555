import React from 'react';

import { Container } from './styles';

function CardValorVendas({ title, label, value, color, icon, label2, value2 }) {
    return (
        <Container color={color} style={{ borderRight: ' 1px solid #FFFFFF' }}>
            <div className="icon">
                {icon}
            </div>

            <div className="info">
                <span>{title}</span>
                <div>
                    <label>{label}: </label>
                    <span>{value}</span>
                </div>
                <div>
                    <label>{label2}: </label>
                    <span>{value2}</span>
                </div>
            </div>
        </Container>
    );
}

export default CardValorVendas;