import React from "react";
import { Layout } from "antd";
import { Menu } from './styles';

const { Sider } = Layout;

function SideBar({ menu }) {
    return (
        <Sider
            breakpoint="lg"
            theme="light"
            collapsedWidth={0}
            trigger={null}
        >
            <Menu>
                {menu}
            </Menu>
        </Sider>
    );
};

export default SideBar;