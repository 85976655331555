import React, { useState, useContext } from 'react';

import api from '../../service/api';
import { Context } from '../../Context/AuthContext';

import { Container, Wrapper, ContainerTitulo, ContainerPesquisa, Bottom, WrapperTable } from './styles';
import { Table, Button, Input, Alert } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import TituloTela from '../TituloTela';

const { Search } = Input;

const columns = [
  {
    title: 'Código',
    dataIndex: 'id',
    align: 'right',
    width: '4%',
  },
  {
    title: 'Nome',
    dataIndex: 'nome',
  },
];

function FiltraClientes({ atualizaCodigoCliente, atualizaNomeCliente, openCloseModal }) {
  const [codigoSelecionado, setCodigoSelecionado] = useState('');
  const [clientesFiltrados, setClientesFiltrados] = useState([]);
  const [clienteNome, setClienteNome] = useState('');
  const [codigoFiltrado, setcodigoFiltrado] = useState('');
  const [erro, setErro] = useState(false);
  const [erros, setErros] = useState();

  const { empresa } = useContext(Context);

  // function filtraClientes(filtro) {
  //   return dadosClientes.filter(cliente => cliente.nome.toLowerCase().indexOf(filtro.toLowerCase()) > -1 );
  // };

  function handleSelecionar() {
    if (codigoSelecionado !== '') {
      atualizaCodigoCliente(codigoSelecionado);
      atualizaNomeCliente(clienteNome);
      openCloseModal();
    } else {
      setErro(true)
    }
  };

  const onChange = (event) => {
    setcodigoFiltrado(event.target.value);
    event.target.value.length > 0 && setErro(false)
  }

  const onSearch = async (value) => {
    try {
      setErro(false);
      let result = [];

      if (codigoFiltrado === '') {
        const { data } = await api.get(`/empresas/${empresa.id}/clientes`, {
          params: { nome_cliente: value }
        });

        result = data.response;
      } else {
        const { data } = await api.get(`/empresas/${empresa.id}/clientes`, {
          params: { id_cliente: codigoFiltrado }
        });
        result = data.response
      }

      const dataCliente = result.map((cliente, index) => {
        return {
          key: `codigo${index}`,
          ...cliente,
        }
      });

      setClientesFiltrados(dataCliente);
    } catch (e) {
      if (e || e.response.data.statusCode >= 500) {
        const erro = 'Erro 500, servidor não respondendo contate o suporte!';
        setErros(erro);
      }
      else {
        const erro = e.response.data.errors[0].message;
        setErros(erro);
      }
      // console.log(`Erro na consulta  de cliente: ${error}`);  
    };
  };

  return (
    <Container>
      <Wrapper>
        <ContainerTitulo>
          <TituloTela codigoTela="01.82.1336" nomeTela="Filtrar Clientes" tema='white' fontSize='medium' paddingBottom='without' />

          <Button size="small" shape="circle" icon={<CloseOutlined />} onClick={openCloseModal} />
        </ContainerTitulo>
        <ContainerPesquisa>
          <Input size="middle" style={{ width: "95px" }} placeholder="Código" value={codigoFiltrado} onChange={onChange} />
          <Search size="middle" placeholder="Cliente" onSearch={onSearch} enterButton />
        </ContainerPesquisa>
        {erro && <Alert message="Operação inválida. Nenhum cliente selecionado." type="warning" />}
        <div md={24} lg={24} xs={24} sm={24} xl={12}>
          {erros &&

            <Alert

              closeText="Fechar"
              message={erros}
              showIcon
              type="error"
              action={
                <Button size="small" danger show={true}>
                  Detalhes
                </Button>
              }
            />
          }
        </div>

        <WrapperTable>
          <Table
            rowSelection={{
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => {
                setCodigoSelecionado(selectedRows[0].id)
              },
              getCheckboxProps: (record) => ({
                disabled: record.name === "Disabled User",
                name: record.name
              })
            }}

            columns={columns}
            size="small"
            // pagination={{ pageSize: 17 }} 
            description={"Nenhum Registro Encontrado"}
            itemRender={("Pagina")}
            dataSource={clientesFiltrados}
          />
          <Bottom>
            <Button size="middle" type="primary" onClick={handleSelecionar}>Selecionar</Button>
          </Bottom>
        </WrapperTable>
      </Wrapper>
    </Container>
  );
}
export default FiltraClientes;