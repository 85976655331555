import React from "react";

function ImageEmptyState() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="127"
      fill="none"
      viewBox="0 0 174 127"
    >
      <path
        fill="#EAEEF9"
        d="M140.728 53.78c0 12.114-4.056 23.497-10.816 32.477-3.744 4.909-8.216 9.19-13.416 12.532-8.32 5.534-18.408 8.667-29.12 8.667-29.433.105-53.353-23.914-53.353-53.676C34.023 24.123 57.84 0 87.376 0c10.712 0 20.696 3.133 29.12 8.668 5.2 3.341 9.672 7.623 13.416 12.53 6.76 9.086 10.816 20.364 10.816 32.582z"
      ></path>
      <path
        fill="#2D5887"
        d="M46.744 46.59H46.1c-.483 0-.805-.15-.805-.376s.322-.376.805-.376h.644c.483 0 .805.15.805.376 0 .225-.322.376-.805.376zM46.744 49.595H46.1c-.483 0-.805-.15-.805-.375 0-.226.322-.376.805-.376h.644c.483 0 .805.15.805.376 0 .225-.322.375-.805.375zM46.744 52.601H46.1c-.483 0-.805-.15-.805-.376 0-.225.322-.375.805-.375h.644c.483 0 .805.15.805.375 0 .226-.322.376-.805.376zM46.744 55.607H46.1c-.483 0-.805-.15-.805-.376 0-.225.322-.376.805-.376h.644c.483 0 .805.15.805.376s-.322.376-.805.376z"
      ></path>
      <path
        fill="#fff"
        d="M76.14 46.59H65.548c-.43 0-.716-.15-.716-.376s.287-.376.716-.376H76.14c.43 0 .716.15.716.376 0 .225-.287.376-.716.376z"
      ></path>
      <path
        fill="url(#paint0_linear_35_620)"
        d="M98.707 55.607H65.524c-.415 0-.692-.15-.692-.376 0-.225.277-.376.692-.376h33.183c.415 0 .692.15.692.376s-.277.376-.692.376z"
      ></path>
      <path
        fill="#2D5887"
        d="M76.181 49.595H61.75c-.405 0-.675-.15-.675-.375 0-.226.27-.376.674-.376h14.432c.405 0 .675.15.675.376 0 .225-.27.375-.675.375zM87.454 52.601H61.748c-.404 0-.673-.15-.673-.376 0-.225.27-.375.673-.375h25.706c.404 0 .673.15.673.375a.804.804 0 01-.673.376z"
      ></path>
      <path
        fill="#fff"
        d="M98.667 52.601h-9.808c-.44 0-.732-.15-.732-.376 0-.225.293-.375.732-.375h9.808c.44 0 .732.15.732.375 0 .226-.293.376-.732.376z"
      ></path>
      <path
        fill="url(#paint1_linear_35_620)"
        d="M104.7 52.601h-3.839c-.426 0-.711-.15-.711-.376 0-.225.285-.375.711-.375h3.839c.426 0 .71.15.71.375 0 .226-.284.376-.71.376z"
      ></path>
      <path
        stroke="#D6DEE8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M22 93.93h126.243"
      ></path>
      <g filter="url(#filter0_d_35_620)">
        <path
          fill="#fff"
          d="M110.671 45.838v48.093H42.487c-2.69 0-4.707-2.162-4.707-4.729V45.838h72.891z"
        ></path>
      </g>
      <path
        fill="#DAE1ED"
        d="M137.723 45.838v43.364c0 2.702-2.243 4.729-4.906 4.729h-22.146V45.838h27.052z"
      ></path>
      <g filter="url(#filter1_d_35_620)">
        <path
          fill="#E7EAF4"
          d="M137.723 45.838v43.364c0 2.702-2.243 4.729-4.906 4.729h-22.146V45.838h27.052z"
        ></path>
      </g>
      <path
        fill="#C5CDDB"
        d="M64.08 45.838l14.338-22.543H152l-14.743 22.543H64.081z"
      ></path>
      <g filter="url(#filter2_d_35_620)">
        <path
          fill="url(#paint2_linear_35_620)"
          d="M64.08 45.838l14.338-22.543H152l-14.743 22.543H64.081z"
        ></path>
      </g>
      <path
        fill="url(#paint3_linear_35_620)"
        d="M137.723 45.97v25.417h-19.726c-1.832 0-3.1-1.185-3.381-2.897l-3.945-22.652 27.052.132z"
        opacity="0.3"
      ></path>
      <g filter="url(#filter3_d_35_620)">
        <path
          fill="url(#paint4_linear_35_620)"
          d="M137.156 45.838h-26.485l13.448 20.384c.961 1.35 2.47 2.16 3.98 2.16h20.584c1.372 0 2.333-1.62 1.509-2.7l-13.036-19.844z"
        ></path>
      </g>
      <g filter="url(#filter4_d_35_620)">
        <path
          fill="#E7EAF4"
          d="M110.671 45.838L96.233 23.295H22l14.983 22.543h73.688z"
        ></path>
      </g>
      <path
        fill="#DAE1ED"
        d="M74.157 73.642H45.74c-.664 0-1.196-.501-1.196-1.127 0-.627.532-1.128 1.196-1.128h28.418c.664 0 1.196.501 1.196 1.127-.133.627-.532 1.128-1.196 1.128zM74.157 78.902H45.74c-.664 0-1.196-.334-1.196-.752 0-.417.532-.751 1.196-.751h28.418c.664 0 1.196.334 1.196.751-.133.418-.532.752-1.196.752zM59.11 84.913H45.757c-.674 0-1.214-.334-1.214-.751 0-.418.54-.752 1.214-.752H59.11c.674 0 1.214.334 1.214.752-.135.417-.675.751-1.214.751z"
      ></path>
      <defs>
        <filter
          id="filter0_d_35_620"
          width="116.89"
          height="92.093"
          x="15.78"
          y="34.838"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="11"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_620"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_35_620"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_35_620"
          width="71.052"
          height="92.093"
          x="88.671"
          y="34.838"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="11"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_620"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_35_620"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_35_620"
          width="131.919"
          height="66.543"
          x="42.081"
          y="12.295"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="11"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_620"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_35_620"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_35_620"
          width="83.827"
          height="66.543"
          x="88.671"
          y="34.838"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="11"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_620"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_35_620"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_d_35_620"
          width="132.671"
          height="66.543"
          x="0"
          y="12.295"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="11"></feOffset>
          <feGaussianBlur stdDeviation="11"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_620"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_35_620"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_35_620"
          x1="64.988"
          x2="99.439"
          y1="55.246"
          y2="55.246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9"></stop>
          <stop offset="1" stopColor="#F53689"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_35_620"
          x1="100.185"
          x2="105.409"
          y1="52.202"
          y2="52.202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83A6FF"></stop>
          <stop offset="1" stopColor="#5A78FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_35_620"
          x1="108.012"
          x2="108.012"
          y1="22.773"
          y2="46.081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF"></stop>
          <stop offset="0.996" stopColor="#ECF0F5"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_35_620"
          x1="124.203"
          x2="124.203"
          y1="71.912"
          y2="48.639"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003" stopColor="#606673" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#AAB2C5"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_35_620"
          x1="130.571"
          x2="130.571"
          y1="45.317"
          y2="68.624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF"></stop>
          <stop offset="0.996" stopColor="#ECF0F5"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ImageEmptyState;
