import styled from 'styled-components';

const colors = {
  green: {
    200: '#A7F3D0',
    400: '#34D399',
  },
  blue: {
    200: '#BFDBFE',
    400: '#60A5FA',
  },
  purple:{
    200: '#DDD6FE',
    400: '#A78BFA',
  },
  red:{
    200: '#fca5a5',
    400: '#ef4444',
  }
}

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 110px;
  max-height: 110px;
  padding: 20px;
  border-radius: 8px;
  background-color:  #fff;
  overflow: hidden;
  border: 1px solid #e3e6f0;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    padding: 8px;
    border-radius: 8px;
    background-color: ${({color}) => colors[color][200]};
    color:  ${({color}) => colors[color][400]};

    > svg, > span > svg {
      width: 28px;
      height: 28px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 20px;
    
    span:nth-child(1) {
      color: #2B2B2B;
      font-size: 18px;
    }
    
    span:nth-child(2) {
      font-weight: 500;
      font-size: 24px;
      color: #ADADAD;
    }

  }

`;
