import React, { useContext } from 'react';
import { Layout } from 'antd';
import { Context } from '../../Context/AuthContext';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import SideBar from '../SideBar/index';
import NavBar from '../NavBar/index';
import ItensMenu from '../ItensMenu/index';

const { Content } = Layout;

const LayoutWithRoute = ({ children }) => {
  const { loadingBancoEmpresaOnline } = useContext(Context);

  const Menu = (
    <ItensMenu />
  );


  return (
    <>
      {loadingBancoEmpresaOnline &&
        <>
          <Modal>
            <Loader isLoading={loadingBancoEmpresaOnline} />
          </Modal>

        </>
      }
      <NavBar menu={Menu} />
      <Layout >
        <SideBar menu={Menu} />
        <Content
          style={{
            background: '#f8f9fa',
            padding: '16px'

          }}
        >
          {children}
        </Content>
      </Layout>
    </>
  );
};

export default LayoutWithRoute;
