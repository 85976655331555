import { useContext } from "react";

import { Context } from "../Context/AuthContext";

export function useAuth() {
  const context = useContext(Context);

  if(!context){
    throw new Error('useAuth precisa ser usado com o AuthProvider');
  }

  return context;
}