import styled from 'styled-components';

export const Container = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  padding: 16px 24px;
  width: 100%;
  border-radius: 5px;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 360px) and (max-width: 780px){
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 781px) and (max-width: 1024px){
    display: flex;
    flex-direction: row;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin: 0;
  }
`;

export const WrapperPesquisa = styled.div`
  position: absolute;
  top: 50% - 21px;
  left: 0;
  display: ${({ visivel }) => visivel ? 'flex' : 'none'};
  flex-direction: row;
  align-items: center;
  background: #fff;
  border: 1px solid #A49EAC;
  border-radius: 4px;
  width: 500px;
  height: 42px;
  max-width: 100%;
  padding: 0 0 0 16px;
  transition: display 0.2s ease;
  
  input {
    width: 100%;
    border: 1px solid transparent;
    outline: 1px solid transparent;
    font-size: 14px;
    padding: 10px 0;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    margin: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 40px;
    height: 40px;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background: transparent;
    transition: background 0.2s ease;
    border: 1px solid transparent;

    &:hover {
      background: rgb(248, 249, 250);
    }
  }
  @media (min-width: 360px) and (max-width: 400px){
    display: ${({ visivel }) => visivel ? 'flex' : 'none'};
    width: 140px;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 401px) and (max-width: 480px){
    display: ${({ visivel }) => visivel ? 'flex' : 'none'};
    width: 180px;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 481px) and (max-width: 715px){
    display: ${({ visivel }) => visivel ? 'flex' : 'none'};
    width: 250px;
    align-items: center;
    justify-content: center;
  }

`;

export const Opcoes = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: row;
  align-items: center;
  height: 100%;
  max-width: 100%;

  @media (min-width: 360px) and (max-width: 869px){
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &:hover {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const Titulo = styled.h1`
  width: 100%;

  @media (min-width: 360px) and (max-width: 869px){
    width: 100%;
    display: none;
  }
`;

export const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;

  .ant-table-thead > tr > th {
    background-color: #fff;
    padding: 12px 0px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.5rem;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(0, 0, 0, 0.04);
}
`;

export const WrapperTables = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  background: #fff;
  border-radius: 4px;


  .ant-table-thead > tr > th {
    background-color: #fff;
    padding: 12px 0px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 13px;;
    line-height: 1.5rem;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(0, 0, 0, 0.04);
}
`;

