import React, { useContext, useEffect, useState } from 'react';
import { FaIndustry } from 'react-icons/fa';
import { BiCalendar, BiCalendarCheck} from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { Container, ContainerTabela, Tabela } from './styles';
import { Tag } from '../Tag';

import api from '../../service/api';
import { Context } from '../../Context/AuthContext';

import { formatDateBrazil } from '../../utils/format-date-brazil';
import { formatDecimal } from '../../utils/format-decimal';

function OrdemProducaoProcesso({ close, idOrdem }) {
  const [processos, setProcessos] = useState([]);
  const { empresa } = useContext(Context);

  useEffect(() => {
    ( async () => {
      try {
        const {data} = await api.get(
          `/empresas/${empresa.id}/pcp/ordem_producao/${idOrdem}/processos`);
        
        const formatData = data.map( item => ({
          ...item,
          dataHoraInicial: formatDateBrazil(item.dataHoraInicial),
          dataHoraPrevistaEntrega: formatDateBrazil(item.dataHoraPrevistaEntrega),
          qtdadeProduzir: formatDecimal(item.qtdadeProduzir, 3),
          qtdadeProduziu: formatDecimal(item.qtdadeProduziu, 3),
          finalizado: item.finalizado === "S" ? "Sim" : "Não"
        }));

        setProcessos(formatData);
      } catch (error) {
        
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <header>
        <div className="titulo">
          <h1>
            <FaIndustry />
            Processos
          </h1>
          <button type="button" onClick={close}><IoClose /></button>
        </div>
        <div className="info">
          <div className="info-ordem">
            <strong>Ordem: </strong>
            <span>{processos[0]?.idOrdemProducao}</span>
          </div>
          <div className="info-cliente">
            <strong>Cliente:</strong>
            <span>{`${processos[0]?.idCliente} - ${processos[0]?.nomeCliente}`}</span>
          </div>
        </div>
      </header>
      <ContainerTabela>
        <Tabela>
          <thead>
            <tr>
              <th>#Processo</th>
              <th>Desc. Processo</th>
              <th>#Recurso</th>
              <th>Desc. Recurso</th>
              <th>
                <BiCalendar />
                Início
              </th>
              <th>
                <BiCalendarCheck />
                Previsão Entrega
              </th>
              <th>Qtd. Produzir</th>
              <th>Qtd. Produziu</th>
              <th>Finalizado</th>
            </tr>
          </thead>
          <tbody>
            {processos.map( processo => (
              <tr key={processo.idProcesso}>
                <td>{processo.idProcesso}</td>
                <td>{processo.descricaoProcesso}</td>
                <td>{processo.idRecurso}</td>
                <td>{processo.descricaoRecurso}</td>
                <td>{processo.dataHoraInicial}</td>
                <td>{processo.dataHoraPrevistaEntrega}</td>                  
                <td>{processo.qtdadeProduzir}</td>
                <td>{processo.qtdadeProduziu}</td>
                <td>
                  <Tag color={ processo.finalizado === "Sim" ? "sucess" : "danger"}>
                    {processo.finalizado}
                  </Tag>
                </td>
              </tr>
            ))}
          </tbody>
        </Tabela>
      </ContainerTabela>
    </Container>
  );
}

export default OrdemProducaoProcesso;
