/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { Context } from '../../Context/AuthContext';
import FormModal from '../Modal';
import ListaEmpesas from '../ListaEmpresas';

import { LogoHeader, InfoEmpresa, IconList, Button, Container, Bar } from './styles'
import { Tooltip, Drawer } from "antd";
import { MenuOutlined, RetweetOutlined, UserOutlined, LoginOutlined } from "@ant-design/icons";

import { isEmptyObject } from '../../utils'


const NavBar = ({ collapsed, menu }) => {
    const [visible, setVisible] = useState(false);
    const [openModalEmpresas, setOpenModalEmpresas] = useState(false);
    
    const { signOut, user, empresa } = useContext(Context);
    
    const openCloseModalEmpresas = useCallback(() => {
        setOpenModalEmpresas(prevState => !prevState);
    }, []);

    useEffect(() => {
        const showModalEmpresas = isEmptyObject(empresa);

        setOpenModalEmpresas(showModalEmpresas);
    }, [empresa]);

    return (
        <Container>
            <LogoHeader>
                {collapsed ? 'C' : 'Centrodata'}
            </LogoHeader>
            <div className="button">
                <Button
                    type="primary"
                    onClick={() => setVisible(true)}
                >
                    <MenuOutlined />
                </Button>
            </div>
            <Bar>
                <Drawer
                    title="Menu"
                    placement="left"
                    onClose={() => setVisible(false)}
                    visible={visible}
                >
                    {menu}
                </Drawer>

                <InfoEmpresa>
                    <small style={{ marginLeft: '18px' }}>
                        {empresa.nome_fantasia}
                    </small>
                    <RetweetOutlined
                        style={{ color: '#fff', fontSize: '18px', paddingLeft: '4px', margin: '10px' }}
                        onClick={openCloseModalEmpresas}
                    >

                    </RetweetOutlined>
                </InfoEmpresa>


                {openModalEmpresas && 
                    (
                        <FormModal>
                            <ListaEmpesas openCloseModal={openCloseModalEmpresas} />
                        </FormModal>
                    )
                }
                
                <IconList className='icons-list'>
                    <div>
                        <UserOutlined style={{ color: '#fff', fontSize: '18px', marginRight: '6px' }} />
                        <span style={{ color: '#fff' }}>{user.nome}</span>
                    </div>
                    <Tooltip title='Sair'>
                        <LoginOutlined
                            style={{ margin: '0 15px 0 30px', color: '#fff', fontSize: '24px' }}
                            onClick={signOut}
                        />
                    </Tooltip>
                </IconList>
            </Bar>
        </Container>
    );
};
export default NavBar;