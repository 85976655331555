import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: 100%; */
    padding: 10px 20px 0 20px;
    overflow-x: auto;

    h3 {
        color: #fff;
        align-items: center;
        text-align: center;
    }
    
`;

export const Headers = styled.div`
    width: 100%;
    background: #1e88e5;
    border-radius: 5px 5px 0 0;

    span {
        color: #fff;
        /* padding: 10px; */
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const Fornercedor = styled.span`
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 17px;
`;

export const DataCriacao = styled.span`
    color: #FFFFFF;
    padding-right: 50px;
  
`;

export const OrcamentoCodigo = styled.span`
    color: #FFFFFF;
    padding-left: 17px;
`;

export const FornecedorCodigo = styled.span`
    color: #FFFFFF;
    padding-left: 17px;
`;

export const NomeSocial = styled.span`
    color: #FFFFFF;
`;

export const Finalidade = styled.span`
    color: #FFFFFF;
    padding-left: 17px;
`;

export const ContainerTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px 0 20px;
    overflow-x: auto;

  .customers {
    border-collapse: collapse;
    background: #FFFFFF;
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .customers td, .customers th {
    font-size: 12px;
    border: 1px solid #f1f1f1;
    color: #888888;
    padding: 10px;
    text-align: center;
    align-items: center;
  }

  .customers th {
    text-align: left;
    font-size: 12px;
    color: #000;
    text-align: center;
    align-items: center;
    font-weight: 600;
  }

  .customers tfoot td {
     border: none;
     text-align: right;
     font-weight: bold;
     color: #000000;
  }`;

export const Inputs = styled.input`
    width: 150px;
    border: 2px solid;
    outline: none;
    text-align: right;
    border: none;
    border-bottom: 1px solid #1e88e5;

    &:focus {
    border: 2px solid;
    outline: none;
    text-align: right;
    border: none;
    border-bottom: 1px solid #0000ff;
    color: #000000;
    }
`;

export const Footer = styled.div`
    margin-top: 40px;
    overflow-x: auto;
    
    div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
    }

    label {
        font-size: 14px;
        margin-right: 20px;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #888888;
    }

    input {
        text-align: center;
        justify-content: center;
        align-items: center;
        border: none;
        border-bottom: 1px solid #1e88e5;
        outline: none;
        background: #f1f1f1;

        &:focus {
        border: 2px solid;
        outline: none;
        text-align: right;
        border: none;
        border-bottom: 1px solid #0000ff;
        color: #000000;
    }
    }

    button {
        background-color: #1e88e5;
        border-radius: 4px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #0000ff;
        }

    }
`;
