import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../Context/AuthContext';
import { Button, Space } from 'antd';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './home.css';

import { isEmptyObject } from '../utils';

import api from './../service/api';
const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

export default function Home() {
  const { empresa } = useContext(Context);

  const [dataBaseOnline, setDataBaseOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const checkDatabaseOnline = useCallback(async () => {
    if(isEmptyObject(empresa)){
      return false;
    };

    try {
      setIsLoading(true);

      const { data } = await api.get(`/empresas/${empresa.id}/status-empresa`);
      setDataBaseOnline(data.statusCode === 200);
    } catch (error) {
      setDataBaseOnline(false);
    } finally {
      setIsLoading(false);
    };
  }, [empresa]);
  
  function handlerClickConectEmpresa() {
    checkDatabaseOnline();
  }

  useEffect(() => {
    checkDatabaseOnline();
  }, [checkDatabaseOnline, empresa]);

  return (
    <>
      {(!dataBaseOnline & !isLoading)
        ? (
          <div>
            <Title level={1} >Error</Title>
            <Title level={3}>Não foi possível se conectar com a base de dados do cliente.</Title>
            <Button 
              disabled={isLoading}
              onClick={handlerClickConectEmpresa}
            >
              <Space>
                Reconectar
                {isLoading && <Spin indicator={antIcon}/>}
              </Space>
            </Button>
          </div> )
        : (
          <div className='home'>
            <Title level={1}>Centrodata</Title>
            <Title level={3}>Um software para empresas como a sua.</Title>
          </div> )
      }
    </>
  )
};
