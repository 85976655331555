import React, { useState, useContext, useEffect } from 'react';

import Text from 'antd/lib/typography/Text';
import { Select } from 'antd';
import { Button, Col, DatePicker, Row, Spin, Alert } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { ContainerFiltro } from './styles';
import FormGrupoInput from '../../components/FormGrupoInput';
import TabelaPersonalizada from '../../components/TabelaPersonalizada';
import Modal from '../../components/Modal';
import ListaClientes from './ListaClientes';

import { Context } from '../../Context/AuthContext';

import { formatDecimal } from '../../utils/format-decimal';
import { round } from '../../utils/round';
import { FORMAT_DATE_BR } from '../../constants';

import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';
import FormModal from '../../components/FormModal';

import TituloTela from '../../components/TituloTela';

import api from '../../service/api';

moment.locale('pt-br');

const { Option } = Select;
const coresClasse = {
  a: '#1cc88a',
  b: '#FCD34D',
  c: '#e74a3b',
}

function CurvaABCProdutos() {
  const colunasTabelaQuantidade = [
    {
      title: 'ID Produto',
      dataIndex: 'id_produto',
      key: 'id_produto',
      width: 100,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Nome Produto',
      dataIndex: 'desc_produto',
      key: 'desc_produto',
      width: 500,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'UND',
      dataIndex: 'unidade_medida',
      key: 'unidade_medida',
      width: 100,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Quantidade Total Vendas',
      dataIndex: 'quantidade_liquida',
      key: 'quantidade_liquida',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: '% Vendas',
      dataIndex: 'perc_quatidade_vendas',
      key: 'perc_quatidade_vendas',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: '% Acumulado',
      dataIndex: 'perc_acumulado_quantidade',
      key: 'perc_acumulado_quantidade',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Classe',
      dataIndex: 'classe',
      key: 'classe',
      align: 'center',
      width: 70,
      isVisible: true,
      isSearch: true,
      render: value => (
        <Text
          style={{ color: coresClasse[value.toLowerCase()] }}
          strong
        >
          {value}
        </Text>)
    },
    {
      title: 'Ordem',
      dataIndex: 'ordem',
      key: 'ordem',
      width: 70,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'ordeacoesm',
      width: 100,
      align: 'center',
      isVisible: true,
      isSearch: true,
      render: (_, record) => (
        <Button
          icon={<SearchOutlined />}
          onClick={() => { handleClickOpenListaProdutos(record) }}
        />),
    }
  ];

  const colunasTabelaValor = [
    {
      title: 'ID Produto',
      dataIndex: 'id_produto',
      key: 'id_produto',
      width: 100,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Nome Produto',
      dataIndex: 'desc_produto',
      key: 'desc_produto',
      width: 500,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'UND',
      dataIndex: 'unidade_medida',
      key: 'unidade_medida',
      width: 100,
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Valor Total Vendas',
      dataIndex: 'valor_total_liquido',
      key: 'valor_total_liquido',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: '% Vendas',
      dataIndex: 'perc_vendas',
      key: 'perc_vendas',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: '% Acumulado',
      dataIndex: 'perc_acumulado',
      key: 'perc_acumulado',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Classe',
      dataIndex: 'classe',
      key: 'classe',
      align: 'center',
      width: 70,
      isVisible: true,
      isSearch: true,
      render: value => (
        <Text
          style={{ color: coresClasse[value.toLowerCase()] }}
          strong
        >
          {value}
        </Text>)
    },
    {
      title: 'Ordem',
      dataIndex: 'ordem',
      key: 'ordem',
      width: 70,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'ordeacoesm',
      width: 100,
      align: 'center',
      isVisible: true,
      isSearch: true,
      render: (_, record) => (
        <Button
          icon={<SearchOutlined />}
          onClick={() => { handleClickOpenListaProdutos(record) }}
        />),
    }
  ];

  const [loading, setLoading] = useState(false);
  const [curvaABC, setCurvaABC] = useState([]);
  const [colunasTabela, setColunasTabela] = useState(colunasTabelaValor)
  const [dataInicial, setDataInicio] = useState(moment());
  const [dataFinal, setDataFinal] = useState(moment());
  const [erros, setErros] = useState();
  const [tipo, setTipo] = useState('valor');
  const [produtoListaCliente, setProdutoListaCliente] = useState(null)
  const { empresa } = useContext(Context);


  useEffect(() => {
    getCurvaABCClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa]);

  async function getCurvaABCClientes() {
    try {
      setLoading(true);

      const { data } = await api.get(
        `/empresas/${empresa.id}/faturamento/curva-abc-produtos`,
        {
          params: {
            dataInicio: moment(dataInicial).format('YYYY/MM/DD'),
            dataFim: moment(dataFinal).format('YYYY/MM/DD'),
            tipo: tipo
          }
        });

      if (tipo === 'valor') {
        const dataFormated = data.response.map((item, key) => ({
          ...item,
          key: `repre${key}`,
          quantidade: formatDecimal(item.quantidade, 3),
          quantidade_devolvida: formatDecimal(item.quantidade_devolvida, 3),
          quantidade_liquida: formatDecimal(item.quantidade_liquida, 3),
          valor_total_descontos: formatDecimal(item.valor_total_descontos),
          valor_total_acrescimos: formatDecimal(item.valor_total_acrescimos),
          valor_devolvido: formatDecimal(item.valor_devolvido),
          valor_total: formatDecimal(item.valor_total),
          valor_total_liquido: formatDecimal(item.valor_total_liquido),
          soma_geral: formatDecimal(item.soma_geral),
          perc_vendas: formatDecimal(round(item.perc_vendas, 2)),
          perc_acumulado: formatDecimal(round(item.perc_acumulado, 2)),
        }))

        setColunasTabela(colunasTabelaValor);
        setCurvaABC(dataFormated);

      } else {
        const dataFormated = data.response.map((item, key) => ({
          ...item,
          key: `repre${key}`,
          quantidade: formatDecimal(item.quantidade, 3),
          quantidade_devolvida: formatDecimal(item.quantidade_devolvida, 3),
          quantidade_liquida: formatDecimal(item.quantidade_liquida, 3),
          valor_total_descontos: formatDecimal(item.valor_total_descontos),
          valor_total_acrescimos: formatDecimal(item.valor_total_acrescimos),
          valor_devolvido: formatDecimal(item.valor_devolvido),
          valor_total: formatDecimal(item.valor_total),
          valor_total_liquido: formatDecimal(item.valor_total_liquido),
          soma_geral_quantidade: formatDecimal(item.soma_geral_quantidade, 3),
          perc_quatidade_vendas: formatDecimal(round(item.perc_quatidade_vendas, 2)),
          perc_acumulado_quantidade: formatDecimal(round(item.perc_acumulado_quantidade, 2)),
        }))

        setColunasTabela(colunasTabelaQuantidade);
        setCurvaABC(dataFormated);
      }

    } catch (e) {
      if (e || e.response.data.statusCode >= 500) {
        const erro = 'Erro 500, servidor não respondendo contate o suporte!';
        setErros(erro);
      }
      else {
        const erro = e.response.data.errors[0].message;
        setErros(erro);
      }
      setCurvaABC([]);
    } finally {
      setLoading(false)
    }
  }

  function handleOnClickFiltro() {
    getCurvaABCClientes();
  }

  function handleOnChangeDataInicio(date) {
    setDataInicio(date);
  }

  function handleOnChangeDataFinal(date) {
    setDataFinal(date);
  }

  function handleOnChangeTipo(tipo) {
    setTipo(tipo);
  }

  function handleClickOpenListaProdutos(cliente) {
    setProdutoListaCliente(cliente);
  }

  function handleClickCloseListaProdutos() {
    setProdutoListaCliente(null);
  }

  const title = {
    codigoTela: '01.76.1341',
    nomeTela: 'Relação de clientes por produtos',
    tema: 'white',
    fontSize: 'medium',
    paddingBottom: 'without',
  }

  return (
    <>
      <TituloTela codigoTela="01.76.1329" nomeTela="Curva ABC Produtos" />

      {produtoListaCliente &&
        <Modal openCloseModal={handleClickCloseListaProdutos}>
          <FormModal
            title={title}
            close={handleClickCloseListaProdutos}
          >
            <ListaClientes
              produto={produtoListaCliente}
              periodo={{ dataInicial, dataFinal, tipo }}
              close={handleClickCloseListaProdutos}
            />
          </FormModal>
        </Modal>}

      <ContainerFiltro>
        <Row>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Data Inicio'
              children={
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE_BR}
                  defaultValue={dataInicial}
                  placeholder='Selecione Data'
                  onChange={handleOnChangeDataInicio}
                />
              }
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Data Final'
              children={
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE_BR}
                  defaultValue={dataFinal}
                  placeholder='Selecione Data'
                  onChange={handleOnChangeDataFinal}
                />
              }
            />
          </Col>
          <Col flex='0 1 130px'>
            <FormGrupoInput
              label='Tipo'
              children={
                <Select defaultValue={tipo} style={{ width: 120 }} onChange={handleOnChangeTipo}>
                  <Option value="valor">Valor</Option>
                  <Option value="quantidade">Quantidade</Option>
                </Select>}
            />
          </Col>

          <Col flex='0 1 60px'>
            <div style={{ padding: '28px 0px 16px 16px' }}>
              <Button
                type='primary'
                onClick={handleOnClickFiltro}>Filtrar</Button>
            </div>
          </Col>
          <Col md={24} lg={24} xs={24} sm={24} xl={24}>
            {erros &&

              <Alert

                closeText="Fechar"
                message={erros}
                showIcon
                type="error"
                action={
                  <Button size="small" danger show={true}>
                    Detalhes
                  </Button>
                }
              />
            }
          </Col>
        </Row>

      </ContainerFiltro>
      {loading
        ?
        <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
          <Spin size="large" />
        </Row>
        :
        <Row>
          <TabelaPersonalizada
            titulo='Curva ABC Produtos'
            columns={colunasTabela}
            dataSource={curvaABC}
            configTabela={{
              size: 'small',
              scroll: { x: '100%', y: '100%' },
              pagination: {
                size: 'small',
                defaultPageSize: 20,
              }
            }}
          />
        </Row>}
    </>
  );
}

export default CurvaABCProdutos;

