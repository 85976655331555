import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Alert, Modal } from 'antd';
import { formatDecimal } from '../../utils/format-decimal';
import api from '../../service/api';
import TituloTela from '../../components/TituloTela';

import {
	Container,
	Headers,
	Fornercedor,
	DataCriacao,
	OrcamentoCodigo,
	FornecedorCodigo,
	Finalidade,
	Header,
	ContainerTable,
	Inputs,
	Footer
} from './styles';

function Orcamento() {
	const [indexItemEditado, setIndexItemEditado] = useState(-1);
	const [orcamento, setOrcamento] = useState({});
	const [itensOrcamento, setItensOrcamento] = useState([]);
	const [valorFreteTotal, setValorFreteTotal] = useState(0);
	const [valorProdutos, setValorProdutos] = useState(0);
	const [valorTotal, setValorTotal] = useState(0);
	const [condicaoPagamento, setCondicaoPagamento] = useState('');
	const [valorTotalIcmsSt, setValorTotalIcmsSt] = useState(0);
	const [valorTotalIpi, setValorTotalIpi] = useState(0);
	const [valorTotalDesconto, setValorTotalDesconto] = useState(0);
	const [valorIdIndex, setValorIdIndex] = useState('');
	const [resultadoRequisicaoSalvar, setResultadoRequisicaoSalvar] = useState({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisibleError, setIsModalVisibleError] = useState(false);
	const [inputDisabled, setInputDisabled] = useState(false);
	const [erro, setErro] = useState('');

	const { token } = useParams();

	useEffect(() => {
		async function getOrcamento() {
			try {
				const { data } = await api.get(`/estoque/orcamento-compra/informar-precos/${token}`);

				const orcamento = data.response;

				const newItens = orcamento.itens.map(item => {
					return {
						...item,
						sincroniza: false,
						loading: false
					}
				});

				const inputBlock = orcamento.situacao !== "Aberto";

				setInputDisabled(inputBlock);
				setOrcamento(orcamento);
				setItensOrcamento(newItens);
				setCondicaoPagamento(orcamento.cond_pagto);
			} catch (error) {
				setErro(error.response.data.response);
			}
		};
		getOrcamento();
	}, [token]);


	useEffect(() => {
		// Totais gerais do orçamento de compra.

		// valor total dos produtos 
		let valorProdutos = 0;

		function somarValorProduto(item, indice) {
			valorProdutos += parseFloat(item.vlr_produtos);
		}

		itensOrcamento.forEach(somarValorProduto);

		setValorProdutos(valorProdutos);

		// valor total frete
		let valorFrete = 0;

		function somarValorFrete(item, indice) {
			valorFrete += parseFloat(item.vlr_frete);
		}

		itensOrcamento.forEach(somarValorFrete);

		setValorFreteTotal(valorFrete);

		//	Valor IPI total 
		let valorTotalIpo = 0;

		function somarValorTotalIcmsSt(item, indice) {
			valorTotalIpo += parseFloat(item.vlr_ST);
		}

		itensOrcamento.forEach(somarValorTotalIcmsSt);
		setValorTotalIcmsSt(valorTotalIpo);

		// valor total do ICMS ST
		let valorTotalIcmsSt = 0;

		function somarValorTotalIpi(item, indice) {
			valorTotalIcmsSt += parseFloat(item.vlr_total_do_ipi);
		}

		itensOrcamento.forEach(somarValorTotalIpi);
		setValorTotalIpi(valorTotalIcmsSt);

		// valor total do ICMS ST
		let ValorTotal = 0;

		function somarValorTotal(item, indice) {
			ValorTotal += parseFloat(item.vlr_total);
		}

		itensOrcamento.forEach(somarValorTotal);
		setValorTotal(ValorTotal);

		// valor total desconto
		let valorTotalDesconto = 0;

		function somarValorDesconto(item, indice) {
			valorTotalDesconto += parseFloat(item.vlr_Desconto);
		}

		itensOrcamento.forEach(somarValorDesconto);
		setValorTotalDesconto(valorTotalDesconto);



	}, [itensOrcamento])

	useEffect(() => {
		async function handleChangeStatus() {

			const itensSincronizar = itensOrcamento.filter((item) => item.sincroniza);


			try {
				const orcamento = {
					itens: itensSincronizar,
					condicaoPagamento
				}

				const { data } = await api.put(`/estoque/orcamento-compra/informar-precos/${token}`, orcamento);
			} catch (error) {
				setErro(error.response.data.response);
			}
		}
		handleChangeStatus();
	}, [condicaoPagamento, indexItemEditado, itensOrcamento, token]);

	function handleChangeInput(index, atributo, valor) { //12,00 -> 12.00
		setItensOrcamento(prevState => {
			let newItens = [...prevState];

			const item = {
				...newItens[index],
				[atributo]: valor.replace(/[^0-9\d.?!]/g, ""),
				sincroniza: true
			};

			newItens[index] = item;
			return newItens;
		});
	}

	function handleBlurInput(index) {
		setIndexItemEditado(index);

		setItensOrcamento(prevState => {
			let newItens = [...prevState];
			newItens[index]['vlr_produtos'] = parseFloat(newItens[index]['qtdade']) * parseFloat(newItens[index]['vlr_unit']) + parseFloat(newItens[index]['vlr_frete']);
			newItens[index]['vlr_total_do_ipi'] = (parseFloat(newItens[index]['vlr_produtos']) - parseFloat(newItens[index]['vlr_frete'])) * parseFloat(newItens[index]['perc_ipi'] / 100);
			newItens[index]['vlr_total'] = parseFloat(newItens[index]['vlr_produtos']) + parseFloat(newItens[index]['vlr_st']) + parseFloat(newItens[index]['vlr_total_do_ipi']) - parseFloat(newItens[index]['vlr_desconto']);
			setValorIdIndex(newItens[index]['id']);

			return newItens;
		});
	}

	function handlingThePaymentConditionSave(value) {
		setCondicaoPagamento(value);
	}

	async function handleSave() {
		try {
			const orcamento = {
				itens: itensOrcamento,
				condicaoPagamento
			}

			const { data } = await api.put(`/estoque/orcamento-compra/informar-precos/${token}`, orcamento);
			setResultadoRequisicaoSalvar(data);
			setIsModalVisible(data.statusCode === 200);
			setIsModalVisibleError(data.status === 500);

		} catch (error) {
			setErro(error.response.data.response);
			setIsModalVisibleError(error.response.status);
		}
	}

	async function handleBlurStatus(index) {
		try {
			let dadosEnviar = { itens: [itensOrcamento[index]] }
			const { data } = await api.put(`/estoque/orcamento-compra/informar-precos/${token}`, dadosEnviar);
		} catch (error) {
			setErro(error.response.data.response);
		}
	}

	function formatDecimalAmericano(value) {
		return parseFloat(value.replace('.', '').replace(',', '.'));
	}

	let data = new Date(orcamento.data_emissao);
	let dia = data.getDate().toString();
	let diaF = (dia.length === 1) ? '0' + dia : dia;
	let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth() Janeiro começa com zero.
	let mesF = (mes.length === 1) ? '0' + mes : mes;
	let anoF = data.getFullYear();

	const diaHora = (diaF + "/" + mesF + "/" + anoF);

	const handleOk = () => {
		setIsModalVisible(false);
		setIsModalVisibleError(false);
	};

	return (
		<>
			<div style={{ height: '968px', backgroundColor: '#f1f1f1' }}>

				{resultadoRequisicaoSalvar.statusCode === 200 &&
					<Modal
						title="Sucesso" visible={isModalVisible}
						onOk={handleOk}
					>
						Itens salvos com sucesso.
					</Modal>
				}

				{isModalVisibleError !== 200 &&
					<Modal
						title="Error" visible={isModalVisibleError}
						onOk={handleOk}
					>
						Erro ao Salvar.
					</Modal>
				}
				{
					erro && <Alert message={erro} type="error" closeText="Fechar" />
				}

				<Container>
					<TituloTela codigoTela="01.75.1365" nomeTela="Digitação de preços de orçamento de compra" />
					<Headers>
						<Header>
							<Fornercedor>{orcamento.razao_social}</Fornercedor>
							<DataCriacao>{diaHora}</DataCriacao>
						</Header>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
							<OrcamentoCodigo>Orçamento de Compra Número: {orcamento.numero}</OrcamentoCodigo>
							<OrcamentoCodigo>Status: {orcamento.situacao}</OrcamentoCodigo>
							<FornecedorCodigo>Fornecedor: {orcamento.codigo_fornecedor} - {orcamento.nome_fornecedor}</FornecedorCodigo>
							<Finalidade>Finalidade: {orcamento.finalidade}</Finalidade>
						</div>
					</Headers>
				</Container >
				<ContainerTable >
					<table className="customers" style={{ overflow: 'auto' }}>
						<thead>
							<tr>
								<th>Cod. Produto</th>
								<th>Cod. Barras</th>
								<th>Cod. Ref</th>
								<th>Descrição Produto</th>
								<th>U. M.</th>
								<th>Qtdade</th>
								<th>Valor Unit.</th>
								<th>Valor Frete</th>
								<th>Valor Produtos</th>
								<th>Valor ICMS ST</th>
								<th>% IPI</th>
								<th>Valor IPI</th>
								<th>Valor Desconto</th>
								<th>Valor Total</th>
							</tr>
						</thead>

						{
							itensOrcamento.length > 0 &&
							itensOrcamento.map((item, index) => {
								return (
									<tbody key={item.produto}>
										<tr>
											<td>{item.produto}</td>
											<td>{item.codigo_barra}</td>
											<td>{item.ref_prod}</td>
											<td>{item.desc_produto}</td>
											<td>{item.unid_med}</td>
											<td>{formatDecimal(item.qtdade, 3)}</td>
											<td>
												<Inputs
													value={item.vlr_unit}
													onBlur={(event) => handleBlurInput(index)}
													onChange={(event) => handleChangeInput(index, 'vlr_unit', event.target.value)}
													disabled={inputDisabled}
												/>
											</td>
											<td>
												<Inputs
													value={item.vlr_frete}
													onBlur={(event) => handleBlurInput(index)}
													onChange={(event) => handleChangeInput(index, 'vlr_frete', event.target.value)}
													disabled={inputDisabled}
												/>
											</td>
											<td style={{ textAlign: 'right' }}>
												{item.vlr_produtos}
											</td>
											<td>
												<Inputs
													value={item.vlr_st}
													onBlur={(event) => handleBlurInput(index)}
													onChange={(event) => handleChangeInput(index, 'vlr_st', event.target.value)}
													disabled={inputDisabled}
												/>
											</td>
											<td>
												<Inputs
													value={item.perc_ipi}
													onBlur={(event) => handleBlurInput(index)}
													onChange={(event) => handleChangeInput(index, 'perc_ipi', event.target.value)}
													disabled={inputDisabled}
												/>
											</td>
											<td style={{ textAlign: 'right' }}>
												{formatDecimal(item.vlr_total_do_ipi, 2)}
											</td>
											<td>
												<Inputs
													value={item.vlr_desconto}
													onBlur={(event) => handleBlurInput(index) || handleBlurStatus(index)}
													onChange={(event) => handleChangeInput(index, 'vlr_desconto', event.target.value)}
													disabled={inputDisabled}
												/>
											</td>
											<td style={{ textAlign: 'right' }}>
												{item.vlr_total}
											</td>
										</tr>
									</tbody>
								)
							})}
						<tfoot>
							<tr>
								<td colSpan={7}>Valores totais: </td>
								<td colSpan={1}>{formatDecimal(valorFreteTotal)}</td>
								<td colSpan={1}>{formatDecimal(valorProdutos)}</td>
								<td colSpan={1}>{formatDecimal(valorTotalIcmsSt)}</td>
								<td colSpan={2}>{formatDecimal(valorTotalIpi)}</td>
								<td colSpan={1}>{formatDecimal(valorTotalDesconto)}</td>
								<td colSpan={1}>{formatDecimal(valorTotal)}</td>
							</tr>
						</tfoot>
					</table>
				</ContainerTable>
				<Footer>
					<div >
						<div>
							<label>Condição de pagamento:</label>
							<input type="text" value={condicaoPagamento} onChange={(event) => handlingThePaymentConditionSave(event.target.value)} disabled={inputDisabled} />
						</div>
						<button onClick={handleSave} disabled={inputDisabled}>Finalizar</button>
					</div>
				</Footer>
			</div >
		</>
	);
}

export default Orcamento;
