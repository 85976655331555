import React from "react";

import { 
  Container
 } from './styles'

function GrupoLabel({ label, children }) {
  return ( 
    <Container>
      <strong>{label}</strong>
      <span>{children}</span>
    </Container>
  );
}

export default GrupoLabel;