import React, { useContext, useState } from 'react';

import pedido from '../../../../src/assets/pedido.svg';
import arrowDown from '../../../../src/assets/arrow-down.svg';
import arrowUp from '../../../../src/assets/arrow-up.svg';
import TabelaAprovacaoOrdemCompraMobile from '../TabelaAprovacaoOrdemCompraMobile';
import { formatDecimal } from '../../../utils/format-decimal';
import api from '../../../service/api';
import { Context } from '../../../Context/AuthContext';
import Loader from '../../../components/Loader';

import {
    ContainerCard,
    Info,
    Divisor,
    BotaoAprovar,
} from './styles';

function CardFinanceiro({ ordemcompra, onAprovar }) {

    const { empresa } = useContext(Context);
    const [ordemPedido, setOrdemPedido] = useState({});
    const [loading, setLoading] = useState(false);

    async function handleDetalhePedidoCompra(numeroOc) {
        setLoading(true);
        const { data } = await api.get(
            `/empresas/${empresa.id}/estoque/detalhe_ordem_compra/${numeroOc}`,
        );

        const pedidoCompra = {
            ...data,
            valor_produtos: formatDecimal(data.valor_produtos),
            valorTotal: parseFloat(data.valor_total) + parseFloat(data.valor_servicos),
            itens: data.itens.map((item) => ({
                ...item,
                quantidade: formatDecimal(item.quantidade),
                valor_unitario: formatDecimal(item.valor_unitario)
            }))
        };

        setOrdemPedido(pedidoCompra);
        setLoading(false);

    }

    function closeModal() {
        setOrdemPedido({});
    }

    return (
        <>
            {loading ? <Loader isLoading={loading} /> :
                <ContainerCard>
                    <div className='topo'>
                        <img src={pedido} alt="Imagem logo pedido"></img>
                    </div>
                    <Info>
                        <div className='grupo-titulo-valor-space-between'>
                            <div className='grupoTituloValor'>
                                <label>
                                    N° Ordem
                                </label>
                                <span>
                                    {ordemcompra.nro_oc}
                                </span>
                            </div>
                            <div className='grupoTituloValor'>
                                <label>
                                    Data emissão
                                </label>
                                <span>
                                    {ordemcompra.data_emissao}
                                </span>
                            </div>
                        </div>
                        <Divisor />
                        <div className='grupoTituloValor'>
                            <label>
                                Nome Fonercedor
                            </label>
                            <span>
                                {ordemcompra.codigo_fornecedor}-{ordemcompra.nome_fornecedor}
                            </span>
                        </div>
                        {!ordemPedido.itens &&
                            <>
                                <Divisor />
                                <div className='grupoTituloValor'>
                                    <label>
                                        Valor total
                                    </label>
                                    <span>
                                        {ordemcompra.valor_total}
                                    </span>
                                </div>

                            </>
                        }
                        {ordemPedido.itens &&
                            <>
                                <Divisor />
                                <div>
                                    <div className='grupo-titulo-valor-space-between'>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor produto
                                            </label>
                                            <span>
                                                {ordemcompra.valor_total}
                                            </span>
                                        </div>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor serviço
                                            </label>
                                            <span className='grupo-valor-a-direita'>
                                                {ordemcompra.valor_servicos}
                                            </span>
                                        </div>
                                    </div>
                                    <Divisor />
                                    <div className='grupo-titulo-valor-space-between'>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor frete
                                            </label>
                                            <span>
                                                {ordemcompra.valor_frete}
                                            </span>
                                        </div>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor ipi
                                            </label>
                                            <span className='grupo-valor-a-direita'>
                                                {ordemcompra.valor_ipi}
                                            </span>
                                        </div>
                                    </div>
                                    <Divisor />
                                    <div className='grupo-titulo-valor-space-between'>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor icms st
                                            </label>
                                            <span>
                                                {ordemcompra.valor_icms_st}
                                            </span>
                                        </div>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor desconto
                                            </label>
                                            <span className='grupo-valor-a-direita'>
                                                {ordemcompra.valor_descontos}
                                            </span>
                                        </div>
                                    </div>
                                    <Divisor />
                                    <div className='grupo-titulo-valor-space-between'>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Situação
                                            </label>
                                            <span>
                                                {ordemcompra.situacao}
                                            </span>
                                        </div>
                                        <div className='grupoTituloValor'>
                                            <label>
                                                Valor total
                                            </label>
                                            <span className='grupo-valor-a-direita'>
                                                {ordemcompra.valorTotal}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <Divisor />

                                <TabelaAprovacaoOrdemCompraMobile itens={ordemPedido.itens} />
                            </>
                        }

                        <Divisor />
                        <div className='grupo-botao'>
                            <button className='detalhe' onClick={ordemPedido.itens ? () => closeModal() : () => handleDetalhePedidoCompra(ordemcompra.nro_oc)}>
                                <span>Detalhes</span>
                                <img src={ordemPedido.itens ? arrowUp : arrowDown} alt="Imagem seta"></img>
                            </button>
                            <BotaoAprovar
                                onClick={() => onAprovar()}
                            >
                                Aprovar
                            </BotaoAprovar>
                        </div>

                    </Info>
                </ContainerCard>
            }
        </>
    );
}

export default CardFinanceiro;