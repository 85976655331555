import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  width: 100%;
  /* overflow-x: auto;
  overflow-y: auto;
  max-height: 600px;
  min-height: 200px; */
`;

export const Table = styled.table`
  width: 100%;
  height: 100%;

  thead {
    background-color: rgba(249, 250, 251, 1);
    /* position: sticky;
    top: 0;
    left: 0; */
    
    th {
      text-transform: uppercase;
      padding: 0.75rem 1.25rem;
      font-size: 16px;
      line-height: 1rem;
      font-weight: 500;
      color: #6B7280;
      letter-spacing: 0.05rem;
      text-align: left;
      white-space: nowrap;
    }
  }

  tbody {
    max-height: 358px;
    width: 100%;
    background-color: #fff;
    
    tr {
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      transition: background 0.3s ease-in;
      
      &:hover {
        background: #F9FAFB;
      }

      td {
        padding: 1rem 1.25rem;
        font-size: 14px;
        font-weight: 100.25rem;
        color: #9CA3AF;
      }
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 80px;  
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: auto;
    min-width: 250px; 
    white-space: break-word; 
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;  
  }

  @media screen and (max-width: 600px) {
    & {
      border: 0;
    }

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  
    tbody tr {
      border: 1px solid rgba(229, 231, 235, 1);
      padding: 12px;
      display: block;
      margin-bottom: .625em;
      border-radius: 8px;

      td {
        display: block;
        text-align: right;
        font-size: 14px;
        width: 100%;
        padding: 4px 0;
      }
    }
  
    td::before {
      content: attr(data-label);
      float: left;
      font-size: 14px;
      color: #6B7280;
      font-weight: bold;
      text-transform: uppercase;
    }

    td:nth-child(1),
    td:nth-child(3) {
      width: 100%;  
    }
  }     
`;