import styled, { css } from "styled-components";
import { MdKeyboardArrowRight, MdKeyboardBackspace } from 'react-icons/md';
import { Link } from "react-router-dom";

const tagVariants = {
  d: css`
    background: #d1d5db;
    color: #374151;
`, 
  l: css`
    background: #bfdbfe;
    color: #1d4ed8;
  `,
    e: css`
    background: #bbf7d0;
    color: #15803d;
  `,
  m: css`
    background: #fecaca;
    color: #b91c1c;
  `,
}

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 100vh;
`;

export const SecaoGruposEquipamentos = styled.div`
  border-right: 1px solid #e5e7eb;
  height: 100%;
  width: 500px;
`;

export const InputFiltro = styled.input`
  width: 100%;
  background: #F5F5F5;
  padding: 16px 12px; 
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  border: none;
  border-radius: 8px;
  margin-top: 34px;
`;

export const WrapperInput = styled.div`
  width: 100%;
  padding: 0 21px;

`;

export const ListaGrupos = styled.ul`
  margin-top: 20px;
`;

export const SecaoListaEquipamentos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;

  width: 100%;
  height: 100%;
  background: #F5F5F5;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;

  margin-bottom: 20px;;
`;
export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  margin-right: 16px;
  background: #d1d5db;
`;

export const TitleGrupo = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const HeaderRow = styled.tr`
  background: #E4E4E7;

  th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const HeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  color: #171717;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const Row = styled.tr`
  td:first-child div {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  td:last-child div {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const Cell = styled.td`
  > div {
    display: flex;
    height: 50px;
    padding-left: 12px;
    align-items: center;
    background:  #FFFFFF;
  }

  padding: 7px 0;
  background: #F5F5F5;
  color: #A3A3A3;
  font-size: 14px;
  line-height: 20px;
`;

export const IconArrowRight = styled(MdKeyboardArrowRight)`
  color: #A3A3A3;
  width: 24px;
  height: 24px;

  cursor: pointer;
`;

export const Tag = styled.div`
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  ${({ type }) => tagVariants[type.toLowerCase() || 'd']}
`;

export const IconBack = styled(MdKeyboardBackspace)`
  width: 24px;
  height: 24px;
`;

export const LinkBack = styled(Link)`
  display: flex;
  gap: 8px;
  font-size: 16px;
  align-items: center;
  background:  transparent;
`;
