import React, { useRef, useCallback, useEffect } from 'react';
import ReactDom from 'react-dom';

import { Container } from './styles';

import useLockBodyScroll from '../../hooks/useDisableBodyScroll ';

function Modal({openCloseModal = () => {}, children}) {
  const modalRef = useRef();

  function handleCloseModal(e) {
    if( e.target === modalRef.current ) {
      openCloseModal();
    }
  }
  
  const keyPress = useCallback( e => {
    if( e.key === 'Escape') {
      openCloseModal();
    }
  }, [openCloseModal])

  useEffect( () => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress) 
  }, [keyPress]);

  useLockBodyScroll();

  return ReactDom.createPortal( 
    <Container 
      ref={modalRef} 
      onClick={handleCloseModal}>
      {children}
    </Container>, document.getElementById('modal-root')
  )
}

export default Modal;