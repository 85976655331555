import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const BoxCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GridCard = styled.div`
  display: grid;
  grid-template-columns: '1fr 10px max-content 10px max-content'
`;

export const ChartFaturamentoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ChartMensalContainer = styled.div`
  height: 445px;
  width: 100%;
`;