import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import api from '../../../service/api';

import { Button, Divider, Input, notification } from 'antd';

import { RiTruckLine } from "react-icons/ri";

import FormGroup from './FormGroup';
import { 
  Container,
  TranspotadoraContainer,
  Form,
  Content,
  NotaFiscalContainer,
  OcorrenciasContainer,
  OcorrenciaContainer,
  Separador,
} from './styles';

import Loader from '../../../components/Loader';
import EmptyState from './EmptyState';
import { formatDateBrazil } from '../../../utils/format-date-brazil';
import InitialState from './InitialState';

function RastreioNotaFiscal() {
  const [isLoading, setIsLoading] = useState(false);
  const [notas, setNotas] = useState(null);
  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');
  const [dadosTransporte, setDadosTransporte] = useState({})
  const { hash } = useParams();

  const [apiNotification, contextHolder] = notification.useNotification();

  const openNotification = (messagem) => {
    apiNotification.error({
      message: `Erro`,
      description: messagem,
      placement: 'topRight',
      duration: 5,
    });
  };

  async function getOcorrencias(){
    try {
      const paramsRequest = {
        nro_nota: numero,
        serie_nota: serie 
      }

      const { data } = await api.get(`/logistica/rastreamento/nota_fiscal/${hash}`, {
        params: paramsRequest
      });

      setNotas(data.result)
    } catch (errorRequest) {
      if(!errorRequest.response) {
        openNotification('Servidor não respondendo. Tente novamente mais tarde.');  
        return;
      }

      const { response } = errorRequest;
      openNotification(response.data.error);
    } finally {
      setIsLoading(false);
    }
  } 
  
  useEffect(() => {
    async function getDadosTransporte() {
      setIsLoading(true);

      try {
        const { data } = await api.get(`/logistica/rastreamento/dados_transporte/${hash}`);
  
        setDadosTransporte(data.result)
      } catch (errorRequest) {
        if(!errorRequest.response) {
          openNotification('Servidor não respondendo. Tente novamente mais tarde.');  
          return;
        }

        const { response } = errorRequest;
        openNotification(response.data.error);
      } finally {
        setIsLoading(false);
      }
    }

    getDadosTransporte();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handlePesquisar(event) {
    event.preventDefault();
    setIsLoading(true);

    getOcorrencias();
  }

  function handleChangeNumero(event){
    const apenasNumeros = event.target.value.replace(/\D/g, '');

    setNumero(apenasNumeros);
  }

  function handleChangeSerie(event){
    setSerie(event.target.value);
  }

  return (
    <Container>
      {contextHolder}

      <Loader isLoading={isLoading} />

      <header>
        <h3>Rastreamento</h3>

        <TranspotadoraContainer>
          <div className='icon-container'>
            <RiTruckLine color='#FFFFFF' size='100%'/> 
          </div>

          <div className="infos">
            <span>Transportadora</span>
            <span>{dadosTransporte?.transportadora?.nome}</span>
          </div>
        </TranspotadoraContainer>
      </header>

      <Form onSubmit={handlePesquisar}>
        <FormGroup 
          label="Remetente"
          minWidth={100}
        >
          <Input 
            disabled
            value={dadosTransporte?.remetente?.nome}
            size='large'
            style={{cursor: 'default'}}
          />
        </FormGroup>

        <FormGroup 
          label="* Nota Fiscal"
        >
          <Input 
            value={numero}
            inputMode="numeric"
            size='large'
            placeholder='Número nota fiscal'
            onChange={handleChangeNumero}
          />
        </FormGroup> 

        <FormGroup 
          label="Série"
        >
          <Input 
            value={serie}
            size='large'
            placeholder='Série nota fiscal'
            onChange={handleChangeSerie}
          />
        </FormGroup>

        <Button
          htmlType='submit'
          type='primary'
          size='large'
          onClick={handlePesquisar}
          disabled={numero.length === 0}
        >
          Pesquisar
        </Button>
      </Form>

      <Divider />

      <Content>

        { (!isLoading && notas?.length > 0) && 
          notas.map((nota, index) => (
            <>
              {index > 0 && <Divider />}

              <NotaFiscalContainer key={nota.numero}>
                <div className='detalhes-nota'>
                  <strong>NOTA</strong>

                  <div>
                    <div className='grupo-info'>
                      <span>Número</span>
                      <span>{nota.numero}</span>
                    </div>

                    <div className='grupo-info'>
                      <span>Série</span>
                      <span>{nota.serie}</span>
                    </div>

                    <div className='grupo-info'>
                      <span>Emissão</span>
                      <span>{formatDateBrazil(nota.data_emissao, 'dd/MM/yyyy')}</span>
                    </div>
                  </div>
                </div>

                <div className='container-remetente-destinatario'>
                  <div className='detalhes-remetente'>
                    <strong>REMETENTE</strong>

                    <div>
                      <div className='grupo-info'>
                        <span>CPF/CNPJ</span>
                        <span>{nota.remetente.cnpj}</span>
                      </div>

                      <div className='grupo-info'>
                        <span>Nome</span>
                        <span>{nota.remetente.nome_fantasia}</span>
                      </div>
                    </div>
                  </div>

                  <Separador />

                  <div className='detalhes-destinatario'>
                    <strong>DISTINATÁRIO</strong>

                    <div>
                      <div className='grupo-info'>
                        <span>CPF/CNPJ</span>
                        <span>{nota.destinatario.cnpj}</span>
                      </div>

                      <div className='grupo-info'>
                        <span>Nome</span>
                        <span>{nota.destinatario.nome_fantasia}</span>
                      </div>
                    </div>
                  </div>        
                </div>
              </NotaFiscalContainer>

              <OcorrenciasContainer>
                <strong>Ocorrências</strong>

                { nota.ocorrencias.map((ocorrencia, index) => (
                  <OcorrenciaContainer
                    key={`${index}-${ocorrencia.descricao}`}
                    isFirst={index === 0}
                    isLast={index === nota.ocorrencias.length - 1}
                  >
                    <div className='status'>
                      <RiTruckLine color='#FFFFFF' size={20}/>  
                    </div>
    
                    <div className='infos'>
                      <strong>{ocorrencia.descricao}</strong>
                      <div className='data-hora'>
                        <span>{formatDateBrazil(ocorrencia.data, 'dd/MM/yyyy')}</span>
                        <div className='separador' />
                        <span>{ocorrencia.hora}</span>
                      </div>
                    </div>
                  </OcorrenciaContainer>
                ))}
              </OcorrenciasContainer>
            </>
          ))
        } 
        
      { ( !isLoading && notas?.length === 0 ) && (
        <EmptyState content={numero}/>
      ) }

      {( !isLoading && !notas) && ( <InitialState /> )}
    </Content>

    <Divider />
    
    <footer>
      <p>
        Desenvolvido por: {' '}   
        <a 
          rel="noopener noreferrer"
          target="_blank" 
          href='http://centrodata.com.br/'
        >
          Centrodata Sistemas
        </a>
      </p>          
    </footer>
    </Container>
  )
}

export default RastreioNotaFiscal;