import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const WrapperTable = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;
`;
