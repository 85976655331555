import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 24px;
  padding: 24px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;

  background-color: #fff;


  @media (max-width: 575px) {
    padding: 12px;  
  }  
`;

export const FormContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  @media (max-width: 574px) {
    .ant-row {
      display: block;
    }

    .ant-form-item .ant-form-item-label, 
    .ant-form-item .ant-form-item-control {
      flex: 1;
    }
  }
  
  @media (min-width: 769px) {
    grid-template-columns: minmax(100px, 130px) minmax(100px, 130px) minmax(100px, 250px) minmax(100px, 130px); 
  }

  @media (max-width: 386px) {
    .select-gestor{
      grid-column: span 2;
    }
  }
  
`;

export const ButtonContainer = styled.div`
  padding-top: 31px;

  @media (max-width: 386px) {
    grid-column: span 2;
    padding-top: 10px;
  }
`;