import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button, Select, Checkbox, Input } from 'antd';
import { BsCircleFill } from "react-icons/bs";
import { AiFillAlert, AiFillCheckCircle } from "react-icons/ai";

import api from '../../../service/api';
import FormGrupoInput from '../../../components/FormGrupoInput';
import TabelaPersonalizada from '../../../components/TabelaPersonalizada';
import TituloTela from '../../../components/TituloTela';
import Modal from '../../../components/Modal';
import FormModal from '../../../components/FormModal';
import Maps from '../../../components/Maps';
import { Context } from '../../../Context/AuthContext';
import { formatDateBrazil } from '../../../utils/format-date-brazil';

import useInterval from '../../../hooks/useInterval';

import { ContainerFiltro, ContainerLabels } from './styles';

const { Option } = Select;

function ConsultaPosicaoVeiculo() {

    const { empresa } = useContext(Context);
    const [gestor, setGestor] = useState([]);
    const [isGestor, setIsGestor] = useState();
    const [usuario, setUsuario] = useState({});
    const [valueFiltro, setValueFiltro] = useState("0");
    const [dadosVeiculosEmOperacao, setDadosVeiculosEmOperacao] = useState([]);
    const [dadosVeiculosSemOperacao, setDadosVeiculosSemOperacao] = useState([]);
    const [andandoSemOperacao, setAndandoSemOperacao] = useState({});
    const [statusEmOperacao, setStatusEmOperacao] = useState({});
    const [checkBoxRefresh, setCheckBoxRefresh] = useState(false);
    const [busca, setBusca] = useState('');
    const [dadosVeiculosEmOperacaoFilter, setDadosVeiculosEmOperacaoFilter] = useState([]);
    const [dadosVeiculosSemOperacaoFilter, setDadosVeiculosSemOperacaoFilter] = useState([]);
    const [locations, setLocations] = useState([]);
    const [coordenadasVeiculosEmOperacoes, SetCoordenadasVeiculosEmOperacoes] = useState([]);
    const [coordenadasVeiculosSemOperacoes, SetCoordenadasVeiculosSemOperacoes] = useState([]);
    const [openModalMap, setOpenModalMap] = useState(false);

    useEffect(() => {
        setDadosVeiculosEmOperacaoFilter([...dadosVeiculosEmOperacao]);
        setDadosVeiculosSemOperacaoFilter([...dadosVeiculosSemOperacao]);

        const coordernadasVeiculos = [
            ...coordenadasVeiculosEmOperacoes,
            ...coordenadasVeiculosSemOperacoes
        ]

        setLocations(coordernadasVeiculos)
    }, [dadosVeiculosEmOperacao, dadosVeiculosSemOperacao])

    useEffect(() => {
        async function usuarios() {
            try {
                const { data } = await api.get(
                    `/empresas/${empresa.id}/logistica/usuarios`,
                );

                const usuario = data;
                //é gestor?
                if (usuario.gestor_frota === "S") {
                    //preenche o state gestor com os dados do usuário [{codigo, nome}]
                    setGestor([{
                        codigo: usuario.codigo,
                        nome: usuario.nome,
                    }])
                    //isGestor => recebe se o usuário é gestor
                    setIsGestor(true);
                    //valueFiltro código do usuario 
                    setValueFiltro(usuario.codigo);
                } else {
                    //consulta todos os gestores e coloca em gestor
                    const { data } = await api.get(
                        `/empresas/${empresa.id}/logistica/gestor_frota`,
                    );

                    setGestor(data);
                    // isGestor => recebe false 
                    setIsGestor(false);
                    //valueFiltro = 0
                    setValueFiltro("Todos");
                }



                setUsuario(data);
            } catch (error) {
                console.log(error.response.data);
            }
        }
        usuarios();
    }, [])

    useEffect(() => {
        veiculosEmOperacao();
        veiculosSemOperacao();
        gestores();
    }, [valueFiltro])

    useEffect(() => {

        if (dadosVeiculosEmOperacao.length === 0) {
            return
        };
        const filtro = String(busca).toLowerCase();

        const veiculosFiltradosEmOperacao = dadosVeiculosEmOperacao.filter((veiculo) => veiculo.placa.toLowerCase().indexOf(filtro) >= 0);
        const veiculosFiltradosSemOperacao = dadosVeiculosSemOperacao.filter((veiculo) => veiculo.placa.toLowerCase().indexOf(filtro) >= 0);

        setDadosVeiculosEmOperacaoFilter(veiculosFiltradosEmOperacao);
        setDadosVeiculosSemOperacaoFilter(veiculosFiltradosSemOperacao);



    }, [busca])

    useInterval(() => {
        if (checkBoxRefresh) {
            veiculosEmOperacao();
            veiculosSemOperacao();
        } else {
            clearInterval(useInterval);
        }
    }, 20000);

    async function gestores() {
        const { data } = await api.get(
            `/empresas/${empresa.id}/logistica/gestor_frota`,
        );

        const teste = data.map((operacao) => {
            return {
                ...operacao,
                key: operacao.codigo,
            }
        })
        setGestor(teste);
    }

    async function veiculosEmOperacao() {
        try {
            const { data } = await api.get(
                `/empresas/${empresa.id}/logistica/veiculos_em_operacao/${valueFiltro}`);

            const qtd = data.reduce((acumulador, item) => {
                if (item.status === 0) {
                    return {
                        ...acumulador,
                        status0: acumulador.status0 + 1
                    }
                }

                if (item.status === 1) {
                    return {
                        ...acumulador,
                        status1: acumulador.status1 + 1
                    }
                }

                if (item.status === 2) {
                    return {
                        ...acumulador,
                        status2: acumulador.status2 + 1
                    }
                }

                return acumulador;
            }, { status0: 0, status1: 0, status2: 0, status3: 0 });

            const teste = data.map((operacao) => {
                return {
                    ...operacao,
                    key: operacao.codigo,
                }
            })
            setStatusEmOperacao(qtd);
            setDadosVeiculosEmOperacao(teste);

            const posicoesVeiculos = data.map((veiculo) => {
                return {
                    uid: veiculo.codigo,
                    lat: veiculo.latitude_loc_atual === null ? '' : veiculo.latitude_loc_atual,
                    lng: veiculo.longitude_loc_atual === null ? '' : veiculo.longitude_loc_atual,
                    placa: veiculo.placa,
                    status: veiculo.status,
                    veiculos: 'Em Operação',
                    type: "info"
                }
            })

            SetCoordenadasVeiculosEmOperacoes(posicoesVeiculos);


        } catch (error) {
            console.log(error);
        }
    }

    async function veiculosSemOperacao() {
        try {
            const { data } = await api.get(
                `/empresas/${empresa.id}/logistica/veiculos_sem_operacao/${valueFiltro}`,
            );

            const qtd = data.reduce((acumulador, item) => {
                if (item.andando_sem_operacao === 0) {
                    return {
                        ...acumulador,
                        andandoSemOperacao0: acumulador.andandoSemOperacao0 + 1
                    }
                }

                if (item.andando_sem_operacao === 1) {
                    return {
                        ...acumulador,
                        andandoSemOperacao1: acumulador.andandoSemOperacao1 + 1
                    }
                }

                return acumulador;
            }, { andandoSemOperacao0: 0, andandoSemOperacao1: 0, andandoSemOperacao2: 0 });

            const teste = data.map((operacao) => {
                return {
                    ...operacao,
                    key: operacao.codigo,
                }
            })

            const posicoesVeiculos = data.map((veiculo) => {
                return {
                    uid: veiculo.Codigo,
                    lat: veiculo.latitude_loc_atual === null ? '' : veiculo.latitude_loc_atual,
                    lng: veiculo.longitude_loc_atual === null ? '' : veiculo.longitude_loc_atual,
                    placa: veiculo.placa,
                    status: veiculo.andando_sem_operacao,
                    veiculos: 'Sem Operação',
                    type: "parking"
                }
            })

            SetCoordenadasVeiculosSemOperacoes(posicoesVeiculos);

            setAndandoSemOperacao(qtd);
            setDadosVeiculosSemOperacao(teste);
        } catch (error) {
            console.error('veiculos sem operação', error);
        }
    }

    function atualizaVeiculos() {
        veiculosEmOperacao();
        veiculosSemOperacao();
    }

    function converteSegundosParaHora(segundos) {
        if (segundos > 0) {
            let segundosResto = 0;

            const dias = Math.trunc(segundos / 86400);
            segundosResto = segundos % 86400;

            const horas = Math.trunc(segundosResto / 3600);
            segundosResto = segundosResto % 3600;

            const minutos = Math.trunc(segundosResto / 60);
            segundosResto = segundosResto % 60;

            return dias + ' dias, ' + horas + " horas " + minutos + " minutos";

        } else {
            return '';
        }
    }

    const statusVeiculoEmOperacaoColor = {
        0: '#e74a3b',
        1: '#1cc88a',
        2: '#ffff00',
    }

    const statusVeiculoSemOperacaoColor = {
        0: '#1cc88a',
        1: '#e74a3b',
    }

    const estruturaColunasveiculosEmOperacao = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 60,
            align: 'center',
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                < BsCircleFill style={{ color: statusVeiculoEmOperacaoColor[record.status] }} />
            ),
        },
        {
            title: 'Placa cavalo',
            dataIndex: 'placa',
            key: 'placa',
            width: 98,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Tipo carroceria',
            dataIndex: 'tipo_carroceria',
            key: 'tipo_carroceria',
            width: 180,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Gestor',
            dataIndex: 'gestor',
            key: 'gestor',
            width: 100,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Nome do motorista',
            dataIndex: 'nome_motorista',
            key: 'nome_motorista',
            width: 200,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'TP',
            dataIndex: 'tipo_ordem',
            key: 'tipo_ordem',
            width: 40,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Município Origem',
            dataIndex: 'desc_mun_expedidor',
            key: 'desc_mun_expedidor',
            width: 220,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'UF O.',
            dataIndex: 'uf_expedidor',
            key: 'uf_expedidor',
            width: 80,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Município Destino',
            dataIndex: 'desc_mun_recebedor', 
            key: 'desc_mun_recebedor',
            width: 220,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'UF D.',
            dataIndex: 'uf_recebedor',
            key: 'uf_recebedor',
            align: 'left',
            width: 80,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Dt Hr Últ. Loc.',
            dataIndex: 'data_hora_evento',
            key: 'data_hora_evento',
            align: 'left',
            width: 200,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <div>
                    <span>{formatDateBrazil(record.data_hora_evento)}</span>
                </div>
            ),
        },
        {
            title: 'Rota',
            dataIndex: 'sit_rota',
            key: 'sit_rota',
            align: 'center',
            width: 50,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                (record.sit_rota === 0 && <AiFillAlert style={{ color: '#e74a3b' }} />) ||

                record.sit_rota === 2 && <AiFillCheckCircle style={{ color: '#1cc88a' }} />

            ),
        },
        {
            title: 'Última Localização',
            dataIndex: 'localizacao_evento',
            key: 'localizacao_evento',
            align: 'left',
            width: 450,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Dt Prog. Entr.',
            dataIndex: 'data_programada_entrega',
            key: 'data_programada_entrega',
            align: 'left',
            width: 150,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <div>
                    <span>{formatDateBrazil(record.data_programada_entrega)}</span>
                </div>
            ),
        },
        {
            title: 'Dt Prev. Entr. Parc.',
            dataIndex: 'data_prev_entrega_parcial',
            key: 'data_prev_entrega_parcial',
            align: 'left',
            width: 200,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <div>
                    <span>{formatDateBrazil(record.data_prev_entrega_parcial)}</span>
                </div>
            ),
        },
        {
            title: 'Justificativa de Atraso',
            dataIndex: 'id_descricao_justificativa',
            key: 'id_descricao_justificativa',
            align: 'left',
            width: 400,
            isVisible: true,
            isSearch: true,
        },
    ];

    const estruturaColunasveiculosSemOperacao = [
        {
            title: 'Status',
            dataIndex: 'andando_sem_operacao',
            key: 'andando_sem_operacao',
            width: 60,
            align: 'center',
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                < BsCircleFill style={{ color: statusVeiculoSemOperacaoColor[record.andando_sem_operacao] }} />
            ),
        },
        {
            title: 'Placa cavalo',
            dataIndex: 'placa',
            key: 'placa',
            width: 98,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Tipo carroceria',
            dataIndex: 'tipo_carroceria',
            key: 'tipo_carroceria',
            width: 180,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Gestor',
            dataIndex: 'gestor',
            key: 'gestor',
            width: 100,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Nome do motorista',
            dataIndex: 'nome_motorista',
            key: 'nome_motorista',
            width: 200,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'TP',
            dataIndex: 'tipo_ordem',
            key: 'tipo_ordem',
            width: 60,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Município Origem',
            dataIndex: 'desc_mun_expedidor',
            key: 'desc_mun_expedidor',
            width: 250,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'UF O.',
            dataIndex: 'uf_expedidor',
            key: 'uf_expedidor',
            width: 80,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Município Destino',
            dataIndex: 'desc_mun_recebedor',
            key: 'desc_mun_recebedor',
            width: 250,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'UF D.',
            dataIndex: 'uf_recebedor',
            key: 'uf_recebedor',
            align: 'left',
            width: 80,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Dt Hr Últ. Loc.',
            dataIndex: 'data_hora_evento',
            key: 'data_hora_evento',
            align: 'left',
            width: 200,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <div>
                    <span>{formatDateBrazil(record.data_hora_evento)}</span>
                </div>
            ),
        },
        {
            title: 'Tempo sem operação',
            dataIndex: 'segundos_sem_op',
            key: 'segundos_sem_op',
            align: 'left',
            width: 450,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <div>
                    <span>{converteSegundosParaHora(record.segundos_sem_op)}</span>
                </div>
            ),
        },
        {
            title: 'Localização atual',
            dataIndex: 'localizacao_atual',
            key: 'localizacao_atual',
            align: 'left',
            width: 250,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Dt Hr Loc. Atual',
            dataIndex: 'dt_localizacao_atual',
            key: 'dt_localizacao_atual',
            align: 'left',
            width: 400,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <div>
                    <span>{formatDateBrazil(record.dt_localizacao_atual)}</span>
                </div>
            ),
        },
    ];

    const numeroDeRegistro = dadosVeiculosEmOperacao.length;

    const numeroDeRegistros = dadosVeiculosSemOperacao.length;

    const onChange = (e) => {
        setCheckBoxRefresh(e.target.checked)
    };

    function onChangeFiltroTabela(busca) {
        setBusca(busca.target.value)
    }

    function onCloseModalMap() {
        setOpenModalMap(null);
    }

    function manipuladorCliqueMapPedido() {
        setOpenModalMap(true);
    }

    const detalhesOrdemPedido = {
        codigoTela: '02.78.1374',
        nomeTela: 'Localização dos veículos Mapa',
        tema: 'white',
        fontSize: 'medium',
        paddingBottom: 'without',
    }

    return (
        <>
            {openModalMap &&
                <Modal openCloseModal={onCloseModalMap} >
                    <FormModal
                        title={detalhesOrdemPedido}
                        close={onCloseModalMap}
                    >
                        <Maps locations={locations}></Maps>
                    </FormModal>
                </Modal>
            }

            <TituloTela codigoTela="02.78.1369" nomeTela="Consulta posição dos veículos" />

            <ContainerFiltro>
                <Row className='row'>
                    <div className='boxFilter'>
                        <Col flex='0 1 130px'>
                            <FormGrupoInput
                                label='Pesquisa rapida placa'
                            >
                                <Input
                                    style={{ width: 160 }}
                                    onChange={(value) => onChangeFiltroTabela(value)}
                                />
                            </FormGrupoInput>
                        </Col>

                        <Col flex='0 1 130px'>
                            <FormGrupoInput
                                label='Gestor'
                                children={
                                    <Select
                                        showSearch
                                        disabled={isGestor}
                                        style={{ width: 160 }}
                                        filterOption={(input, option) =>
                                            option.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        defaultValue={valueFiltro}
                                        value={valueFiltro}
                                        onChange={(value) => { setValueFiltro(value) }}
                                    >
                                        <Option value='0'>Todos</Option>
                                        {gestor.map((gestores) => (
                                            <Option 
                                                key={gestores.codigo}
                                                value={gestores.codigo}
                                            >
                                                {gestores.codigo} - {gestores.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                }
                            />
                        </Col>

                        <Col flex='0 1 60px'>
                            <div style={{ padding: '30px 0px 16px 8px' }}>
                                <Button type='primary' onClick={() => atualizaVeiculos()}>Atualizar veículos</Button>
                            </div>
                        </Col>

                        <Col flex='120px 120px 120px 120px' style={{ paddingTop: '35px' }}>
                            <span >
                                <Checkbox onClick={onChange}>
                                    Atualizar tela automaticamente
                                </Checkbox>
                            </span>
                        </Col>
                    </div>
                    <div>
                        <Col flex='0 1 60px' >
                            <div style={{ padding: '30px 10px 16px 8px' }}>
                                < Button
                                    type='primary'
                                    onClick={() => manipuladorCliqueMapPedido()}
                                >
                                    Mapa
                                </ Button>
                            </div>
                        </Col>
                    </div>
                </Row>
            </ContainerFiltro>

            <Row>
                <Col span={24} >
                    <TabelaPersonalizada
                        titulo='Veículos em operação'
                        columns={estruturaColunasveiculosEmOperacao}
                        dataSource={dadosVeiculosEmOperacaoFilter}
                        configTabela={{
                            size: 'small',
                            width: '100%',
                            scroll: { x: 400, y: 600 },
                            pagination: {
                                size: 'small',
                                defaultPageSize: 20,
                            },
                        }}
                    />
                </Col>
            </Row>


            <ContainerLabels>
                <Row>
                    <Col>
                        <div>
                            <span>
                                Número de registro: {numeroDeRegistro}
                            </span>
                        </div>
                        <div>
                            <label style={{ paddingTop: '2px' }}>
                                <BsCircleFill style={{ color: '#e74a3b' }} />
                            </label>
                            <span>
                                Atrasados: {statusEmOperacao.status0}
                            </span>
                        </div>
                        <div>
                            <label style={{ paddingTop: '2px' }}>
                                <BsCircleFill style={{ color: '#1cc88a' }} />
                            </label>
                            <span>
                                Dentro do prazo: {statusEmOperacao.status1}
                            </span>
                        </div>
                        <div>
                            <label style={{ paddingTop: '2px' }}>
                                <BsCircleFill style={{ color: '#ffff00' }} />
                            </label>
                            <span>
                                Programado e não iniciado: {statusEmOperacao.status2}
                            </span>
                        </div>
                    </Col>
                </Row>
            </ContainerLabels>


            <Row>
                <Col span={24} >
                    <TabelaPersonalizada
                        titulo='Veículos sem operação'
                        columns={estruturaColunasveiculosSemOperacao}
                        dataSource={dadosVeiculosSemOperacaoFilter}
                        configTabela={{
                            size: 'small',
                            scroll: { x: 400, y: 600 },
                            pagination: {
                                size: 'small',
                                defaultPageSize: 10,
                            },
                        }}
                    />
                </Col>
            </Row>

            <Row>

            </Row>


            <ContainerLabels>
                <Row>
                    <Col>
                        <div><span>Número de registro: {numeroDeRegistros}</span></div>
                        <div>
                            <label style={{ paddingTop: '2px' }}>
                                <BsCircleFill style={{ color: '#e74a3b' }} />
                            </label>
                            <span>Andando sem operação: {andandoSemOperacao.andandoSemOperacao1}</span>
                        </div>
                        <div>
                            <label style={{ paddingTop: '2px' }}>
                                <BsCircleFill style={{ color: '#1cc88a' }} />
                            </label>
                            <span>Ocioso: {andandoSemOperacao.andandoSemOperacao0}</span>
                        </div>
                    </Col>
                </Row>
            </ContainerLabels>
        </>


    )
}

export default ConsultaPosicaoVeiculo;