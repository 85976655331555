import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  gap: 32px;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    position: relative;
    
    .header-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 0;
      gap: 16px;
      background-image: 
        linear-gradient(315deg, #00FFFF2E 92%, #073AFF00 100%),
        radial-gradient(75% 75% at 238% 218%, #00FFFF12 30%, #073AFF14 39%),
        radial-gradient(99% 99% at 109% 2%, #00C9FFFF 1%, #073AFF00 100%),
        radial-gradient(99% 99% at 21% 78%, #2800FFFF 0%, #073AFF00 100%),
        radial-gradient(160% 154% at 711px -303px, #2000FFFF 0%, #073AFFFF 100%);

      @media (max-width: 1200px) {
        padding: 24px;
      }
      
      .header-title {
        .header-title-container {
          max-width: 1200px;
          margin: 0 auto;
          display: grid;
          grid-template-areas: "title title";
          row-gap: 24px;
          
          @media (min-width: 1200px) {
            padding: 0;
          }
  
          @media (min-width: 576px) {
            display: flex;
            align-items: center;
            width: 100%;
          }
        }
      }
    }

    h3 {
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin: 0;
      flex: 1;
      grid-area: title;

      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 14px;
        display: none;
      }
    }
  }

  .alert-container {
    width: 100%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    color: #787878;

    margin-top: 8px;

    p, b {
      margin: 0;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    button, div {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        max-width: 200px;
        min-width: 100%;
        width: 100%;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
   
    p {
      font-size: 16px;
      line-height: 24px;
      color: #B6BFCC;
    }
  }
`;

export const TranspotadoraContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 24px;
  column-gap: 24px;
  border-radius: 2px;

  /* @media (max-width: 768px) {
    padding: 48px 24px;
  } */

  .infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span:first-child {
      font-size: 14px;
      line-height: 20px;
      color: #f4f4f5;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    span:last-child {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      color: #fafafa;

      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: auto;
      }

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`


export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%; 
  max-width: 1200px;
  margin: 0 auto;

  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px 24px;
  }

  @media (max-width: 1200px) {
    padding: 0 24px;
  }

  @media screen and (max-width: 600px){
    gap: 16px;

    button {
      min-width: 100%;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%; 
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 24px 24px;
  }

  @media (max-width: 1200px) {
    padding: 0 24px;
  }
`;

export const LoadingScreen = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NotaFiscalContainer = styled.div`
  display: flex;
  flex-direction: column;

  .grupo-info {
    display: flex;
    flex-direction: column;

    span:first-child {
      font-size: 14px;
      line-height: 20px;
      color: #B0B0B0;
    }

    span:last-child {
      font-size: 16px;
      line-height: 24px;
      color: #232323;
    }
  }

  .detalhes-nota {
    display: flex;
    padding: 12px;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    border-radius: 12px;

    div {
      display: flex;
      column-gap: 24px;
      margin-top: 4px;
    }

    strong {
      font-size: 16px;
      line-height: 24px;
      color: #232323;
      text-transform: uppercase;
    }
  }

  .container-remetente-destinatario {
    display: flex;
    padding: 12px;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    border-radius: 12px;  
    margin-top: -1px;

    .detalhes-remetente{
      div {
        display: flex;
        column-gap: 24px;  
        margin-top: 4px;
      }

      strong {
        color: #0EBC93
      }

      @media screen and (max-width: 600px){
        div {
          flex-direction: column;
        }
      }
    }

    .detalhes-destinatario{
      div {
        display: flex;
        column-gap: 24px;
        margin-top: 4px;  
      }

      strong {
        color: #5251FA
      }

      @media screen and (max-width: 600px){
        div {
          flex-direction: column;
        }
      }
    }
  }
`
export const OcorrenciasContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 32px;

`
export const OcorrenciaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .status {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #0EBC93;

    ::before, ::after {
      position: absolute;
      content: '';
      border-left: 2px dashed #E3E3E3;
      height: 80%;
    }

    ::before {
      top: 100%;
      display: ${({ isLast }) => isLast ? 'none' : 'flex'};
    }    
    
    ::after {
      bottom: 100%;
      display: ${({ isFirst }) => isFirst ? 'none' : 'flex'};
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    color: #232323;

    .data-hora {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;
      line-height: 20px;
      color: #B0B0B0;

      .separador {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #D9D9D9;
      }
    }
  }
`

export const Separador = styled.div`
  width: 100%;
  border-top: 1px dashed #B0B0B0;
  margin: 12px 0;
` 