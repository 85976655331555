import React from "react";

import { Container, WrapperIcon, WrapperInfo, TitleCard, ValueContiner, LinkAmount } from './styles';

function Card( {type, title, amount, percentage, to, icon:Icon} ) {
  return (
    <Container>
      <WrapperIcon type={type}>
        <Icon/>
      </WrapperIcon>

      <WrapperInfo>
        <TitleCard>{title}</TitleCard>  

        <ValueContiner type={type}>
          <LinkAmount to={to}>{amount}</LinkAmount>
          {percentage && <span>{percentage}%</span>}
        </ValueContiner>
      </WrapperInfo>
    </Container>    
  )
}

export default Card;