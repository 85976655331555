import React, { useState, useEffect, useContext } from "react";
import { Alert } from 'antd';
import moment from "moment";
import api from '../../service/api';
import { zonedTimeToUtc } from 'date-fns-tz';


import { Chart } from "react-google-charts";

import { Container } from './styles';

import { Context } from '../../Context/AuthContext';


const columns = [
    { type: "string", id: "Room" },
    { type: "string", id: "Name" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
];

const options = {

    allowHtml: true,
    timeline: {
        colorByRowLabel: true,
    },
    hAxis: {
        format: 'dd  /  hh:mm',
        location: 'pt-br'
        // format: 'hh:mm:ss hh',
        // format: 'HH:mm :mm'
    }

};

function OcupacaoRecurso({ recursoId }) {

    const { empresa } = useContext(Context);
    const [loading, setLoading] = useState(false);

    const [recursoGraficoGantt, setRecursoGraficoGantt] = useState([]);
    const [erro, setErro] = useState();

    useEffect(() => {
        async function recursos() {
            try {
                setLoading(true);

                const { data } = await api.get(
                    `/empresas/${empresa.id}/pcp/grafico_gantt/${recursoId}`,
                );
                console.log(data);

                const informacaoGantt = data.response.map((item) => {
                    const dataInicial = moment(item.data_inicial);
                    const dataFinal = moment(item.data_prevista_entrega);

                    const formatDataInicial = new Date(
                        dataInicial.date(),
                        dataInicial.month(),
                        dataInicial.year(),
                        dataInicial.hour(),
                        dataInicial.minute(),
                        dataInicial.second(),
                    );

                    const formatDataFinal = new Date(
                        dataFinal.date(),
                        dataFinal.month(),
                        dataFinal.year(),
                        dataFinal.hour(),
                        dataFinal.minute(),
                        dataFinal.second(),
                    );

                    return [
                        item.descricao_processo,
                        `Numero da OP: ${item.numero_op}`,
                        zonedTimeToUtc(formatDataInicial, '-06:00'),
                        zonedTimeToUtc(formatDataFinal, '-06:00')
                    ]
                });

                setRecursoGraficoGantt(informacaoGantt);
                setLoading(false);

            } catch (error) {
                setErro(error)
            }

        }
        recursos();
    }, []);



    const data = [columns, ...recursoGraficoGantt];

    return (
        <>
            {erro &&
                <Alert
                    closeText="Fechar"
                    message={erro}
                    showIcon
                    type="error"
                />
            }

            <Container>
                <Chart
                    chartType="Timeline"
                    data={data}
                    options={options}
                    chartLanguage="pt-br"
                />
                <h5>Legendas: Dia / Hora</h5>
            </Container>

        </>
    );
};

export default OcupacaoRecurso;