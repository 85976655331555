function toHHMMSS(secunds) {
  const sec_num = parseInt(secunds, 10)
  const hours   = Math.floor(sec_num / 3600)
  const minutes = Math.floor(sec_num / 60) % 60
  const seconds = sec_num % 60

  return [hours, minutes, seconds]
      .map(value => value < 10 ? "0" + value : value)
      .filter((value, i) => value !== "00" || i > 0)
      .join(":")
};

export default toHHMMSS;