import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

`;

export const OpcoesTipoExibicao = styled.div`
  margin-bottom: 12px;
`;

export const ContainerTabela = styled.div`
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 400px;
  min-height: 400px;
`;

export const Tabela = styled.table`
  width: 100%;
  height: 100%;

  thead {
    background-color: rgba(249, 250, 251, 1);
    position: sticky;
    top: 0;
    left: 0;
    
    th {
      text-transform: uppercase;
      padding: 0.75rem 1.25rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      color: #6B7280;
      letter-spacing: 0.05rem;
      text-align: left;
      white-space: nowrap;
    }
  }

  tbody {
    max-height: 358px;
    width: 100%;
    background-color: #fff;
    
    tr {
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      transition: background 0.3s ease-in;
      
      &:hover {
        background: #F9FAFB;
      }

      td {
        padding: 1rem 1.25rem;
        font-size: 0.785rem;
        font-weight: 100.25rem;
        color: #9CA3AF;
      }
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;  
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 150px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
    text-align: right;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
    text-align: right;  
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
    text-align: right; 
  }
  
  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;
    text-align: right; 
  }
  th:nth-child(7),
  td:nth-child(7) {
    width: 150px;
    text-align: right; 
  }
  th:nth-child(8),
  td:nth-child(8) {
    width: 150px;
    text-align: right; 
  }

  `;