import React from "react";

import { DataTableColumnHeader } from '../../../../components/Table/DataTableColumnHeader';
import { Tag } from "antd";

export const columns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Placa" />,
    title: "Placa",
    accessorKey: "placa",
    widthColumn: 100,
    cell: ({ row }) => (
      <Tag 
        style={{margin: 0, fontSize: 14}}
        color="#108ee9"
      >
        {row.getValue("placa")}
      </Tag>),
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Modelo" />,
    title: "Modelo",
    accessorKey: "modelo",
    widthColumn: 140,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Ano" />,
    title: "Ano",
    accessorKey: "ano_modelo",
    widthColumn: 40,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Marca" />,
    title: "Marca",
    accessorKey: "marca",
    widthColumn: 140,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Rodado" />,
    title: "Rodado",
    accessorKey: "descricao_rodado",
    widthColumn: 140,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Fat." />,
    cell: ({ row }) => row.original.valor_faturamento_formatado,
    title: "Valor",
    accessorKey: "valor_faturamento",
    widthColumn: 60,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Vlr Rom." />,
    cell: ({ row }) => row.original.valor_reentrega_formatado,
    title: "Vlr Rom.",
    accessorKey: "valor_reentrega",
    widthColumn: 50,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Vlr Tot. Fat." />,
    cell: ({ row }) => row.original.valor_fat_geral_formatado,
    title: "Vlr Tot. Fat.",
    accessorKey: "valor_fat_geral",
    widthColumn: 50,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Média" />,
    cell: ({ row }) => row.original.media_km_litro_formatada,
    title: "Média",
    accessorKey: "media_km_litro",
    widthColumn: 40,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Custos" />,
    cell: ({ row }) => row.original.despesas_custos_formatado,
    title: "Despesas Custos",
    accessorKey: "despesas_custos",
    widthColumn: 60,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Resultado" />,
    cell: ({ row }) => {
      const resultado = row.getValue('resultado');
      const color = (resultado === 0)
                    ? '#000' 
                    : (resultado < 0) 
                        ? '#f5222d' 
                        : '#389e0d';

      return (
        <span style={{color: `${color}`}}> 
          { row.original.resultado_formatada }
        </span>
      );
     },
    title: "Resultado",
    accessorKey: "resultado",
    widthColumn: 70,
    align: 'right',
  } 
];