/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../../service/api';
import { Container, Informacoes, Item, ContainerProdutos, InformacoesProduto } from './styles';
import { Col } from 'antd';
import { Context } from '../../../../Context/AuthContext';
import { formatDateBrazil } from '../../../../utils/format-date-brazil';

function InformacaoOp({ numeroOp }) {
  const [ordemProducao, setOrdemProducao] = useState([]);
  const { empresa } = useContext(Context);

  useEffect(() => {
      carregaInformacaoOp();
  }, [empresa])

  async function carregaInformacaoOp() {
    const { data } = await api.get(
        `/empresas/${empresa.id}/pcp/ordem-producao/${numeroOp}`,
    );

    const { response } = data;

    const informacoesDeOpecacoes = {
      ...response,
      data_abertura: formatDateBrazil(response.data_abertura, 'dd/MM/yyyy'),
    };

    setOrdemProducao(informacoesDeOpecacoes);
  }

  return (
    <div style={{ padding: '0 10px 10px 10px', background: '#F3F4F6', height: '100%' }}>
      <Col style={{ margin: '10px', }}>
        <Container>
          <Informacoes>
            <div>
                <Item>
                    <strong>Nome Cliente:</strong>
                    <span>{ordemProducao.nome_cliente}</span>
                </Item>
                <Item>
                    <strong>Data Abertura: </strong>
                    <span>{ordemProducao.data_abertura}</span>
                </Item>
                <Item>
                    <strong>Numero Pedido:</strong>
                    <span>{ordemProducao.numero_pedido}</span>
                </Item>
            </div>
            <div className='divisor' />
            <div>
              <Item>
                <strong>Tipo: </strong>
                <span>{ordemProducao.tipo}</span>
              </Item>
              <Item >
                <strong>Situação: </strong>
                <span>{ordemProducao.situacao}</span>
              </Item>
              <Item>
                <strong>Programada:</strong>
                <span>{ordemProducao.programada}</span>
              </Item>
            </div>
          </Informacoes>
        </Container>
        <ContainerProdutos>
          <InformacoesProduto>
            <Item>
              <strong>Produto:</strong>
              <span>{ordemProducao.desc_produto}</span>
            </Item>
            <Item>
              <strong>Unidade de Medida:</strong>
              <span>{ordemProducao.unid_med}</span>
            </Item>
            <Item>
              <strong>Quantidade:</strong>
              <span>{ordemProducao.qtdade}</span>
            </Item>
          </InformacoesProduto>
        </ContainerProdutos>
      </Col>
    </div>
  )
}

export default InformacaoOp;