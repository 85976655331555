import React from 'react';

import { Container } from './styles';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai'

import DataItem from '../../DataItem';
import { Collapse } from 'antd';
import { formatDateBrazil } from '../../../../../../utils/format-date-brazil';

function Card({ data }) {
  const { hash } = useParams()

  return <Container>
    <div className='card-title'>
      <h4>{data.numero_nota}</h4>
      <Link to={`/logistica/rastreamento-transportadora/${hash}/detalhes/${data.hash}`}>
        Ver detalhes <AiOutlineArrowRight />
      </Link>
    </div>
    <div className='nf-basic-data'>
      <DataItem label="Número" record={data.numero_nota} />
      <DataItem label="Série" record={data.serie_nota} />
      <DataItem label="Data de emissão" record={data.data_emissao ? formatDateBrazil(data.data_emissao, "dd/MM/yyyy") : "Não informado"} />
      <DataItem label="Nº do pedido" record={data.nro_pedido} />
    </div>
    <Collapse>
      <Collapse.Panel header="Remetente">
        <DataItem label="Nome" record={data.nome_fantasia_remetente} />
      </Collapse.Panel>
      <Collapse.Panel header="Destinatário">
        <DataItem label="Nome" record={data.nome_fantasia_destinatario} />
      </Collapse.Panel>
    </Collapse>
  </Container>;
}

export default Card;