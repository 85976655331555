import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  max-width: 1440px;
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;

  @media only screen and (max-width: 425px){
    max-height: 95vh;
    padding: 0.6rem;
  }

  > header {
    display: flex;
    flex-direction: column;
    width: 100%;

    .titulo {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      
      h1 {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 1.25rem;
        color: #374151;
        padding: 0.5rem 0;

        > svg {
          height: 24px;
          width: auto;
          fill: #D1D5DB;
          margin-right: 0.7rem;
        }
      }
      
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 36px;
        width: 36px;
        background: transparent;
        border-radius: 50%;
        transition: background 0.3s ease-in;

        svg {
          height: 100%;
          width: auto;
          fill: #D1D5DB;
          transition: fill 0.3s ease-in;     
        }

        &:hover {
          background: #FCA5A5;

          svg {
            fill: #DC2626;     
          }
        }
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;

      div {
        display: flex;
        align-items: center;
      }

      .info-ordem {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        background: #F3F4F6;
      }

      .info-cliente {
        margin-left: 2rem;
      }

      @media only screen and (max-width: 425px){
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .info-cliente {
          margin-left: 0;
          margin-top: 0.5rem;
        }
      }
    }
    
    strong {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 500;
      color: #4B5563;
    }

    span {
      font-size: 1rem;
      font-weight: 100.25rem;
      color: #9CA3AF;  
      margin-left: 0.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ContainerTabela = styled.div`
  overflow-x: auto;
  overflow-y: auto;
`;


export const Tabela = styled.table`
  width: 100%;

  > thead {
    background-color: rgba(249, 250, 251, 1);
  
    > tr > th {
      text-transform: uppercase;
      padding: 0.75rem 1.25rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      color: #6B7280;
      letter-spacing: 0.05rem;
      text-align: left;
      white-space: nowrap;

      svg {
        margin-right: 4px;
      }
    }
  }

  tbody {
    background-color: #fff;
    
    > tr {
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      transition: background 0.3s ease-in;
      
      &:hover {
        background: #F9FAFB;
      }

      > td {
        padding: 1rem 1.25rem;
        font-size: 0.785rem;
        font-weight: 100.25rem;
        white-space: nowrap;
        color: #9CA3AF;
        
        .column-data-hora {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;