import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Button, Tooltip, notification } from 'antd';

import TituloTela from '../../../components/TituloTela';
import api from '../../../service/api';
import TabelaPersonalizada from '../../../components/TabelaPersonalizada';
import Loader from '../../../components/Loader';
import Modal from '../../../components/Modal';
import FormModal from '../../../components/FormModal';
import PedidoCompra from '../PedidoCompra';
import CardFinanceiro from '../CardFinanceiro';
import { Context } from '../../../Context/AuthContext';
import { formatDateBrazil } from '../../../utils/format-date-brazil';
import { formatDecimal } from '../../../utils/format-decimal';
import useIsMobile from '../../../hooks/useIsMobile';

const text = <span>Aprovar a ordem de compra</span>;
const nroPedido = <span>Visualizar os itens do pedido</span>;


function AprovacaoOrdensDeCompras() {
    const { empresa } = useContext(Context);
    const mobile = useIsMobile();

    const [resultadosDasOrdensDeCompras, setResultadosDasOrdensDeCompras] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tipoTelaDetalhesPedido, setTipoTelaDetalhesPedido] = useState('');

    const consultaDasOrdensDeCompras = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await api.get(`/empresas/${empresa.id}/estoque/aprovado_ordem_compra`);

            const OrdensDeComprasAbertas = data.map((item, index) => ({
                ...item,
                key: `item${index}`,
                data_emissao: formatDateBrazil(item.data_emissao, 'dd/MM/yyyy'),
                valorTotal: parseFloat(item.valor_total) + parseFloat(item.valor_servicos),
                valor_produtos: formatDecimal(item.valor_produtos),
                valor_servicos: formatDecimal(item.valor_servicos),
                valor_ipi: formatDecimal(item.valor_ipi),
                valor_frete: formatDecimal(item.valor_frete),
                valor_icms_st: formatDecimal(item.valor_icms_st),
                valor_descontos: formatDecimal(item.valor_descontos),
                valor_total: formatDecimal(item.valor_total),
            }));

            setResultadosDasOrdensDeCompras(OrdensDeComprasAbertas);
            setLoading(false);
        } catch (error) {
            if (error.response.data.statusCode === 500) {
                openNotificationError('error', 'Erro 500, sem conexão com o servidor.');
            } else {
                openNotificationError('error', 'Erro, favor contatar o suporte.');
            }
            setLoading(false);
        }

    }, [empresa.id]);


    async function handleAprovarOrdem(idPedido) {
        try {
            const { data } = await api.put(`/empresas/${empresa.id}/estoque/aprovado_ordem_compra/${idPedido}`);
            if (data.statusCode === 200) {
                openNotificationSuccess('success', 'Sucesso seu título foi aprovado');
                consultaDasOrdensDeCompras();
            }
        } catch (error) {
            openNotificationError('error', error.response.data.error);
        }
    }

    const openNotificationSuccess = (success, error) => {
        notification[success]({
            message: 'Sucesso',
            description:
                error,
        });
    };

    const openNotificationError = (warning, sucesso) => {
        notification[warning]({
            message: 'Erro',
            description:
                sucesso,
        });
    };

    function manipuladorCliqueTelaDetalhesPedido(data) {
        setTipoTelaDetalhesPedido(data);
    }

    function onCloseModal() {
        setTipoTelaDetalhesPedido(null);
    }

    const estruturaColunasTabelaOrdem = [
        {
            title: 'Número Pedido',
            dataIndex: 'nro_oc',
            key: 'nro_oc',
            width: 100,
            align: 'right',
            isVisible: true,
            isSearch: true,
            render: (_, record) => (

                <Tooltip placement="leftTop" title={nroPedido}>
                    <Button
                        style={{ color: '#1e88e5', borderColor: '#1e88e5', border: 'none' }}
                        onClick={() => manipuladorCliqueTelaDetalhesPedido(record.nro_oc)}
                    >{record.nro_oc}</Button>
                </Tooltip>
            ),
        },
        {
            title: 'Data Emissão',
            dataIndex: 'data_emissao',
            key: 'data_emissao',
            width: 90,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Código Fornecedor',
            dataIndex: 'codigo_fornecedor',
            key: 'codigo_fornecedor',
            width: 112,
            align: 'cenleftter',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Nome Fornecedor',
            dataIndex: 'nome_fornecedor',
            key: 'nome_fornecedor',
            width: 280,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor Produtos',
            dataIndex: 'valor_produtos',
            key: 'valor_produtos',
            width: 100,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor Serviços',
            dataIndex: 'valor_servicos',
            key: 'valor_servicos',
            width: 100,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor IPI',
            dataIndex: 'valor_ipi',
            key: 'valor_ipi',
            width: 90,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor Frete',
            dataIndex: 'valor_frete',
            key: 'valor_frete',
            width: 90,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor ICMS ST',
            dataIndex: 'valor_icms_st',
            key: 'valor_icms_st',
            width: 100,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor Descontos',
            dataIndex: 'valor_descontos',
            key: 'valor_descontos',
            align: 'right',
            width: 100,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor Total',
            dataIndex: 'valor_total',
            key: 'valor_total',
            align: 'right',
            width: 90,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Ação',
            dataIndex: 'usa_aprovacao',
            key: 'usa_aprovacao',
            align: 'center',
            width: 80,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                < Tooltip placement="leftTop" title={text} >
                    <Button
                        style={{ color: '#1cc88a', borderColor: '#1cc88a' }}
                        onClick={() => handleAprovarOrdem(record.nro_oc)}
                        disabled={record.usa_aprovacao === 'N'}
                    >Aprovar</Button>
                </Tooltip >
            ),
        },
    ];

    const detalhesOrdemPedido = {
        codigoTela: '01.77.1368',
        nomeTela: 'Pedido de compra',
        tema: 'white',
        fontSize: 'medium',
        paddingBottom: 'without',
    }

    useEffect(() => {
        consultaDasOrdensDeCompras();
    }, [consultaDasOrdensDeCompras]);

    return (
        <>
            {tipoTelaDetalhesPedido &&
                <Modal openCloseModal={onCloseModal} >
                    <FormModal
                        title={detalhesOrdemPedido}
                        close={onCloseModal}
                    >
                        <PedidoCompra nroOrdemPedido={tipoTelaDetalhesPedido} />
                    </FormModal>
                </Modal>
            }

            <Loader isLoading={loading} />

            <TituloTela codigoTela="01.75.1367" nomeTela="Aprovação de ordens de compras" />

            {
                mobile ?
                    <>
                        {
                            resultadosDasOrdensDeCompras.map((ordemcompra) => (

                                <CardFinanceiro
                                    key={ordemcompra.nro_oc}
                                    ordemcompra={ordemcompra}
                                    onAprovar={() => handleAprovarOrdem(ordemcompra.nro_oc)}
                                />
                            ))
                        }
                    </>
                    :
                    <Row>
                        <Col span={24} >
                            <TabelaPersonalizada
                                titulo='Aprovação de ordens de compras'
                                columns={estruturaColunasTabelaOrdem}
                                dataSource={resultadosDasOrdensDeCompras}
                                configTabela={{
                                    size: 'small',
                                    scroll: { x: 400, y: 600 },
                                    pagination: {
                                        size: 'small',
                                        defaultPageSize: 20,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
            }
        </>
    );
}

export default AprovacaoOrdensDeCompras;

