import React, { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Button, Col, ConfigProvider, DatePicker, Grid, Input, Row, Spin, notification } from 'antd';
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";

import {
  Container,
  TranspotadoraContainer,
  Form,
  Content,
  LoadingContainer,
  LoadingScreen,
} from './styles';

import ptBr from 'antd/lib/locale/pt_BR'

import FormGroup from './components/FormGroup';
import EmptyState from './components/EmptyState';
import InitialState from './components/InitialState';
import DataTable from './components/DataTable';
import CardsList from './components/CardsList';

import { useTrackingFormContext } from '../../../Context/TrackingFormContext';
import ErrorState from './components/ErrorState';

function RastreioTransportadora() {
  const { hash } = useParams();

  const [, contextHolder] = notification.useNotification()

  const {
    invoiceInfo,
    sender,
    recipient,
    invoices,
    queryPeriod,
    loadingInvoices,
    isValidatingDocument,
    disabledByDocument,
    disabledByInvoiceInfo,
    disabledByDate,
    getInvoices,
    handleInvoiceChange,
    handleDocumentChange,
    handleChangePeriod,
    clearForm,
    getTransporterData,
    transporterData,
    isLoadingTransporterError,
    isLoadingTransporter
  } = useTrackingFormContext()

  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  const searchResultRef = useRef(null)

  const handleSearch = async (event) => {
    event.preventDefault();

    await getInvoices(hash)
  }

  useEffect(() => {
    if (!!hash && !transporterData) {
      getTransporterData(hash);
    }

  }, [getTransporterData, hash, transporterData])

  useEffect(() => {
    if (!loadingInvoices && invoices?.length > 0 && !!searchResultRef.current) {
      const offsetTop = searchResultRef.current?.offsetTop
      window.scrollTo({ top: offsetTop, left: 0, behavior: "smooth" })
    }
  }, [invoices, loadingInvoices])

  if (isLoadingTransporter) return <LoadingScreen> <Spin size='large' /></LoadingScreen>

  return isLoadingTransporterError
    ? <ErrorState />
    : (
      <ConfigProvider locale={ptBr}>
        {contextHolder}
        <Container>
          <header>
            <div className='header-content'>
              <div className='header-title'>
                <div className='header-title-container'>
                  <h3>Rastreamento</h3>
                </div>
              </div>

              {!!transporterData?.razao_social && (
                <TranspotadoraContainer>
                  <div className="infos">
                    <span>Transportadora</span>
                    <span>{transporterData.razao_social}</span>
                  </div>
                </TranspotadoraContainer>
              )}
            </div>
          </header>

          <Form onSubmit={handleSearch}>
            <Row gutter={[16, 12]}>
              <Col xs={24} md={8}>
                <FormGroup
                  label="* Nota Fiscal"
                >
                  <Input
                    value={invoiceInfo.number}
                    inputMode="numeric"
                    size={screens.md ? 'large' : 'middle'}
                    placeholder='Número nota fiscal'
                    onChange={(ev) => handleInvoiceChange(ev, 'number')}
                  />
                </FormGroup>
              </Col>
              <Col xs={24} md={8}>
                <FormGroup
                  label="Série"
                >
                  <Input
                    value={invoiceInfo.series}
                    size={screens.md ? 'large' : 'middle'}
                    placeholder='Série da nota fiscal'
                    onChange={(ev) => handleInvoiceChange(ev, 'series')}
                  />
                </FormGroup>
              </Col>
              <Col xs={24} md={8}>
                <FormGroup
                  label="Nº do pedido"
                >
                  <Input
                    size={screens.md ? 'large' : 'middle'}
                    value={invoiceInfo.orderNumber}
                    onChange={(ev) => handleInvoiceChange(ev, 'orderNumber')}
                    placeholder='Nº do pedido'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row gutter={16} align='bottom'>
              <Col md={4} xs={24}>
                <Row gutter={[16, 12]} align="bottom">
                  <Col xs={24} md={24}>
                    <FormGroup
                      label="De"
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder="Selec. a data"
                        size={screens.md ? 'large' : 'middle'}
                        value={queryPeriod.from}
                        onChange={(ev) => handleChangePeriod(ev, 'from')}
                        allowClear={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={24} md={24}>
                    <FormGroup
                      label="Até"
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder="Selec. a data"
                        size={screens.md ? 'large' : 'middle'}
                        value={queryPeriod.to}
                        onChange={(ev) => handleChangePeriod(ev, 'to')}
                        allowClear={false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={20}>
                <Row gutter={[16, 12]} align="bottom">
                  <Col xs={24} md={8}>
                    <FormGroup
                      label="Remetente"
                    >
                      <Input
                        allowClear
                        disabled={isValidatingDocument}
                        onChange={(ev) => handleDocumentChange(ev.target.value, 'sender', hash)}
                        value={sender.document}
                        size={screens.md ? 'large' : 'middle'}
                        style={{ cursor: 'default' }}
                        placeholder='CNPJ ou CPF do remetente'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={24} md={16}  >
                    <FormGroup>
                      <Input
                        disabled
                        value={sender.name}
                        size={screens.md ? 'large' : 'middle'}
                        style={{ cursor: 'default' }}
                        placeholder='Nome (preenchido automaticamente)'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={24} md={8}>
                    <FormGroup
                      label="Destinatário"
                    >
                      <Input
                        allowClear
                        disabled={isValidatingDocument}
                        onChange={(ev) => handleDocumentChange(ev.target.value, 'recipient', hash)}
                        value={recipient.document}
                        size={screens.md ? 'large' : 'middle'}
                        style={{ cursor: 'default' }}
                        placeholder='CNPJ ou CPF do destinatário'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={24} md={16}>
                    <FormGroup>
                      <Input
                        disabled
                        value={recipient.name}
                        size={screens.md ? 'large' : 'middle'}
                        style={{ cursor: 'default' }}
                        placeholder='Nome (preenchido automaticamente)'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="alert-container"><p><b>Atenção!</b> Ao digitar o número da nota ou número do pedido a data não afetará os resultados da pesquisa.</p></div>

            <div className="button-container">
              <Button
                htmlType='reset'
                type='ghost'
                size={screens.md ? 'large' : 'middle'}
                onClick={() => {
                  clearForm()
                }}
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}

              >
                <AiOutlineCloseCircle />Limpar
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                size={screens.md ? 'large' : 'middle'}
                onClick={handleSearch}
                disabled={
                  disabledByInvoiceInfo || disabledByDate || disabledByDocument || loadingInvoices
                } // quando um dos dois é diferente de zero
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <AiOutlineSearch />Pesquisar
              </Button>
            </div>
          </Form>

          <Content ref={searchResultRef} >

            {loadingInvoices && (
              <LoadingContainer>
                <Spin size='large' />
              </LoadingContainer>
            )}

            {(!loadingInvoices && invoices?.length > 0) && (screens.md ? <DataTable /> : <CardsList />)}

            {(!loadingInvoices && invoices?.length === 0) && (
              <EmptyState />
            )}

            {(!loadingInvoices && !invoices) && (<InitialState />)}
          </Content>

          <footer>
            <p>
              Desenvolvido por: {' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href='http://centrodata.com.br/'
              >
                Centrodata Sistemas
              </a>
            </p>
          </footer>

        </Container>
      </ConfigProvider>
    )
}

export default RastreioTransportadora;