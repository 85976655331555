import styled from 'styled-components';

export const ContainerCard = styled.div`
  font-family: "Roboto";
  width: 100%;

  .topo {
   background: #1e88e5;
   height: 40px;
   border-radius: 5px 5px 0 0;
   padding: 17px 7px; 
  }

  .grupoTituloValor{
    display: flex;
    flex-direction: column;

    label {
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #000;
    }

    span {
      font-weight: 500;
      color: #000;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .grupo-titulo-valor-space-between {
    display: flex;
    justify-content: space-between;
  }

  .grupo-valor-a-direita {
    text-align: right;
  }

`;

export const Info = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 0 0 5px 5px;
  padding: 27px 11px;
  font-family: "Roboto";
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  margin-bottom: 10px;
  

  .grupo-botao {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .detalhe {
    text-align: center;
    align-items: center;
    color: #000;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    span{
      font-weight: 700;
      font-family: "Roboto";
      text-decoration:  underline;
      color: #000;
      font-size: 14px;
      padding: 0;
    }

    img {
      width: 18px;
      margin-left: 7px;
    }
  }
  
`;

export const Divisor = styled.hr`
    color: #000000;
    opacity: 20%;
`;

export const BotaoDetalhe = styled.button`
    width: 99px;
    height: 30px;
    margin-top: 4px;
    border-radius: 5px;
    background: #FFF;
    border: none;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img{
      margin-top: 7px;
    }

    span {
      text-decoration: underline;
    }

    /* &:hover {
        background: #1e88e5;
        color: #FFFFFF;
        transition-duration: 0.4s ease-out;
    } */
`;

export const BotaoAprovar = styled.button`
    width: 99px;
    height: 30px;
    margin-top: 4px;
    border-radius: 5px;
    background: #1e88e5;
    border: 1px solid #FFF;
    font-weight: 600;
    color: #FFF;

    &:hover {
        background-color: #FFF;
        color: #1e88e5;
        transition-duration: 0.4s, 0.4s;
    }
`;



