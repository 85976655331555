import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, DatePicker, Button, Spin, notification } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { BsCircleFill } from "react-icons/bs";

import TituloTela from '../../../components/TituloTela';
import FormGrupoInput from '../../../components/FormGrupoInput';
import 'moment/locale/pt-br';
import moment from 'moment';
import TabelaPersonalizada from '../../../components/TabelaPersonalizada';
import api from '../../../service/api';
import Loader from '../../../components/Loader';
import { formatDateBrazil } from '../../../utils/format-date-brazil';
import { Context } from '../../../Context/AuthContext';

import { ContainerFiltro } from './styles';

moment.locale('pt-br');

const formatoDataBR = 'DD/MM/YYYY';

function AprovarTitulosPagar() {
    const { empresa } = useContext(Context);
    const [dataInicial, setDataInicio] = useState(moment());
    const [dataFinal, setDataFinal] = useState(moment().add(30, 'days'));
    const [dadosOrdemPedidoAprovacao, setDadosOrdemPedidoAprovacao] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [loadingAprovarDesaprovar, setLoadingAprovarDesaprovar] = useState(false);
    const [idTitulo, setIdTitulo] = useState(0);

    useEffect(() => {
        detalheOrdemPedido();
    }, [idTitulo])

    async function detalheOrdemPedido() {
        try {
            setCarregando(true);
            const { data } = await api.get(
                `/empresas/${empresa.id}/financeiro/titulos-pagar`,
                {
                    params: {
                        dataInicio: moment(dataInicial).format('YYYY-MM-DD'),
                        dataFim: moment(dataFinal).format('YYYY-MM-DD'),
                    }
                }
            );
            const ordemPedidoAprovar = data.map((pedido) => {
                return {
                    ...pedido,
                    key: pedido.id_titulo,
                    data_emissao: formatDateBrazil(pedido.data_emissao, "dd/MM/yyyy"),
                    data_vencimento: formatDateBrazil(pedido.data_vencimento, "dd/MM/yyyy"),
                    valor: pedido.valor,
                    saldo: pedido.saldo,
                }
            })
            setDadosOrdemPedidoAprovacao(ordemPedidoAprovar);
        } catch (error) {
            console.log(error);
        }
        finally {
            setCarregando(false);
        }
    }


    async function aprovarTitulo(record) {
        try {
            setLoadingAprovarDesaprovar(true);
            const { data } = await api.put(
                `/empresas/${empresa.id}/financeiro/titulos-pagar/${record.id_titulo}/aprovar`,
            );

            if (data) {
                openNotificationSuccess('success', data);
                setIdTitulo(record.id_titulo);
            }
            setLoadingAprovarDesaprovar(false);
        } catch (error) {
            openNotificationError('error', error.response.data.error);
            setLoadingAprovarDesaprovar(false);
        }
    }

    async function desaprovarTitulo(record) {
        try {
            setLoadingAprovarDesaprovar(true);
            const { data } = await api.put(
                `/empresas/${empresa.id}/financeiro/titulos-pagar/${record.id_titulo}/desaprovar`,
            );
            if (data) {
                openNotificationSuccess('success', data);
                setIdTitulo(record.id_titulo);
            }
            setLoadingAprovarDesaprovar(false);
        } catch (error) {
            openNotificationError('error', error.response.data.error);
            setLoadingAprovarDesaprovar(false);
        }
    }

    function atualizaOrdemPedidoAprovar() {
        detalheOrdemPedido();
    }

    function handleOnChangeDataInicio(date) {
        setDataInicio(date);
    }

    function handleOnChangeDataFinal(date) {
        setDataFinal(date);
    }

    const openNotificationSuccess = (success, error) => {
        notification[success]({
            message: 'Sucesso',
            description:
                error,
        });
    };

    const openNotificationError = (warning, sucesso) => {
        notification[warning]({
            message: 'Erro',
            description:
                sucesso,
        });
    };

    const aprovadoColor = {
        "N": '#e74a3b',
        "S": '#1cc88a',
    }

    const estruturaColunasTabelaTitulosPagarAprovar = [
        {
            title: 'Aprovado',
            dataIndex: 'aprovado',
            key: 'aprovado',
            width: 80,
            align: 'center',
            isVisible: true,
            isSearch: true,
            render: (_, record) =>
            (
                < BsCircleFill style={{ color: aprovadoColor[record.aprovado] }} />
            )
            ,
        },
        {
            title: 'Codigo For.',
            dataIndex: 'codigo_fornecedor',
            key: 'codigo_fornecedor',
            width: 80,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Nome Fornecedor',
            dataIndex: 'nome_fornecedor',
            key: 'nome_fornecedor',
            width: 340,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Título',
            dataIndex: 'titulo',
            key: 'titulo',
            width: 100,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Parcela',
            dataIndex: 'parcela',
            key: 'parcela',
            width: 65,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Série',
            dataIndex: 'serie',
            key: 'serie',
            width: 40,
            align: 'right',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Data emissão',
            dataIndex: 'data_emissao',
            key: 'data_emissao',
            width: 90,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Data vencimento',
            dataIndex: 'data_vencimento',
            key: 'data_vencimento',
            width: 90,
            align: 'left',
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            align: 'right',
            width: 80,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Saldo',
            dataIndex: 'saldo',
            key: 'saldo',
            align: 'right',
            width: 80,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'Dias atraso',
            dataIndex: 'dias_atraso',
            key: 'dias_atraso',
            align: 'right',
            width: 70,
            isVisible: true,
            isSearch: true,
        },
        {
            title: 'ID nf entrada',
            dataIndex: 'id_nf_entrada',
            key: 'id_nf_entrada',
            align: 'right',
            width: 100,
            isVisible: false,
            isSearch: false,
        },
        {
            title: 'ID título',
            dataIndex: 'id_titulo',
            key: 'id_titulo',
            align: 'right',
            width: 80,
            isVisible: false,
            isSearch: false,
        },
        {
            title: 'Ação',
            dataIndex: 'usa_aprovacao',
            key: 'usa_aprovacao',
            align: 'center',
            width: 80,
            isVisible: true,
            isSearch: true,
            render: (_, record) => (
                <>
                    {record.aprovado === "N" ?
                        <Button
                            style={{ color: '#1cc88a', borderColor: '#1cc88a', width: '100px' }}
                            onClick={() => aprovarTitulo(record)}
                        >
                            Aprovar
                        </Button>
                        :
                        <Button
                            style={{ color: '#e74a3b', borderColor: '#e74a3b', width: '100px' }}
                            onClick={() => desaprovarTitulo(record)}
                        >
                            Desaprovar
                        </Button>
                    }
                </>
            ),
        },
    ];

    return (
        <>
            <Loader isLoading={loadingAprovarDesaprovar} />
            <TituloTela codigoTela="01.74.1370" nomeTela="Aprovar títulos a pagar" />

            <ContainerFiltro>

                <Row>
                    <Col flex='0 1 130px'>
                        <FormGrupoInput
                            label='Data de'
                            children=
                            {
                                <DatePicker
                                    locale={locale}
                                    format={formatoDataBR}
                                    defaultValue={dataInicial}
                                    placeholder='Selecione Data'
                                    onChange={date => handleOnChangeDataInicio(date)}
                                />
                            }
                        />
                    </Col>

                    <Col flex='0 1 130px'>
                        <FormGrupoInput
                            label='Data até'
                            children=
                            {
                                <DatePicker
                                    locale={locale}
                                    format={formatoDataBR}
                                    defaultValue={dataFinal}
                                    placeholder='Selecione Data'
                                    onChange={date => handleOnChangeDataFinal(date)}
                                />
                            }
                        />
                    </Col>

                    <Col flex='0 1 60px'>
                        <div style={{ padding: '30px 0px 16px 8px' }}>
                            <Button type='primary' onClick={() => atualizaOrdemPedidoAprovar()}>Buscar</Button>
                        </div>
                    </Col>

                </Row>
            </ContainerFiltro>

            {
                carregando
                    ?
                    <Row justify="center" align="middle" style={{ height: 'calc(100vh / 2)' }}>
                        <Spin size="large" />
                    </Row>
                    :
                    <Row>
                        <Col span={24}>
                            <TabelaPersonalizada
                                titulo='Aprovar títulos a pagar'
                                columns={estruturaColunasTabelaTitulosPagarAprovar}
                                dataSource={dadosOrdemPedidoAprovacao}
                                configTabela={{
                                    size: 'small',
                                    scroll: { x: 400, y: 600 },
                                    pagination: {
                                        size: 'small',
                                        defaultPageSize: 10,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
            }
        </>
    )
}

export default AprovarTitulosPagar;