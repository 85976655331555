import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  width: 100%;

  display: flex;
  flex-direction: column;

  -webkit-box-shadow: 3px 3px 10px 3px #d9d9d9;
  -moz-box-shadow: 3px 3px 10px 3px #d9d9d9;
  box-shadow: 3px 3px 10px 3px #d9d9d9;


  .card-title {
    padding: 16px 16px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #d9d9d9;

    h4, a {
      display: flex;
      align-items: center;
    }

    h4 {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    a {
      gap: 2px;
    }
  }

  .nf-basic-data {
    padding: 0 16px;

    padding: 12px 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr)
  }

  .nf-data {
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }

`;
