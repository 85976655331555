import React from "react";
import { GrupoIcon, ArrowIcon } from "./icons";

import { Container,
         WrapperIcon,
         WrapperInfo,
         TitleCard,
         ValuesContiner } from './styles';

function CardGrupo({ grupo, active, onClick } ) {
  return (
    <>
      <Container 
        active={active}
        onClick={() => onClick(grupo)}
      >
        <div>
          <WrapperIcon>
            <GrupoIcon width={24} height={24}/>
          </WrapperIcon>
          <WrapperInfo>
            <TitleCard>{grupo.descGrupo}</TitleCard>  

            <ValuesContiner>
              <span>{grupo.quantidade}</span>
              <span>{grupo.percentual}%</span>
            </ValuesContiner>
          </WrapperInfo>
        </div>
        <ArrowIcon width={24} height={24}/>
      </Container>

    </>

  )
}

export default CardGrupo;