import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;

  > label {
    font-size: 14px;
    color: #646777;
  }

  small {
    height: 16px;
    font-size: 10px;
    color: #721c24;
  }

  > div {
    display: flex;
    flex: 1;
    flex-direction: row;   
    align-items: center;
    justify-content: flex-start;

    > input {
      margin-right: 8px;
    }
    
    

    /* > small {
      display: block;
      position: absolute;
      top: -5px;
      right: 50%;
      padding: 4px 8px;
      background: #f8d7da;
      border-radius: 3px; 
      font-size: 10px;
      color: #721c24;
    }

    > small::after {
      position: absolute;
      top: 18px;
      left: 50%;
      transform: rotate(50deg);
      content: '';
      width: 8px;
      height: 8px;
      background: #f8d7da; 
    } */
  }
`;

