import React, { useContext, useEffect, useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import { Table, TableContainer, LoadingContainer } from "./styles";

import { formatDecimal } from "../../../../../utils/format-decimal";
import { Container } from "../styles";

import api from "../../../../../service/api";
import { Context } from "../../../../../Context/AuthContext";

const gruposDRE = {
  1: 'Receitas',
  2: 'Custos',
  3: 'Despesas'
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} />;

function DetalhesGrupoDRE({ payLoad }) {
  const [dadosContas, setDadosContas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function getDetalhesGrupoDRE() {
      try {
        if(payLoad.grupo === 0){
          setDadosContas([]);
          return;
        }

        setIsLoading(true);

        const { data } = await api.get(`/empresas/${empresa.id}/intercarg/financeiro/dre/grupo/detalhes`, {
          params: {
            periodo: payLoad.periodo,
            id_grupo: payLoad.grupo,
            id_empresa: payLoad.empresa.id
          }
        });

        const dataMapper = data.response.dadosGrupo.map(conta => ({
          codigo_conta: conta.codigo_conta,
          descricao_conta: conta.descricao_conta,
          valor: formatDecimal(conta.valor)
        }));

        setDadosContas(dataMapper)
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    getDetalhesGrupoDRE();
  }, [empresa, payLoad])

  return (
    <Container>
      <Typography.Title level={5}>Empresa: {`${payLoad.empresa.id} - ${payLoad.empresa.descricao}`}</Typography.Title>
      <Typography.Title level={5}>Grupo: {`${gruposDRE[payLoad.grupo]}`}</Typography.Title>
      { isLoading && (
        <LoadingContainer>
          <Spin indicator={antIcon} />
        </LoadingContainer>
      )}      
 
      {!isLoading && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{textAlign: 'right'}}>ID Conta</th>
                <th>Descrição</th>
                <th style={{textAlign: 'right'}}>VAlor</th>
              </tr>
            </thead>
            <tbody>
              {dadosContas.map( (conta) => (
                <tr key={conta.codigo_conta}>
                  <td 
                    data-label="ID Conta"
                    style={{textAlign: 'right'}}
                  >
                    {conta.codigo_conta}
                  </td>
                  <td
                  data-label="Descrição" 
                  >
                    {conta.descricao_conta}
                  </td>
                  <td 
                    data-label="Valor"
                    style={{textAlign: 'right'}}
                  >
                    {conta.valor}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
       </TableContainer>        
      )}
    </Container>
  )
}

export default DetalhesGrupoDRE;