import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Row, Col, Select, Button, Empty, Grid, BackTop, Tag, Collapse } from 'antd';
import { SyncOutlined, PercentageOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { BiDollar } from 'react-icons/bi';
import { FiTruck } from 'react-icons/fi';
import { FaBalanceScale } from 'react-icons/fa';

import Loader from '../../../components/Loader';
import TituloTela from '../../../components/TituloTela';
import Card from "./Card";
import TableExpanding  from '../../frota/Dashboard/TableExpanding'
import DataTableColumnHeader from '../../frota/Dashboard/TableExpanding/DataTableColumnHeader';
import GrupoCards from '../../../components/GrupoCards';

import { Container, TableContainer, Table, TituloCollapse } from './styels';

import { MESES_EXTENSO } from '../../../constants';
import api from '../../../service/api';
import { Context } from '../../../Context/AuthContext';
import { formatDecimal } from '../../../utils/format-decimal';

const { useBreakpoint } = Grid;

const mesCorrente = new Date().getMonth();
const anoCorrente = new Date().getFullYear();

const inicialDataDashboard = {
  valor_faturamento: 0,
  peso_bruto: 0,
  capacidade_carga: 0,
  perc_ocupacao: 0,
  fretes_por_placa: [],
  fretes_por_motorista: [],
}

export default function Dasboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [opcoesAno, setOpcoesAno] = useState([2023]);
  const [anoSelecionado, setAnoSelecionado] = useState(anoCorrente);
  const [mesSelecionado, setMesSelecionado] = useState(mesCorrente);
  const [dataDashboard, setDataDasdboard] = useState(inicialDataDashboard);
  const [dataFaturamentoGestores, setDataFaturamentoGestores] = useState([]);
  const [isActiveCollapseMotorista, setIsActiveCollapseMotorista] = useState(true);
  const [isActiveCollapsePlaca, setIsActiveCollapsePlaca] = useState(true);

  const { empresa } = useContext(Context);

  const activeScreens = useBreakpoint();

  const sizeFormComponent = useMemo(() => activeScreens.lg ? 'default' : 'large', [activeScreens]);

  const periodo = useMemo(() => {
    const mesFormatado = String(mesSelecionado + 1).padStart(2, '0')
    return `${anoSelecionado}-${mesFormatado}-01`;
  }, [mesSelecionado, anoSelecionado]);

  const hasFretesPorPlaca =  dataDashboard.fretes_por_placa.length > 0;
  const hasFretesPorMotorista = dataDashboard.fretes_por_motorista.length > 0;

  const columnsFaturmamentoGestores = useMemo(() => [
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nome" />,
      title: "Nome",
      accessorKey: "descricao_gestor",
      widthColumn: 300,
    },
    
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Faturamento" />,
      cell: ({ row }) => row.original.valor_faturamento_formatado,
      title: "Valor Faturamento",
      accessorKey: "valor_faturamento",
      widthColumn: 50,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="% Faturamento" />,
      cell: ({ row }) => row.original.perc_faturamento_formatado,
      title: "% Faturamento ",
      accessorKey: "perc_faturamento",
      widthColumn: 50,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Reentrega" />,
      cell: ({ row }) => row.original.valor_reentrega_formatado,
      title: "Valor Reentrega",
      accessorKey: "valor_reentrega",
      widthColumn: 50,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Total Geral" />,
      cell: ({ row }) => row.original.valor_total_geral_formatado,
      title: "Valor Total Geral",
      accessorKey: "valor_total_geral",
      widthColumn: 50,
      align: 'right',
    },

  ], []);

  const columnsDetalhesFaturmamentoGestor = useMemo(() => [
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nro. Ordem" />,
      title: "Nro. Ordem",
      accessorKey: "nro_ordem",
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Placa" />,
      title: "Placa",
      accessorKey: "placa",
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nome Motorista" />,
      title: "Nome Motorista",
      accessorKey: "nome_motorista",
      widthColumn: 250,
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Marca" />,
      title: "Marca",
      accessorKey: "marca",
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Tipo Rodado" />,
      title: "Tipo Rodado",
      accessorKey: "descricao_tipo_rodado",
    },
    
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Faturamento" />,
      cell: ({ row }) => row.original.valor_faturamento_formatado,
      title: "Valor Faturamento",
      accessorKey: "valor_faturamento",
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="% Faturamento" />,
      cell: ({ row }) => row.original.perc_faturamento_formatado,
      title: "% Faturamento ",
      accessorKey: "perc_faturamento",
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Reentrega" />,
      cell: ({ row }) => row.original.valor_reentrega_formatado,
      title: "Valor Reentrega",
      accessorKey: "valor_reentrega",
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Total Geral" />,
      cell: ({ row }) => row.original.valor_total_geral_formatado,
      title: "Valor Total Geral",
      accessorKey: "valor_total_geral",
      align: 'right',
    },
  ], []);  

  function handleChangeMes(value) {
    setMesSelecionado(value);
  }
  function handleChangeAno(value) {
    setAnoSelecionado(value);
  }
  function handleAtualuzarDados() {
    getDataDashboard(periodo);
  }

  function handleChangeCollapseMotorista() {
    setIsActiveCollapseMotorista(prevState => !prevState)
  }

  function handleChangeCollapsePlaca() {
    setIsActiveCollapsePlaca(prevState => !prevState)
  }

  const getDataDashboard = useCallback( async (periodo) => {
    setIsLoading(true);
  
    try {
      const { data } = await api.get(`/empresas/${empresa.id}/frota/dashboard`, {
        params: {
          periodo
        }
      });
      
      const { data: dataFaturamentoGestores } = await api.get(`empresas/${empresa.id}/intercarg/faturamento/mediaGestores`, {
        params: {
          periodo
        }
      });

      const { response } = data;
  
      const fretesPorPlacaMapper = response.fretes_por_placa.map(fretePlaca => ({
        placa: fretePlaca.placa,
        modelo: fretePlaca.modelo,
        ano_fab: fretePlaca.ano_fab,
        marca: fretePlaca.marca,
        ano_modelo: fretePlaca.ano_modelo,
        valor_faturamento: formatDecimal(fretePlaca.valor_faturamento || 0),
        peso_bruto: formatDecimal(fretePlaca.peso_bruto || 0, 3),
        capacidade_carga: formatDecimal(fretePlaca.capacidade_carga || 0, 3),
        perc_ocupacao: formatDecimal(fretePlaca.perc_ocupacao || 0),
        perc_faturamento: formatDecimal(fretePlaca.perc_faturamento || 0),
      }));
  
      const fretesPorMotoristaMapper = response.fretes_por_motorista.map(freteMotorista => ({
        codigo_motorista: freteMotorista.codigo_motorista,
        nome_motorista: freteMotorista.nome_motorista,
        valor_faturamento: formatDecimal(freteMotorista.valor_faturamento || 0),
        peso_bruto: formatDecimal(freteMotorista.peso_bruto, 3 || 0),
        capacidade_carga: formatDecimal(freteMotorista.capacidade_carga, 3 || 0),
        perc_ocupacao: formatDecimal(freteMotorista.perc_ocupacao || 0),
        perc_faturamento: formatDecimal(freteMotorista.perc_faturamento || 0),
      }));
  
      const dataMapper = {
        valor_faturamento: formatDecimal(response.valor_faturamento || 0),
        peso_bruto: formatDecimal(response.peso_bruto || 0, 3), 
        capacidade_carga: formatDecimal(response.capacidade_carga || 0, 3), 
        perc_ocupacao: formatDecimal(response.perc_ocupacao || 0), 
        fretes_por_placa: fretesPorPlacaMapper,
        fretes_por_motorista: fretesPorMotoristaMapper 
      }

      const faturamentoPorGestoresMapper = dataFaturamentoGestores.response.map(faturamentoGestor => ({
        ...faturamentoGestor,
        valor_faturamento: Number(faturamentoGestor.valor_faturamento),
        perc_faturamento: Number(faturamentoGestor.perc_faturamento),
        valor_reentrega: Number(faturamentoGestor.valor_reentrega),
        valor_total_geral: Number(faturamentoGestor.valor_total_geral),
        valor_faturamento_formatado: formatDecimal(faturamentoGestor.valor_faturamento),
        perc_faturamento_formatado: formatDecimal(faturamentoGestor.perc_faturamento),
        valor_reentrega_formatado: formatDecimal(faturamentoGestor.valor_reentrega),
        valor_total_geral_formatado: formatDecimal(faturamentoGestor.valor_total_geral),
        detalhes: faturamentoGestor.detalhes.map(detalhe => ({
          ...detalhe,
          valor_faturamento: Number(detalhe.valor_faturamento),
          perc_faturamento: Number(detalhe.perc_faturamento),
          valor_reentrega: Number(detalhe.valor_reentrega),
          valor_total_geral: Number(detalhe.valor_total_geral),
          valor_faturamento_formatado: formatDecimal(Number(detalhe.valor_faturamento)),
          perc_faturamento_formatado: formatDecimal(Number(detalhe.perc_faturamento)),
          valor_reentrega_formatado: formatDecimal(Number(detalhe.valor_reentrega)),
          valor_total_geral_formatado: formatDecimal(Number(detalhe.valor_total_geral))
        }))
      }));

      setIsActiveCollapseMotorista(fretesPorMotoristaMapper.length === 0);
      setIsActiveCollapsePlaca(fretesPorPlacaMapper.length === 0);
      setDataDasdboard(dataMapper);
      setDataFaturamentoGestores(faturamentoPorGestoresMapper)

    } catch (error) {
      console.log(error.response);
    } finally {
      setIsLoading(false);  
    }
  }, [empresa.id]);

  useEffect(() => {
    getDataDashboard(periodo)
  }, [getDataDashboard, periodo]);

  useEffect(() => {
    async function getAnoPrimeiroFrete() {
      const {data} = await api.get(`/empresas/${empresa.id}/frota/anoPrimeiroFrete`)
      const anoPrimeiroFrete = data.response.ano;

      let anos = [];

      if(anoPrimeiroFrete){
        let ano = anoPrimeiroFrete;

        while (ano <= anoCorrente) {
          anos.push(ano);
          ano += 1;
        }
      };

      setOpcoesAno(anos);
    }

    getAnoPrimeiroFrete();
  }, [empresa.id]);

  return(
    <Container>
      <Loader isLoading={isLoading} />

      <TituloTela
        codigoTela="02.78.1416"
        nomeTela="Dashboard Frota"
      />

      <Row gutter={12}>
        <Col xs={10} md={8} lg={4}>
          <Select
            size={sizeFormComponent}
            style={{width: '100%'}}
            defaultValue={`${MESES_EXTENSO[mesCorrente]}`}
            onChange={handleChangeMes}
          >
            {MESES_EXTENSO.map((mes, index) => (
              <Select.Option
                key={mes}
                value={index}
                >
                {mes}
              </Select.Option>))}
          </Select>
        </Col>

        <Col xs={9} md={6} lg={3}>
          <Select
            size={sizeFormComponent}
            style={{width: '100%'}}
            defaultValue={`${anoCorrente}`}
            onChange={handleChangeAno}
          >
            {opcoesAno.map((ano) => (
              <Select.Option
                key={ano}
                value={ano}
              >
                {ano}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col xs={5} md={3} lg={4} xl={2} xxl={1}>
          <Button
            size={sizeFormComponent}
            style={{width: '100%'}}
            type="primary"
            icon={<SyncOutlined />}
            onClick={handleAtualuzarDados}
          />
        </Col>
      </Row>

      <Row gutter={[24, 16]} style={{marginTop: '12px'}}>
        <Col xs={24} sm={12} lg={12} xl={6}>
          <Card
            title='Faturamento'
            value={dataDashboard.valor_faturamento}
            icon={<BiDollar />}
            color='green'
          />
        </Col> 

        <Col xs={24} sm={12} lg={12} xl={6}>
          <Card
            title='Peso Bruto'
            value={dataDashboard.peso_bruto}
            icon={<FaBalanceScale />} 
            color='blue'
          />
        </Col>   

        <Col xs={24} sm={12} lg={12} xl={6}>
          <Card
            title='Capacidade'
            value={dataDashboard.capacidade_carga}
            icon={<FiTruck />}
            color= 'purple'
          />
        </Col>

        <Col xs={24} sm={12} lg={12} xl={6}>
          <Card
            title='Percentual Capacidade'
            value={dataDashboard.perc_ocupacao}
            icon={<PercentageOutlined/>}
            color={'red'}
          />
        </Col> 
      </Row>

      <Row>
        <Col span={24}>
          <GrupoCards
            titulo="Faturamento por Gestor"
          >
            <TableExpanding 
              columns={columnsFaturmamentoGestores}
              dataSource={dataFaturamentoGestores}
              detailsColumns={columnsDetalhesFaturmamentoGestor}
            />
          </GrupoCards>
        </Col>
      </Row>      

      <Row>
        <Col span={24}>
          <Collapse
            activeKey={isActiveCollapsePlaca ? 'P1' : '0'} 
            onChange={() => handleChangeCollapsePlaca()}>
            <Collapse.Panel
              key="P1"
              header={<TituloCollapse>Frete por Placa</TituloCollapse>}
            >
              {!hasFretesPorPlaca && 
                <Empty 
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description= 'Não foram encontrados registros para o período selecionado.' 
                />
              }

              {hasFretesPorPlaca && 
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <th>Placa</th>
                        <th>Modelo</th>
                        <th>Marca</th>
                        <th style={{textAlign: 'right'}} >Ano Fab.</th>
                        <th style={{textAlign: 'right'}} >Ano/Modelo</th>
                        <th style={{textAlign: 'right'}} >Peso Bruto</th>
                        <th style={{textAlign: 'right'}} >Capacidade Carga</th>
                        <th style={{textAlign: 'right'}} >Perc. Ocupação</th>
                        <th style={{textAlign: 'right'}} >Valor Faturamento</th>
                        <th style={{textAlign: 'right'}} >Perc. Faturamento</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                    {dataDashboard.fretes_por_placa.map(frete => (
                      <tr key={frete.placa}>
                        <td data-label="Placa">
                          { activeScreens.md 
                            ? frete.placa
                            : <Tag  
                                style={{margin: 0, fontSize: 14}}
                                color="#108ee9"
                              >
                                {frete.placa}
                              </Tag>
                          }
                        </td>
                        <td data-label="Modelo">{frete.modelo}</td>
                        <td data-label="Marca">{frete.marca}</td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Ano Fab."
                        >
                          {frete.ano_fab}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Ano/Modelo"
                        >
                          {frete.ano_modelo}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Peso Bruto"
                        >
                          {frete.peso_bruto || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Capacidade Carga"
                        >
                          {frete.capacidade_carga || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Perc. Ocupação"
                        >
                          {frete.perc_ocupacao || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Valor Faturamento"
                        >
                          {frete.valor_faturamento || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Perc. Faturamento"
                        >
                          {frete.perc_faturamento || 0}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </TableContainer>            
              }
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>

      <Row style={{marginTop: 16}}>
        <Col span={24}>
          <Collapse
            activeKey={isActiveCollapseMotorista ? 'P1' : '0'} 
            onChange={() => handleChangeCollapseMotorista()}>
            <Collapse.Panel
              key="P1"
              header={<TituloCollapse>Frete por Motorista</TituloCollapse>}
            >
              {!hasFretesPorMotorista && 
                <Empty 
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description= 'Não foram encontrados registros para o período selecionado.' 
                />
              }

              {hasFretesPorMotorista &&  
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <th style={{textAlign: 'right'}}>Código</th>
                        <th>Nome</th>
                        <th style={{textAlign: 'right'}}>Peso Bruto</th>
                        <th style={{textAlign: 'right'}}>Capacidade Carga</th>
                        <th style={{textAlign: 'right'}}>Perc. Ocupação</th>
                        <th style={{textAlign: 'right'}}>Valor Faturamento</th>
                        <th style={{textAlign: 'right'}}>Perc. Faturamento</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                    {dataDashboard.fretes_por_motorista.map(motorista => (
                      <tr key={motorista.codigo_motorista}>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Código"
                        >
                          { activeScreens.md 
                            ? motorista.codigo_motorista
                            : <Tag  
                                style={{margin: 0, fontSize: 14}}
                                color="#108ee9"
                              >
                                {motorista.codigo_motorista}
                              </Tag>
                          }                        
                        </td>
                        <td data-label="Nome">{motorista.nome_motorista}</td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Peso Bruto"
                        >
                          {motorista.peso_bruto || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Capacidade Carga"
                        >
                          {motorista.capacidade_carga || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Perc. Ocupação"
                        >
                          {motorista.perc_ocupacao || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Valor Faturamento"
                        >
                          {motorista.valor_faturamento || 0}
                        </td>
                        <td 
                          style={{textAlign: 'right'}}
                          data-label="Perc. Faturamento"
                        >
                          {motorista.perc_faturamento || 0}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </TableContainer>
              }
            </Collapse.Panel>
          </Collapse>  
        </Col>
      </Row>

      <BackTop>
        <Button 
          type="primary" 
          shape='circle'
          size={sizeFormComponent}
          icon={<ArrowUpOutlined />}
        />
      </BackTop>
      </Container>
  )
}