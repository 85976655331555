import styled, { css } from "styled-components";

import { Link } from "react-router-dom";

const wrapperIconVariants = {
  total: css`
    background:  rgba(59, 130, 246, 0.2);

    svg {
      path {
        fill: #3B82F6;
      }
    }
  `,
  locado: css`
    background:  rgba(255, 181, 119, 0.2);

    svg {
      path {
        fill: #FFB577;
      }
    }
  `,
  estoque: css`
    background:  rgba(0, 144, 69, 0.2);

    svg {
      path {
        fill: #009045;
      }
    }
  `,
  manutencao: css`
    background:  rgba(239, 68, 68, 0.2);

    svg {
      path {
        fill: #EF4444;
      }
    }
  `,
  }

const valueContinerVariants = {
  total: css`
    color: #3B82F6;  
  `,
  locado: css`
    color: #FFB577;  
  `,
  estoque: css`
    color: #009045;  
  `,
  manutencao: css`
    color: #EF4444;  
  `,  
}

export const Container = styled.div`
  display: flex;
  padding: 40px 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 440px;
  background: #ffffff;
`;
export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
  border-radius: 12px;
  margin-right: 20px;
  ${({ type }) => wrapperIconVariants[type] || 'total'}
`;
export const WrapperInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TitleCard = styled.strong`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #A3A3A3;
`;

export const ValueContiner = styled.div`
  display: flex;
  align-items: baseline;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    ${({ type }) => valueContinerVariants[type] || 'total'}
  }
`;

export const LinkAmount = styled(Link)`
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: #000000;
  margin-right: 70px;
`;

