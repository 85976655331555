import React, { useCallback, useEffect, useMemo, useState } from "react"
import { DataTable } from "../../../../../components/Table/DataTable"
import { Drawer, notification, Spin } from "antd"
import moment from 'moment';

import DataTableColumnHeader from "../DataTableColumnHeader"
import { Container, Title } from "./styles"
import { sortByTime } from "../helper"
import { formatDateBrazil } from "../../../../../utils/format-date-brazil"
import { currency } from "../../../../../utils/currency"

import { IoMdCheckbox } from "react-icons/io";
import { useAuth } from "../../../../../hooks/useAuth";
import api from "../../../../../service/api";
import TituloTela from "../../../../../components/TituloTela";


const detalhesApontamentoColumns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Nº OP" />,
    title: "Nº OP",
    accessorKey: 'nr_ordem_producao',
    cell: ({ row }) => row.original.nr_ordem_producao,
    widthColumn: 50,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Nº OS" />,
    title: "Nº OS",
    accessorKey: 'nr_ordem_servico',
    cell: ({ row }) => row.original.nr_ordem_servico,
    widthColumn: 50,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Início" />,
    title: "Início",
    accessorKey: "dt_hr_inicio",
    cell: ({ row }) => {
      return formatDateBrazil(row.original.dt_hr_inicio)
    },
    widthColumn: 200,
    sortingFn: 'datetime'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Fim" />,
    title: "Fim",
    accessorKey: "dt_hr_fim",
    cell: ({ row }) => {
      return formatDateBrazil(row.original.dt_hr_fim)
    },
    widthColumn: 200,
    sortingFn: 'datetime'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Executado" />,
    title: "Executado",
    cell: ({ row }) => row.original.qtd_hr_executado,
    accessorKey: "qtd_hr_executado",
    widthColumn: 50,
    align: 'right',
    sortingFn: sortByTime
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Produto/Serviço" />,
    title: "Produto/Serviço",
    accessorKey: 'desc_produto',
    cell: ({ row }) => row.original.desc_produto,
    widthColumn: 300,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Processo" />,
    title: "Processo",
    accessorKey: 'desc_processo',
    cell: ({ row }) => row.original.desc_processo,
    widthColumn: 200,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Máquina" />,
    title: "Máquina",
    accessorKey: 'desc_maquina',
    cell: ({ row }) => row.original.desc_maquina,
    widthColumn: 200,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tipo Apontamento" />,
    title: "Tipo Apontamento",
    accessorKey: 'desc_tipo_apontamento',
    cell: ({ row }) => row.original.desc_tipo_apontamento,
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Cancelado" />,
    title: "Cancelado",
    accessorKey: 'cancelado',
    cell: ({ row }) => row.original.cancelado === "S"
      ? <IoMdCheckbox size={16} color="#52c41a" />
      : "Não",
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Finalizado" />,
    title: "Finalizado",
    accessorKey: 'finalizou_processo',
    cell: ({ row }) => row.original.finalizou_processo === "S"
      ? <IoMdCheckbox size={16} color="#52c41a" />
      : "Não",
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Qtd. Produzida" />,
    title: "Qtd. Produzida",
    accessorKey: 'qtdade_produziu',
    cell: ({ row }) => Number.parseInt(row.original.qtdade_produziu),
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Qtd. Perdida" />,
    title: "Qtd. Perdida",
    accessorKey: 'qtdade_perdeu',
    cell: ({ row }) => Number.parseInt(row.original.qtdade_perdeu),
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Total" />,
    title: "Valor Total",
    cell: ({ row }) => currency.mask({ value: row.original.valor_total, currency: "BRL", locale: "pt-BR" }),
    accessorKey: "valor_total",
    widthColumn: 50,
    align: 'right',
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Horas cobradas na OS" />,
    title: "Horas cobradas na OS",
    cell: ({ row }) => row.original.qtd_hr_cobrada_os,
    accessorKey: "qtd_hr_cobrada_os",
    widthColumn: 50,
    align: 'right',
    sortingFn: sortByTime
  },
]

export const DetalhesApontamento = ({
  operador,
  produtoAcabado,
  isOpen,
  closeFn,
  timePeriod
}) => {

  const { id: idOperador, nome: nomeOperador } = operador || { id: null, name: null }
  const { id: idProdutoAcabado, nome: nomeProdutoAcabado } = produtoAcabado || { id: null, nome: null }

  const onClose = useCallback(() => {
    closeFn()
  }, [closeFn])

  const [fetchStatus, setFetchStatus] = useState('idle')
  const [data, setData] = useState([])
  const [isFirstQuery, setIsFirstQuery] = useState(true)

  const { empresa } = useAuth()

  const isLoading = useMemo(() => fetchStatus === 'fetching', [fetchStatus])

  const getData = useCallback(async () => {
    try {
      setFetchStatus('fetching')
      const { data } = await api.get(
        `/empresas/${empresa.id}/pcp/apontamento/operador/${idOperador}/produto-acabado/${idProdutoAcabado}/detalhes`,
        {
          params: {
            inicioPeriodo: new Date(timePeriod.from),
            finalPeriodo: new Date(timePeriod.to)
          }
        }
      )

      if (data.response.length === 0) {
        notification.open({
          message: 'Nenhum resultado encontrado',
          description: "Nenhum registro encontrado no período informado"
        });
      }

      setData(data.response)
    } catch (error) {
      notification.open({
        message: 'Erro!',
        description: "Ocorreu um erro ao realizar a busca"
      });
    } finally {
      setFetchStatus('idle')
    }
  }, [empresa.id, idOperador, idProdutoAcabado, timePeriod.from, timePeriod.to])



  useEffect(() => {
    if (fetchStatus === 'idle' && data.length === 0 && isFirstQuery && isOpen) {
      setIsFirstQuery(false)
      getData()
    }
  }, [data, fetchStatus, getData, isFirstQuery, isOpen])

  return (
    <Drawer 
      placement="right" 
      onClose={onClose} 
      visible={isOpen} 
      title={
        <TituloTela codigoTela="01.77.1476" nomeTela="Apontamentos por Operador por Produto Acabado - Detalhes" />
      }
      width={"75vw"} 
    >
      <Container>
        <Title>
          <h3 className="operator">{nomeOperador}</h3>
          <h4 className="product">{nomeProdutoAcabado}</h4>
        </Title>
        <Spin spinning={isLoading} >
          <DataTable
            columns={detalhesApontamentoColumns}
            dataSource={data}
          />
        </Spin>
      </Container>
    </Drawer>
  )
}