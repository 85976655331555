import React, { useContext, useEffect, useState } from 'react';
import { Container, Informacoes, Item, ContainerProdutos, InformacoesProduto, ContainerPrincipal, ContainerTabela } from './styles';
import { Col, Row } from 'antd';
import api from '../../../service/api';
import { Context } from '../../../Context/AuthContext';
import { formatDateBrazil } from '../../../utils/format-date-brazil';

function ApontamentosOrdemProducao({ opNumero }) {
    const [ordemProducao, setOrdemProducao] = useState([]);
    const { empresa } = useContext(Context);

    useEffect(() => {
        apontamentos();
    }, [empresa])

    async function apontamentos() {
        const { data } = await api.get(
            `/empresas/${empresa.id}/apontamento-ordem-producao/${opNumero}`,
        );

        const apontamento = data.response;
        setOrdemProducao(apontamento);
    }

    return (
        <>
            {ordemProducao.map((ordemProducao) => (
                <ContainerPrincipal>
                    <Col style={{ margin: '10px', }}>
                        <Container>
                            <Informacoes>
                                <div>
                                    <Item>
                                        <strong>Numero op:</strong>
                                        <span>{ordemProducao.nro_op}</span>
                                    </Item>
                                    <Item>
                                        <strong>Data abertura:</strong>
                                        <span>{formatDateBrazil(ordemProducao.data_abertura, 'dd/MM/yyyy HH:mm:ss')}</span>
                                    </Item>
                                    <Item>
                                        <strong>Tipo:</strong>
                                        <span>{ordemProducao.tipo}</span>
                                    </Item>
                                    <Item>
                                        <strong>Situação:</strong>
                                        <span>{ordemProducao.situacao}</span>
                                    </Item>
                                </div>
                                <div className='divisor' />
                                <div>
                                    <Item>
                                        <strong>Codigo cliente:</strong>
                                        <span>{ordemProducao.codigo_cliente}</span>
                                    </Item>
                                    <Item>
                                        <strong>Nome cliente:</strong>
                                        <span>{ordemProducao.nome_cliente}</span>
                                    </Item>
                                    <Item>
                                        <strong>Numero Pedido:</strong>
                                        <span>{ordemProducao.numero_pedido}</span>
                                    </Item>
                                    <Item>
                                        <strong>Programada:</strong>
                                        <span>{ordemProducao.programada}</span>
                                    </Item>
                                </div>
                            </Informacoes>
                        </Container>
                        <ContainerProdutos>
                            <Col style={{ margin: '4px', overflowX: 'hidden' }}>
                                <ContainerTabela>
                                    <div>
                                        <table className="customers">
                                            <tr>
                                                <th>Código do produto</th>
                                                <th>Produto</th>
                                                <th>Unidade de medida</th>
                                                <th>Quantidade</th>
                                            </tr>
                                            <tr>
                                                <td>{ordemProducao.codigo_produto}</td>
                                                <td>{ordemProducao.desc_produto}</td>
                                                <td>{ordemProducao.unid_med}</td>
                                                <td>{ordemProducao.qtdade}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </ContainerTabela>
                            </Col>
                        </ContainerProdutos>
                    </Col>
                    <Col style={{ margin: '10px', overflowX: 'hidden' }}>
                        <ContainerTabela>
                            <div>
                                <table className="customers" >
                                    <tr>
                                        <th>ID recurso</th>
                                        <th>Recurso</th>
                                        <th>ID processos</th>
                                        <th>Processos</th>
                                        <th>ID operador</th>
                                        <th>Operador</th>
                                        <th>Quantidade produzida</th>
                                        <th>Data/Hora inicio</th>
                                        <th>Data/Hora final</th>
                                        <th>Tempo previsto</th>
                                        <th>Tempo real</th>
                                        <th>Tempo previsto real</th>
                                    </tr>
                                    {ordemProducao.apontamentos.map((apontamento) => (
                                        <tr>
                                            <td>{apontamento.id_recurso}</td>
                                            <td>{apontamento.desc_recurso}</td>
                                            <td>{apontamento.id_processo}</td>
                                            <td>{apontamento.desc_processo}</td>
                                            <td>{apontamento.id_operador}</td>
                                            <td>{apontamento.desc_operador}</td>
                                            <td>{apontamento.qtdade_produziu}</td>
                                            <td>{formatDateBrazil(apontamento.dt_hr_inicio, 'dd/MM/yyyy HH:mm:ss')}</td>
                                            <td>{formatDateBrazil(apontamento.dt_hr_fim, 'dd/MM/yyyy HH:mm:ss')}</td>
                                            <td>{apontamento.tempo_previsto}</td>
                                            <td>{apontamento.tempo_real}</td>
                                            <td>{apontamento.dif_tempo_previsto_real}</td>

                                        </tr>
                                    ))}
                                </table>
                            </div>

                        </ContainerTabela>
                    </Col>
                </ContainerPrincipal>
            ))}
        </>
    )
}

export default ApontamentosOrdemProducao;