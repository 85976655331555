import React, { useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../service/api';

import { Card, Divider, Empty, Space, Tag, Typography } from 'antd';
import { DataTable } from '../../../../components/Table/DataTable';
import { DataTableColumnHeader } from '../../../../components/Table/DataTableColumnHeader';

import Loading from '../Loading';

import { Container } from './styles';

import { Context } from '../../../../Context/AuthContext';
import { formatDecimal } from '../../../../utils/format-decimal';
import { formatDateBrazil } from '../../../../utils/format-date-brazil';

export default function MediaVeiculoDetalhes({veiculo, periodo}) {
  const [loading, setLoading] = useState(true);
  const [medias, setMedias] = useState([]);

  const { empresa } = useContext(Context);

  const showEmpty = !loading && (medias.length === 0);
  const showTable = !loading && (medias.length > 0);  

  const columns = useMemo(() => [
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
      accessorKey: "id_motorista",
      widthColumn: 100,
      align: 'right',
      cell: ({ row }) => ( 
        <Tag 
          style={{margin: 0, fontSize: 14}}
          color="#108ee9"
        >
          {row.getValue('id_motorista')}
        </Tag>
      ),
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nome" />,
      title: "Nome",
      accessorKey: "nome_motorista",
      widthColumn: 300,
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Frete" />,
      title: "Frete",
      accessorKey: "nro_ficha_acerto",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Emissão" />,
      cell: ({ row }) => row.original.data_emissao_formatada,
      title: "Emissão",
      accessorKey: "data_emissao",
      widthColumn: 130,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Km Inicial" />,
      title: "Km Inicial",
      accessorKey: "km_inicial",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Km Final" />,
      title: "Km Final",
      accessorKey: "km_final",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Total Km" />,
      title: "Total Km",
      accessorKey: "quantidade_km",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Litros Diesel" />,
      cell: ({ row }) => row.original.litros_diesel_formatada,
      title: "Litros Diesel",
      accessorKey: "litros_diesel",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel " />,
      cell: ({ row }) => row.original.media_diesel_formatada,
      title: "Média Diesel ",
      accessorKey: "media_diesel",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Litros Arla" />,
      cell: ({ row }) => row.original.litros_arla_formatada,
      title: "Litros Arla",
      accessorKey: "litros_arla",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Arla" />,
      cell: ({ row }) => row.original.media_arla_formatada,
      title: "Média Arla",
      accessorKey: "media_arla",
      widthColumn: 100,
      align: 'right',
    },
  ], []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const placa = veiculo.getValue('placa');

        const { data } = await api.get(
          `/empresas/${empresa.id}/frota/combustiveis/veiculo/${placa}/media`,
          {
            params: periodo
          }
        );
  
        const dataMapper = data.response.map(media => ({
          ...media,
          data_emissao: media.data_emissao,
          litros_diesel: Number(media.litros_diesel),
          media_diesel: Number(media.media_diesel),
          litros_arla: Number(media.litros_arla),
          media_arla: Number(media.media_arla),
          data_emissao_formatada: formatDateBrazil(media.data_emissao, 'dd/MM/yyyy'),
          litros_diesel_formatada: formatDecimal(media.litros_diesel, 3),
          media_diesel_formatada: formatDecimal(media.media_diesel, 3),
          litros_arla_formatada: formatDecimal(media.litros_arla, 3),
          media_arla_formatada: formatDecimal(media.media_arla, 3),
        }));
        
        setMedias(dataMapper);        
      } finally {
        setLoading(false)
      }
    };

    loadData();    
  }, [empresa, veiculo, periodo]);

  console.log(medias, veiculo, periodo);

  return (
    <Container>
      <Card>
        <Space direction='vertical' size={0}>
          <Typography.Text>Veículo</Typography.Text>
          <Typography.Text strong>
            {`${veiculo?.getValue('placa')} -
              ${veiculo?.getValue('marca')} -
              ${veiculo?.getValue('modelo')} `}
          </Typography.Text>
        </Space>
      </Card>

      <Divider />

      <Loading isLoading={loading} />

      {showTable && 
        <DataTable 
          columns={columns}
          dataSource={medias}
        />
      }

      {showEmpty && <Empty />}
    </Container>
  );
}
