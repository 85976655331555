const preparaDadosCSV = ( columns, dataSource) => {
  const columnsFormated = {};
  const listColumns = [];
  const dataSourceFormated = [];
  
  columns.forEach(column => {
    if( column.isVisible ){
      listColumns.push(column.dataIndex)
      columnsFormated[column.dataIndex] = column.title;
    }
  });

  dataSource.forEach( row => {
    let newRow = {};
  
    listColumns.forEach( field => {
      newRow[field] = row[field] 
    });

    dataSourceFormated.push(newRow);
  });

  return({
    dataSourceFormated,
    columnsFormated
  });
}

export default preparaDadosCSV;