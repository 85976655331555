import React from 'react';

import { Container } from './styles';

export default function FormGroup({ children, error, label, minWidth }) {
  return (
    <Container minWidth={minWidth}>
      <label>{label}</label>
      { children }
      {error && <small>{error}</small>}
    </Container>
  );
}