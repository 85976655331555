import React from 'react';

import { Container } from './styles';
import SadEmoji from '../SadEmoji';

function ErrorState() {
  return <Container>
    <SadEmoji />
      <div className='textContainer'>
        <h1>Ocorreu um erro!</h1>
        <p>Não conseguimos identificar a transportadora/NF-e</p>
        <p>Verifique se a URL está correta. É por meio dela que conseguimos fazer as buscas</p>
      </div>
  </Container>;
}

export default ErrorState;