import React, { useState } from 'react';
import { Button } from 'antd';
import { Container, Cabecalho, Informacoes, Item, ButtonStyled, ButtonStyledOcupado } from './styles';
import InformacaoOp from '../InformacaoOp';

import { MdTimer } from 'react-icons/md';

import Modal from '../../../../components/Modal';
import FormModal from '../../../../components/FormModal';
import OcupacaoRecurso from '../../../../components/OcupacaoRecurso';
import { formatDateBrazil } from '../../../../utils/format-date-brazil'

function CardRecurso({ recurso }) {
  const [tipoModalRecurso, setTipoModalRecurso] = useState(null);
  const [tipoModalGraficoGantt, setTipoModalGraficoGantt] = useState(false);

  // referencia a numero da OP
  function onCloseModal() {
    setTipoModalRecurso(null);
  }

  function handlerClickModalRecurso(numeroOp) {
    setTipoModalRecurso(numeroOp)
  }

  // referencia a data ocupada e sobre o modal de gantt
  function onCloseModalGraficoGantt() {
    setTipoModalGraficoGantt(null);
  }

  function handlerClickModalGraficoGantt(data) {
    setTipoModalGraficoGantt(data)
  }

  const title = {
    codigoTela: '01.77.1339',
    nomeTela: 'Detalhes ordem produção',
    tema: 'white',
    fontSize: 'medium',
    paddingBottom: 'without',
  }

  const titleProgramacao = {
    codigoTela: '01.77.1366',
    nomeTela: 'Gráfico de ocupação de recursos',
    tema: 'white',
    fontSize: 'medium',
    paddingBottom: 'without',
  }
  return (
    <>
      {tipoModalRecurso &&
        <Modal openCloseModal={onCloseModal} >
          <FormModal
            title={title}
            close={onCloseModal}
          >
            <InformacaoOp numeroOp={tipoModalRecurso} />
          </FormModal>
        </Modal>
      }
      {tipoModalGraficoGantt &&
        <Modal openCloseModal={onCloseModalGraficoGantt} >
          <FormModal
            title={titleProgramacao}
            close={onCloseModalGraficoGantt}
          >
            <OcupacaoRecurso recursoId={recurso.id_recurso} />
          </FormModal>
        </Modal>
      }
      <Container situacao={recurso.codigo_situacao_recurso}>
        <Cabecalho>
          <h3>{`#${recurso.id_recurso} - ${recurso.desc_recurso}`}</h3>
        </Cabecalho>
        <Informacoes>
          <div>
            <Item>
              <strong>Terceirizado:</strong>
              <span>{recurso.terceirizado}</span>
            </Item>
            <Item>
              <strong>Operador:</strong>
              <span>{recurso.nome_operador}</span>
            </Item>
            <Item>
              <strong>Ocupado até:</strong>
              <ButtonStyledOcupado
                recursoId={recurso.data_ocupado !== null}
                onClick={recurso.data_ocupado !== null ? () => handlerClickModalGraficoGantt(true) : null}
              >
                <span>{recurso.data_ocupado === null ? '--' : formatDateBrazil(recurso.data_ocupado, 'dd/MM/yyyy - kk:mm')}</span>
              </ButtonStyledOcupado>
            </Item>
          </div>
          <div className='divisor' />
          <div>
            <Item>
              <strong>OP:</strong>
              <ButtonStyled
                nrOp={Number(recurso.nro_op) > 0}
                onClick={Number(recurso.nro_op) > 0 ? () => handlerClickModalRecurso(recurso.nro_op) : null}
              >
                {
                  Number(recurso.nro_op) > 0 ? <span style={{ color: '#3CB371', padding: '0 4px' }}>{recurso.nro_op}</span> : <span style={{ color: '#f44236', padding: '0 4px' }}>{recurso.nro_op}</span>
                }
              </ButtonStyled>
            </Item>
            <Item>
              <strong>Processo:</strong>
              <span>{recurso.processo}</span>
            </Item>
            <Item>
              <strong>Situação:</strong>
              <span>{recurso.situacao_recurso}</span>
            </Item>
            <Item>
              <strong>Tempo:</strong>
              <span className='timer'> <MdTimer />{recurso.tempo_na_situacao}</span>
            </Item>
          </div>
        </Informacoes>
      </Container>
    </>
  );
}

export default CardRecurso;