import styled from 'styled-components';

export const ContainerFiltro = styled.div`
  margin-bottom: 16px;
  border-radius: 3px;
  background: #fff;

  span {
    align-items: center;
    padding: 0 0 0 4px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .boxFilter {
    display: flex;
    flex-direction: row;

    @media (min-width: 320px) and (max-width: 767px){
      flex-direction: column;
    }
  }
`;

export const ContainerLabels = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  border-radius: 3px;
  background: #fff;
  font-weight: bold;

  div {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    border-radius: 3px;
    background: #fff;
    font-weight: bold;
    color: black;
    font-size: 16px;

    @media (min-width: 320px) and (max-width: 760px){
      display: flex;
      flex-direction: column;
    }
  }

  div:nth-child(1) {
    padding: 0 1.25rem 0 0.5rem;
  }

  div:nth-child(2) {
    padding: 0 1.25rem 0 0.5rem;
  }

  div:nth-child(3) {
    padding: 0 1.25rem 0 0.5rem;
  }

  div:nth-child(4) {
    padding: 0 1.25rem 0 0.5rem;
  }

  span {
    padding: 0 0 0 5px;
    align-items: center;
  }

 
`;

export const ButtonStyled = styled.button`
  width: 100%;
  height: auto;
  padding: 2px;
  margin: 1px solid transparent;
  text-align: left;
  background-color: #1e88e5;
  color: #FFFFFF;
  outline: none;
`;

