import React from 'react';

import ImageEmptyState from './ImageEmptyState';
import { Container } from './styles';

function EmptyState({content}) {
  return ( 
    <Container>
      <ImageEmptyState /> 
      <p>Nota fiscal <strong>“{content}”</strong> não encontrada.</p>
    </Container> );
}

export default EmptyState;