import styled from "styled-components";

export  const Container = styled.div`
    width: 100%;
    height: 100%;
`

export const CardContainer = styled.button`
  width: 100%;
  height: 100%;
  padding: 0;
  background: transparent;
  
  cursor: pointer;
`;

export const ButtonTag = styled.button`
  width: 100%;
  height: 100%;
  text-align: right;
  padding: 0;
  background: transparent;
  
  cursor: pointer;
`;

export const TableContainer = styled.div`
  position: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 600px;
  min-height: 200px;
`;

export const Table = styled.table`
  width: 100%;
  height: 100%;

  thead {
    background-color: rgba(249, 250, 251, 1);
    position: sticky;
    top: 0;
    left: 0;
    
    th {
      text-transform: uppercase;
      padding: 0.75rem 1.25rem;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      color: #6B7280;
      letter-spacing: 0.05rem;
      text-align: left;
      white-space: nowrap;
    }
  }

  tbody {
    max-height: 358px;
    width: 100%;
    background-color: #fff;
    
    tr {
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      transition: background 0.3s ease-in;
      
      &:hover {
        background: #F9FAFB;
      }

      td {
        padding: 1rem 1.25rem;
        font-size: 0.785rem;
        font-weight: 100.25rem;
        color: #9CA3AF;
      }
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 80px;  
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: auto;
    min-width: 250px; 
    white-space: break-word; 
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;  
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;  
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;  
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;  
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 150px;  
  }
     
`;