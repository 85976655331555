import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  /* box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15); */
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: .35rem;
  margin-bottom: 16px;  

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
    border-radius: calc(.35rem - 1px) calc(.35rem - 1px) 0 0;
    
    > h6 {
      margin: 0;
      font-size: 1rem;
      font-weight: 700;
      color: #5a5c69;
    }
  }

  > div:nth-child(2) {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
`;
