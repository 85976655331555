import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: #A3A3A3;
  }
`;