/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../../Context/AuthContext";
import api from "../../../service/api";

import { Tag } from "../EquipamentosSituacao/styles";
import GrupoLabel from "./GrupoLabel";
import Movimentacao from "./Movimentacao";


import { 
  Container,
  WrapperInfo,
  WrapperInfoEquipamento,
  WrapperLocacao,
  WrapperMovimentacaoes,
  ListaMovimentacoes,
 } from './styles'

function EquipamentoDetalhes({ idEquipamento, onClickBack }) {
  const [equipamento, setEquipamento] = useState(null);
  const { empresa } = useContext(Context);

  useEffect(() => {
    async function getDadosEquipamento() {
      const { data } = await api.get(`empresas/${empresa.id}/faturamento/locacao_equipamentos/equipamento/${idEquipamento}`)

      setEquipamento(data.result)
    }

    getDadosEquipamento();
  }, []);
  
  return (
    <Container>
      <h3>Detalhes Equipamento</h3>

      {equipamento 
      ? (
        <>
          <WrapperInfo>
            <WrapperInfoEquipamento>
              <GrupoLabel label='Equipamento'>
                {equipamento.id_equipamento} - {equipamento.desc_equipamento} 
              </GrupoLabel>
              <GrupoLabel label='Lacre'>
              {equipamento.nro_lacre}  
              </GrupoLabel>
              <GrupoLabel label='Situação'>
                <Tag type={equipamento.id_situacao}>
                  {equipamento.desc_situacao}  
                </Tag>  
              </GrupoLabel>
            </WrapperInfoEquipamento>

            {equipamento.id_contrato && (
              <WrapperLocacao>
                <GrupoLabel label='Contrato'>
                  {equipamento.id_contrato}
                </GrupoLabel>
                
                <GrupoLabel label='Remessa'>
                  {equipamento.id_remessa}  
                </GrupoLabel>

                <GrupoLabel label='Cliente'>
                  {equipamento.id_cliente} - {equipamento.desc_cliente}  
                </GrupoLabel>
                
                <GrupoLabel label='Obra'>
                  {equipamento.id_obra} - {equipamento.desc_obra}  
                </GrupoLabel>
              </WrapperLocacao>
            )}
          </WrapperInfo>

          <WrapperMovimentacaoes>
            <h3>Movimentações</h3>
            <ListaMovimentacoes>
              {equipamento.movimentos.map((movimento => (
                <Movimentacao
                  key={movimento.dt_hr_movimento}
                  movimento={movimento} />
              )))}
            </ListaMovimentacoes>
          </WrapperMovimentacaoes>
        </>
      )
      : (
        <div>Carregando dados</div>
      )}
      
    </Container>
  )
}

export default EquipamentoDetalhes;