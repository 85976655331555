import React from 'react';
import { Header, Container, Body, ButtonCloseContainer } from './styles';
import { IoCloseOutline } from "react-icons/io5";

import TituloTela from '../TituloTela'



function FormModal({ title, close, children }) {

  return (
    <Container>
      <Header>
        <TituloTela
          codigoTela={title.codigoTela}
          nomeTela={title.nomeTela}
          tema={title.tema}
          fontSize={title.fontSize}
          paddingBottom={title.paddingBottom} />

        <ButtonCloseContainer onClick={close}>
          <IoCloseOutline />
        </ButtonCloseContainer>
      </Header>
      <Body>
        {children}
      </Body>
    </Container>
  );
}

export default FormModal;