import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  background: #fff; 
  overflow-x: auto;
  flex: 1;
  font-family: 'Roboto';
  padding-top: 10px;

  .tabela {
    border-collapse: collapse;
    width: 100%;
  }

  .tabela td, #tabela th {
    padding: 8px 12px 8px 0;
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    color: #000;

    th:nth-child(4){
        text-align: right;
    }
  }


  .tabela th {
    padding-bottom: 0;
    text-align: left;
    font-size: 12px;
    color: #000;
    font-weight: 300;
    border-bottom: solid 4px rgba(0, 0, 0, 0.12);
    text-align: right;

    
  }

  th:nth-child(1){
      text-align: left;
  }

  td:nth-child(2){
      text-align: right;
      padding: 0;
  }

  td:nth-child(3){
      text-align: center;
      padding: 0;
  }

  td:nth-child(4){
      text-align: right;
      padding: 0;
  }

  thead {
    padding: 0;
  }
    
`;