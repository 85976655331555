import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  width: 400px;

  h5 {
    position: relative;
    color: #fff;
    background-color: #1e88e5;
    border-radius: 4px 4px 0 0;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding: 12px 0;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  padding: 16px;
`;
