import React from 'react';

import { Container } from './styles.js';

function FormGrupoInput({label, erro, children}) {
  return (
    <Container>
      <label>{label}</label>
      <div>
        {children}
      </div>
      
      <small> {erro && erro} </small>
    </Container>
  );
};


export default FormGrupoInput;