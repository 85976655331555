import React, { useCallback, useState } from 'react';

import { useReactTable,
         getCoreRowModel,
         getSortedRowModel,
         flexRender,
         getPaginationRowModel,
         getFilteredRowModel,
         filterFns } from '@tanstack/react-table';

import { Empty } from 'antd';

import { DataTableToolbar } from '../DataTableToolbar';
import { DataTablePagination } from '../DataTablePagination';

import { ContainerTable,
         Table,
         TableHeader,
         TableHeaderRow,
         TableBody,
         TableBodyRow,
         TableHead,
         TableCell,
         } from '../table';

import { Container, WrapperTable } from './styles';

const defaultValueTableConfig = {
  initialColumnOrdering: [],
  columnPinning: [],
  onClickRow: null,
}

export function DataTable({ 
  columns=[],
  dataSource=[],
  tableConfig: tableConfigValues}) {

  const tableConfig = {
    ...defaultValueTableConfig,
    ...tableConfigValues,
  };

  const [sorting, setSorting] = useState(tableConfig.initialColumnOrdering);
  const [columnPinning, setColumnPinning] = useState(tableConfig.columnPinning);
  const [globalFilter, setGlobalFilter] = useState(''); 
  const [tableStructure, setTableStructure] = useState('list'); 

  const table = useReactTable( { 
    columns, 
    data: dataSource,
    state: {
      sorting,
      columnPinning,
      globalFilter
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: filterFns.includesString
  } );

  const handleClearGlobalFilter = useCallback(() => {
    setGlobalFilter('');
  }, []);

  const handleChangeGlobalFilter = useCallback( (event) => {
    setGlobalFilter(event.target.value);
  }, []);

  const handleChangeTableStructure = useCallback( (value) => {
    setTableStructure(value);
  }, []);

  const isBlockStructureTable = tableStructure === 'card';

  return (
    <Container>
      <DataTableToolbar
        onChangeGlobalFilter={handleChangeGlobalFilter}
        onClearGlobalFilter={handleClearGlobalFilter}
        onChangeTableStructure={handleChangeTableStructure}
        tableStructure={tableStructure}
        globalFilter={globalFilter}
      />

      <WrapperTable>
        <ContainerTable>
          <Table
            isBlockStructureTable={isBlockStructureTable}
          >
            <TableHeader
              isBlockStructureTable={isBlockStructureTable}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableHeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, _index) => {
                    return(
                      <TableHead
                        key={header.id}
                        pinning={header.column.getIsPinned()}
                        isBlockStructureTable={isBlockStructureTable}
                        align={header.column.columnDef.align}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </TableHead>
                    );
                  })}
                </TableHeaderRow>
              ))}
            </TableHeader>     

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableBodyRow
                    key={row.id}
                    isBlockStructureTable={isBlockStructureTable}
                    onClick={tableConfig.onClickRow ? () => tableConfig.onClickRow(row) : null}
                    cursorPointer={tableConfig.onClickRow ? true : null}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell 
                          key={cell.id}
                          isBlockStructureTable={isBlockStructureTable}
                          pinning={cell.column.getIsPinned()}
                          data-label={cell.column.columnDef.title}
                          align={cell.column.columnDef.align}
                          width={cell.column.columnDef.widthColumn}
                        >
                          {flexRender(
                            cell.column.columnDef.cell, 
                            cell.getContext())}
                        </TableCell>
                      )
                    })}
                  </TableBodyRow>
                ))
              ) : (
                <TableBodyRow>
                  <TableCell colSpan={columns.length}>
                    <Empty />
                  </TableCell>
                </TableBodyRow>
              )}
            </TableBody>
          </Table>
        </ContainerTable>
      </WrapperTable>

      <DataTablePagination dataTable={table} />
    </Container>
  );
}

