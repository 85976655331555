import styled from 'styled-components';

import { Table } from 'antd';

const TabelaResponsiva = styled(Table)`
  @media only screen and (max-width: 480px){
    .ant-table > .ant-table-container {
      table, thead, tbody, th, td, tr  {
        
      background-color: #fff;
      font-weight: 600;
      font-size: 10px;
      }
  }
} 
`;

export default TabelaResponsiva;