import React, { useEffect, useState } from 'react';

import { formatDecimal } from '../../../../../utils/format-decimal';
import api from '../../../../../service/api';

import { useAuth } from '../../../../../hooks/useAuth';
import { Divider, Drawer, Space, Typography, Grid } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import Loading from '../../../../frota/MediaCombustiveis/Loading';
import TituloTela from '../../../../../components/TituloTela';
import { DataTable } from '../../../../../components/Table/DataTable';

import { Container, WrapperHeaderDrawer, Card, Box } from './styles';

import { columns } from './table-columns';
import { round } from '../../../../../utils/round';

const { useBreakpoint } = Grid;

export default function MediaMotoristaDetalhes({veiculo, periodo, onCloseDrawer}) {
  const [loading, setLoading] = useState(true);
  const [contas, setContas] = useState([]);

  const { empresa } = useAuth();

  const screens = useBreakpoint();

  const widthDrawer = screens['lg'] ? 60 : 90;
   
  useEffect(() => {
    async function loadData() {
      try {
        if(!veiculo){
          return
        }

        setLoading(true);
      
        const placa = veiculo.getValue('placa');

        const { data } = await api.get(
          `/empresas/${empresa.id}/intercarg/financeiro/dre/veiculo/${placa}/detalhes`,
          {
            params: periodo
          }
        );

        const dataMapper = data.response.map(conta => {
          const percentual_valor =  round(Number(conta.valor) / veiculo.getValue('despesas_custos') * 100);

          return {
            ...conta,
            valor: Number(conta.valor),
            valor_formatado: formatDecimal(conta.valor), 
            parcentual: percentual_valor,
            parcentual_formatado: formatDecimal(percentual_valor), 
          }
        });

        setContas(dataMapper)
      }finally{
        setLoading(false);
      }
    };
  
    loadData();        
  }, [empresa, veiculo, periodo]);

  return (
    <Drawer
      title={
        <Box>
          <WrapperHeaderDrawer>
            <TituloTela 
              codigoTela='02.74.1430'
              nomeTela='DRE Veículo Detalhes'
              paddingBottom='without'
            /> 
            <CloseOutlined onClick={() => onCloseDrawer()} />
          </WrapperHeaderDrawer>
        </Box>}
      width={`${widthDrawer}%`} 
      placement="right"
      onClose={() => onCloseDrawer()}
      visible={veiculo ? true : false}
      bodyStyle={{padding: 0}}
      headerStyle={{padding: 0}}
      closable={false}
    >
      <Box>
        <Container>
          <Card>
            <Space direction='vertical' size={0}>
              <Typography.Text>Veículo</Typography.Text>
              <Typography.Text strong>
                {`${veiculo?.getValue('placa')} -
                  ${veiculo?.getValue('marca')} - 
                  ${veiculo?.getValue('modelo')}`}
              </Typography.Text>
            </Space>
            <Space direction='vertical' size={0}>
              <Typography.Text>Total Custos</Typography.Text>
              <Typography.Text strong>
                {`R$ ${veiculo?.original?.despesas_custos_formatado}`}
              </Typography.Text>
            </Space>
          </Card>

          <Divider />

          <Loading isLoading={loading} />

          {!loading && <DataTable 
                          columns={columns}
                          dataSource={contas}
                        />}
        </Container>        
      </Box>
    </Drawer>      
  );
}
