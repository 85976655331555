/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';

import { Layout, Menu } from 'antd';

import { Link, useLocation, useHistory } from 'react-router-dom';
import { Context } from '../../Context/AuthContext';
import { ESTRUTURA_MENU } from '../../constants';
import { useAccess } from '../../Context/AccessContext'
import { Container } from './styles';
const { Sider } = Layout;
const { SubMenu, Item } = Menu;

function ItensMenu({ collapsed }) {
  const location = useLocation();
  const history = useHistory();
  const { hasAccess, hideItem, accessCodeRelease, acessCodesHide } = useAccess();
  const { empresa } = useContext(Context);

  const sideBar = useMemo(() => {
    let menu = [];

    ESTRUTURA_MENU.forEach(categoriaMenu => {
      let exibeCategoria = false;
      const linkURL = ((empresa.codigo_modulo === 2) && categoriaMenu.link_URL_intercarg) ? categoriaMenu.link_URL_intercarg : categoriaMenu.link_URL;
      const codigoAcesso = ((empresa.codigo_modulo === 2) && categoriaMenu.link_URL_intercarg) ? categoriaMenu.codigo_acesso_intercarg : categoriaMenu.codigo_acesso;

      if (!exibeCategoria) {
        exibeCategoria = hasAccess(codigoAcesso);
      }

      const categoriaMenuItem = {
        ...categoriaMenu,
        link_URL: linkURL,
        codigo_acesso: codigoAcesso,
        disabled: !hasAccess(codigoAcesso),
        itens_menu: []
      };

      categoriaMenu.itens_menu.forEach(itemMenu => {
        const linkURL = ((empresa.codigo_modulo === 2) && itemMenu.link_URL_intercarg) ? itemMenu.link_URL_intercarg : itemMenu.link_URL;
        const codigoAcesso = ((empresa.codigo_modulo === 2) && itemMenu?.link_URL_intercarg) ? itemMenu.codigo_acesso_intercarg : itemMenu.codigo_acesso;

        if(hideItem(codigoAcesso)){
          return;
        }
        
        if (!exibeCategoria) {
          exibeCategoria = hasAccess(codigoAcesso);
        }

        categoriaMenuItem.itens_menu.push({
          ...itemMenu,
          link_URL: linkURL,
          codigo_acesso: codigoAcesso,
          disabled: !hasAccess(codigoAcesso),
        });
      });

      if (exibeCategoria) {
        menu.push(categoriaMenuItem);
      }
    });

    return menu;

  }, [empresa, accessCodeRelease, acessCodesHide])

  function handleSubMenu(url, disabled) {
    if (!url || disabled) {
      return;
    }

    history.push(url);
  }

  return (
    <Container>
      <Sider
        theme='light'
        trigger={null}
        collapsed={collapsed}
        width={200}
      >
        <Menu
          theme='light'
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          inlineCollapsed={collapsed}
        >
          {sideBar?.map(menuCategoria => (
            menuCategoria.itens_menu.length > 0
              ?
              <SubMenu
                key={menuCategoria.link_URL}
                icon={menuCategoria.icon}
                title={menuCategoria.title}
                onTitleClick={() => handleSubMenu(menuCategoria.link_URL, menuCategoria.disabled)}
              >
                {menuCategoria.itens_menu.map(itens_menu_categoria => (
                  <Item
                    key={itens_menu_categoria.link_URL}
                    disabled={itens_menu_categoria.disabled}
                  >
                    {itens_menu_categoria.title}
                    <Link to={itens_menu_categoria.link_URL} />
                  </Item>
                )
                )}
              </SubMenu>
              :
              <Item
                key={menuCategoria.link_URL}
                icon={menuCategoria.icon}
                disabled={menuCategoria.disabled}
              >
                {menuCategoria.title}
                <Link to={menuCategoria.link_URL} />
              </Item>
          ))}
        </Menu>
      </Sider >
    </Container>
  );
}

export default ItensMenu;